import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/Button';
import Dropdown from '../../../components/Dropdown';
import DateField from '../../../components/input/date-field/DateField';
import MultiSelect from '../../../components/input/MultiSelect';
import SingleSelect from '../../../components/input/SingleSelect';
import { HSpacer, VSpacer } from '../../../components/layout/Spacer';
import { useAuthContext } from '../../../contexts/AuthContext';
import LoadingScreen from '../../../core/LoadingScreen';
import { CalendarWire, CheckedWhite, ChevronDownBlack, TrashBin } from '../../../icons';
import { DatalayerEventLabel } from '../../../models/Datalayer';
import { DelayAct } from '../../../models/DelayAct';
import { NotificationAct } from '../../../models/NotificationAct';
import { DatalayerService } from '../../../services/DatalayerService';
import NotificationActHttp from '../../../services/http/NotificationActHttp';
import { UserActHttp } from '../../../services/http/UserActHttp';
import { useMaterialSheetStore } from '../../../store/MaterialSheetStore';
import { TypedFieldValues } from '../MaterialSheetSchema';
import { HidePart, Link, LittleText, RappelText, ReminderSettingsStyle } from './ReminderSettingsStyle';

export interface ReminderSettingsProps {
  materialSheetId: number;
  /** The existing notification if it exists. `undefined` stands for "fetching notification". */
  existingNotification: NotificationAct | null | undefined;
  reminder: boolean;
  disabled: boolean;
  setReminder: (reminder: boolean) => void;
  setDefaultDelayIriValue: (defaultDelayIriValue: string) => void;
}

export function ReminderSettings(props: ReminderSettingsProps) {
  const { readOnly } = useAuthContext();

  const [linkEmployeeOpen, setLinkEmployeeOpen] = useState(false);
  const [linkTimeOpen, setLinkTimeOpen] = useState(false);
  const [linkDateNotif, setLinkDateNotif] = useState(false);
  const [delayOptions, setDelayOptions] = useState<{ label: string; value: string }[]>([]);
  const [receiversList, setReceiversList] = useState<{ label: string; value: string }[]>([]);
  const getFirstPlannedDate = useMaterialSheetStore((state) => state.getFirstPlannedDate);

  const { watch, setValue, formState } = useFormContext<TypedFieldValues>();
  const { errors } = formState;

  const watchDateNotif = watch('dateNotif');
  const watchDelay = watch('delay');
  const watchNotifyEmployee = watch('notifyEmployee');

  const { organizationId } = useParams<{ organizationId: string }>();

  useEffect(() => {
    if (organizationId !== undefined) {
      NotificationActHttp.getDelays({ isRemindableWithEmail: true }).then((data: DelayAct[]) => {
        const formattedDelays = data.map((delay) => {
          if (delay.name === '1 mois avant') {
            props.setDefaultDelayIriValue(delay.iri);
          }
          return {
            value: delay.iri,
            label: delay.name,
          };
        });
        setDelayOptions(formattedDelays);
      });
    }
  }, [organizationId]);

  useEffect(() => {
    if (organizationId !== undefined && (!receiversList || receiversList.length === 0)) {
      new UserActHttp()
        .getReceivers(organizationId, {
          pagination: 'false',
        })
        .then(({ data }) => {
          const formattedReceivers = data.map((receiver) => {
            return {
              value: receiver.iri,
              label: receiver.fullname,
            };
          });
          setReceiversList(formattedReceivers);
        });
    }
  }, [organizationId]);

  useEffect(() => {
    if (
      props.existingNotification !== undefined &&
      props.existingNotification !== null &&
      receiversList.length > 0 &&
      delayOptions.length > 0
    ) {
      setValue('dateNotif', dayjs(props.existingNotification.effectiveDate).toDate(), {
        shouldValidate: true,
      });
      setValue(
        'delay',
        delayOptions.find((delay) => delay.value === props.existingNotification?.delay.iri),
        { shouldValidate: false }
      );
      setValue(
        'notifyEmployee',
        receiversList.filter((receiver) => {
          return props.existingNotification?.receivers.map((r) => r.iri)?.includes(receiver.value);
        }),
        { shouldValidate: false }
      );
    }
  }, [receiversList, delayOptions, props.existingNotification]);

  const resetDate = () => {
    const date = getFirstPlannedDate();
    if (date) {
      setValue('dateNotif', date);
    }
  };

  useEffect(() => {
    // * We don't take planned date into account if there is an existing notification in database
    if (props.reminder === true && props.existingNotification == null) {
      resetDate();
    }
  }, [props.reminder]);

  return (
    <ReminderSettingsStyle className="ReminderSettings">
      {props.existingNotification !== undefined ? (
        <div className="reminder-block">
          <div className="button-wrapper">
            <Button
              variant={props.reminder ? 'success' : 'bigLight'}
              callback={() => {
                if (props.reminder === false) {
                  new DatalayerService().push({
                    event: 'GAEvent',
                    eventCategory: 'Votre espace',
                    eventAction: 'MAT',
                    eventLabel: DatalayerEventLabel.CREATE_REMINDER,
                  });
                }
                props.setReminder(true);
              }}
              icon={props.reminder ? CheckedWhite : undefined}
              isBold={true}
              widthSize={props.reminder ? '16px' : undefined}
              heightSize={props.reminder ? '16px' : undefined}
              type={'button'}
              disabled={(readOnly && !props.reminder) || props.disabled}
            >
              {props.reminder ? 'Rappel créé' : 'Créer un rappel'}
            </Button>
          </div>
          <HidePart hide={!props.reminder}>
            <VSpacer size="4px" />
            <RappelText>
              <div>
                <LittleText>
                  Un rappel sera envoyé{' '}
                  <Link
                    onClick={props.disabled ? undefined : () => setLinkTimeOpen(!linkTimeOpen)}
                    id="time-dropdown-id"
                    hasError={errors && errors.delay && errors.delay.message !== undefined}
                    style={{ cursor: props.disabled ? 'not-allowed' : undefined }}
                  >
                    {watchDelay && watchDelay.label ? watchDelay.label : 'sélectionner un délai'}{' '}
                    <HSpacer size="2px"></HSpacer>
                    <ChevronDownBlack />
                    <HSpacer size="4px"></HSpacer>
                  </Link>
                </LittleText>
                <LittleText>
                  l'échéance du{' '}
                  <Link
                    onClick={
                      props.disabled
                        ? undefined
                        : () => {
                            setLinkDateNotif(!linkDateNotif);
                          }
                    }
                    id="date-dropdown-id"
                    hasError={errors && errors.dateNotif && errors?.dateNotif?.message !== undefined}
                    style={{ cursor: props.disabled ? 'not-allowed' : undefined }}
                  >
                    {watchDateNotif ? dayjs(watchDateNotif).format('DD/MM/YYYY') : 'sélectionner une date'}{' '}
                    <HSpacer size="2px"></HSpacer>
                    <CalendarWire />
                    <HSpacer size="4px"></HSpacer>
                  </Link>
                </LittleText>
                <LittleText>
                  à{' '}
                  <Link
                    onClick={props.disabled ? undefined : () => setLinkEmployeeOpen(!linkEmployeeOpen)}
                    id="employee-dropdown-id"
                    hasError={errors && errors.notifyEmployee && errors.notifyEmployee.message !== undefined}
                    style={{ cursor: props.disabled ? 'not-allowed' : undefined }}
                  >
                    <>
                      {watchNotifyEmployee && watchNotifyEmployee.length > 0
                        ? watchNotifyEmployee.map(
                            (employe: { value: string; label: string }, index: number) => {
                              return index === watchNotifyEmployee.length - 1
                                ? employe.label + ' '
                                : employe.label + ', ';
                            }
                          )
                        : 'sélectionner le(s) destinataire(s)'}{' '}
                      <ChevronDownBlack />
                    </>
                  </Link>
                </LittleText>
                {/* <LittleText isGrey={true}>{' (cliquer pour ajouter ou modifier).'}</LittleText> */}
              </div>
              <div className="low-block">
                <div>
                  {errors && (errors.dateNotif || errors.notifyEmployee || errors.delay) && (
                    <LittleText hasError={true}>Ces valeurs doivent être renseignées.</LittleText>
                  )}
                </div>

                {!readOnly && !props.disabled && (
                  <div className="buttons-wrapper">
                    <Button
                      variant="bigLight"
                      icon={TrashBin}
                      callback={() => {
                        if (props.reminder === true) {
                          new DatalayerService().push({
                            event: 'GAEvent',
                            eventCategory: 'Votre espace',
                            eventAction: 'MAT',
                            eventLabel: DatalayerEventLabel.DELETE_REMINDER,
                          });
                        }
                        props.setReminder(false);
                      }}
                      type="button"
                    >
                      Supprimer ce rappel
                    </Button>
                  </div>
                )}
              </div>
            </RappelText>
            <Dropdown
              isOpen={linkEmployeeOpen && !readOnly}
              attachedId="employee-dropdown-id"
              down={true}
              menuWidth={340}
              menuPadding="19px"
              onClose={() => setLinkEmployeeOpen(false)}
              centerOnMobile
            >
              <div>
                <MultiSelect
                  label="Choisissez qui va recevoir cette notification"
                  name="notifyEmployee"
                  options={receiversList}
                  placeholder="Tapez le nom d'un/de collaborateur(s)..."
                  error={errors && errors.notifyEmployee && errors.notifyEmployee.message}
                />
              </div>
            </Dropdown>
            <Dropdown
              isOpen={linkDateNotif && !readOnly}
              attachedId="date-dropdown-id"
              down={true}
              menuWidth={340}
              menuPadding="19px"
              onClose={() => setLinkDateNotif(false)}
              centerOnMobile
            >
              <div>
                <DateField
                  name={'dateNotif'}
                  placeholder="Choisir la date du rappel"
                  error={errors && errors.dateNotif && errors?.dateNotif?.message}
                />
              </div>
            </Dropdown>
            <Dropdown
              isOpen={linkTimeOpen && !readOnly}
              attachedId="time-dropdown-id"
              down={true}
              menuWidth={340}
              menuPadding="19px"
              onClose={() => setLinkTimeOpen(false)}
              centerOnMobile
            >
              <div>
                <SingleSelect
                  label="Choisissez quand recevoir cette notification"
                  name={'delay'}
                  options={delayOptions}
                  placeholder="Choisir une durée..."
                  hasLimitedHeight={true}
                  error={errors && errors.delay && errors.delay?.message}
                />
              </div>
            </Dropdown>
          </HidePart>
        </div>
      ) : (
        <LoadingScreen />
      )}
    </ReminderSettingsStyle>
  );
}
