// * Example : numberedSentenceBuilder(myNum, 'Aucune compétence', 'compétence', 'compétences')
export function numberedSentenceBuilder(
  num: number | null | undefined,
  sentenceNone: string,
  sentenceSingular: string,
  sentencePlural: string
): string {
  // not === to handle null or undefined value
  if (num == 0) {
    return sentenceNone;
  } else if (num === 1) {
    return num + ' ' + sentenceSingular;
  } else {
    return num + ' ' + sentencePlural;
  }
}

export function versionCompare(v1: string, v2: string, options: Record<string, boolean>): number {
  const lexicographical = options && options.lexicographical,
    zeroExtend = options && options.zeroExtend;
  let v1parts: number[] | string[] = v1.split('.'),
    v2parts: number[] | string[] = v2.split('.');

  function isValidPart(x: string) {
    return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
  }

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN;
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) v1parts.push('0');
    while (v2parts.length < v1parts.length) v2parts.push('0');
  }

  if (!lexicographical) {
    v1parts = v1parts.map(Number);
    v2parts = v2parts.map(Number);
  }

  for (let i = 0; i < v1parts.length; ++i) {
    if (v2parts.length == i) {
      return 1;
    }

    if (v1parts[i] == v2parts[i]) {
      continue;
    } else if (v1parts[i] > v2parts[i]) {
      return 1;
    } else {
      return -1;
    }
  }

  if (v1parts.length != v2parts.length) {
    return -1;
  }

  return 0;
}
