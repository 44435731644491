import { unwrap } from './Assertions';

/** */

/**
 * From an {@link iri}, retrieves the numerical identifier of an entity.
 */
export function iriToId(iri: string): number {
  return parseInt(unwrap(iri.split('/').at(-1)));
}

/**
 * @param iri The entity iri.
 * @returns The base information about the entity identity.
 */
export function entityBaseDataFromIri(iri: string): { id: number; '@id': string; iri: string } {
  return {
    id: iriToId(iri),
    '@id': iri,
    iri,
  };
}
