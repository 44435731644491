import styled from 'styled-components';
import StyleData from '../../utilities/StyleData';

export interface Style {
  gridTemplateColumn: string;
  gridColumnNumber: number;
}

export const DesktopGrid = styled.div<Style>`
  display: flex;
  flex-direction: column;
  margin: 0 -1rem;

  --grid-template-columns: ${({ gridTemplateColumn }) => gridTemplateColumn};
  --grid-column-number: ${({ gridColumnNumber }) => gridColumnNumber};
`;

export const DesktopGridContent = styled.div`
  display: grid;
  position: relative;
  gap: ${StyleData.spacing.md} 0;
  padding: 0rem 1rem 0rem 1rem;
  grid-template-columns: 100%;
`;

export const DesktopGridHeader = styled.div`
  position: sticky;
  top: calc(5rem - 14px);
  border-radius: 0 0 ${StyleData.borderRadius.md} ${StyleData.borderRadius.md};
  margin: 0 1rem;
  background-color: ${StyleData.color.base};
  transition: box-shadow 150ms ease-out;

  &.sticky {
    z-index: 2;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.18);
  }
`;

export const DesktopGridHeaderContent = styled.div`
  display: grid;
  grid-template-columns: var(--grid-template-columns);
`;

export const DesktopGridRow = styled.div`
  scroll-snap-align: start;
  scroll-snap-stop: normal;

  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.18);
  display: grid;
  grid-template-columns: var(--grid-template-columns);
  border-radius: ${StyleData.borderRadius.md};
  transition: box-shadow ${StyleData.transition.duration.short} ${StyleData.transition.timingFunction.short};

  :hover {
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.36);
    z-index: 1;
  }
`;

export const Cell = styled.div<Pick<Style, 'gridColumnNumber'>>`
  min-height: 54px;
  max-height: 54px;
  height: 54px;
  padding: 0px;

  :not(:nth-child(${({ gridColumnNumber }) => gridColumnNumber}n)) {
    border-right: 2px solid ${StyleData.color.lightGrey};
  }
`;

export const TableFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  div {
    display: flex;
  }

  div:nth-child(1) {
    align-items: center;
    gap: 24px;
  }

  div:nth-child(2) {
    justify-content: center;
  }

  .more-results {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    background-color: transparent;
    border: none;
    text-decoration: underline;
    font-family: 'Work Sans';
    cursor: pointer;
    svg {
      width: 16px;
    }
  }
  @media all and (max-width: ${StyleData.breakpoints.max.lg}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 8px;
    div:nth-child(1) {
      order: 1;
    }
  }
`;
