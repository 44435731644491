import styled from 'styled-components';
import StyleData from '../utilities/StyleData';

/*** Used in ...List.tsx***/
export const EmployeeHeader = styled.div`
  display: flex;
  gap: 10px;
  img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }
  button {
    font-size: ${StyleData.fsize.xxxs};
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.lg}px) {
    justify-content: space-between;
    img {
      display: none;
    }
  }
`;

export const LeftText = styled.p`
  margin: 0;
  width: 673px;
  color: ${StyleData.color.text};
  font-family: ${StyleData.font.secondary};
  font-size: ${StyleData.fsize.md};
  line-height: 21px;
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    width: 100%;
    font-size: ${StyleData.fsize.sm};
    line-height: 18px;
  }
`;

export const CardLinkText = styled.div`
  display: grid;
  grid-template: auto / auto 320px;
  gap: 30px;
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    grid-template: auto auto / 100%;
  }
`;

export const FilterButton = styled.a<{ isActive?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  svg {
    margin-top: 2px;
    width: 16px;
    height: 16px;
    padding: 2px;
  }

  ${(p) =>
    p.isActive
      ? `
  .icon {
    border-radius: 50%;
    background-color: ${StyleData.color.secondary};
    .st0-fill {
      fill: ${StyleData.color.base};
      transition: fill 0.15s ease-in;
    }
  }
  `
      : `:hover {
    .icon {
      .st0-fill {
        fill: ${StyleData.color.secondary};
        transition: color 0.15s ease-in;
      }
    }
  }`}

  :hover {
    cursor: pointer;
  }

  

  .active 
`;

export const Container = styled.div`
  max-width: 1024px;
  box-sizing: border-box;
  margin: 0 auto;
  @media screen and (max-width: ${StyleData.breakpoints.max.lg}px) {
    width: 100%;
    justify-content: center;
    padding: 0 20px;
  }
`;

export const GridHeaderContainer = styled.div`
  font-size: 11px;
  font-weight: normal;
  font-family: ${StyleData.font.secondary};
  vertical-align: middle;
  padding: ${StyleData.spacing.md} 16px;
`;

export const Element = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ElementCentered = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
`;

export const ColumnCentered = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const BackContainer = styled.div`
  display: flex;
  cursor: pointer;
`;
