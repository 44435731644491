import styled from 'styled-components';
import { MaterialSheetDefault } from '../../../icons';
import StyleData from '../../../utilities/StyleData';

export const Image = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  object-fit: cover;
`;

export const DefaultImage = styled(MaterialSheetDefault)`
  width: 100px;
  height: 100px;
`;

export const LoaderContainer = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Root = styled.div`
  display: flex;
  gap: ${StyleData.spacing.md};
  flex-direction: column;
  align-items: center;
  justify-self: end;
  font-size: ${StyleData.fsize.md};

  &:not(.disabled) {
    cursor: pointer;
  }
`;

export const ButtonLikeSpan = styled.span`
  white-space: nowrap;
  font-weight: normal;
  line-height: 1.45;
  font-family: ${StyleData.font.primary};
  color: ${StyleData.color.primary};
  text-decoration: underline;
`;

export const ButtonLine = styled.div`
  display: flex;
  font-size: ${StyleData.fsize.xxs};
  gap: 0.5em;
`;

export const EndContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: ${StyleData.spacing.md};
`;
