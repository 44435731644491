import EntityInterface from '../interfaces/Entity';

export default class EventRef implements EntityInterface {
  id!: number;
  name!: string;

  constructor(data?: Partial<EventRef>) {
    Object.assign(this, data);
  }
}
