import { Link } from 'react-router-dom';
import styled from 'styled-components';
import StyleData from '../utilities/StyleData';

/***  Used in ....constants.tsx***/

export const ButtonCellContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  &.lighter {
    &,
    * {
      color: #bbb;
    }
  }
  .row-edit-button {
    /* margin: auto; */
    display: flex;
    button {
      visibility: hidden;
      opacity: 0;
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
    margin-right: 7px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-right: 7px;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
`;

export const BasicCellContainer = styled.div`
  font-synthesis: none;
  min-height: 25px;
  padding: 10px 16px 10px 16px;
  font-size: ${StyleData.fsize.sm};
  font-weight: bold;
  font-family: ${StyleData.font.primary};
  color: ${StyleData.color.text};
  &.lighter {
    &,
    * {
      color: #bbb;
    }
  }
  & .alert {
    color: #ef3340;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    font-size: ${StyleData.fsize.xs};
  }
`;

export const TextCell = styled.div<{ noPadding?: boolean; clickable?: boolean } & ColorProps>`
  color: ${(props) => {
    return props.color ? props.color : StyleData.color.text;
  }};
  font-family: ${StyleData.font.primary};
  font-size: ${StyleData.fsize.sm};
  font-weight: 600;
  line-height: 16px;
  padding-left: ${(props) => {
    return props.noPadding ? '0' : '10px';
  }};
  ${(props) => {
    if (props.clickable) {
      return 'color: #4ec3e0; cursor: pointer;font-weight: normal;';
    }
  }}
`;

export const Photo = styled.img`
  object-fit: cover;
  width: 26px;
  height: 26px;
  border-radius: 50%;
`;

export const HealthCellContainer = styled.div<{
  clickable?: boolean;
  minWidth?: boolean;
}>`
  padding: 10px 10px 10px 5px;
  ${(props) => {
    if (props.minWidth) {
      return 'min-width: 130px;';
    }
  }}
  ${(props) => {
    if (props.clickable) {
      return 'cursor: pointer;font-weight: normal;';
    }
  }}
`;

export const State = styled.div`
  display: grid;
  grid-template: repeat(2, auto) / 1fr 5fr;
`;

export type ColorProps = {
  color?: string;
};

export const EndContainer = styled.div<{ noPaddingRight?: boolean; centeredContent?: boolean }>`
  display: flex;
  justify-content: ${(props) => {
    return props.centeredContent ? 'end' : 'space-between';
  }};
  align-items: center;
  ${(props) => {
    if (props.noPaddingRight) {
      return 'padding: 10px 0px 10px 16px;';
    }
    return 'padding: 10px 16px 10px 16px;';
  }}
  height: 25px;
  color: ${StyleData.color.text};
  font-family: ${StyleData.font.primary};
  font-size: ${StyleData.fsize.sm};
  font-weight: 600;
  &.lighter {
    &,
    * {
      color: #bbb;
    }
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    line-height: 15px;
    font-size: ${StyleData.fsize.xxs};
    font-weight: normal;
    font-family: ${StyleData.font.secondary};
    padding-left: 0px;
  }
`;

export const EndActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  .action-dropdown-button button {
    padding: 2px 1px 2px 1px;
    margin-left: 8px;
    svg {
      margin: 0;
    }
  }
`;

export const StateIcon = styled.div<ColorProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  min-width: 16px;
  max-width: 16px;
  background-color: ${(props) => {
    return props.color;
  }};
  border-radius: 100px;
  margin-right: 6px;
  svg {
    width: 12px;
    height: 12px;
  }
`;

export const CellLinkContainer = styled(Link)`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
  gap: 6px;
  padding: 10px 16px 10px 16px;

  .text-container {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    padding-left: 0px;
  }
  text-decoration: none;
  &.lighter {
    &,
    * {
      color: #bbb;
    }
  }
  > img {
    border-radius: 50%;
  }

  :hover {
    cursor: pointer;
  }
`;

export const RowCellContainer = styled.div<{ clickable?: boolean }>`
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  ${(props) => {
    if (props.clickable) {
      return 'cursor: pointer;font-weight: normal;';
    }
  }}
`;
