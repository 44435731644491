import { AxiosResponse } from 'axios';
import http from '../../ApiHttp';
import { HydraMemberInterface } from '../../interfaces/HydraMember';
import { DelayAct } from '../../models/DelayAct';
import { NotificationAct, NotificationActFromAPI } from '../../models/NotificationAct';
import { UserAct } from '../../models/UserAct';

const {
  REACT_APP_URL_API_ACT_RECEIVERS,
  REACT_APP_URL_API_ACT_DELAYS,
  REACT_APP_URL_API_ACT_NOTIFICATIONS,
  REACT_APP_URL_API_ACT_NOTIFICATION,
} = process.env;

export default class NotificationActHttp {
  public static async persist(
    method: 'post' | 'put',
    materialSheetId: number,
    notification: NotificationAct
  ): Promise<AxiosResponse | undefined> {
    let route = REACT_APP_URL_API_ACT_NOTIFICATIONS;

    if (route) {
      route = route.replace(/_resourceID_/gi, `${materialSheetId}`);

      return await http[method](route, notification.toAPI());
    }
  }

  public static async delete(notification: NotificationAct): Promise<boolean> {
    const result = await http.delete(`${REACT_APP_URL_API_ACT_NOTIFICATION}/${notification['id']}`);
    return result.status === 204;
  }

  public static async getNotification(
    materialSheetId: number,
    organizationID: number
  ): Promise<NotificationAct | undefined> {
    let route = REACT_APP_URL_API_ACT_NOTIFICATIONS;

    if (route) {
      route = route
        .replace(/_resourceID_/gi, `${materialSheetId}`)
        .replace(/_organizationID_/gi, `${organizationID}`);

      const result = await http.get(route, {
        params: {
          resourceId: materialSheetId,
        },
      });

      const notifications = result.data['hydra:member'];
      if (notifications && notifications.length > 0 && notifications[0]) {
        const notification: NotificationActFromAPI = notifications[0];
        notification.organizationId = organizationID;
        return NotificationAct.fromAPI(notification);
      }
    }
  }

  public static async getReceivers(organizationID: number): Promise<UserAct[]> {
    const route = REACT_APP_URL_API_ACT_RECEIVERS?.replace(/_organizationID_/gi, `${organizationID}`);

    if (route) {
      const result = await http.get(route);

      return result.data['hydra:member'].map((u: HydraMemberInterface) => new UserAct(u));
    }

    return [];
  }

  /**
   * Gets available ACT delays.
   * @param options The options
   *   * `isRemindableWithEmail`: if `true`, only the delays remindable with email will be returned, if `false` only the delays which are not remindable with email will be returned. If `undefined` (which is the default), both will be returned.
   * @returns The delays.
   */
  public static async getDelays(options?: Partial<{ isRemindableWithEmail?: boolean }>): Promise<DelayAct[]> {
    if (REACT_APP_URL_API_ACT_DELAYS) {
      const searchParams = new URLSearchParams();
      const isRemindableWithEmail = options?.isRemindableWithEmail;
      if (isRemindableWithEmail !== undefined) {
        searchParams.set('isRemindableWithEmail', isRemindableWithEmail.toString());
      }
      const paramsString = searchParams.toString();
      const url = REACT_APP_URL_API_ACT_DELAYS + (paramsString.length > 0 ? `?${paramsString}` : '');

      const result = await http.get(url);
      return result.data['hydra:member'].map((d: HydraMemberInterface) => new DelayAct(d));
    }

    return [];
  }
}
