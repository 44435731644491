import styled from 'styled-components';
import { default as StyleData, default as styleData } from '../../../utilities/StyleData';

const breakpoint = styleData.breakpoints.max.smd;

export const ReminderSettingsStyle = styled.article`
  display: flex;
  justify-content: center;

  .reminder-block {
    display: flex;
    width: 100%;
    gap: ${styleData.spacing.lg};
    align-items: flex-start;

    @media all and (max-width: ${breakpoint}px) {
      flex-direction: column;
      align-items: center;

      .button-wrapper {
        align-self: flex-start;
      }
    }
  }
`;

export const Settings = styled.div`
  padding: ${styleData.spacing.md} ${styleData.spacing.lg};
  background-color: ${styleData.color.lightGrey};
  border-radius: ${styleData.borderRadius.md};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: ${styleData.fsize.md};

  > *:last-child {
    align-self: flex-end;
  }

  @media all and (max-width: ${breakpoint}px) {
    align-self: stretch;
  }
`;

export const SettingsSentence = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  word-break: keep-all;
  flex-grow: 1;
  flex-wrap: wrap;

  > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
  }
`;

export const DropdownButton = styled.button`
  width: 100%;
  background-color: transparent;
  border: none;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.18);
  padding: 10px 15px;
  border-radius: 5px;
  .button-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: ${styleData.color.text};
      font-family: ${styleData.font.primary};
      font-size: ${styleData.fsize.md};
      font-weight: normal;
    }
  }
`;

export const DateContainer = styled.div`
  max-width: 6.5rem;
`;

export const HidePart = styled.div<{ hide: boolean }>`
  background: #f7f7f8;
  display: ${(props) => (props.hide ? 'none' : 'block')};
  flex: 1;
  border-radius: ${styleData.borderRadius.xl};
  padding: 10px;
  padding-left: 20px;
  padding-top: 20px;
`;

export const RappelText = styled.div`
  .low-block {
    display: flex;
    justify-content: space-between;
  }

  .low-block .buttons-wrapper {
    display: flex;
    margin-top: ${styleData.spacing.xl};
    gap: ${styleData.spacing.xl};
  }
`;

export const LittleText = styled.span<{ hasError?: boolean; isGrey?: boolean }>`
  margin: 0;
  color: ${(props) =>
    props.hasError
      ? StyleData.color.primary
      : props.isGrey
      ? StyleData.color.lightText
      : StyleData.color.text};
  font-family: ${StyleData.font.secondary};
  font-size: ${StyleData.fsize.xs};
  line-height: 16px;
  .link {
    text-decoration: underline;
  }
  .delete-rappel {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    text-decoration: underline;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    font-size: ${StyleData.fsize.xxs};
    line-height: 15px;
  }
`;

export const Link = styled.a<{ hasError?: boolean }>`
  position: relative;
  margin: 0;
  color: ${(props) => (props.hasError ? StyleData.color.primary : StyleData.color.text)};
  font-family: ${StyleData.font.secondary};
  font-size: ${StyleData.fsize.xs};
  line-height: 16px;
  text-decoration: underline;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  svg {
    height: 18px;
  }

  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    font-size: ${StyleData.fsize.xxs};
    line-height: 15px;
  }
`;
