import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle, css } from 'styled-components';
import { z } from 'zod';
import { Button } from '../../components/Button';
import DateField from '../../components/input/date-field/DateField';
import MultiSelect from '../../components/input/MultiSelect';
import { RadioClassic } from '../../components/input/RadioClassic';
import { VSpacer } from '../../components/layout/Spacer';
import Line from '../../components/Line';
import Text from '../../components/Text';
import { useQuery } from '../../hooks/UseQuery';
import { ChevronDownBlack, ChevronUpBlack, CloseBlack, Reload } from '../../icons';
import EventRefHttp from '../../services/http/EventRefHttp';
import MaterialFamilyHttp from '../../services/http/MaterialFamilyHttp';
import { useListStore } from '../../store/ListStore';
import { toAPIDateFormat } from '../../utilities/Date';
import StyleData from '../../utilities/StyleData';

const schema = z.late.object(() => ({
  materialCategory: z.string().optional().nullable(),
  materialFamily: z
    .object({
      value: z.string(),
      label: z.string(),
    })
    .array()
    .optional()
    .nullable(),
  minDateNextVerificationCheck: z
    .instanceof(Date, { message: 'Veuillez entrer une date valide' })
    .optional()
    .nullable(),
  maxDateNextVerificationCheck: z
    .instanceof(Date, { message: 'Veuillez entrer une date valide' })
    .optional()
    .nullable(),
  eventType: z
    .object({
      value: z.string(),
      label: z.string(),
    })
    .array()
    .optional()
    .nullable(),
  isScrapped: z.string().optional().nullable(),
}));

export type MaterialHeaderTableFormType = z.infer<typeof schema>;

interface Props {
  isOpen: boolean;
  onClose: () => unknown;
  handleFilters: (headerForm: Record<string, string[]>) => unknown;
  setHasMobileFilters: (value: boolean) => unknown;
}

function MobileFilters(props: Props) {
  const params = useQuery();
  const navigate = useNavigate();
  const pathname = location.pathname.split('?')[0];
  const submitRef = useRef<HTMLInputElement>(null);

  const [selected, setSelected] = useState<number>(-1);

  const [materialFamilies, setMaterialFamilies] = useListStore((state) => [
    state.materialFamilies,
    state.setMaterialFamilies,
  ]);

  const [eventTypeOptions, setEventTypeOptions] = useState<{ label: string; value: string }[]>([]);
  // Form
  const methods = useForm({
    mode: 'onTouched',
    resolver: zodResolver(schema),
  });

  const errors: any = methods.formState.errors;

  // * Fetch material families values
  useEffect(() => {
    if (materialFamilies.length === 0) {
      new MaterialFamilyHttp().get().then((data) => {
        const formattedMaterialFamilies = data.map((item) => {
          return {
            value: item.id.toString(),
            label: item.name,
            isIpe: item.isIpe,
            isInstallation: item.isInstallation,
            isMaterial: item.isMaterial,
          };
        });
        setMaterialFamilies(formattedMaterialFamilies);
        const materialFamiliesSelected = formattedMaterialFamilies.filter((item) => {
          return params.getAll('lastPlannedMaterialChecking.eventRef.id[]').includes(item.value);
        });
        methods.setValue('materialFamily', materialFamiliesSelected);
      });
    }
    if (eventTypeOptions.length === 0) {
      new EventRefHttp().get().then((data) => {
        const formattedEventType = data.map((item) => {
          return { value: item.id.toString(), label: item.name };
        });
        setEventTypeOptions(formattedEventType);
        const eventSelected = formattedEventType.filter((item) => {
          return params.getAll('lastPlannedMaterialChecking.eventRef.id[]').includes(item.value);
        });
        methods.setValue('eventType', eventSelected);
      });
    }
  }, []);

  useEffect(() => {
    if (props.isOpen) {
      window.scrollTo(0, 0);
    }
  }, [props.isOpen]);

  const watchFields = methods.watch([
    'materialFamily',
    'materialCategory',
    'minDateNextVerificationCheck',
    'maxDateNextVerificationCheck',
    'eventType',
    'isScrapped',
  ]);

  const checkHasSomeValues = () => {
    const filteredValues: any[] = watchFields.filter(
      (element: any) => element !== '' && element !== undefined && element !== null
    );
    props.setHasMobileFilters(filteredValues.length > 0);
  };

  const onSubmit: SubmitHandler<MaterialHeaderTableFormType> = (data: MaterialHeaderTableFormType) => {
    checkHasSomeValues();
    if (
      data.maxDateNextVerificationCheck &&
      data.minDateNextVerificationCheck &&
      data.minDateNextVerificationCheck.getTime() > data.maxDateNextVerificationCheck.getTime()
    ) {
      methods.setError('maxDateNextVerificationCheck', {
        message: 'La date de fin doit être supérieure à la date de début',
      });
      return;
    }

    data.materialCategory
      ? params.set('materialCategory', data.materialCategory)
      : params.delete('materialCategory');

    data.materialFamily && data.materialFamily.length > 0
      ? (() => {
          params.delete('materialType.materialFamily.id[]');
          data.materialFamily.forEach((item) => {
            params.append('materialType.materialFamily.id[]', item.value);
          });
        })()
      : params.delete('materialType.materialFamily.id[]');

    data.minDateNextVerificationCheck
      ? params.set(
          'lastPlannedMaterialChecking.plannedDate[after]',
          toAPIDateFormat(data.minDateNextVerificationCheck)
        )
      : params.delete('lastPlannedMaterialChecking.plannedDate[after]');

    data.maxDateNextVerificationCheck
      ? params.set(
          'lastPlannedMaterialChecking.plannedDate[before]',
          toAPIDateFormat(data.maxDateNextVerificationCheck)
        )
      : params.delete('lastPlannedMaterialChecking.plannedDate[before]');

    data.eventType && data.eventType.length > 0
      ? (() => {
          params.delete('lastPlannedMaterialChecking.eventRef.id[]');
          data.eventType.forEach((item) => {
            params.append('lastPlannedMaterialChecking.eventRef.id[]', item.value);
          });
        })()
      : params.delete('lastPlannedMaterialChecking.eventRef.id[]');

    data.isScrapped ? params.set('isScrapped', data.isScrapped) : params.delete('isScrapped');

    navigate({
      pathname,
      search: '?' + params.toString().replaceAll('%5B', '[').replaceAll('%5D', ']'),
    });

    props.handleFilters(
      Array.from(params.entries()).reduce((result: Record<string, string[]>, [paramKey, paramVal]) => {
        const values = result[paramKey] ?? [];
        values.push(paramVal);
        result[paramKey] = values;
        return result;
      }, {})
    );
    return data;
  };

  const resetAction = () => {
    props.setHasMobileFilters(false);
    methods.setValue('materialCategory', 'all');
    methods.setValue('materialFamily', null);
    methods.setValue('minDateNextVerificationCheck', null);
    methods.setValue('maxDateNextVerificationCheck', null);
    methods.setValue('eventType', null);
    methods.setValue('isScrapped', '');

    params.delete('materialCategory');
    params.delete('materialType.materialFamily.id[]');
    params.delete('lastPlannedMaterialChecking.plannedDate[after]');
    params.delete('lastPlannedMaterialChecking.plannedDate[before]');
    params.delete('lastPlannedMaterialChecking.eventRef.id[]');
    params.delete('isScrapped');

    navigate({
      search: '?' + params.toString(),
    });
    props.handleFilters(
      Array.from(params.entries()).reduce((result: Record<string, string[]>, [paramKey, paramVal]) => {
        const values = result[paramKey] ?? [];
        values.push(paramVal);
        result[paramKey] = values;
        return result;
      }, {})
    );
    props.onClose();
  };

  const handleToggle = (index: number) => {
    selected === index ? setSelected(-1) : setSelected(index);
  };

  return (
    <MobileFiltersPopin isOpen={props.isOpen} className="DEBUG">
      <ScrollContainer>
        <div className="header">
          <button
            onClick={() => {
              props.onClose();
            }}
            className="x-close"
          >
            <CloseBlack />
          </button>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Accordion visible={props.isOpen}>
              <Text fsize={'xxl'} fontWeight={'600'} textAlign={'center'}>
                Filtres
              </Text>
              <VSpacer />
              <AccordionContainer>
                <AccordionItem>
                  <AccordionHeader onClick={() => handleToggle(0)}>
                    <Text fontWeight={'600'}>Type de matériel</Text>
                    {selected === 0 ? <ChevronUpBlack /> : <ChevronDownBlack />}
                  </AccordionHeader>
                  <VSpacer />
                  <AccordionContent visible={selected === 0}>
                    <RadioClassic
                      name="materialCategory"
                      label="Voir seulement la catégorie:"
                      values={[
                        {
                          value: 'all',
                          label: 'Tout',
                        },
                        {
                          value: 'installation',
                          label: 'Installation',
                        },
                        {
                          value: 'materiel',
                          label: 'Matériel',
                        },
                        {
                          value: 'ipe',
                          label: 'EPI',
                        },
                      ]}
                      onChange={() => {
                        methods.setValue('materialFamily', [], { shouldTouch: true });
                      }}
                    />
                    <VSpacer size="10px"></VSpacer>
                    <Line></Line>
                    <VSpacer size="6px"></VSpacer>
                    <MultiSelect
                      label="Filtrer par famille :"
                      placeholder="Choisir une famille"
                      name="materialFamily"
                      options={materialFamilies.filter((item) => {
                        if (methods.getValues().materialCategory === 'installation') {
                          return item.isInstallation;
                        }
                        if (methods.getValues().materialCategory === 'materiel') {
                          return item.isMaterial;
                        }
                        if (methods.getValues().materialCategory === 'ipe') {
                          return item.isIpe;
                        }
                        return true;
                      })}
                    ></MultiSelect>
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader onClick={() => handleToggle(1)}>
                    <Text fontWeight={'600'}>Date prévue</Text>
                    {selected === 1 ? <ChevronUpBlack /> : <ChevronDownBlack />}
                  </AccordionHeader>
                  <VSpacer />
                  <AccordionContent visible={selected === 1}>
                    <div className="date-wrapper">
                      <div className="label">
                        <Text>Du</Text>
                      </div>
                      <DateField
                        name="minDateNextVerificationCheck"
                        placeholder="jj/mm/aaaa"
                        required={false}
                        error={
                          errors &&
                          errors.minDateNextVerificationCheck &&
                          errors.minDateNextVerificationCheck.message
                        }
                      ></DateField>
                    </div>
                    <VSpacer size="12.5px" />
                    <div className="date-wrapper">
                      <div className="label">
                        <Text>au</Text>
                      </div>
                      <DateField
                        name="maxDateNextVerificationCheck"
                        required={false}
                        placeholder="jj/mm/aaaa"
                        error={
                          errors &&
                          errors.maxDateNextVerificationCheck &&
                          errors.maxDateNextVerificationCheck.message
                        }
                      ></DateField>
                    </div>
                    <VSpacer size={'16px'} />
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader onClick={() => handleToggle(2)}>
                    <Text fontWeight={'600'}>Type d'évenement</Text>
                    {selected === 2 ? <ChevronUpBlack /> : <ChevronDownBlack />}
                  </AccordionHeader>
                  <VSpacer />
                  <AccordionContent visible={selected === 2}>
                    <MultiSelect
                      label="Filtrer par événement :"
                      name="eventType"
                      placeholder="Choisir un événement"
                      options={eventTypeOptions}
                    ></MultiSelect>
                    <VSpacer size="12.5px" />
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader onClick={() => handleToggle(3)}>
                    <Text fontWeight={'600'}>Statut</Text>
                    {selected === 3 ? <ChevronUpBlack /> : <ChevronDownBlack />}
                  </AccordionHeader>
                  <VSpacer />
                  <AccordionContent visible={selected === 3}>
                    <RadioClassic
                      name="isScrapped"
                      label="Voir seulement le matériel:"
                      values={[
                        {
                          value: 'false',
                          label: 'Actif',
                        },
                        {
                          value: 'true',
                          label: 'Inactif',
                        },
                      ]}
                    />
                    <VSpacer size="12.5px" />
                  </AccordionContent>
                </AccordionItem>
              </AccordionContainer>
            </Accordion>
            <ButtonContainer>
              <Button
                variant="bigLightFilterMobile"
                type={'reset'}
                icon={Reload}
                skipResponsive={true}
                callback={() => {
                  resetAction();
                }}
              >
                Réinitialiser
              </Button>
              <Button
                variant="primaryRoundFilterMobile"
                callback={() => {
                  submitRef.current?.click();
                  props.onClose();
                }}
              >
                Valider
              </Button>
              <input type="submit" style={{ display: 'none' }} ref={submitRef} />
            </ButtonContainer>
          </form>
        </FormProvider>
        <GlobalStyleOverflow isOpen={props.isOpen} />
      </ScrollContainer>
    </MobileFiltersPopin>
  );
}

const GlobalStyleOverflow = createGlobalStyle<{ isOpen: boolean }>`
  ${(props) => {
    if (props.isOpen) {
      return css`
        body {
          overflow: hidden !important;
        }
      `;
    }
    return '';
  }}
`;

const MobileFiltersPopin = styled.div<{ isOpen: boolean }>`
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 8000;
  padding: 0.7rem;
  display: ${(props) => {
    return props.isOpen ? 'block' : 'none';
  }};
  .header {
    display: flex;
    justify-content: flex-end;
  }
  .x-close {
    background-color: ${StyleData.color.base};
    border: none;
    margin-top: 5px;
  }
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  max-height: 100%;
`;

export const Accordion = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'inherit' : 'none')};
  margin-bottom: 16px;
`;

export const AccordionItem = styled.div`
  margin: 15px 15px 0px;
  border-bottom: 1px rgba(0, 0, 0, 0.2) solid;
`;

export const AccordionContent = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'inherit' : 'none')};
  padding: 10px 15px;

  .date-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .label {
      width: 25px;
    }
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    font-size: 0.8rem;
  }
`;

export const AccordionContainer = styled.div`
  /* box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.18); */
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export default MobileFilters;
