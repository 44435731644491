import EventType from '../../models/EventType';
import HttpAbstract from './AbstractHttp';

const ROUTE = process.env.REACT_APP_URL_API_EVENT_TYPES;

export default class EventTypeHttp extends HttpAbstract<EventType> {
  constructor() {
    super(`${ROUTE}`, EventType);
  }
}
