import { Card, CardRow, Cards } from './CardDeckStyle';

interface Props {
  format: ((rowData: any) => JSX.Element)[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  data?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  hasCarousel?: boolean;
}

export const CardDeck = (props: Props): JSX.Element => {
  return (
    <Cards hasCarousel={props.hasCarousel}>
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        props.data?.map((item: any, index: number) => {
          return (
            <Card
              hasCarousel={props.hasCarousel}
              key={index}
              isDisable={item.matriculation !== undefined && item.matriculation === false}
            >
              {props.format.map((col, key) => (
                <CardRow key={key} {...(key === 0 ? { as: 'header' } : '')}>
                  {col(item)}
                </CardRow>
              ))}
            </Card>
          );
        })
      }
    </Cards>
  );
};

CardDeck.displayName = 'CardDeck';
