import { DelayAct } from '../../models/DelayAct';
import HttpAbstract from './AbstractHttp';

const ROUTE = process.env.REACT_APP_URL_API_DELAY || '';

export class DelayActHttp extends HttpAbstract<DelayAct> {
  constructor() {
    super(`${ROUTE}`, DelayAct);
  }
}
