/**
 * Completes an option object for ease of use.
 * @remarks All fields that are `undefined` in {@link userOptions}
 * (whether or not `undefined` has been given explicitely by the
 * user) are considered lacking from the options and will not be used.
 * If you need the value to be incomplete but defined, consider using
 * `null` as an alternative to `undefined`.
 * @param userOptions The potentially incomplete options given by the user.
 * @param defaultOptions The default options.
 * @returns A complete option object composed of the options the user gave and default values for the one that are missing.
 */
export function fillOptions<O>(userOptions: Partial<O> | undefined, defaultOptions: O): O {
  if (userOptions === undefined) return defaultOptions;

  const filteredUserOptions = { ...userOptions };
  for (const key in filteredUserOptions) {
    if (filteredUserOptions[key] === undefined) {
      delete filteredUserOptions[key];
    }
  }

  const filledOptions: O = { ...defaultOptions, ...filteredUserOptions };

  return filledOptions;
}
