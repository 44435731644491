import { ReactElement, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import StyleData from '../../utilities/StyleData';
import { VSpacer } from '../layout/Spacer';
import InputError from './InputError';

export type Option = { value: string; label: string };

interface Props {
  name: string;
  label?: string;
  options: Option[];
  placeholder?: string;
  error?: string;
  creatable?: boolean;
  maxWidth?: string;
  disabled?: boolean;
  tabIndex?: number;
}

function MultiSelect(props: Props): ReactElement {
  const { control } = useFormContext();
  const responsive = useContext(ResponsiveContext);

  const customStyles = (isError: boolean, hasOptions: boolean) => {
    return {
      // Les options listées
      option: () => ({
        color: StyleData.color.text,
        fontFamily: StyleData.font.secondary,
        fontSize: StyleData.fsize.md,
        fontWeight: 400,
        lineHeight: '16px',
        padding: '5px 10px 5px 10px',
        cursor: 'pointer',
        '&:hover': {
          color: StyleData.color.base,
          backgroundColor: StyleData.color.secondary,
        },
      }),

      multiValue: () => ({
        display: 'flex',
        alignItems: 'center',
        borderRadius: '30px',
        padding: '5px 8px 5px 10px',
        backgroundColor: props.disabled ? StyleData.color.lightText : StyleData.color.secondary,
        margin: '2px 2px 2px 2px',
      }),

      multiValueLabel: () => ({
        color: StyleData.color.base,
        fontFamily: StyleData.font.primary,
        fontSize: StyleData.fsize.xxs,
        fontWeight: 600,
        marginRight: '3.5px',
      }),

      multiValueRemove: () => ({
        display: 'flex',
        alignItems: 'center',
        color: StyleData.color.base,
        cursor: 'pointer',
      }),

      clearIndicator: () => ({
        display: 'none',
      }),

      dropdownIndicator: () => ({
        display: hasOptions ? 'flex' : 'none',
        color: props.disabled ? StyleData.color.lightText : StyleData.color.text,

        alignItems: 'center',
        cursor: 'pointer',
      }),
      indicatorsContainer: (existing: any) => ({
        ...existing,
        backgroundColor: props.disabled ? '#f5f5f5' : StyleData.color.base,
      }),

      indicatorSeparator: () => ({
        display: 'none',
      }),

      input: () => ({
        color: props.disabled ? StyleData.color.lightText : StyleData.color.text,
        fontFamily: StyleData.font.secondary,
        fontSize: StyleData.fsize.md,
        lineHeight: '16px',
        gridColumn: '1',
        gridRow: '1',
        minHeight: '28px',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '6px',
      }),
      singleValue: () => ({
        color: props.disabled ? StyleData.color.lightText : StyleData.color.text,
      }),
      valueContainer: (existing: any) => ({
        ...existing,
        backgroundColor: props.disabled ? '#f5f5f5' : StyleData.color.base,
        gridTemplateRows: '1fr',
        gridTemplateColumns: '1fr',
      }),

      placeholder: () => ({
        marginLeft: '6px',
        color: StyleData.color.lightText,
        fontFamily: StyleData.font.secondary,
        fontSize: StyleData.fsize.md,
        gridColumn: '1',
        gridRow: '1',
      }),

      control: () => ({
        display: 'grid',
        gridTemplate: '100% / 1fr 34px',
        border: '1px solid #92999C',
        borderRadius: '3px',
        minHeight: '36px',
        borderColor: isError ? 'red' : 'none',
        maxWidth: responsive.isMobile ? 'unset' : props.maxWidth,
      }),
      menu: (provided: Record<string, unknown>) => ({
        ...provided,
        marginBottom: '0',
        marginTop: '0',
      }),
    };
  };

  return (
    <MultiSelectContainer>
      {props.label && (
        <>
          <label>{props.label}</label> <VSpacer size="6px" />
        </>
      )}
      {props.creatable ? (
        <Controller
          control={control}
          name={props.name}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <CreatableSelect
              isClearable
              placeholder={props.placeholder}
              options={props.options}
              onChange={onChange}
              ref={ref}
              onBlur={onBlur}
              value={value}
              tabIndex={props.tabIndex}
              isMulti
              styles={customStyles(props.error ? true : false, false)}
              noOptionsMessage={(obj) => {
                if (!obj || !obj.inputValue) {
                  return null;
                } else {
                  return 'Aucune option ne correspond à ' + obj.inputValue;
                }
              }}
              formatCreateLabel={(input) => 'Ajouter l\'élément "' + input + '"...'}
              isDisabled={props.disabled}
            />
          )}
        />
      ) : (
        <Controller
          control={control}
          name={props.name}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Select
              isClearable
              placeholder={props.placeholder}
              options={props.options}
              onChange={onChange}
              ref={ref}
              onBlur={onBlur}
              value={value}
              tabIndex={props.tabIndex}
              isMulti
              styles={customStyles(props.error ? true : false, true)}
              noOptionsMessage={(obj) => {
                if (!obj || !obj.inputValue) {
                  return 'Aucune option';
                } else {
                  return 'Aucune option ne correspond à ' + obj.inputValue;
                }
              }}
              isDisabled={props.disabled}
            />
          )}
        />
      )}

      <InputError error={props.error} />
    </MultiSelectContainer>
  );
}

const MultiSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${StyleData.color.text};
  font-family: ${StyleData.font.secondary};
  font-size: ${StyleData.fsize.md};

  label {
    line-height: 21px;
    text-align: left;
    font-weight: normal;
    white-space: break-spaces;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    label {
      font-size: ${StyleData.fsize.sm};
      line-height: 18px;
    }
  }
`;

export default MultiSelect;
