import ReactTooltip from '@huner2/react-tooltip';
import { tooltipStyle } from './Tooltip.style';

export interface TooltipContainerProps {
  /** The text to display in the tooltip. */
  text: string;
  /** Should the tooltip be displayed when hovering {@link children}? Defaults to `true`. */
  display?: boolean;
  /** The children that will triggered the tooltip when hovered. */
  children: React.ReactNode;
  /** The unique id of the tooltip. */
  uniqueId: string;
  /** Additional classes. */
  className?: string;
}

/**
 * Displays a tooltip when the children are hovered.
 */
export function TooltipContainer(props: TooltipContainerProps) {
  return (
    <div className={`TooltipContainer ${props.className ?? ''}`}>
      <div data-tip={props.text} data-for={props.uniqueId}>
        {props.children}
      </div>
      {(props.display ?? true) && <ReactTooltip id={props.uniqueId} {...tooltipStyle} />}
    </div>
  );
}
