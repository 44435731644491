import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { z } from 'zod';
import { routeToLink } from '../../App.routing';
import { Button } from '../../components/Button';
import { CardDeck } from '../../components/card-deck/CardDeck';
import { DataGrid } from '../../components/data-grid/DataGrid';
import { TableFooter } from '../../components/data-grid/DataGridStyle';
import Dropdown, { Coordinate } from '../../components/Dropdown';
import DateField from '../../components/input/date-field/DateField';
import MultiSelect from '../../components/input/MultiSelect';
import { RadioClassic } from '../../components/input/RadioClassic';
import { VSpacer } from '../../components/layout/Spacer';
import Line from '../../components/Line';
import { Element, FilterButton, GridHeaderContainer } from '../../components/List.style';
import MessagePage from '../../components/MessagePage';
import Text from '../../components/Text';
import Title from '../../components/Title';
import { useAuthContext } from '../../contexts/AuthContext';
import { useResponsiveContext } from '../../contexts/ResponsiveContext';
import LoadingScreen from '../../core/LoadingScreen';
import { useMutateModals } from '../../hooks/UseMutateModals';
import { useHttpQuery } from '../../hooks/UsePagination';
import { useQuery } from '../../hooks/UseQuery';
import {
  Bonhomme2x,
  CheckedWhite,
  ChevronDownBlack,
  ChevronUpBlack,
  ChevronUpDownBlack,
  FilterBlack,
  FilterFillBlue,
  PencilWhite,
} from '../../icons';
import MaterialSheet from '../../models/MaterialSheet';
import EventRefHttp from '../../services/http/EventRefHttp';
import MaterialFamilyHttp from '../../services/http/MaterialFamilyHttp';
import MaterialSheetHttp from '../../services/http/MaterialSheetHttp';
import { cardConstants, tableConstants } from '../../shared/material-overview/MaterialConstants';
import { MaterialDisplayTypes } from '../../shared/material-overview/MaterialDisplayTypes';
import { FormContainer, GenericContainer } from '../../shared/material-overview/TableHeaderStyle';
import { useEmployeeStore } from '../../store/EmployeeStore';
import { useListStore } from '../../store/ListStore';
import { ArrayUtils } from '../../utilities/Array';
import { toAPIDateFormat } from '../../utilities/Date';
import { downloadFile } from '../../utilities/DownloadFile';
import { notify } from '../../utilities/Notify';
import StyleData from '../../utilities/StyleData';
import MaterialDelete from '../list/delete/MaterialDelete';
import Summary from '../summary/Summary';
import { Root } from './MaterialDeadlineBlockStyle';

const schema = z.late.object(() => ({
  materialCategory: z.string().optional().nullable(),
  materialFamily: z
    .object({
      value: z.string(),
      label: z.string(),
    })
    .array()
    .optional(),
  minDateNextVerificationCheck: z
    .instanceof(Date, { message: 'Veuillez entrer une date valide' })
    .optional()
    .nullable(),
  maxDateNextVerificationCheck: z
    .instanceof(Date, { message: 'Veuillez entrer une date valide' })
    .optional()
    .nullable(),
  eventType: z
    .object({
      value: z.string(),
      label: z.string(),
    })
    .array()
    .or(z.null())
    .optional(),
  isScrapped: z.string().optional().nullable(),
}));

type TableHeaderProps = {
  handleFilters: (headerForm: Record<string, string[]>) => unknown;
  mode: MaterialDisplayTypes;
};

function TableHeader(props: TableHeaderProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.split('?')[0];

  const [eventTypeIsOpen, setEventTypeIsOpen] = useState(false);
  const [plannedDateIsOpen, setPlannedDateIsOpen] = useState(false);
  const [isScrappedIsOpen, setIsScrappedIsOpen] = useState(false);
  const [materialFamilyIsOpen, setMaterialFamilyIsOpen] = useState(false);

  const [materialFamilyFieldActive, setMaterialFamilyFieldActive] = useState(false);
  const [plannedDateFieldActive, setPlannedDateFieldActive] = useState(false);
  const [eventTypeFieldsActive, setEventTypeFieldsActive] = useState(false);
  const [isScrappedFieldsActive, setIsScrappedFieldsActive] = useState(false);

  const [sortings, setSortings] = useState<{
    'materialType.name': 'off' | 'asc' | 'desc';
    matriculation: 'off' | 'asc' | 'desc';
    'lastPlannedMaterialChecking.plannedDate': 'off' | 'asc' | 'desc';
    [key: string]: string; // for typing key and dereferencing as an usual array
  }>({
    'materialType.name': 'off',
    matriculation: 'off',
    'lastPlannedMaterialChecking.plannedDate': 'asc',
  });

  const [paramsLoaded, setParamsLoaded] = useState<boolean>(false);

  const [materialFamilies, setMaterialFamilies] = useListStore((state) => [
    state.materialFamilies,
    state.setMaterialFamilies,
  ]);

  const params = useQuery();

  const methods = useForm({
    mode: 'onTouched',
    resolver: zodResolver(schema),
  });

  const errors: any = methods.formState.errors;
  const { watch } = methods;

  const watchMaterialFamily = watch('materialFamily');
  const watchMaterialCategory: string = watch('materialCategory');
  const watchEventTypeFields = watch(['eventType']);
  const watchDateNextVerificationFields = watch([
    'minDateNextVerificationCheck',
    'maxDateNextVerificationCheck',
  ]);
  const watchIsScrappedFields = watch('isScrapped');

  // Style
  const dropdownPosTweaks = useRef<Partial<Coordinate>>({ y: -10 });

  const [eventTypeOptions, setEventTypeOptions] = useState<{ label: string; value: string }[]>([]);

  const onSubmit: any = (
    data: Record<string, any>,
    event: any,
    newSort?: {
      'materialType.name': 'off' | 'asc' | 'desc';
      matriculation: 'off' | 'asc' | 'desc';
      'lastPlannedMaterialChecking.plannedDate': 'off' | 'asc' | 'desc';
      [key: string]: string; // for typing key and dereferencing as an usual array
    }
  ) => {
    if (
      data.maxDateNextVerificationCheck &&
      data.minDateNextVerificationCheck &&
      data.minDateNextVerificationCheck.getTime() > data.maxDateNextVerificationCheck.getTime()
    ) {
      methods.setError('maxDateNextVerificationCheck', {
        message: 'La date de fin doit être supérieure à la date de début',
      });
      return;
    }

    setMaterialFamilyIsOpen(false);
    setPlannedDateIsOpen(false);
    setEventTypeIsOpen(false);
    setIsScrappedIsOpen(false);

    data.materialCategory
      ? params.set('control_materialCategory', data.materialCategory)
      : params.delete('control_materialCategory');

    data.materialFamily && data.materialFamily.length > 0
      ? (() => {
          params.delete('control_materialType.materialFamily.id[]');
          data.materialFamily.forEach((item: any) => {
            params.append('control_materialType.materialFamily.id[]', item.value);
          });
        })()
      : params.delete('control_materialType.materialFamily.id[]');

    data.minDateNextVerificationCheck
      ? params.set(
          'control_lastPlannedMaterialChecking.plannedDate[after]',
          toAPIDateFormat(data.minDateNextVerificationCheck)
        )
      : params.delete('control_lastPlannedMaterialChecking.plannedDate[after]');

    data.maxDateNextVerificationCheck
      ? params.set(
          'control_lastPlannedMaterialChecking.plannedDate[before]',
          toAPIDateFormat(data.maxDateNextVerificationCheck)
        )
      : params.delete('control_lastPlannedMaterialChecking.plannedDate[before]');

    data.eventType && data.eventType.length > 0
      ? (() => {
          params.delete('control_lastPlannedMaterialChecking.eventRef.id[]');
          data.eventType.forEach((item: any) => {
            params.append('control_lastPlannedMaterialChecking.eventRef.id[]', item.value);
          });
        })()
      : params.delete('control_lastPlannedMaterialChecking.eventRef.id[]');

    data.isScrapped ? params.set('control_isScrapped', data.isScrapped) : params.delete('control_isScrapped');

    const sort = newSort ?? sortings;
    sort['materialType.name'] && sort['materialType.name'] !== 'off'
      ? params.set('control_order[materialType.name]', sort['materialType.name'])
      : params.delete('control_order[materialType.name]');
    sort['lastPlannedMaterialChecking.plannedDate'] &&
    sort['lastPlannedMaterialChecking.plannedDate'] !== 'off'
      ? params.set(
          'control_order[lastPlannedMaterialChecking.plannedDate]',
          sort['lastPlannedMaterialChecking.plannedDate']
        )
      : params.delete('control_order[lastPlannedMaterialChecking.plannedDate]');
    sort['matriculation'] && sort['matriculation'] !== 'off'
      ? params.set('control_order[matriculation]', sort['matriculation'])
      : params.delete('control_order[matriculation]');

    navigate({
      pathname,
      search: '?' + params.toString().replaceAll('%5B', '[').replaceAll('%5D', ']'),
    });

    props.handleFilters(
      Array.from(params.entries()).reduce((result: Record<string, string[]>, [paramKey, paramVal]) => {
        const values = result[paramKey] ?? [];
        values.push(paramVal);
        result[paramKey] = values;
        return result;
      }, {})
    );
    return data;
  };

  useEffect(() => {
    if (watchIsScrappedFields) {
      setIsScrappedFieldsActive(true);
    } else {
      setIsScrappedFieldsActive(false);
    }
  }, [watchIsScrappedFields]);

  useEffect(() => {
    const shouldEventTypeFieldsBeActive =
      watchEventTypeFields && ArrayUtils.any(watchEventTypeFields, (el) => el !== null && el !== undefined);
    if (shouldEventTypeFieldsBeActive !== eventTypeFieldsActive) {
      setEventTypeFieldsActive(shouldEventTypeFieldsBeActive);
    }
  }, [watchEventTypeFields]);

  useEffect(() => {
    const shouldPlannedDateFieldBeActive = ArrayUtils.any(
      watchDateNextVerificationFields,
      (item) => item !== null && item !== undefined && item !== ''
    );
    if (shouldPlannedDateFieldBeActive !== plannedDateFieldActive) {
      setPlannedDateFieldActive(shouldPlannedDateFieldBeActive);
    }
  }, [watchDateNextVerificationFields]);

  useEffect(() => {
    const hasMaterialFamily = watchMaterialFamily && watchMaterialFamily.length > 0;
    const hasSpecificMaterialCategory = watchMaterialCategory && watchMaterialCategory !== 'all';
    setMaterialFamilyFieldActive(hasMaterialFamily || hasSpecificMaterialCategory);
  }, [watchMaterialFamily, watchMaterialCategory]);

  // set params in URL
  useEffect(() => {
    // * wait for having params
    if (paramsLoaded) {
      methods.trigger();
      // onSubmit(methods.getValues());
    }
  }, [sortings, paramsLoaded]);

  // init parameters
  useEffect(() => {
    if (!paramsLoaded) {
      initSorting();

      navigate({
        pathname,
        search: '?' + params.toString().replaceAll('%5B', '[').replaceAll('%5D', ']'),
      });
    }
  }, [paramsLoaded]);

  // * Fetch material families values
  useEffect(() => {
    if (materialFamilies.length === 0) {
      new MaterialFamilyHttp().get().then((data) => {
        setMaterialFamilies(
          data.map((item) => {
            return {
              value: item.id.toString(),
              label: item.name,
              isIpe: item.isIpe,
              isInstallation: item.isInstallation,
              isMaterial: item.isMaterial,
            };
          })
        );
      });
    }
    if (eventTypeOptions.length === 0) {
      new EventRefHttp().get().then((data) => {
        setEventTypeOptions(
          data.map((item) => {
            return { value: item.id.toString(), label: item.name };
          })
        );
      });
    }
  }, []);

  // * Initial simple param load
  useEffect(() => {
    if (materialFamilies.length === 0 || eventTypeOptions.length === 0) {
      return;
    }
    if (params.get('control_materialCategory') === null) {
      methods.setValue('materialCategory', 'all');
    } else {
      methods.setValue('materialCategory', params.get('control_materialCategory'));
    }
    if (params.get('control_materialType.materialFamily.id[]')) {
      const ids = params.getAll('control_materialType.materialFamily.id[]');
      const selectedMaterialFamily = materialFamilies.filter((item) => {
        return ids.includes(item.value);
      });
      methods.setValue('materialFamily', selectedMaterialFamily);
    }
    if (params.get('control_lastPlannedMaterialChecking.plannedDate[after]')) {
      methods.setValue(
        'minDateNextVerificationCheck',
        new Date(
          params.get('control_lastPlannedMaterialChecking.plannedDate[after]') + 'T00:00:00.000Z' || ''
        )
      );
    }
    if (params.get('control_lastPlannedMaterialChecking.plannedDate[before]')) {
      methods.setValue(
        'maxDateNextVerificationCheck',
        new Date(
          params.get('control_lastPlannedMaterialChecking.plannedDate[before]') + 'T00:00:00.000Z' || ''
        )
      );
    }
    if (params.has('control_lastPlannedMaterialChecking.eventRef.id[]')) {
      const ids = params.getAll('control_lastPlannedMaterialChecking.eventRef.id[]');
      const selectedEventType = eventTypeOptions.filter((item) => {
        return ids.includes(item.value);
      });
      methods.setValue('eventType', selectedEventType);
    }
    setParamsLoaded(true);
  }, [materialFamilies, eventTypeOptions]);

  function handleSortingFilter(selected: string[]) {
    const newSort: {
      'materialType.name': 'off' | 'asc' | 'desc';
      matriculation: 'off' | 'asc' | 'desc';
      'lastPlannedMaterialChecking.plannedDate': 'off' | 'asc' | 'desc';
      [key: string]: string; // for typing key and dereferencing as an usual array
    } = {
      'materialType.name': 'off',
      matriculation: 'off',
      'lastPlannedMaterialChecking.plannedDate': 'asc',
    };
    for (const selection of selected) {
      newSort[selection] = (sortings[selection] as string) === 'asc' ? 'desc' : 'asc';
    }

    setSortings(newSort);
    onSubmit(methods.getValues(), undefined, newSort);
  }

  function initSorting() {
    const newSort: {
      'materialType.name': 'off' | 'asc' | 'desc';
      matriculation: 'off' | 'asc' | 'desc';
      'lastPlannedMaterialChecking.plannedDate': 'off' | 'asc' | 'desc';
      [key: string]: string; // for typing key and dereferencing as an usual array
    } = {
      'materialType.name': 'off',
      matriculation: 'off',
      'lastPlannedMaterialChecking.plannedDate': 'asc',
    };
    params.forEach((value, key) => {
      if (key.includes('control_order')) {
        newSort[key.replace('control_order[', '').replace(']', '')] = value;
      }
    });
    setSortings(newSort);
  }

  return (
    <>
      <GridHeaderContainer>
        <Element>
          <GenericContainer>
            <FilterButton onClick={() => handleSortingFilter(['materialType.name'])}>
              <span className="filter-span">Type de matériel</span>
              {sortings['materialType.name'] === 'desc' ? (
                <ChevronUpBlack />
              ) : sortings['materialType.name'] === 'asc' ? (
                <ChevronDownBlack />
              ) : (
                <ChevronUpDownBlack />
              )}
            </FilterButton>
            <FilterButton
              isActive={materialFamilyFieldActive}
              onClick={(e) => {
                e.stopPropagation();
                setMaterialFamilyIsOpen(!materialFamilyIsOpen);
              }}
              id="material-family-filter"
            >
              {materialFamilyIsOpen ? <FilterFillBlue /> : <FilterBlack className="icon" />}
            </FilterButton>
            <Dropdown
              isOpen={materialFamilyIsOpen}
              attachedId="material-family-filter"
              down={true}
              menuWidth={360}
              menuPadding="19px"
              onClose={() => setMaterialFamilyIsOpen(false)}
              endYOverride={150}
              positionTweaks={dropdownPosTweaks.current}
            >
              <FormContainer>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <RadioClassic
                      name="materialCategory"
                      label="Voir seulement la catégorie:"
                      values={[
                        {
                          value: 'all',
                          label: 'Tout',
                        },
                        {
                          value: 'installation',
                          label: 'Installation',
                        },
                        {
                          value: 'materiel',
                          label: 'Matériel',
                        },
                        {
                          value: 'ipe',
                          label: 'EPI',
                        },
                      ]}
                      onChange={() => {
                        methods.setValue('materialFamily', [], { shouldTouch: true });
                      }}
                    />
                    <VSpacer size="10px"></VSpacer>
                    <Line></Line>
                    <VSpacer size="6px"></VSpacer>
                    <MultiSelect
                      label="Filtrer par famille :"
                      placeholder="Choisir une famille"
                      name="materialFamily"
                      options={materialFamilies.filter((item) => {
                        if (methods.getValues().materialCategory === 'installation') {
                          return item.isInstallation;
                        }
                        if (methods.getValues().materialCategory === 'materiel') {
                          return item.isMaterial;
                        }
                        if (methods.getValues().materialCategory === 'ipe') {
                          return item.isIpe;
                        }
                        return true;
                      })}
                    ></MultiSelect>
                    <VSpacer size="12.5px" />
                    <div className="form-buttons">
                      <Button
                        variant="bigLight"
                        callback={() => {
                          methods.setValue('materialCategory', undefined, { shouldTouch: false });
                          methods.setValue('materialFamily', [], { shouldTouch: true });
                        }}
                        type="submit"
                      >
                        Supprimer le filtre
                      </Button>
                      <Button variant="primary" icon={CheckedWhite} type="submit">
                        Appliquer
                      </Button>
                    </div>
                  </form>
                </FormProvider>
              </FormContainer>
            </Dropdown>
          </GenericContainer>
        </Element>
      </GridHeaderContainer>
      <GridHeaderContainer>
        <Element>
          <GenericContainer>
            <FilterButton onClick={() => handleSortingFilter(['matriculation'])}>
              <span className="filter-span">Immatriculation / Identification</span>
              {sortings['matriculation'] === 'desc' ? (
                <ChevronUpBlack />
              ) : sortings['matriculation'] === 'asc' ? (
                <ChevronDownBlack />
              ) : (
                <ChevronUpDownBlack />
              )}
            </FilterButton>
          </GenericContainer>
        </Element>
      </GridHeaderContainer>
      <GridHeaderContainer>
        <Element>
          <GenericContainer>
            <FilterButton onClick={() => handleSortingFilter(['lastPlannedMaterialChecking.plannedDate'])}>
              <span className="filter-span">Date prévue</span>
              {sortings['lastPlannedMaterialChecking.plannedDate'] === 'desc' ? (
                <ChevronUpBlack />
              ) : sortings['lastPlannedMaterialChecking.plannedDate'] === 'asc' ? (
                <ChevronDownBlack />
              ) : (
                <ChevronUpDownBlack />
              )}
            </FilterButton>
            <FilterButton
              isActive={plannedDateFieldActive}
              onClick={(e) => {
                e.stopPropagation();
                setPlannedDateIsOpen(!plannedDateIsOpen);
              }}
              id="planned-date-filter"
            >
              {plannedDateIsOpen ? <FilterFillBlue /> : <FilterBlack className="icon" />}
            </FilterButton>
            <Dropdown
              isOpen={plannedDateIsOpen}
              attachedId="planned-date-filter"
              down={true}
              menuWidth={360}
              menuPadding="19px"
              onClose={() => setPlannedDateIsOpen(false)}
              endYOverride={150}
              positionTweaks={dropdownPosTweaks.current}
            >
              <FormContainer>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="form-checkbox"></div>
                    <DateField
                      name="minDateNextVerificationCheck"
                      label="Entre le"
                      placeholder="Optionnel"
                      required={false}
                      error={
                        errors &&
                        errors.minDateNextVerificationCheck &&
                        errors.minDateNextVerificationCheck.message
                      }
                    ></DateField>
                    <VSpacer size="12.5px" />
                    <DateField
                      name="maxDateNextVerificationCheck"
                      label="et le"
                      required={false}
                      placeholder="Optionnel"
                      error={
                        errors &&
                        errors.maxDateNextVerificationCheck &&
                        errors.maxDateNextVerificationCheck.message
                      }
                    ></DateField>
                    <VSpacer size="25px" />
                    <div className="form-buttons">
                      <Button
                        variant="bigLight"
                        callback={() => {
                          methods.setValue('minDateNextVerificationCheck', undefined, {
                            shouldTouch: false,
                          });
                          methods.setValue('maxDateNextVerificationCheck', undefined, {
                            shouldTouch: false,
                          });
                        }}
                        type="submit"
                      >
                        Supprimer le filtre
                      </Button>
                      <Button variant="primary" icon={CheckedWhite} type="submit">
                        Appliquer
                      </Button>
                    </div>
                  </form>
                </FormProvider>
              </FormContainer>
            </Dropdown>
          </GenericContainer>
        </Element>
      </GridHeaderContainer>
      <GridHeaderContainer>
        <Element>
          <GenericContainer>
            <span>Type d'évenement</span>
            <VSpacer size="4px" />
            <FilterButton
              isActive={eventTypeFieldsActive}
              onClick={(e) => {
                e.stopPropagation();
                setEventTypeIsOpen(!eventTypeIsOpen);
              }}
              id="event-type-filter"
            >
              {eventTypeIsOpen ? <FilterFillBlue /> : <FilterBlack className="icon" />}
            </FilterButton>
            <Dropdown
              isOpen={eventTypeIsOpen}
              attachedId="event-type-filter"
              down={true}
              menuWidth={360}
              menuPadding="19px"
              onClose={() => setEventTypeIsOpen(false)}
              endYOverride={150}
              positionTweaks={dropdownPosTweaks.current}
            >
              <>
                <FormContainer>
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                      <MultiSelect
                        name="eventType"
                        options={eventTypeOptions}
                        placeholder="Choisir un événement"
                      ></MultiSelect>
                      <VSpacer size="12.5px" />
                      <div className="form-buttons">
                        <Button
                          variant="bigLight"
                          callback={() => {
                            methods.setValue('eventType', null);
                          }}
                          type="submit"
                        >
                          Supprimer le filtre
                        </Button>
                        <Button variant="primary" icon={CheckedWhite} type="submit">
                          Appliquer
                        </Button>
                      </div>
                    </form>
                  </FormProvider>
                </FormContainer>
              </>
            </Dropdown>
          </GenericContainer>
        </Element>
      </GridHeaderContainer>
      <GridHeaderContainer>
        <Element>
          <GenericContainer>
            <span>Attribué à</span>
            <VSpacer size="4px" />
          </GenericContainer>
        </Element>
      </GridHeaderContainer>
      <GridHeaderContainer>
        <Element>
          <GenericContainer>
            <span>Statut</span>
            <FilterButton
              className={isScrappedFieldsActive ? 'active' : ''}
              onClick={(e) => {
                e.stopPropagation();
                setIsScrappedIsOpen(!isScrappedIsOpen);
              }}
              id="isScrapped-filter"
            >
              {isScrappedIsOpen ? <FilterFillBlue /> : <FilterBlack className="icon" />}
            </FilterButton>
            <Dropdown
              isOpen={isScrappedIsOpen}
              attachedId="isScrapped-filter"
              down={true}
              menuWidth={360}
              menuPadding="19px"
              onClose={() => setIsScrappedIsOpen(false)}
              endYOverride={150}
              positionTweaks={dropdownPosTweaks.current}
            >
              <FormContainer>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="form-checkbox"></div>
                    <RadioClassic
                      name="isScrapped"
                      label="Voir seulement le matériel:"
                      values={[
                        {
                          value: 'false',
                          label: 'Actif',
                        },
                        {
                          value: 'true',
                          label: 'Inactif',
                        },
                      ]}
                    />
                    <VSpacer size="12.5px" />
                    <div className="form-buttons">
                      <Button
                        variant="bigLight"
                        callback={() => {
                          methods.setValue('isScrapped', '', { shouldTouch: true });
                        }}
                        type="submit"
                      >
                        Supprimer le filtre
                      </Button>
                      <Button variant="primary" icon={CheckedWhite} type="submit">
                        Appliquer
                      </Button>
                    </div>
                  </form>
                </FormProvider>
              </FormContainer>
            </Dropdown>
          </GenericContainer>
        </Element>
      </GridHeaderContainer>
    </>
  );
}

interface Props {
  title: string;
  onQueryParamsChanged: (params: URLSearchParams) => void;
}

export function DeadlineBlock(props: Props) {
  const responsive = useResponsiveContext();
  const { user, readOnly } = useAuthContext();
  const { organizationId } = useParams<{ organizationId: string }>();
  const [opened, setOpened] = useState(-1);
  const [materialCount, setMaterialCount] = useState<number>();

  const [employees, fetchEmployees] = useEmployeeStore((state) => [state.data, state.fetch]);

  const [summaryModalOpened, setSummaryModalOpened] = useState<boolean>(false);
  const [summaryId, setSummaryId] = useState<number | undefined>(undefined);

  const [incrementTotalDeadlines] = useListStore((state) => [state.incrementTotalDeadlines]);

  const params = useQuery();
  const navigate = useNavigate();

  // Allow to make combined changes and trigger call only once.
  const [fullQuery, setFullQuery] = useState<{ query: string; pageNumber: number }>({
    query: '',
    pageNumber: 1,
  });

  const { items, setItems, hasMore, trigger, alreadyFetchData } = useHttpQuery<MaterialSheet>(
    MaterialSheetHttp,
    fullQuery.query,
    fullQuery.pageNumber,
    setMaterialCount
  );

  // Fetch employees
  useEffect(() => {
    if (organizationId === undefined) return;
    fetchEmployees({ organizationId });
  }, [fetchEmployees, organizationId]);

  useEffect(() => {
    if (organizationId) {
      params.set('group.organizationId', organizationId || '');
      params.set('control_order[lastPlannedMaterialChecking.plannedDate]', 'asc');
      params.set('exists[lastPlannedMaterialChecking]', 'true');

      const filter = new URLSearchParams(params.toString());
      if (filter.get('materialCategory') === 'ipe') {
        filter.set('materialType.materialFamily.isIpe', 'true');
        filter.delete('materialType.materialFamily.isMaterial');
        filter.delete('materialType.materialFamily.isInstallation');
      } else if (filter.get('materialCategory') === 'materiel') {
        filter.set('materialType.materialFamily.isMaterial', 'true');
        filter.delete('materialType.materialFamily.isIpe');
        filter.delete('materialType.materialFamily.isInstallation');
      } else if (filter.get('materialCategory') === 'installation') {
        filter.set('materialType.materialFamily.isInstallation', 'true');
        filter.delete('materialType.materialFamily.isIpe');
        filter.delete('materialType.materialFamily.isMaterial');
      }
      props.onQueryParamsChanged(filter);
      setFullQuery(() => {
        return {
          query: filter.toString().replaceAll('control_', ''),
          pageNumber: 1,
        };
      });
    }
  }, [organizationId]);

  //#region Widget methods
  const triggerQuery = () => {
    return trigger(setFullQuery);
  };
  //#endregion

  //#region Widget methods
  function handleFilters(filterForm: Record<string, string[]>) {
    const filter = new URLSearchParams();
    for (const [key, values] of Object.entries(filterForm)) {
      for (const value of values) {
        filter.append(key, value);
      }
    }

    //#region Handle material category filtering
    filter.delete('materialType.materialFamily.isMaterial');
    filter.delete('materialType.materialFamily.isInstallation');
    filter.delete('materialType.materialFamily.isIpe');
    if (filter.get('control_materialCategory') === 'ipe') {
      filter.set('materialType.materialFamily.isIpe', 'true');
    } else if (filter.get('control_materialCategory') === 'materiel') {
      filter.set('materialType.materialFamily.isMaterial', 'true');
    } else if (filter.get('control_materialCategory') === 'installation') {
      filter.set('materialType.materialFamily.isInstallation', 'true');
    }
    filter.delete('control_materialCategory');
    //#endregion

    if (user?.groupId) {
      filter.set('group.organizationId', user?.groupId.toString());
      filter.set('hasPlannedChecking', 'true');
      filter.set('exists[lastPlannedMaterialChecking]', 'true');
      props.onQueryParamsChanged(filter);

      setFullQuery(() => {
        return {
          query: filter.toString().replaceAll('control_', ''),
          pageNumber: 1,
        };
      });
    }
  }

  function handleDropdownToggle(id: number) {
    opened !== id ? setOpened(id) : setOpened(-1);
  }
  //#endregion

  //#region Data handling methods
  function deleteMaterial(id: number): void {
    //eslint-disable-next-line
    new MaterialSheetHttp().delete(id).then((data: any) => {
      incrementTotalDeadlines(-1);
      setItems(items.filter((material) => material.id !== id));

      window.scrollTo({ top: 0 });
    });
  }
  //#endregion

  const { DisplayMutateModals, openDeletePopin } = useMutateModals(
    undefined,
    undefined,
    undefined,
    undefined,
    MaterialDelete,
    { modalSize: 'xs', name: 'Supprimer un matériel', trigger: triggerQuery },
    deleteMaterial
  );

  function downloadDocument(id: number) {
    new MaterialSheetHttp().exportMaterialSheetDocument(id).then(({ data, filename }) => {
      downloadFile(data, filename, 'xlsx');
      notify();
    });
  }

  function openSummaryPopin(id: number) {
    setSummaryModalOpened(true);
    setSummaryId(id);
  }

  const handleNextPage = function () {
    if (hasMore) {
      setFullQuery((prevFullQuery) => {
        return { query: prevFullQuery.query, pageNumber: prevFullQuery.pageNumber + 1 };
      });
    }
  };

  const dontHaveMeaningfulParams = useCallback(
    function () {
      return (
        params.toString().length == 0 ||
        (params.toString().length == 1 &&
          params.get('control_order[lastPlannedMaterialChecking.plannedDate]') != null)
      );
    },
    [params]
  );

  return (
    <Root className="MaterialDeadlineBlock">
      {(!items || items.length === 0) && alreadyFetchData && dontHaveMeaningfulParams() ? (
        <>
          <VSpacer size={StyleData.spacing.xxl} />
          <MessagePage
            title="Vous n'avez actuellement aucune échéance sur vos matériels"
            description={''}
            image={Bonhomme2x}
          >
            <>
              <Button
                variant="primary"
                icon={PencilWhite}
                callback={() =>
                  navigate(routeToLink('/:organizationId/liste', { organizationId: user?.groupId }))
                }
                disabled={readOnly}
              >
                Modifier mes matériels
              </Button>
            </>
          </MessagePage>
          <VSpacer size="64px"></VSpacer>
          <TierContainer>
            <Text>
              L'OPPBTP met à votre disposition un outil permettant de suivre facilement les vérifications de
              votre matériel.
            </Text>
            {/* <CardLink
              name="Découvrir les ressources associées à votre disposition"
              image={ScreenRed}
              widthSize="310px"
            ></CardLink> */}
          </TierContainer>
          <VSpacer size={'6rem'}></VSpacer>
        </>
      ) : (
        <>
          {alreadyFetchData && employees !== undefined ? (
            <>
              <Title level={2} name={props.title} />
              <VSpacer size="12px" />
              {!responsive.isMobile ? (
                <DataGrid
                  format={tableConstants(
                    organizationId,
                    handleDropdownToggle,
                    opened,
                    setOpened,
                    openDeletePopin,
                    openSummaryPopin,
                    readOnly,
                    MaterialDisplayTypes.Deadline,
                    downloadDocument,
                    employees
                  )}
                  data={items}
                  style={{
                    gridTemplateColumn: '250px 220px 128px 128px 128px 1fr',
                    gridColumnNumber: 6,
                  }}
                >
                  <TableHeader handleFilters={handleFilters} mode={MaterialDisplayTypes.Deadline} />
                </DataGrid>
              ) : (
                <CardDeck
                  format={cardConstants(
                    organizationId,
                    handleDropdownToggle,
                    opened,
                    setOpened,
                    openDeletePopin,
                    openSummaryPopin,
                    readOnly,
                    MaterialDisplayTypes.Deadline,
                    downloadDocument,
                    employees
                  )}
                  data={items}
                ></CardDeck>
              )}
              <VSpacer size="1rem"></VSpacer>
              <TableFooter>
                <div>
                  <Text fontWeight="bold" fsize="md">
                    {materialCount
                      ? `${materialCount} échéance${materialCount > 1 ? 's' : ''}`
                      : 'Aucune échéance'}
                  </Text>
                </div>
                <div>
                  {hasMore ? (
                    <button className="more-results" onClick={() => handleNextPage()}>
                      Afficher plus de résultats <ChevronDownBlack />{' '}
                    </button>
                  ) : (
                    <Text fsize="sm">Vous avez atteint les derniers résultats.</Text>
                  )}
                </div>
              </TableFooter>
            </>
          ) : (
            <LoadingScreen></LoadingScreen>
          )}
        </>
      )}

      <DisplayMutateModals />
      <Summary
        isOpen={summaryModalOpened}
        onClose={() => setSummaryModalOpened(false)}
        summaryId={summaryId}
        name="Récapitulatif"
        readOnly={false}
        downloadDocument={downloadDocument}
      ></Summary>
    </Root>
  );
}

const TierContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 3fr;

  @media all and (max-width: 960px) {
    grid-template-columns: auto;
  }
`;
