import styled from 'styled-components';

const { REACT_APP_DEV_MESSAGES } = process.env;

/**
 * Display a TODO message if environment is set to display dev messages.
 * @param message The message to display (may contains '\n' for multiline texts).
 * @returns A widget to display it (if in dev mode, `undefined` otherwise).
 */
export function devTodo(message: string) {
  if (!shouldDisplayDevMessages()) return undefined;
  return (
    <DevPrintWidget
      message={message}
      tag={'TODO'}
      tagBackground={'linear-gradient(0.125turn, black, #9198e5)'}
    />
  );
}

/**
 * Display a DEBUG message if environment is set to display dev messages.
 * @param message The message to display (may contains '\n' for multiline texts).
 * @returns A widget to display it (if in dev mode, `undefined` otherwise).
 */
export function devDebug(message: string) {
  if (!shouldDisplayDevMessages()) return undefined;
  return <DevPrintWidget message={message} tag={'DEBUG'} tagBackground={'#7de6e4'} />;
}

/**
 * Display a LOG message if environment is set to display dev messages.
 * @param message The message to display (may contains '\n' for multiline texts).
 * @returns A widget to display it (if in dev mode, `undefined` otherwise).
 */
export function devLog(message: string) {
  if (!shouldDisplayDevMessages()) return undefined;
  return <DevPrintWidget message={message} tag={'LOG'} tagBackground={'#fbc309'} />;
}

/**
 * Display a WARN message if environment is set to display dev messages.
 * @param message The message to display (may contains '\n' for multiline texts).
 * @returns A widget to display it (if in dev mode, `undefined` otherwise).
 */
export function devWarn(message: string) {
  if (!shouldDisplayDevMessages()) return undefined;
  return <DevPrintWidget message={message} tag={'WARNING'} tagBackground={'#ef6d33'} />;
}

/**
 * Display a ERROR message if environment is set to display dev messages.
 * @param message The message to display (may contains '\n' for multiline texts).
 * @returns A widget to display it (if in dev mode, `undefined` otherwise).
 */
export function devError(message: string) {
  if (!shouldDisplayDevMessages()) return undefined;
  return <DevPrintWidget message={message} tag={'ERROR'} tagBackground={'#e71b47'} />;
}

/**
 * @param value The value to stringify.
 * @returns A stringification of the object on several lines.
 */
export function devStringify(value: any): string {
  if (value === undefined) return 'undefined';
  return JSON.stringify(value, undefined, '\u00a0\u00a0');
}

/**
 * @returns `true` is REACT_APP_DEV_MESSAGES, is set to display dev messages.
 */
function shouldDisplayDevMessages(): boolean {
  if (REACT_APP_DEV_MESSAGES === undefined) return false;
  if (REACT_APP_DEV_MESSAGES === 'true') return true;
  const asInt = parseInt(REACT_APP_DEV_MESSAGES);
  if (!isNaN(asInt)) {
    return asInt > 0;
  }
  return false;
}

export interface DevPrintWidgetProps {
  tag?: string;
  tagBackground?: string;
  message: string;
}

function formatMessage(message: string): JSX.Element {
  const messageLines = message.split('\n');
  return (
    <Message title={message}>
      {messageLines.map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ))}
    </Message>
  );
}

function DevPrintWidget(props: DevPrintWidgetProps) {
  return (
    <Root>
      {props.tag && <Tag style={{ background: props.tagBackground ?? 'black' }}>{props.tag}</Tag>}
      {formatMessage(props.message)}
    </Root>
  );
}

const Root = styled.div`
  background: #f7e8d2;
  color: darkred;
  padding: 1rem;
  display: flex;
  font-family: monospace;
  gap: 1em;
  align-items: flex-start;
  max-width: 100%;
`;

const Tag = styled.span`
  color: white;
  font-weight: bold;
  padding: 0.25rem;
  border-radius: 9999px;
  min-width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

const Message = styled.span`
  padding-top: 0.25rem;
`;
