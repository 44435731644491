import { useLoading } from '@agney/react-loading';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Accept, FileRejection, FileWithPath, useDropzone } from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';
import { Button } from '../../../components/Button';
import { VSpacer } from '../../../components/layout/Spacer';
import Line from '../../../components/Line';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import { useAuthContext } from '../../../contexts/AuthContext';
import MaterialTypeInterface from '../../../interfaces/MaterialType';
import { ONE_MO_SIZE } from '../../../utilities/ByteSizes';
import { quoteMarkCloseFrench, quoteMarkOpenFrench, spaceUnbreakable } from '../../../utilities/SpecialChars';
import StyleData from '../../../utilities/StyleData';
import {
  ButtonLikeSpan,
  ButtonLine,
  DefaultImage,
  EndContainer,
  Image,
  LoaderContainer,
  Root,
} from './MaterialImageStyle';

interface MaterialTypeDetails {
  name: MaterialTypeInterface['name'];
  custom: boolean;
}

export interface MaterialImageProps {
  name: string;
  initialImageUrl?: string;
  /** Defaults to `false`. */
  loading?: boolean;
  materialTypeDetails?: MaterialTypeDetails;
}

export function MaterialImage(props: MaterialImageProps) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { onChange } }) => (
        <MaterialImageCore
          onChange={onChange}
          initialImageUrl={props.initialImageUrl}
          loading={props.loading ?? false}
          materialTypeDetails={props.materialTypeDetails}
        />
      )}
    />
  );
}

interface MaterialImageCoreProps {
  onChange: (file: FileWithPath | null) => void;
  initialImageUrl?: string;
  loading: boolean;
  materialTypeDetails?: MaterialTypeDetails;
}

function MaterialImageCore(props: MaterialImageCoreProps) {
  const { readOnly } = useAuthContext();
  const [error, setError] = useState<string | undefined>();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const { containerProps, indicatorEl: loader } = useLoading({
    loading: true,
    indicator: <div className="lds-dual-ring" />,
  });
  const acceptedImages: Accept = useMemo(
    () => ({
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/webp': ['.webp'],
      'image/gif': ['.gif'],
    }),
    []
  );

  useEffect(() => {
    if (error) {
      console.debug(error);
    }
  }, []);

  // Undefined for no override, null for deletion.
  const [imageOverrideUrl, setImageOverrideUrl] = useState<string | undefined | null>(undefined);

  const onDrop = useCallback(
    (droppedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
      for (const fileError of fileRejections.flatMap((el) => el.errors)) {
        if (fileError.code === 'file-too-large' || fileError.code === 'file-invalid-type') {
          setError(
            `L'image doit être au format ${Object.values(acceptedImages)
              .flatMap((extensions) => extensions)
              .join(', ')} et ne doit pas excéder le poids de 1Mo.`
          );
          break;
        } else {
          console.error('Unknown error met while dropping image', fileError);
        }
      }

      if (droppedFiles.length === 0) return;
      setError(undefined);

      if (imageOverrideUrl) {
        URL.revokeObjectURL(imageOverrideUrl);
      }

      setImageOverrideUrl(URL.createObjectURL(droppedFiles[0]));
      props.onChange(droppedFiles[0]);
    },
    [acceptedImages, imageOverrideUrl, props]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: ONE_MO_SIZE,
    disabled: readOnly,
    accept: acceptedImages,
  });

  // Clean created object url when the component is destroyed
  useEffect(() => {
    return () => {
      if (imageOverrideUrl) URL.revokeObjectURL(imageOverrideUrl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const imageUrl =
    imageOverrideUrl === null || (imageOverrideUrl === undefined && props.initialImageUrl === undefined)
      ? null
      : imageOverrideUrl ?? props.initialImageUrl;

  return (
    <Root {...getRootProps()} {...containerProps} className={readOnly || props.loading ? 'disabled' : ''}>
      <input {...getInputProps()} />
      {props.loading ? (
        <LoaderContainer>{loader}</LoaderContainer>
      ) : imageUrl ? (
        <Image src={imageOverrideUrl ?? props.initialImageUrl} />
      ) : (
        <DefaultImage />
      )}
      {!readOnly && !props.loading && (
        <ButtonLine>
          <ButtonLikeSpan>{imageUrl !== null ? 'Modifier' : 'Ajouter'}</ButtonLikeSpan>
          {imageUrl !== null && (
            <ButtonLikeSpan
              onClick={(e) => {
                setDeleteModalOpen(true);
                e.stopPropagation();
              }}
            >
              {'Supprimer'}
            </ButtonLikeSpan>
          )}
        </ButtonLine>
      )}
      {error && (
        <Text fsize="sm" color={StyleData.color.primary}>
          {error}
        </Text>
      )}

      {/* Prevent the click to bubble up and thus opening the file picker. */}
      <div onClick={(e) => e.stopPropagation()}>
        <Modal name="Supprimer le visuel" onClose={() => setDeleteModalOpen(false)} isOpen={deleteModalOpen}>
          <>
            <VSpacer size="12px" />
            {props.materialTypeDetails === undefined ? (
              <Text>Voulez-vous vraiment supprimer le visuel associé au matériel ?</Text>
            ) : (
              <>
                <Text>Voulez-vous vraiment supprimer cet élément ?</Text>
                <VSpacer size="12px" />
                <Text fontWeight="bold">
                  Image associée au matériel de type {props.materialTypeDetails.custom ? 'personnalisé' : ''}
                  {props.materialTypeDetails.name.length > 0
                    ? ` ${quoteMarkOpenFrench}${spaceUnbreakable}${props.materialTypeDetails.name}${spaceUnbreakable}${quoteMarkCloseFrench}`
                    : ''}
                  .
                </Text>
              </>
            )}
            <VSpacer size="12px" />
            <VSpacer size="28px" />
            <Line />
            <VSpacer size="16px" />
            <EndContainer>
              <Button
                type="reset"
                variant="bigLight"
                callback={() => {
                  setDeleteModalOpen(false);
                }}
              >
                Annuler la suppression
              </Button>
              <Button
                variant="primary"
                type="submit"
                callback={() => {
                  setImageOverrideUrl(null);
                  props.onChange(null);
                  setDeleteModalOpen(false);
                }}
              >
                Confirmer la suppression
              </Button>
            </EndContainer>
          </>
        </Modal>
      </div>
    </Root>
  );
}
