import { AxiosResponse } from 'axios';

/**
 * Attempts to find the filename given by the response if there is any.
 * @param response The response to parse.
 * @returns The filename if found.
 */
export function extractFilename(response: AxiosResponse<any, any>): string | undefined {
  return response.headers['content-disposition'].split('=').pop();
}
