import styled from 'styled-components';
import StyleData from '../../utilities/StyleData';

export const PaddingContainer = styled.div`
  flex-basis: 100%;
`;

export const GenericContainer = styled.div`
  display: flex;
  flex-basis: 100%;
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    justify-content: space-between;
  }
`;

export const QualificationsMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobileQualifications = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const QualificationsNumber = styled.span`
  white-space: nowrap;
  color: ${StyleData.color.text};
  font-family: ${StyleData.font.primary};
  font-size: ${StyleData.fsize.sm};
  font-weight: 600;
  line-height: 16px;
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    line-height: 15px;
    font-size: ${StyleData.fsize.xxs};
    font-weight: normal;
    font-family: ${StyleData.font.secondary};
  }
`;

export const MedicalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const MedicalTitle = styled.div`
  font-size: ${StyleData.fsize.xs};
  font-weight: normal;
  font-family: ${StyleData.font.secondary};
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    font-size: ${StyleData.fsize.xxs};
  }
`;

export const StatusInfo = styled.div`
  grid-column: 2;
  color: ${StyleData.color.lightText};
  font-size: ${StyleData.fsize.xs};
  font-weight: normal;
  font-family: ${StyleData.font.secondary};
  white-space: pre;
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    font-size: ${StyleData.fsize.xxs};
    text-overflow: ellipsis;
    white-space: unset;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  gap: 2%;
  grid-template: auto / 25% 50% 21%;
`;
