import EntityInterface from '../interfaces/Entity';
import Entity from './Entity';

export default class EventFamily extends Entity implements EntityInterface {
  id!: number;
  name!: string;
  description!: string;
  hasPlannedDate!: boolean;

  constructor(data?: Partial<EventFamily>) {
    super();
    Object.assign(this, data);
  }
}
