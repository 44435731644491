import { useState } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import { AuthContextType } from './contexts/AuthContext';
import { useModalStore } from './store/ErrorModalStore';
import MaterialDeadline from './views/deadline/MaterialDeadline';
import MaterialSheetDetails from './views/details/MaterialSheetDetails';
import MaterialList from './views/list/MaterialList';
import MaterialUpdateValidation from './views/validation/MaterialUpdateValidation';

/**
 * @description This route object supports subroutes, lazy loading routes, guarding a route and its subroutes, adding a layout around a route component
 */
export const routes: RouteObject[] = [
  {
    index: true,
    path: ':organizationId/liste',
    element: <MaterialList />,
  },
  {
    index: false,
    path: ':organizationId/echeances',
    element: <MaterialDeadline />,
  },
  {
    index: false,
    path: ':organizationId/ajouter',
    element: <MaterialSheetDetails />,
  },
  {
    index: false,
    path: ':organizationId/validation/:isNewMaterial',
    element: <MaterialUpdateValidation />,
  },
  {
    index: false,
    path: ':organizationId/modifier/:materialSheetId',
    element: <MaterialSheetDetails />,
  },
];

type RouterType = { setAuthContext: (value: AuthContextType) => void };

const { REACT_APP_URL_EXT_PREVENTION_GESTIONNAIRES_ENTREPRISE } = process.env;

export function Router(routerType: RouterType) {
  const [setErrorModalData, setErrorModalOpened] = useModalStore((state) => [state.setData, state.setIsOpen]);

  const [hasReadonlyOpened, setHasReadonlyOpened] = useState<boolean>(false);

  const printReadonly = () => {
    if (!hasReadonlyOpened) {
      setErrorModalData({
        name: 'Mode lecture seule',
        subtitle: 'Note : accès en lecture seule',
        description:
          "Vous pouvez accéder à l'outil de suivi du matériel en lecture uniquement. </br>" +
          'Pour pouvoir créer ou modifier des matériels, <a href=' +
          `${REACT_APP_URL_EXT_PREVENTION_GESTIONNAIRES_ENTREPRISE}` +
          '>contactez un gestionnaire</a> pouvant modifier vos droits',
        actionText: 'Continuer',
      });
      setHasReadonlyOpened(true);
      setErrorModalOpened(true);
    }
  };

  const element = useRoutes([
    {
      element: <AuthGuard setAuthContext={routerType.setAuthContext} printReadonly={printReadonly} />,
      path: '/',
      children: routes,
    },
  ]);
  return element;
}

export const routeToLink = (
  routePath: string,
  params: Record<string, string | number | boolean | undefined>
): string => {
  for (const paramKey in params) {
    switch (typeof params[paramKey]) {
      case 'number':
        routePath = routePath.replaceAll(':' + paramKey, (params[paramKey] as number).toString());
        break;
      case 'string':
        routePath = routePath.replaceAll(':' + paramKey, params[paramKey] as string);
        break;
      case 'boolean':
        routePath = routePath.replaceAll(':' + paramKey, (params[paramKey] as boolean) ? 'true' : 'false');
        break;
      default:
        break;
    }
  }
  return routePath;
};

export function routeParameterToBoolean(routeParameter: string | undefined): boolean {
  if (routeParameter === 'true') {
    return true;
  } else if (routeParameter === 'false') {
    return false;
  } else {
    throw new Error(`Route parameter with value "${routeParameter}" cannot be converted to a boolean value.`);
  }
}
