import { useEffect, useRef, useState } from 'react';
import { EventFamilyContext } from '../../../contexts/EventFamilyContext';
import LoadingScreen from '../../../core/LoadingScreen';
import MaterialTypeInterface from '../../../interfaces/MaterialType';
import EventFamily from '../../../models/EventFamily';
import EventResult from '../../../models/EventResult';
import MaterialType from '../../../models/MaterialType';
import EventFamilyHttp from '../../../services/http/EventFamilyHttp';
import EventResultHttp from '../../../services/http/EventResultHttp';
import MaterialTypeHttp from '../../../services/http/MaterialTypeHttp';
import { useMaterialSheetStore } from '../../../store/MaterialSheetStore';
import { ArrayUtils } from '../../../utilities/Array';
import { unwrap } from '../../../utilities/Assertions';
import { EventFamilyBlock } from '../event-family-block/EventsFamilyBlock';
import { Events } from './EventsBlockStyle';

interface EventsBlockProps {
  materialTypeId?: MaterialType['id'];
  materialTypeOverride?: Partial<Pick<MaterialTypeInterface, 'periodicity' | 'isRepository'>>;
}

/** Displays the {@link EventFamilyBlock}s linked to a {@link MaterialSheet}. */
export function EventsBlock(props: EventsBlockProps) {
  const [eventFamilies, setEventFamilies] = useState<EventFamily[] | undefined>(undefined);
  const [eventResults, setEventResults] = useState<EventResult[] | undefined>(undefined);
  const [fetchedMaterialType, setFetchedMaterialType] = useState<MaterialTypeInterface | undefined>(
    undefined
  );
  const [materialType, setMaterialType] = useState<MaterialTypeInterface | undefined>(undefined); // material type eventually overrided by materialTypeOverride
  const materialTypeInitialized = useRef(false);
  const dateCommissioning = useMaterialSheetStore((state) => state.dateCommissioning);
  const loading = ArrayUtils.any([eventFamilies, eventResults, materialType], (el) => el === undefined);
  const clearMaterialCheckings = useMaterialSheetStore((state) => state.clearMaterialCheckings);

  // Fetch event families
  useEffect(() => {
    if (props.materialTypeId === undefined) return;

    (async () => {
      setEventFamilies(undefined);
      const fetchedEventFamilies = await new EventFamilyHttp().get({
        'eventTypes.materialType.id': props.materialTypeId,
      });

      setEventFamilies(fetchedEventFamilies);
    })();
  }, [props.materialTypeId]);

  // Clear material checkings when changing event families.
  useEffect(() => {
    if (eventFamilies === undefined) return;

    if (materialTypeInitialized.current) {
      clearMaterialCheckings();
    } else {
      materialTypeInitialized.current = true;
    }
  }, [props.materialTypeId, eventFamilies, materialTypeInitialized, clearMaterialCheckings]);

  // Fetch event results
  useEffect(() => {
    new EventResultHttp().get({ pagination: false }).then((fetchedEventResult) => {
      setEventResults(fetchedEventResult);
    });
  }, []);

  // Fetch material type
  useEffect(() => {
    if (!props.materialTypeId) return;
    new MaterialTypeHttp().getById(props.materialTypeId).then((fetchedMaterialType) => {
      setFetchedMaterialType(fetchedMaterialType);
    });
  }, [props.materialTypeId]);

  useEffect(() => {
    if (props.materialTypeOverride && fetchedMaterialType) {
      const overridedMaterialType = Object.assign({}, fetchedMaterialType);
      if (props.materialTypeOverride.isRepository !== undefined) {
        overridedMaterialType.isRepository = props.materialTypeOverride.isRepository;
      }
      if (props.materialTypeOverride.periodicity !== undefined) {
        overridedMaterialType.periodicity = props.materialTypeOverride.periodicity;
      }
      setMaterialType(overridedMaterialType);
    } else {
      setMaterialType(fetchedMaterialType);
    }
  }, [props.materialTypeOverride, fetchedMaterialType]);

  // We should display the widget only if material type id is defined,
  // and if we have a non-repository material, we should display only if periodicity is given.
  const shouldDisplay =
    props.materialTypeId !== undefined &&
    dateCommissioning !== null &&
    !(
      props.materialTypeOverride?.isRepository === false &&
      props.materialTypeOverride?.periodicity === undefined
    );

  // Rendering
  return (
    <div className="EventsBlock">
      {shouldDisplay && (
        <>
          {loading ? (
            <LoadingScreen></LoadingScreen>
          ) : (
            <Events>
              {unwrap(eventFamilies).map((evFamily) => (
                <EventFamilyContext.Provider
                  key={evFamily.id}
                  value={{
                    eventFamily: evFamily,
                    eventResults: unwrap(eventResults),
                    materialType: unwrap(materialType),
                  }}
                >
                  <EventFamilyBlock></EventFamilyBlock>
                </EventFamilyContext.Provider>
              ))}
            </Events>
          )}
        </>
      )}
    </div>
  );
}
