import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { routeToLink } from '../../App.routing';
import { Button } from '../../components/Button';
import { HSpacer } from '../../components/layout/Spacer';
import { AuthContext, useAuthContext } from '../../contexts/AuthContext';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import { AddBoldWhite, UploadWhite } from '../../icons';
import MaterialSheetHttp from '../../services/http/MaterialSheetHttp';
import { downloadFile } from '../../utilities/DownloadFile';
import { notify } from '../../utilities/Notify';
import { queryStringToQueryObject } from '../../utilities/Object';
import StyleData from '../../utilities/StyleData';

interface ModalProps {
  query: string;
  trigger: () => void;
}

function MaterialListToolbarActions(props: ModalProps): JSX.Element {
  const responsive = useContext(ResponsiveContext);
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { readOnly } = useContext(AuthContext);
  const [downloading, setDownloading] = useState<boolean>(false);

  type UrlParams = {
    organizationId: string;
  };
  const { organizationId } = useParams<UrlParams>();

  const exportMaterial = function () {
    setDownloading(true);
    notify();
    const params = queryStringToQueryObject(props.query.replaceAll('%5B', '[').replaceAll('%5D', ']'));

    const exportChoice = {
      'group.organizationId': user?.groupId,
      ...params,
    };
    new MaterialSheetHttp().getFile('xlsx', exportChoice).then(({ data, filename }) => {
      downloadFile(data, filename, 'xlsx');
      setDownloading(false);
    });
  };

  return (
    <ToolbarActions>
      <Button
        variant={!responsive.isMobile ? 'primary' : 'primaryRound'}
        callback={() => navigate(routeToLink('/:organizationId/ajouter', { organizationId }))}
        widthSize="16px"
        heightSize="16px"
        icon={AddBoldWhite}
        disabled={readOnly}
      >
        {!responsive.isMobile ? 'Ajouter' : ''}
      </Button>
      <HSpacer size="8px" />
      <Button
        variant={!responsive.isMobile ? 'secondary' : 'secondaryRound'}
        callback={() => exportMaterial()}
        widthSize="16px"
        heightSize="16px"
        icon={UploadWhite}
        disabled={downloading}
      >
        Exporter
      </Button>
    </ToolbarActions>
  );
}

export const ToolbarActions = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    display: none;
  }
`;

export default MaterialListToolbarActions;
