import { z, ZodErrorMap } from 'zod';
import { defaultErrorMap } from '../../App';

export const errorMap: ZodErrorMap = (issue, ctx) => {
  if (issue.code === 'invalid_enum_value') {
    return {
      message: 'Le champ est requis.',
    };
  } else {
    return defaultErrorMap(issue, ctx);
  }
};

export const schema = z
  .object({
    materialFamily: z.object({ value: z.string() }),
    materialType: z.object({ value: z.string() }), // Optional when customMaterialType is true
    customMaterialType: z.boolean(),
    customMaterialTypeName: z.string().trim().optional(),
    customMaterialTypePeriodicity: z.object({ value: z.string() }).or(z.null()).optional(),
    dateCommissioning: z.date(),
    matriculation: z.string().min(1, 'Le champ est requis.'),
    model: z.string(),
    brand: z.string(),
    comment: z.string(),
    conformityCertificate: z.string(),
    scrapped: z.enum(['no', 'yes']),
    remoteCollaborators: z.array(z.object({ value: z.string() })).optional(),
    // `null` means delete the attached image if any, `undefined` means no change (no image when in material addition mode)
    image: z.instanceof(File).or(z.null()).optional(),
    delay: z
      .object({
        value: z.string(),
        label: z.string(),
      })
      .optional()
      .nullable(),
    notifyEmployee: z
      .object({
        value: z.string(),
        label: z.string(),
      })
      .array()
      .default([]),
    dateNotif: z.instanceof(Date, { message: 'Veuillez entrer une date valide' }).optional().nullable(),
  })
  // Custom logic validation for custom material types
  .superRefine((data, ctx) => {
    if (data.customMaterialType === true) {
      if (data.customMaterialTypeName === undefined || data.customMaterialTypeName.length === 0) {
        ctx.addIssue({
          code: 'custom',
          message: 'Le champ est requis.',
          path: ['customMaterialTypeName'],
        });
      }
      if (data.customMaterialTypePeriodicity === undefined || data.customMaterialTypePeriodicity === null) {
        ctx.addIssue({
          code: 'custom',
          message: 'Le champ est requis.',
          path: ['customMaterialTypePeriodicity'],
        });
      }
    }
  });

export type TypedFieldValues = z.infer<typeof schema>;
