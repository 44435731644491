import styled from 'styled-components';
import StyleData from '../utilities/StyleData';

export const FlexButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  flex-wrap: wrap-reverse;
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    justify-content: center;
  }
`;
