import React, { ReactElement } from 'react';
import styled from 'styled-components';
import StyleData from '../../utilities/StyleData';
interface Props {
  children: JSX.Element[];
}

function Tabs({ children }: Props): ReactElement {
  return (
    <StyledTabs>
      {children.map((child, i) => {
        const tabItem = <li>{child}</li>;

        return <React.Fragment key={i}>{tabItem}</React.Fragment>;
      })}
    </StyledTabs>
  );
}

const StyledTabs = styled.ul`
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0 75px 0 0;
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    margin: 0 20px 0 0;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.sm}px) {
    margin: unset;
  }
`;

export default Tabs;
