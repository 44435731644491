import styled from 'styled-components';
import { CloseBlack } from '../icons';
import StyleData from '../utilities/StyleData';

export const ErrorIcon = styled(CloseBlack)`
  border-radius: 50%;
  background-color: ${StyleData.color.primary};
  .st0-fill {
    fill: white;
  }
  min-height: 24px;
  min-width: 24px;
}`;
