import styled from 'styled-components';
import { default as StyleData, default as styleData } from '../../../utilities/StyleData';
import { colTemplate } from '../material-checking-line/MaterialCheckingLineStyle';

export const Root = styled.article`
  font-size: ${styleData.fsize.md};
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: ${colTemplate};

  > div {
    padding-left: 15px;
  }

  @media all and (max-width: ${styleData.breakpoints.max.smd}px) {
    display: none;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export const Events = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${StyleData.spacing.xl};
`;
