import { Params, useParams } from 'react-router-dom';
import styled from 'styled-components';
import MultiSelect, { Option } from '../../../components/input/MultiSelect';
import { FieldCaption, FieldWithCaptionContainer } from '../../../components/layout/FormLayouts';
import Text from '../../../components/Text';
import { Info } from '../../../icons';
import { Employee } from '../../../models/Employee';
import { useEmployeeStore } from '../../../store/EmployeeStore';
import { unwrap } from '../../../utilities/Assertions';
import StyleData from '../../../utilities/StyleData';

const { REACT_APP_URL_EXT_PER } = process.env;

function employeeToMultiSelectOption(employee: Employee): Option {
  return { label: employee.name, value: employee.id.toString() };
}

export function employeesToMultiSelectOptions(employees: Employee[]): Option[] {
  return employees.map(employeeToMultiSelectOption);
}

export interface MaterialEmployeeSelectProps {
  /** Defaults to `false`. */
  disabled?: boolean;
}

export function MaterialEmployeeSelect(props: MaterialEmployeeSelectProps) {
  const { organizationId } = useParams<Params>();
  const employees = useEmployeeStore((state) => state.data);
  useEmployeeStore((state) => state.fetch)({ organizationId: unwrap(organizationId) });

  return (
    <FieldWithCaptionContainer>
      <MultiSelect
        name="remoteCollaborators"
        options={employeesToMultiSelectOptions(employees ?? [])}
        label="Attribué à"
        creatable={false}
        placeholder="Sélectionnez..."
        tabIndex={6}
        disabled={employees === undefined || props.disabled}
      />
      <FieldCaption>
        <Info />
        <Text fsize="sm">
          <HasLink>
            <span>Le salarié doit être enregistré dans le </span>
            <a
              href={`${REACT_APP_URL_EXT_PER}/${organizationId}/collaborateurs`}
              target="_blank"
              rel="noreferrer"
            >
              Suivi du personnel
            </a>
            <span> pour pouvoir lui attribuer le matériel.</span>
          </HasLink>
        </Text>
      </FieldCaption>
    </FieldWithCaptionContainer>
  );
}

const HasLink = styled.span`
  a {
    color: ${StyleData.color.primary};
  }
`;
