import EntityInterface from '../interfaces/Entity';
import EventFamily from './EventFamily';
import EventRef from './EventRef';
import EventTypeRule from './EventTypeRule';
import MaterialType from './MaterialType';

export default class EventType implements EntityInterface {
  id!: number;
  name!: string;
  eventRef!: EventRef;
  eventFamily!: EventFamily;
  eventTypeRules!: EventTypeRule[];

  isDefaultValue!: boolean;
  materialType!: MaterialType;
  defaultPeriodicity!: number;

  constructor(data?: Partial<EventType>) {
    Object.assign(this, data);
  }
}
