import ReactTooltip from '@huner2/react-tooltip';
import React, { useState } from 'react';
import { Button } from '../../../components/Button';
import Text from '../../../components/Text';
import { tooltipStyle } from '../../../components/Tooltip.style';
import { TooltipContainer } from '../../../components/TooltipContainer';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useEventFamilyContext } from '../../../contexts/EventFamilyContext';
import { ChatBlack } from '../../../icons';
import { DatalayerEventLabel } from '../../../models/Datalayer';
import MaterialChecking from '../../../models/MaterialChecking';
import { DatalayerService } from '../../../services/DatalayerService';
import { documentNeedsUpdate, useMaterialSheetStore } from '../../../store/MaterialSheetStore';
import { unwrap } from '../../../utilities/Assertions';
import DateUtilities from '../../../utilities/DateUtility';
import { downloadFile, downloadFileByURL } from '../../../utilities/DownloadFile';
import StyleData from '../../../utilities/StyleData';
import { MaterialCheckingFinalizationPopin } from '../material-checking-finalization-popin/MaterialCheckingFinalizationPopin';
import {
  ActionIcons,
  Cell,
  CompleterCell,
  DeleteIcon,
  DocRow,
  EditIcon,
  FileOnWhite,
  MobileEventTypeNameBox,
  MobileHeader,
  ResponsiveRoot,
  RootDesktop,
  RootMobile,
  SmallCellColumn,
  SmallDocCellColumn,
  SpaceBetween,
} from './MaterialCheckingLineStyle';
import {
  completeButtonTooltipText,
  deleteButtonTooltipText,
  materialCheckingToPopinMode,
} from './MaterialCheckingLineUtilities';

export interface MaterialCheckingLineProps {
  materialChecking: MaterialChecking;
  prevMaterialChecking: MaterialChecking | null;
  nextMaterialChecking: MaterialChecking | null;
  canBeDeleted: boolean;
  onDeletionRequested: () => void;
  style?: React.CSSProperties;
}

/** Displays a {@link MaterialChecking} entity and allows to use a {@link MaterialCheckingFinalizationPopin} to edit it. */
export function MaterialCheckingLine(props: MaterialCheckingLineProps) {
  const textSize = 'sm';
  const fontWeight = '600';
  const font = 'primary';
  const { materialChecking, prevMaterialChecking, nextMaterialChecking, canBeDeleted, onDeletionRequested } =
    props;
  const { readOnly } = useAuthContext();
  const { eventFamily } = unwrap(useEventFamilyContext());
  const [popinOpened, setPopinOpened] = useState(false);
  const [randomID] = useState(String(Math.random()));
  const [tooltip, showTooltip] = useState(true);
  const materialIsScrapped = useMaterialSheetStore((state) => state.isScrapped);

  //#region UI callbacks
  const onPopinFinalized = (): void => {
    setPopinOpened(false);
  };

  const onPopinCanceled = (): void => {
    setPopinOpened(false);
  };

  const openPopin = () => {
    setPopinOpened(true);
    // ? PUSH TO GA WHEN "Compléter" is clicked
    if (materialChecking) {
      let eventLabel: DatalayerEventLabel = DatalayerEventLabel.DEFAULT;
      if (materialChecking.eventFamily?.name === 'Vérifications périodiques') {
        eventLabel = DatalayerEventLabel.VERIFICATION_PERIODIQUE;
      }
      if (materialChecking.eventFamily?.name === 'Autres examens') {
        eventLabel = DatalayerEventLabel.AUTRE_EXAMEN;
      }
      if (materialChecking.eventFamily?.name === 'Entretiens') {
        eventLabel = DatalayerEventLabel.ENTRETIEN;
      }
      if (eventLabel !== DatalayerEventLabel.DEFAULT) {
        new DatalayerService().push({
          event: 'GAEvent',
          eventCategory: 'Votre espace',
          eventAction: 'MAT',
          eventLabel,
        });
      }
    }
  };

  const requestDeletion = () => {
    onDeletionRequested();
  };
  //#endregion

  //#region Computed values
  const deleteButtonIsDisabled =
    readOnly || (materialIsScrapped && materialChecking.eventResult?.isInvolvingScrapping !== true);
  const deleteButton =
    canBeDeleted &&
    (!materialChecking.isFilled() || materialChecking.eventResult?.isInvolvingScrapping === true) ? (
      <TooltipContainer
        className="deleteIconContainer"
        uniqueId={`tooltip-delete-desktop-${materialChecking.uuid}`}
        display={deleteButtonIsDisabled}
        text={deleteButtonTooltipText({ readOnly, materialIsScrapped })}
      >
        <DeleteIcon
          onClick={deleteButtonIsDisabled ? undefined : requestDeletion}
          className={deleteButtonIsDisabled ? 'disabled' : ''}
        />
      </TooltipContainer>
    ) : undefined;
  const editButton = materialChecking.isFilled() ? (
    <EditIcon title="Éditer" onClick={openPopin} />
  ) : undefined;
  //#endregion

  /**
   * @returns The right side of the row for desktop.
   */
  const rightSideDesktop = () => {
    if (materialChecking.notBeingFilledYet()) {
      return [
        <CompleterCell className="desktop" key="desktop-completer">
          <div>
            <TooltipContainer
              uniqueId={`tooltip-complete-desktop-${materialChecking.uuid}`}
              display={readOnly || materialIsScrapped}
              text={completeButtonTooltipText({ readOnly, materialIsScrapped })}
            >
              <Button variant="primary" callback={openPopin} disabled={readOnly || materialIsScrapped}>
                Compléter
              </Button>
            </TooltipContainer>
            {deleteButton}
          </div>
        </CompleterCell>,
      ];
    } else {
      return [
        <Cell key="desktop-fulfillment-date">
          <Text fontWeight={fontWeight} textAlign="left" fsize={textSize} font={font}>
            {DateUtilities.toFrenchString(new Date(unwrap(materialChecking.effectiveDate).toString()))}
          </Text>
        </Cell>,
        <Cell key="desktop-fulfillment-inspectors">
          <Text fontWeight={fontWeight} textAlign="left" fsize={textSize} font={font}>
            {materialChecking.checker}
          </Text>
        </Cell>,
        <Cell key="desktop-fulfillment-file">
          <div>
            <Button
              variant="successRound"
              icon={FileOnWhite}
              callback={async () => {
                if (materialChecking.document && documentNeedsUpdate(materialChecking)) {
                  downloadFile(
                    await materialChecking.document.arrayBuffer(),
                    materialChecking.document.name,
                    materialChecking.document.type,
                    true
                  );
                } else {
                  downloadFileByURL(
                    unwrap(process.env.REACT_APP_URL_API) + materialChecking.documentUri ?? ''
                  );
                }
              }}
              disabled={!materialChecking.documentUri && !materialChecking.document}
            ></Button>
          </div>
        </Cell>,
        <Cell key="desktop-fulfillment-notes">
          <SpaceBetween>
            <Text
              fontWeight={fontWeight}
              fsize={textSize}
              color={
                materialChecking.eventResult?.isInvolvingScrapping === true
                  ? StyleData.color.primary
                  : StyleData.color.text
              }
            >
              {materialChecking.eventResult?.name}
            </Text>
            <ActionIcons>
              {editButton}
              {deleteButton}
            </ActionIcons>
          </SpaceBetween>
        </Cell>,
      ];
    }
  };

  /**
   * @returns The bottom side of the column for mobile.
   */
  const bottomSideMobile = () => {
    if (materialChecking.isFilled()) {
      return (
        <>
          <hr></hr>
          <Cell>
            <SmallCellColumn>
              <Text>Vérificateur</Text>
              <Text fontWeight={fontWeight} font={font}>
                {materialChecking.checker}
              </Text>
            </SmallCellColumn>
          </Cell>
          <Cell>
            <SmallDocCellColumn>
              <Text>Docs</Text>
              <DocRow>
                <Button
                  variant="successRound"
                  icon={FileOnWhite}
                  callback={async () => {
                    if (materialChecking.document && documentNeedsUpdate(materialChecking)) {
                      downloadFile(
                        await materialChecking.document.arrayBuffer(),
                        materialChecking.document.name,
                        materialChecking.document.type,
                        true
                      );
                    } else {
                      downloadFileByURL(
                        unwrap(process.env.REACT_APP_URL_API) + materialChecking.documentUri ?? ''
                      );
                    }
                  }}
                  disabled={!materialChecking.documentUri && !materialChecking.document}
                ></Button>
                <Text fontWeight={fontWeight} font={font}>
                  {materialChecking.document?.name}
                </Text>
              </DocRow>
            </SmallDocCellColumn>
          </Cell>
          <hr></hr>
          <Cell>
            <SmallCellColumn>
              <Text>Résultats</Text>
              <Text
                fontWeight={fontWeight}
                font={font}
                color={
                  materialChecking.eventResult?.isInvolvingScrapping === true
                    ? StyleData.color.primary
                    : StyleData.color.text
                }
              >
                {materialChecking.eventResult?.name}
              </Text>
            </SmallCellColumn>
          </Cell>
        </>
      );
    } else {
      return (
        <CompleterCell className="mobile">
          <div>
            <TooltipContainer
              uniqueId={`tooltip-complete-mobile-${materialChecking.uuid}`}
              display={readOnly || materialIsScrapped}
              text={completeButtonTooltipText({ readOnly, materialIsScrapped })}
            >
              <Button variant="primary" callback={openPopin} disabled={readOnly || materialIsScrapped}>
                {readOnly ? 'Consulter' : 'Compléter'}
              </Button>
            </TooltipContainer>
          </div>
        </CompleterCell>
      );
    }
  };

  return (
    <ResponsiveRoot className="EventSummaryElement" style={props.style}>
      <RootDesktop containsPlannedDate={eventFamily.hasPlannedDate}>
        <Cell>
          {materialChecking.eventRef?.name ? (
            <Text fontWeight={fontWeight} fsize="md" font={font}>
              {materialChecking.eventRef.name}
            </Text>
          ) : (
            <Text fontWeight={fontWeight} fsize="md" font={font} color={StyleData.color.lightText}>
              À renseigner
            </Text>
          )}
          {materialChecking.comment && (
            <>
              <span
                data-tip={materialChecking.comment}
                data-for={`tooltip-${randomID}`}
                onMouseEnter={() => showTooltip(true)}
                onMouseLeave={() => {
                  showTooltip(false);
                  setTimeout(() => showTooltip(true), 50);
                }}
              >
                <ChatBlack />
              </span>
              {tooltip && <ReactTooltip id={`tooltip-${randomID}`} {...tooltipStyle} />}
            </>
          )}
        </Cell>
        {eventFamily.hasPlannedDate && (
          <Cell>
            <Text fontWeight={fontWeight} fsize={textSize} font={font}>
              {materialChecking.plannedDate
                ? DateUtilities.toFrenchString(new Date(materialChecking.plannedDate.toString()))
                : '-'}
            </Text>
          </Cell>
        )}
        {rightSideDesktop()}
      </RootDesktop>
      <RootMobile>
        <Cell>
          <MobileHeader>
            {materialChecking.eventRef?.name ? (
              <MobileEventTypeNameBox>
                <Text fontWeight={fontWeight} fsize="xl" textAlign="center" font={font}>
                  {materialChecking.eventRef.name}
                  {materialChecking.comment && (
                    <>
                      <span
                        data-tip={materialChecking.comment}
                        data-for={`tooltip-${randomID}`}
                        onMouseEnter={() => showTooltip(true)}
                        onMouseLeave={() => {
                          showTooltip(false);
                          setTimeout(() => showTooltip(true), 50);
                        }}
                      >
                        <ChatBlack />
                      </span>
                      {tooltip && <ReactTooltip id={`tooltip-${randomID}`} {...tooltipStyle} />}
                    </>
                  )}
                </Text>
              </MobileEventTypeNameBox>
            ) : (
              <Text
                fontWeight={fontWeight}
                fsize="xl"
                textAlign="center"
                font={font}
                color={StyleData.color.lightText}
              >
                À renseigner
              </Text>
            )}
            <ActionIcons className="action-icons">
              {editButton}
              {deleteButton}
            </ActionIcons>
          </MobileHeader>
        </Cell>
        <hr></hr>
        {eventFamily.hasPlannedDate && (
          <Cell>
            <SmallCellColumn>
              <Text>Date prévue</Text>
              <Text fontWeight={fontWeight} font={font}>
                {materialChecking.plannedDate
                  ? DateUtilities.toFrenchString(new Date(materialChecking.plannedDate.toString()))
                  : '-'}
              </Text>
            </SmallCellColumn>
          </Cell>
        )}
        <Cell>
          <SmallCellColumn>
            <Text>Date de réalisation</Text>
            <Text fontWeight={fontWeight} font={font}>
              {materialChecking.effectiveDate
                ? DateUtilities.toFrenchString(new Date(unwrap(materialChecking.effectiveDate).toString()))
                : '-'}
            </Text>
          </SmallCellColumn>
        </Cell>
        {bottomSideMobile()}
      </RootMobile>
      <MaterialCheckingFinalizationPopin
        isOpen={popinOpened}
        materialChecking={materialChecking}
        prevMaterialChecking={prevMaterialChecking}
        nextMaterialChecking={nextMaterialChecking}
        onFinalized={onPopinFinalized}
        onCanceled={onPopinCanceled}
        mode={materialCheckingToPopinMode(materialChecking)}
      ></MaterialCheckingFinalizationPopin>
    </ResponsiveRoot>
  );
}
