import MaterialChecking from '../../../models/MaterialChecking';
import { Mode } from '../material-checking-finalization-popin/MaterialCheckingFinalizationPopin';

export function materialCheckingToPopinMode(materialChecking: MaterialChecking): Mode {
  if (materialChecking.isFilled()) {
    if (materialChecking.eventResult?.isInvolvingScrapping === true) {
      return Mode.Completion;
    } else {
      return Mode.LateEdit;
    }
  } else {
    return Mode.Completion;
  }
}

export function completeButtonTooltipText(conditions: {
  readOnly: boolean | undefined;
  materialIsScrapped: boolean;
}): string {
  if (conditions.readOnly) {
    return "Vous n'êtes pas autorisé à modifier la vérification de matériel";
  } else if (conditions.materialIsScrapped) {
    return 'Le matériel est mis au rebut, aucune vérification de matériel ne peut être ajoutée.';
  } else {
    return '';
  }
}

export function deleteButtonTooltipText(conditions: {
  readOnly: boolean | undefined;
  materialIsScrapped: boolean;
}): string {
  if (conditions.readOnly) {
    return "Vous n'êtes pas autorisé à supprimer la vérification de matériel";
  } else if (conditions.materialIsScrapped) {
    return 'Le matériel est mis au rebut, aucune vérification de matériel ne peut être supprimée.';
  } else {
    return 'Supprimer';
  }
}
