import EntityInterface from '../interfaces/Entity';
import Entity from './Entity';

export default class MaterialFamily extends Entity implements EntityInterface {
  public id!: number;

  public name!: string;

  public isIpe!: boolean;
  public isMaterial!: boolean;
  public isInstallation!: boolean;
  public isCustomizable!: boolean;

  constructor(data?: Partial<MaterialFamily>) {
    super();
    Object.assign(this, data);
  }
}
