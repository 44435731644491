import dayjs from 'dayjs';
import MaterialTypeInterface from '../interfaces/MaterialType';
import { unwrap } from '../utilities/Assertions';
import DateUtility from '../utilities/DateUtility';
import EventFamily from './EventFamily';
import EventRef from './EventRef';
import EventResult from './EventResult';
import EventType from './EventType';
import EventTypeRule from './EventTypeRule';
import MaterialSheet from './MaterialSheet';

export default class MaterialChecking {
  id?: number;
  uuid!: string; // this ID is used to manage not persisted elements in the list.

  eventFamily?: EventFamily;
  eventResult?: EventResult;
  eventRef?: EventRef;

  plannedDate?: string;
  effectiveDate?: string;

  checker?: string;
  comment?: string;

  document?: File;
  documentUri?: string;

  constructor(data?: Partial<MaterialChecking>) {
    Object.assign(this, data);

    if (!this.uuid) {
      this.uuid = Date.now() + Math.random().toString(36).substr(2, 9);
    }

    if (data?.plannedDate) {
      this.plannedDate = DateUtility.toString(dayjs(data.plannedDate).toDate());
    }
    if (data?.effectiveDate) {
      this.effectiveDate = DateUtility.toString(dayjs(data.effectiveDate).toDate());
    }
  }

  /**
   * Make sure the current checking is filled by the user.
   */
  public notBeingFilledYet(): boolean {
    return !this.eventResult?.id || !this.eventFamily?.id || !this.eventRef?.id || !this.effectiveDate;
  }

  /**
   * @returns `true` If this is filled. Equivalent to `!notBeingFilledYet`.
   */
  public isFilled(): boolean {
    return !this.notBeingFilledYet();
  }

  /**
   * Clears data that the user completes making it an incomplete material checking.
   */
  public clear(): void {
    this.effectiveDate = undefined;
    this.checker = undefined;
    this.document = undefined;
    this.comment = undefined;
    this.eventResult = undefined;
  }

  /**
   * @param materialType The {@link MaterialTypeInterface} this {@link MaterialChecking} belongs to.
   * @param nextEventType The next event type. Can be retrieved by fetching eventTypes using the material type and the event family of the material checking and looking the {@link EventType} matching the {@link MaterialChecking.eventRef}.
   * @return The periodicity (in months) of the {@link MaterialChecking}.
   */
  public static computeNextPeriodicity(
    materialType: MaterialTypeInterface,
    nextEventType: EventType
  ): number {
    // FIXME Replace this part of the condition (`|| currentEventType.eventRef.name !== 'Vérification périodique'`) for a real **clean** condition.
    if (materialType.isRepository || nextEventType.eventRef.name !== 'Vérification périodique') {
      return (
        EventTypeRule.getDefault(nextEventType.eventTypeRules)?.periodicity ??
        nextEventType.defaultPeriodicity
      );
    } else {
      return unwrap(materialType.periodicity).value;
    }
  }

  /**
   * @param materialType The {@link MaterialTypeInterface} this {@link MaterialChecking} belongs to.
   * @param eventTypes Those can be retrieved by fetching eventTypes using the material type and the event family of the material checking.
   * @returns The next {@link MaterialChecking}. It is an incomplete {@link MaterialChecking}. WARNING! In some cases, the {@link eventRef} and the {@link plannedDate} of the generated event may be missing, only the {@link eventFamily} is guaranteed to be filled.
   */
  public computeNext(materialType: MaterialTypeInterface, eventTypes: EventType[]): MaterialChecking {
    if (this.notBeingFilledYet()) {
      console.error('[computeNext]', {
        this: this,
        materialType,
      });
      throw new Error('The material checking cannot compute next material checking as it is not filled yet.');
    }

    const eventResult = unwrap(this.eventResult);
    const eventRef = unwrap(this.eventRef);
    const mcEventType = unwrap(eventTypes.find((et) => et.eventRef.id === eventRef.id));

    const matchingRule: EventTypeRule | undefined = (() => {
      for (const rule of mcEventType.eventTypeRules) {
        if (rule.eventResult !== null && rule.eventResult.id === eventResult.id) {
          return rule;
        }
      }

      return EventTypeRule.getDefault(mcEventType.eventTypeRules);
    })();

    const nextMaterialCheckingEventRef = matchingRule?.nextEventRef;

    if (!nextMaterialCheckingEventRef) {
      return new MaterialChecking({
        eventFamily: this.eventFamily,
        eventRef: undefined,
        plannedDate: undefined,
      });
    }

    const nextMaterialCheckingEventType = unwrap(
      eventTypes.find((et) => et.eventRef.id === nextMaterialCheckingEventRef.id)
    );

    const nextPeriodicity = MaterialChecking.computeNextPeriodicity(
      materialType,
      nextMaterialCheckingEventType
    );

    return new MaterialChecking({
      eventFamily: this.eventFamily,
      eventRef: nextMaterialCheckingEventRef,
      plannedDate: dayjs(DateUtility.addPeriodicity(dayjs(unwrap(this.effectiveDate)), nextPeriodicity))
        .toDate()
        .toDateString(),
    });
  }

  /**
   * @param materialType The {@link MaterialTypeInterface} this {@link MaterialChecking} belongs to.
   * @param eventTypes Those can be retrieved by fetching eventTypes using the material type and the event family of the material checking.
   * @param eventFamily The {@link EventFamily} of the {@link MaterialChecking} to be computed.
   * @param eventType The {@link EventType} if the user gives one in particular.
   * @returns The first {@link MaterialChecking} of the {@link eventFamily}.
   */
  public static computeFirst(
    msDateCommissioning: MaterialSheet['dateCommissioning'],
    eventTypes: EventType[],
    eventFamily: EventFamily,
    eventType?: EventType
  ): MaterialChecking {
    const defaultEventType = eventType ?? eventTypes.find((eventType) => eventType.isDefaultValue);
    if (msDateCommissioning === undefined) {
      throw new Error(
        'First materialChecking computation requires MaterialSheet.dateCommissioning to be defined.'
      );
    }

    return new MaterialChecking({
      // Independent of event type
      eventFamily,

      // Dependent of event type
      eventRef: defaultEventType?.eventRef,
      plannedDate: defaultEventType
        ? DateUtility.addPeriodicity(dayjs(msDateCommissioning), defaultEventType.defaultPeriodicity)
            .toDate()
            .toDateString()
        : undefined,
    });
  }

  /**
   * Slightly changes the instance to prepare it to be posted. (May change some fields or strip some depending on conditions.)
   * @returns The modified instance.
   */
  public prepareToPost(): this {
    //#region Family without planned date
    if (this.eventFamily?.hasPlannedDate === false) {
      delete this.plannedDate;
    }
    //#endregion

    return this;
  }

  public toApi(): Record<string, unknown> | undefined {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = { ...this };

    // make sure the material checking has everything we need (its input is NOT controlled by form)
    if (this.notBeingFilledYet()) {
      return undefined;
    }

    if (this.eventResult?.id) {
      data.eventResult = `/api/event-results/${this.eventResult.id}`;
    }

    if (this.eventRef?.id) {
      data.eventRef = `/api/event-refs/${this.eventRef.id}`;
    }

    if (this.eventFamily?.id) {
      data.eventFamily = `/api/event-families/${this.eventFamily.id}`;
    }

    if (!this.plannedDate) {
      delete data['plannedDate'];
    }

    delete data.id;
    delete data['@type'];

    return data;
  }
}
