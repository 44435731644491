export class MathUtils {
  /**
   * @returns the {@link value} but in range [{@link min}, {@link max}].
   * @details
   * * If {@link value} is less than {@link min}, then returns {@link min}.
   * * If {@link value} is greater than {@link max}, then returns {@link max}.
   * * Otherwise returns {@link value}.
   */
  static clamp(value: number, min: number, max: number) {
    return Math.max(min, Math.min(value, max));
  }
}
