import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { routeParameterToBoolean, routeToLink } from '../../App.routing';
import { Button } from '../../components/Button';
import { VSpacer } from '../../components/layout/Spacer';
import Navbar from '../../components/Navbar';
import Text from '../../components/Text';
import Title from '../../components/Title';
import { useAuthContext } from '../../contexts/AuthContext';
import { AddWhite, BigValidateOutline, ChevronRightLightGrey, HomeGrey } from '../../icons';
import StyleData from '../../utilities/StyleData';
import {
  ActionContainer,
  ContentContainer,
  DescriptionsContainer,
  MaterialUpdateValidationContainer,
  TitleContainer,
} from './MaterialUpdateValidationStyle';
const { REACT_APP_URL_EXT_PREVENTION_SERVICE, REACT_APP_URL_PREVENTIONBTP_PREFIXE } = process.env;

interface Content {
  title: string;
  descriptions: string[];
}

function getContent(isNewMaterial: boolean) {
  if (isNewMaterial) {
    return {
      title: "Un nouveau matériel vient d'être créé",
      descriptions: [
        'Ce matériel a été ajouté à votre liste.',
        'Vous pouvez dès à présent effectuer vos actions concernant ce matériel en cliquant sur celui-ci depuis votre liste de matériels.',
      ],
    };
  } else {
    return {
      title: 'Votre matériel a été mis à jour',
      descriptions: [
        'Vous pouvez effectuer de nouvelles actions concernant ce matériel en cliquant sur celui-ci depuis votre liste de matériels.',
      ],
    };
  }
}

/**
 * Composant suivant l'ajout ou la modification d'un matériel.
 */
function MaterialUpdateValidation() {
  const params = useParams();
  const isNewMaterial = routeParameterToBoolean(params['isNewMaterial']);
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [content, setContent] = useState<Content>({
    title: '',
    descriptions: [''],
  });

  useEffect(() => {
    setContent(getContent(isNewMaterial || false));
  }, [isNewMaterial]);

  return (
    <MaterialUpdateValidationContainer>
      <Navbar>
        <>
          <a href={REACT_APP_URL_PREVENTIONBTP_PREFIXE}>
            <HomeGrey />
          </a>
          <ChevronRightLightGrey />
          <a href={REACT_APP_URL_EXT_PREVENTION_SERVICE}>Mon espace</a>
          <ChevronRightLightGrey />
          <Link
            to={routeToLink('/:organizationId/liste', {
              organizationId: params.organizationId,
            })}
          >
            Suivi de matériel
          </Link>
          <ChevronRightLightGrey />
          <Text fontWeight={'bold'} color={'#92999C'} fsize={'xxs'}>
            {isNewMaterial ? 'Ajout de matériel' : 'Modification de matériel'}
          </Text>
        </>
      </Navbar>
      <VSpacer size="15vh"></VSpacer>
      <ContentContainer>
        <BigValidateOutline width={92} height={92} stroke={StyleData.color.success} />
        <TitleContainer>
          <Title name={content.title} level={1} inline={false} />
        </TitleContainer>
        <DescriptionsContainer>
          {content.descriptions.map((description, index) => (
            <Text key={index} textAlign="center" fsize="md" fsizeMobile="lg">
              {description}
            </Text>
          ))}
        </DescriptionsContainer>
        <ActionContainer>
          <Button
            variant="bigLight"
            callback={() =>
              navigate(routeToLink('/:organizationId/liste', { organizationId: user?.groupId }), {})
            }
          >
            Revenir à la liste des matériels
          </Button>
          <Button
            variant="primary"
            icon={AddWhite}
            callback={() =>
              navigate(routeToLink('/:organizationId/ajouter', { organizationId: user?.groupId }), {})
            }
          >
            Ajouter un matériel
          </Button>
        </ActionContainer>
      </ContentContainer>
      <VSpacer size="20vh"></VSpacer>
    </MaterialUpdateValidationContainer>
  );
}

export default MaterialUpdateValidation;
