import { useLoading } from '@agney/react-loading';
import React from 'react';
import styled from 'styled-components';

function LoadingScreen(): JSX.Element {
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    indicator: <div className="lds-dual-ring" />,
  });

  return <LoadingContent {...containerProps}>{indicatorEl}</LoadingContent>;
}
export const LoadingContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10em 0;
`;

export default LoadingScreen;
