import { useEffect } from 'react';
import ReactDom from 'react-dom';
import styled from 'styled-components';
import { useResponsiveContext } from '../contexts/ResponsiveContext';
import { CloseBlack } from '../icons';
import StyleData from '../utilities/StyleData';
import Title from './Title';

export type ModalSize = 'xs' | 'sm' | 'md' | 'ml' | 'lg' | 'xl';

export type Props = {
  children: JSX.Element | JSX.Element[];
  name: string;
  subtitle?: string;
  onClose: () => void;
  isOpen: boolean;
  modalClass?: string;
  modalSize?: ModalSize;
  disableCloseOnOutClick?: boolean;
  hasScroll?: boolean;
  isError?: boolean;
  actionButton?: JSX.Element;
};

const modalRoot = document.getElementById('modal-root');
const errorModalRoot = document.getElementById('error-modal-root');

function Modal(props: Props): JSX.Element {
  const responsive = useResponsiveContext();

  useEffect(() => {
    props.isOpen && responsive.isMobile && window.scrollTo(0, 0);

    props.isOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'initial');

    return () => {
      document.body.style.overflow = 'initial';
    };
  }, [props.isOpen]);

  return ReactDom.createPortal(
    <StyledModal
      className={`wrapper ${props.modalClass ? props.modalClass : ''}`}
      role="dialog"
      isOpen={props.isOpen}
      modalSize={props.modalSize || 'md'}
      hasScroll={props.hasScroll}
    >
      <div className="box-dialog">
        <div className="box-header">
          <div>
            <Title level={2} name={props.name} />
            {props.subtitle && <Subtitle>{props.subtitle}</Subtitle>}
          </div>
          <div className="box-buttons">
            {props.actionButton}
            <button
              onClick={() => {
                props.onClose();
              }}
              className="x-close"
            >
              <CloseBlack />
            </button>
          </div>
        </div>
        <div className="box-content">
          <div className="box-content-inner">{props.children}</div>
        </div>
      </div>
      {props.disableCloseOnOutClick ? (
        <div
          className="background"
          onClick={() => {
            props.onClose();
          }}
        />
      ) : (
        <div className="background" />
      )}
    </StyledModal>,
    props.isError ? errorModalRoot || document.body : modalRoot || document.body
  );
}

type StyleProps = {
  isOpen: boolean;
  modalSize?: string;
  hasScroll?: boolean;
};

const StyledModal = styled.div<StyleProps>`
  background-color: ${StyleData.color.base};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: ${(props) => {
    if (props.isOpen) {
      return 'flex';
    } else {
      return 'none';
    }
  }};
  ${(props) => {
    if (props.isOpen) {
      return 'body {overflow: hidden;}';
    }
  }}
  align-items: center;
  justify-content: center;
  z-index: 1000000;
  width: ${(props) => {
    switch (props.modalSize) {
      case 'xl':
        return '1012';
      case 'lg':
        return '980';
      case 'ml':
        return '900';
      case 'md':
        return '813';
      case 'sm':
        return '674';
      case 'xs':
        return '575';
      default:
        return '813';
    }
  }}px;
  margin: auto;
  height: fit-content;
  .background {
    background: rgba(0, 0, 0, 0.29);
    position: fixed;
    z-index: 1040;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 0;
  }
  .box-dialog {
    z-index: 1050;
    width: 100%;
    background-color: ${StyleData.color.base};
    border-radius: 17px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    ${(props) => {
      if (props.hasScroll) {
        return 'overflow-y: auto;';
      }
    }}
    max-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .box-dialog::-webkit-scrollbar-thumb {
    background: #92929c;
    border-radius: 15px;
  }
  .box-dialog::-webkit-scrollbar-track {
    border-radius: 15px;
  }
  .box-dialog::-webkit-scrollbar {
    width: 7px;
  }
  .box-dialog::-webkit-scrollbar-thumb:hover {
    background: #808088;
  }
  .box-header {
    position: sticky;
    border-radius: 17px;
    z-index: 1;
    top: 0;
    min-height: 48px;
    padding: 29px 32px 0px;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: flex-start;
    background-color: ${StyleData.color.base};
    .x-close {
      background-color: ${StyleData.color.base};
      border: none;
      margin-top: 5px;
    }
    .x-close {
      cursor: pointer;
    }
    .box-buttons {
      display: flex;
    }
  }
  .box-content {
    padding: 0px 32px 23px;
    min-height: 0;
    display: flex; /* Flex with alignment row is the only way the make the things works as expected. Thanks to this, there is no overflow in inner flex column container. */
    align-items: stretch;
  }
  /* Necessary to restore an expected layout for the component user (not flex row as box-content uses) */
  .box-content-inner {
    flex-grow: 1;
  }

  @media screen and (max-width: ${(props) => {
      switch (props.modalSize) {
        case 'xl':
          return '1012';
        case 'lg':
          return '980';
        case 'ml':
          return '900';
        case 'md':
          return '813';
        case 'sm':
          return '674';
        case 'xs':
          return '575';
        default:
          return '813';
      }
    }}px) {
    width: 99%;
  }
`;

const Subtitle = styled.span`
  font-family: 'Work Sans', sans-serif;
  font-size: 12px;
  word-break: break-all;
`;

export default Modal;
