import EntityInterface from '../interfaces/Entity';
import EventRef from './EventRef';
import EventResult from './EventResult';

export default class EventTypeRule implements EntityInterface {
  id!: number;
  name!: string;
  eventResult!: EventResult | null;
  nextEventRef?: EventRef;
  periodicity!: number;

  constructor(data: Partial<EventTypeRule>) {
    Object.assign(this, data);
  }

  /**
   * @param rules The rules to examine.
   * @returns The default rule found in {@link rules} if there is any.
   */
  static getDefault(rules: EventTypeRule[]): EventTypeRule | undefined {
    // Rules with null event result are a wildcard rule that matches anything.
    // They also represents the default rule.
    return rules.find((rule) => rule.eventResult === null);
  }
}
