import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routeToLink } from '../../App.routing';
import { Button } from '../../components/Button';
import { ExportButton } from '../../components/ExportButton';
import { VSpacer } from '../../components/layout/Spacer';
import { Container } from '../../components/List.style';
import { DefaultNavbar } from '../../components/Navbar';
import Title from '../../components/Title';
import { useAuthContext } from '../../contexts/AuthContext';
import { useResponsiveContext } from '../../contexts/ResponsiveContext';
import { useQuery } from '../../hooks/UseQuery';
import { PencilWhite } from '../../icons';
import MaterialSheetHttp from '../../services/http/MaterialSheetHttp';
import { MainToolbar } from '../../shared/toolbar/Toolbar';
import { useListStore } from '../../store/ListStore';
import DateUtility from '../../utilities/DateUtility';
import { downloadFile } from '../../utilities/DownloadFile';
import { DeadlineBlock } from './DeadlineBlock';
import { VerticalCenterContainer } from './MaterialDeadlineStyle';

/**
 * Composant principal pour les échéances de materiel
 */
function MaterialDeadline() {
  const responsive = useResponsiveContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user, readOnly } = useAuthContext();

  const params = useQuery();
  const navigate = useNavigate();
  const [exportParams, setExportParams] = useState<Record<string, unknown>>({});

  useEffect(() => {
    if (params.get('control_order[lastPlannedMaterialChecking.plannedDate]') === null) {
      params.set('control_order[lastPlannedMaterialChecking.plannedDate]', 'asc');
    }
    navigate({
      search: '?' + params.toString().replaceAll('%5B', '[').replaceAll('%5D', ']'),
    });
  }, []);

  const exportFunc = async () => {
    const filetype = 'xlsx';
    const { data, filename } = await new MaterialSheetHttp().exportMaterialSheetListDocument(
      filetype,
      exportParams
    );
    downloadFile(data, filename ?? `export_${DateUtility.toFileSuffix(new Date())}.${filetype}`, filetype);
  };

  const onQueryParamsChanged = (params: URLSearchParams) => {
    //#region Collect query params
    const newExportParams: Record<string, unknown> = {};
    params.forEach((val, originalKey) => {
      const key = originalKey.startsWith('control_') ? originalKey.replace('control_', '') : originalKey;
      newExportParams[key] = val;
    });

    setExportParams(newExportParams);
  };
  const totalMaterialDeadlines = useListStore((state) => state.totalDeadlines);

  return (
    <>
      <Container>
        <DefaultNavbar />
        <Title
          level={1}
          name={responsive.isMobile ? 'Suivi du matériel' : "Bienvenue dans l'outil suivi du matériel"}
        />
        {responsive.isMobile ? <VSpacer size="15px" /> : <VSpacer size="28px" />}

        <MainToolbar>
          <>
            <VerticalCenterContainer>
              {totalMaterialDeadlines > 0 && (
                <ExportButton label="Exporter les échéances" exportFunction={exportFunc} />
              )}
              {totalMaterialDeadlines === 0 && (
                <>
                  <Button
                    variant="primary"
                    icon={PencilWhite}
                    callback={() =>
                      navigate(routeToLink('/:organizationId/liste', { organizationId: user?.groupId }))
                    }
                    disabled={readOnly}
                  >
                    {responsive.isMobile ? '' : 'Modifier'}
                  </Button>
                </>
              )}
            </VerticalCenterContainer>
          </>
        </MainToolbar>
        {!responsive.isMobile && <VSpacer size="38px" />}
        <DeadlineBlock title="" onQueryParamsChanged={onQueryParamsChanged} />
      </Container>
    </>
  );
}

export default MaterialDeadline;
