import styled from 'styled-components';
import { VSpacer } from '../../components/layout/Spacer';
import { useResponsiveContext } from '../../contexts/ResponsiveContext';
import HasChildrenProps from '../../types/props/HasChildrenProps.type';
import StyleData from '../../utilities/StyleData';
import Footer from '../footer/Footer';
import Header from '../header/Header';

function MainLayout({ children }: HasChildrenProps): JSX.Element {
  const responsive = useResponsiveContext();
  return (
    <>
      <Header />
      <MainLayoutStyle>
        {responsive.isMobile && <VSpacer size="15px" />}
        {children}
      </MainLayoutStyle>
      <Footer />
    </>
  );
}

const MainLayoutStyle = styled.main`
  margin-top: 5rem;
  @media screen and (min-width: ${StyleData.breakpoints.min.mmd}px) and (max-width: ${StyleData.breakpoints
      .max.lg}px) {
    margin-top: 4rem;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.smd}px) {
    margin-top: 6rem;
  }
`;

export default MainLayout;
