/**
 * Utility function to manipulate {@link Date}s.
 */

import dayjs, { Dayjs } from 'dayjs';

export default class DateUtility {
  /**
   *
   * @param date the string date to transform
   */
  static toDate(date: string): Date {
    return new Date(Date.parse(date));
  }
  /**
   *
   * @param date the date to have in yyyy-MM-dd format.
   */
  static toString(date: Date): string | undefined {
    if (!isNaN(date.getTime())) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
    }
  }

  /**
   * @param date The date to have in YYYYMMDD format (appropriate for filename name ordering).
   */
  static toFileSuffix(date: Date): string {
    return dayjs(date).format('YYYYMMDD');
  }

  /**
   * @param date the date to have in dd/MM/yyyy format.
   */
  static toFrenchString(date: Date | string): string {
    return dayjs(date).locale('fr').format('DD/MM/YYYY').toString();
  }

  /**
   * Is the {@link date} anterior to now?
   * @param date The date to check.
   */
  static isInThePast(date: Date): boolean {
    return new Date().getTime() > date.getTime();
  }

  /**
   * Adds the {@link periodicity} to {@link date}.
   * @details If the day does not exists (example: 2021-29-02), this will be rounded to the following month (2021-29-02 become 2021-01-02)
   *
   * @param date The date
   * @param periodicity The periodicity in months.
   */
  static addPeriodicity(date: Dayjs, periodicity: number): Dayjs {
    let result = date.add(periodicity, 'month');

    // If day of month differs, it means that the month addition initially leads to an invalid value so Dayjs adjusted it to an anterior day in the month.
    // But we want a posterior day in this case.
    if (result.date() !== date.date()) {
      result = result.add(1, 'month').startOf('month');
    }

    return result;
  }
}
