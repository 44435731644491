import { FileWithPath } from 'react-dropzone';
import create from 'zustand';
import EventFamily from '../models/EventFamily';
import MaterialChecking from '../models/MaterialChecking';
import MaterialSheet from '../models/MaterialSheet';
import { ArrayUtils } from '../utilities/Array';
import { unwrap } from '../utilities/Assertions';
import { dateComparer } from '../utilities/Comparer';

type State = {
  /** The commissioning date of the {@link MaterialSheet}. */
  dateCommissioning: Date | null;
  /** The scrapped state of the {@link MaterialSheet} */
  isScrapped: boolean;
  /** Sets {@link isScrapped} */
  setIsScrapped(isScrapped: boolean): void;
  /** The {@link MaterialChecking}s in the {@link MaterialSheet}. */
  materialCheckings: MaterialChecking[];
  /** Fill the store from the {@link materialSheet}. */
  fillFromMaterialSheet(materialSheet: MaterialSheet): void;
  /** Set the commissioning date of the material sheet. */
  setDateCommissioning(date: Date | null): void;
  /** Add a new {@link MaterialChecking}. */
  addMaterialChecking(materialChecking: MaterialChecking): void;
  /**
   * Replace a {@link MaterialChecking}. Comparison function used is {@link materialCheckingEqualish}.
   * @param oldOne The one to be replaced.
   * @param newOne The one replacing {@link oldOne}.
   */
  replaceMaterialChecking(oldOne: MaterialChecking, newOne: MaterialChecking): void;
  /**
   * Replace a {@link MaterialChecking}. Comparison function used is {@link materialCheckingEqualish}. Only replace when {@link oldOne} is not approximatively equals to {@link newOne}.
   * @param oldOne The one to be replaced.
   * @param newOne The one replacing {@link oldOne}.
   * @returns `true` if the replacement has been done. `false` otherwise.
   */
  replaceMaterialCheckingIfDifferent(oldOne: MaterialChecking, newOne: MaterialChecking): boolean;
  /**
   * Removes a {@link MaterialChecking}. Comparison function used is {@link materialCheckingEqualish}.
   */
  removeMaterialChecking(materialChecking: MaterialChecking): void;
  /**
   * Clears all material checkings.
   */
  clearMaterialCheckings(): void;
  /** Gets the {@link MaterialChecking}s that belongs to {@link eventFamily}. */
  getMaterialCheckings(eventFamily: EventFamily): MaterialChecking[];
  /** Returns completed {@link MaterialChecking}s, they are the one considered ready to be posted. */
  getCompletedMaterialCheckings(): MaterialChecking[];
  /** Empty the store, as if it was a new one. */
  empty(): void;
  /** Gets the first planned date of incomplete {@link MaterialChecking}s. */
  getFirstPlannedDate(): Date | undefined;
  /** Returns a material checking that involves scrapping if there is any. */
  getMaterialCheckingInvolvingScrapping(): MaterialChecking | undefined;
};

export const useMaterialSheetStore = create<State>((set, get) => ({
  dateCommissioning: null,
  isScrapped: false,
  setIsScrapped(isScrapped) {
    set(() => ({
      isScrapped,
    }));
  },
  materialCheckings: [],
  fillFromMaterialSheet(materialSheet) {
    set(() => ({
      dateCommissioning: materialSheet.dateCommissioning ? new Date(materialSheet.dateCommissioning) : null,
      isScrapped: materialSheet.isScrapped,
      materialCheckings: materialSheet.materialCheckings.map((mc) => {
        if (mc.documentUri) {
          const name = unwrap(mc.documentUri.split('/').at(-1)).split('_').splice(1).join('');
          mc.document = { name, path: mc.documentUri, size: 0 } as FileWithPath;
        }

        return mc;
      }),
    }));
  },
  setDateCommissioning(date) {
    // If there is no changes, skip
    const oldDate = get().dateCommissioning;
    if (oldDate && date && oldDate.getTime() === date.getTime()) {
      return;
    }
    set(() => ({
      dateCommissioning: date,
    }));
  },
  addMaterialChecking(materialChecking) {
    set((state) => ({
      materialCheckings: state.materialCheckings.concat([materialChecking]),
    }));
  },
  replaceMaterialChecking(oldOne: MaterialChecking, newOne: MaterialChecking) {
    set((state) => {
      const materialCheckings = ArrayUtils.replace(
        state.materialCheckings,
        oldOne,
        newOne,
        materialCheckingEqualish
      );

      return {
        materialCheckings,
      };
    });
  },
  replaceMaterialCheckingIfDifferent(oldOne, newOne) {
    if (materialCheckingEqualish(oldOne, newOne)) return false;
    get().replaceMaterialChecking(oldOne, newOne);
    return true;
  },
  removeMaterialChecking(materialChecking) {
    set((state) => ({
      materialCheckings: state.materialCheckings.filter(
        (el) => !materialCheckingEqualish(el, materialChecking)
      ),
    }));
  },
  clearMaterialCheckings() {
    set(() => ({
      materialCheckings: [],
    }));
  },
  getMaterialCheckings(eventFamily) {
    return get().materialCheckings.filter((mc) => mc.eventFamily?.id === eventFamily.id);
  },
  getCompletedMaterialCheckings() {
    return get().materialCheckings.filter((mc) => !!mc.effectiveDate);
  },
  empty() {
    set(() => {
      return {
        materialCheckings: [],
        dateCommissioning: null,
      };
    });
  },
  getFirstPlannedDate() {
    return ArrayUtils.min(
      get()
        .materialCheckings.filter((mc) => !mc.effectiveDate)
        .map((mc) => mc.plannedDate)
        .filter((date) => date !== undefined)
        .map((date) => new Date(unwrap(date))),
      dateComparer
    );
  },
  getMaterialCheckingInvolvingScrapping() {
    return get().materialCheckings.find((mc) => mc.eventResult?.isInvolvingScrapping);
  },
}));

/** Does the {@link MaterialChecking} documents needs an update on the backend? */
export function documentNeedsUpdate(mc: MaterialChecking): boolean {
  if (mc.documentUri) {
    // Deletion
    if (mc.document === undefined) return true;
    // Replacement
    else if ((mc.document as FileWithPath).path !== mc.documentUri) return true;
    return false;
  } else {
    // Addition
    return mc.document !== undefined;
  }
}

/**
 * @param ms1 The first element to compare.
 * @param ms2 The second element to compare.
 * @returns Returns `true` if the {@link ms1} *equalish* {@link ms2}.
 */
export function materialCheckingEqualish(ms1: MaterialChecking, ms2: MaterialChecking): boolean {
  const fieldsPrimitiveGetters: ((m: MaterialChecking) => unknown)[] = [
    (m) => m.id,
    (m) => m.eventFamily?.id,
    (m) => m.eventResult?.id,
    (m) => m.eventRef?.id,
    (m) => m.plannedDate,
    (m) => m.checker,
    (m) => m.comment,
  ];

  for (const getter of fieldsPrimitiveGetters) {
    if (getter(ms1) !== getter(ms2)) return false;
  }
  return true;
}
