import EntityInterface from '../interfaces/Entity';

export default class Entity implements EntityInterface {
  public id!: number;
  public name!: string;

  toEntity(): Entity {
    const e = new Entity();

    if (this?.id) {
      e.id = this.id;
    }

    if (this?.name) {
      e.name = this.name;
    }

    return e;
  }
}
