import EventType from './EventType';

export default class EventResult extends EventType {
  public isInvolvingScrapping!: boolean;

  constructor(data?: Partial<EventResult>) {
    super(data);
    Object.assign(this, data);
  }
}
