import axios, { AxiosResponse, Canceler } from 'axios';
import http from '../../ApiHttp';
import PersistableInterface from '../../interfaces/Persistable';

export default abstract class HttpAbstract<T> {
  constructor(private route: string, protected instance: new (data?: Record<string, unknown> | T) => T) {
    this.route = route;
  }

  public async get(params: Record<string, unknown> = {}): Promise<T[]> {
    const result = await http.get(this.route, {
      params: {
        ...params,
      },
    });

    return result.data['hydra:member'].map((data: Record<string, unknown>) => new this.instance(data));
  }

  public async getById(id: number, params: Record<string, unknown> = {}): Promise<T> {
    const result = await http.get(this.route + '/' + id.toString(), {
      params: {
        ...params,
      },
    });

    return new this.instance(result.data);
  }

  public async getInfiniteScroll(
    params: string
  ): Promise<{ data: T[]; last: number; total: number; cancel: Canceler }> {
    let cancel: Canceler = () => {
      return;
    };
    const result = await http.get(this.route + '?' + params, {
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    });
    const data = result.data['hydra:member'].map((data: Record<string, unknown>) => new this.instance(data));
    const lastPage =
      result.data['hydra:view'] && result.data['hydra:view']['hydra:last']
        ? Number.parseInt(result.data['hydra:view']['hydra:last']?.match(/page=(\d+)/)[1] || '1')
        : 1;
    const total = result.data['hydra:totalItems'];
    return {
      data,
      last: lastPage,
      total,
      cancel,
    };
  }

  public async put(id: number, data: T | Record<string, unknown>): Promise<AxiosResponse<T>> {
    const persistable = new this.instance(data) as unknown as PersistableInterface;

    return await http.put(`${this.route}/${id}`, persistable.toApi(), {
      headers: { 'Content-Type': 'application/ld+json' },
    });
  }

  public async post(data: T | Record<string, unknown>): Promise<AxiosResponse<T>> {
    const persistable = new this.instance(data) as unknown as PersistableInterface;

    return await http.post(this.route, persistable.toApi());
  }

  public async delete(id: number): Promise<boolean> {
    const result = await http.delete(`${this.route}/${id}`);

    return result?.status === 204;
  }

  public async getFile(fileType: string, params: Record<string, unknown> = {}): Promise<any> {
    const result = await http.get(`${this.route}/export.${fileType}`, {
      params: {
        ...params,
      },
      responseType: 'blob',
    });

    return { filename: result.headers['content-disposition'].split('=').pop(), data: result.data };
  }

  public async count(params: Record<string, unknown> = {}): Promise<number> {
    const fullParams = { ...params, itemsPerPage: 1 };
    const result = await http.get(this.route, {
      params: {
        ...fullParams,
      },
    });

    return result.data['hydra:totalItems'];
  }
}
