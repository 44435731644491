import React, { FunctionComponent } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import StyleData from '../utilities/StyleData';

export type ButtonVariant =
  | 'primary'
  | 'primaryRoundFilterMobile'
  | 'primaryRound'
  | 'primaryInverted'
  | 'primaryHeader'
  | 'primaryNoIcon'
  | 'primaryNoIconSmall'
  | 'secondary'
  | 'secondaryForTh'
  | 'secondaryRound'
  | 'secondaryRoundMobileFilterOn'
  | 'secondaryRoundMobileFilterOff'
  | 'light'
  | 'bigLight'
  | 'bigLightSkill'
  | 'lightNoBorder'
  | 'bigLightFilterMobile'
  | 'lightNoBorderHeader'
  | 'lightNoBorderNoText'
  | 'lightNoBorderMini'
  | 'lightNoBorderMiniReverse'
  | 'lightNoBorderUnderline'
  | 'lightNoBorderUnderlinePrimary'
  | 'arrowLeft'
  | 'arrowRight'
  | 'success'
  | 'successRound';

export type Props = {
  children?: string;
  type?: 'submit' | 'button' | 'reset' | 'li' | 'a';
  variant: ButtonVariant;
  icon?: React.ElementType;
  loading?: boolean;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: string | React.ComponentType<any>;
  callback?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => unknown;
  widthSize?: string;
  heightSize?: string;
  href?: string;
  id?: string;
  color?: string;
  isBold?: boolean;
  size?: string;
  iconRight?: boolean;
  skipResponsive?: boolean;
  position?: string;
};

export const Button: FunctionComponent<Props> = (props) => {
  const Icon = props.icon || React.Fragment;
  const SizedIcon =
    (props.icon && (
      <Icon
        width={props.widthSize ? props.widthSize : '16px'}
        height={props.heightSize ? props.heightSize : '16px'}
        max-width={props.widthSize ? props.widthSize : '16px'}
        max-height={props.heightSize ? props.heightSize : '16px'}
      />
    )) ||
    React.Fragment;

  return (
    <ButtonStyles
      id={props.id}
      type={props.type || 'button'}
      disabled={props.disabled || false}
      onClick={props.callback || undefined}
      variant={props.variant}
      as={props.as || 'button'}
      href={props.href}
      color={props.color}
      isBold={props.isBold || false}
      size={props.size}
      skipResponsive={props.skipResponsive ?? false}
      position={props.position ?? 'relative'}
    >
      {!props.iconRight && SizedIcon}
      {props.children && (
        <ButtonText isBold={props.isBold} size={props.size} className={props.loading ? 'loading' : ''}>
          {props.children}
        </ButtonText>
      )}
      {props.iconRight && SizedIcon}
    </ButtonStyles>
  );
};

export type ButtonTextProps = {
  isBold?: boolean;
  size?: string;
};

const ButtonText = styled.span<ButtonTextProps>`
  font-weight: ${(props) => {
    return props.isBold ? '600' : '';
  }};
  font-size: ${(props) => {
    return props.size;
  }};
`;

/**
 * Custom variants. Each must implement all style rules.
 *
 * Ex: <Button variant="primary" as="li"></Button>
 */
const variantOptions: Record<string, Record<string, string>> = {
  primary: {
    backgroundColor: StyleData.color.primary,
    color: StyleData.color.base,
    fontFamily: StyleData.font.primary,
    fontSizeMobile: StyleData.fsize.sm,
    padding: '9.5px 14px 9.5px 10px',
    fontWeight: '600',
    borderRadius: '30px',
    flexDirection: 'row',
    gap: '9px',
    activeShadow: '0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)',
    hoverBoxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  },
  primaryNoIcon: {
    backgroundColor: StyleData.color.primary,
    color: StyleData.color.base,
    fontFamily: StyleData.font.primary,
    fontSizeMobile: StyleData.fsize.sm,
    padding: '11px 14px 11px 10px',
    fontWeight: '600',
    borderRadius: '30px',
    flexDirection: 'row',
    activeShadow: '0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)',
    hoverBoxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  },
  primaryNoIconSmall: {
    backgroundColor: StyleData.color.primary,
    color: StyleData.color.base,
    fontFamily: StyleData.font.primary,
    fontSizeMobile: StyleData.fsize.sm,
    padding: '9.5px 14px 9.5px 10px',
    fontWeight: '600',
    borderRadius: '30px',
    flexDirection: 'row',
    activeShadow: '0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)',
    hoverBoxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  },
  primaryHeader: {
    backgroundColor: StyleData.color.primary,
    color: StyleData.color.base,
    fontFamily: StyleData.font.secondary,
    fontSizeMobile: StyleData.fsize.sm,
    padding: '9px 18px 7px 12px',
    marginRight: '18px',
    marginLeft: '9px',
    fontWeight: '500',
    borderRadius: '30px',
    flexDirection: 'row',
    svgMarginBottom: '2px',
    lineHeight: '18px',
    activeShadow: '0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)',
    hoverBoxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  },
  secondary: {
    backgroundColor: StyleData.color.secondary,
    color: StyleData.color.base,
    fontFamily: StyleData.font.primary,
    fontSizeMobile: StyleData.fsize.sm,
    padding: '9.5px 14px 9.5px 10px',
    fontWeight: '600',
    borderRadius: '30px',
    flexDirection: 'row',
    gap: '9px',
    activeShadow: '0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)',
    hoverBoxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  },
  secondaryForTh: {
    backgroundColor: StyleData.color.secondary,
    color: StyleData.color.base,
    fontFamily: StyleData.font.primary,
    fontSize: '9px',
    fontWeight: '600',
    borderRadius: '30px',
    padding: '5px 12px 5px 7px',
    gap: '6px',
    svgColor: '#FFFFFF',
    activeShadow: '0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)',
    hoverBoxShadow: '0 1px 3px #e0caca1e, 0 1px 2px rgba(0,0,0,0.24)',
    flexDirection: 'row',
  },
  primaryRound: {
    backgroundColor: StyleData.color.primary,
    color: StyleData.color.base,
    svgColor: StyleData.color.base,
    borderRadius: '50px',
    padding: '10px 10px',
    activeShadow: '0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)',
    hoverBoxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  },
  primaryRoundFilterMobile: {
    backgroundColor: StyleData.color.primary,
    color: StyleData.color.base,
    borderRadius: '50px',
    padding: '10px 10px',
    gap: '0',
    activeShadow: '0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)',
    hoverBoxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    width: '150px',
  },
  secondaryRound: {
    backgroundColor: StyleData.color.secondary,
    color: StyleData.color.base,
    borderRadius: '50%',
    padding: '10px 10px',
    gap: '0',
    activeShadow: '0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)',
    hoverBoxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  },
  secondaryRoundMobileFilterOn: {
    backgroundColor: StyleData.color.secondary,
    color: StyleData.color.base,
    borderRadius: '50px',
    padding: '9px 9px',
    gap: '0',
    svgColor: StyleData.color.base,
    activeShadow: '0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)',
    hoverBoxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  },
  secondaryRoundMobileFilterOff: {
    backgroundColor: StyleData.color.base,
    color: StyleData.color.secondary,
    border: '1px solid ' + StyleData.color.secondary,
    borderRadius: '50px',
    padding: '9px 9px',
    gap: '0',
    svgColor: StyleData.color.secondary,
    activeShadow: '0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)',
    hoverBoxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  },
  primaryInverted: {
    backgroundColor: StyleData.color.base,
    color: StyleData.color.primary,
    fontFamily: StyleData.font.primary,
    fontSize: '14px',
    fontSizeMobile: '8px',
    fontWeight: '600',
    border: '1px solid ' + StyleData.color.primary,
    borderRadius: '30px',
  },
  light: {
    border: '1px solid ' + StyleData.color.text,
    borderRadius: '30px',
    color: StyleData.color.text,
    fontSize: '9px',
    padding: '4px 12px 4px 6px',
    fontFamily: StyleData.font.primary,
    fontWeight: '600',
    gap: '6px',
    hoverColor: StyleData.color.primary,
    hoverBorderColor: StyleData.color.primary,
    flexDirection: 'row',
    svgColor: '#000000',
  },
  bigLight: {
    border: '1px solid ' + StyleData.color.text,
    borderRadius: '30px',
    color: StyleData.color.text,
    fontSize: StyleData.fsize.sm,
    padding: '8.5px 18px',
    fontFamily: StyleData.font.primary,
    fontWeight: '600',
    gap: '6px',
    hoverColor: StyleData.color.primary,
    hoverBorderColor: StyleData.color.primary,
    flexDirection: 'row',
    backgroundColor: StyleData.color.base,
  },
  bigLightFilterMobile: {
    border: '1px solid ' + StyleData.color.text,
    borderRadius: '30px',
    color: StyleData.color.text,
    fontSize: StyleData.fsize.sm,
    padding: '8.5px 18px',
    fontFamily: StyleData.font.primary,
    fontWeight: '600',
    gap: '6px',
    hoverColor: StyleData.color.primary,
    hoverBorderColor: StyleData.color.primary,
    flexDirection: 'row',
    backgroundColor: StyleData.color.base,
  },
  bigLightSkill: {
    border: '1px solid ' + StyleData.color.text,
    borderRadius: '30px',
    color: StyleData.color.text,
    fontSize: StyleData.fsize.sm,
    padding: '8.5px',
    fontFamily: StyleData.font.primary,
    fontWeight: '600',
    gap: '0px',
    flexDirection: 'row',
    backgroundColor: StyleData.color.base,
  },
  success: {
    border: '1px solid ' + StyleData.color.success,
    borderRadius: '30px',
    color: StyleData.color.base,
    fontSize: StyleData.fsize.sm,
    padding: '8.5px 18px',
    fontFamily: StyleData.font.primary,
    fontWeight: '600',
    flexDirection: 'row',
    backgroundColor: StyleData.color.success,
  },
  successRound: {
    border: '1px solid ' + StyleData.color.success,
    borderRadius: '30px',
    color: StyleData.color.base,
    fontSize: StyleData.fsize.sm,
    padding: '8px',
    paddingMobile: '5px',
    flexDirection: 'row',
    backgroundColor: StyleData.color.success,
  },
  lightNoBorder: {
    fontFamily: StyleData.font.primary,
    fontSize: '14px',
    fontSizeMobile: '8px',
    fontWeight: '600',
    hoverColor: StyleData.color.primary,
  },
  lightNoBorderHeader: {
    fontFamily: StyleData.font.secondary,
    fontSize: '16px',
    fontSizeMobile: '8px',
    fontWeight: '500',
    hoverColor: StyleData.color.primary,
    margin: 'auto',
  },
  lightNoBorderNoText: {
    fontFamily: StyleData.font.primary,
    hoverColor: StyleData.color.primary,
    padding: '0',
  },
  lightNoBorderMini: {
    color: StyleData.color.black,
    svgColor: StyleData.color.black,
    fontFamily: StyleData.font.secondary,
    fontSize: '12px',
    fontWeight: 'normal',
    hoverColor: StyleData.color.primary,
    gap: '1px',
    paddingLeft: '0',
    lineHeight: '18px',
  },
  lightNoBorderMiniReverse: {
    color: StyleData.color.black,
    svgColor: StyleData.color.black,
    fontFamily: StyleData.font.secondary,
    fontSize: '12px',
    fontWeight: 'normal',
    hoverColor: StyleData.color.primary,
    flexDirection: 'row-reverse',
    gap: '1px',
    paddingLeft: '8px',
    paddingRight: '0',
    lineHeight: '18px',
  },
  lightNoBorderUnderline: {
    fontFamily: StyleData.font.primary,
    fontSize: '1em',
    fontWeight: 'normal',
    textDecoration: 'underline',
    padding: '0',
    display: 'inline-flex',
  },
  lightNoBorderUnderlinePrimary: {
    fontFamily: StyleData.font.primary,
    fontSize: '1em',
    fontWeight: 'normal',
    textDecoration: 'underline',
    color: StyleData.color.primary,
    padding: '0',
    display: 'inline-flex',
  },
  arrowRight: {
    border: 'none',
    borderRadius: '0',
    paddingRight: '4px',
    paddingBottom: '0',
    paddingLeft: '0',
    paddingTop: '0',
  },
  arrowLeft: {
    border: 'none',
    borderRadius: '0',
    paddingRight: '0',
    paddingBottom: '0',
    paddingLeft: '4px',
    paddingTop: '0',
  },
};

type StyleProps = {
  variant: string;
  skipResponsive: boolean;
  position: string;
};

export const ButtonStyles = styled.button`
  // Generic Rules
  font-synthesis: none;
  display: flex;
  gap: 9px;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  height: fit-content;
  position: ${({ position }: StyleProps) => position};
  border: none;
  border-radius: 10px;
  padding: 6px 6px;
  font-weight: bold;
  font-size: ${StyleData.fsize.sm};
  background-color: transparent;
  text-decoration: none;
  color: ${StyleData.color.text};
  ${({ skipResponsive }: StyleProps) =>
    skipResponsive
      ? ''
      : `@media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    flex-direction: column;
    justify-content: center;
    padding: 8px 8px;
  }`}
  &:hover {
    cursor: pointer;
    transition: color 0.15s ease-in;
  }
  & svg {
    height: auto;
  }
  &:disabled {
    color: white;
    background-color: #b9b9b9;
    border: none;
    &:focus,
    &:hover,
    &:focus-within {
      outline: none;
      box-shadow: none;
      cursor: initial;
    }
  }
  // Variant styles
  ${({ variant, skipResponsive }: StyleProps) => {
    if (!(variant && variantOptions[variant])) return css``;
    const baseStyle: FlattenSimpleInterpolation = css`
      display: ${variantOptions[variant].display};
      vertical-align: ${variantOptions[variant].verticalAlign};
      background-color: ${variantOptions[variant].backgroundColor};
      color: ${variantOptions[variant].color};
      font-family: ${variantOptions[variant].fontFamily};
      font-size: ${variantOptions[variant].fontSize};
      font-weight: ${variantOptions[variant].fontWeight};
      border: ${variantOptions[variant].border};
      border-radius: ${variantOptions[variant].borderRadius};
      margin: ${variantOptions[variant].margin};
      transition: all 0.15s ease-out;
      gap: ${variantOptions[variant].gap};
      flex-direction: ${variantOptions[variant].flexDirection};
      padding: ${variantOptions[variant].padding};
      margin-left: ${variantOptions[variant].marginLeft};
      margin-top: ${variantOptions[variant].marginTop};
      width: ${variantOptions[variant].width};
      height: ${variantOptions[variant].height};
      text-decoration: ${variantOptions[variant].textDecoration};
      line-height: ${variantOptions[variant].lineHeight ?? '1.45'};

      & span {
        position: ${variantOptions[variant].position};
        bottom: ${variantOptions[variant].textBottom};
        ${variant === 'light' ? 'line-height: 12px;' : ''}
      }
      & svg {
        top: ${variantOptions[variant].svgTop};
        position: ${variantOptions[variant].position};
        margin-bottom: ${variantOptions[variant].svgMarginBottom};
        .st0-fill {
          fill: ${variantOptions[variant].svgColor};
          transition: fill 0.15s ease-in;
        }
        .st0 {
          stroke: ${variantOptions[variant].svgColor};
          transition: stroke 0.15s ease-in;
        }
      }
      &:hover:enabled {
        box-shadow: ${variantOptions[variant].hoverBoxShadow};
        color: ${variantOptions[variant].hoverColor};
        border-color: ${variantOptions[variant].hoverBorderColor};
        transition: all 0.15s ease-in;
        svg {
          .st0-fill {
            fill: ${variantOptions[variant].hoverColor};
            transition: fill 0.15s ease-in;
          }
          .st0 {
            stroke: ${variantOptions[variant].hoverColor};
            transition: stroke 0.15s ease-in;
          }
        }
      }
      :active {
        box-shadow: ${variantOptions[variant].activeShadow};
      }
    `;

    if (skipResponsive) {
      return baseStyle;
    } else {
      const responsiveRules = css`
        & svg {
          @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
            ${variant === 'primary' || variant === 'secondary' ? '' : 'margin: 0;'}
          }
        }

        @media screen and (min-width: ${StyleData.breakpoints.min.md}px) {
          &:first-child {
            padding-left: ${variantOptions[variant].paddingLeft};
          }
          &:last-child {
            padding-right: ${variantOptions[variant].paddingRight};
          }
        }
        @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
          font-size: ${variantOptions[variant].fontSizeMobile};

          padding: ${variantOptions[variant].paddingMobile};
          flex-direction: ${variantOptions[variant].flexDirection};
        }
        @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
          padding-top: ${variantOptions[variant].paddingBottomMobile};
          padding-right: ${variantOptions[variant].paddingRightMobile};
          padding-bottom: ${variantOptions[variant].paddingTopMobile};
          padding-left: ${variantOptions[variant].paddingLeftMobile};
        }
      `;
      return [baseStyle, responsiveRules];
    }
  }}
`;
