// ! FILE CALLED IN A COMPONENT, SO HOOKS ARE PROHIBITED

import ReactTooltip from '@huner2/react-tooltip';
import dayjs from 'dayjs';
import fr from 'dayjs/locale/fr';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { routeToLink } from '../../App.routing';
import { Button } from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import { HSpacer, VSpacer } from '../../components/layout/Spacer';
import { ElementCentered } from '../../components/List.style';
import {
  BasicCellContainer,
  CellLinkContainer,
  EndActions,
  EndContainer,
  TextCell,
} from '../../components/Table.style';
import Text, { Ellipsis } from '../../components/Text';
import { tooltipStyle } from '../../components/Tooltip.style';
import {
  ArrowDownBar,
  Catalogue,
  DotsBlack,
  EnCours,
  MiseAuRebut,
  Pencil,
  Recap,
  TrashBin,
} from '../../icons';
import { Employee } from '../../models/Employee';
import MaterialSheet from '../../models/MaterialSheet';
import { ArrayUtils } from '../../utilities/Array';
import DateUtility from '../../utilities/DateUtility';
import { GenericContainer, PaddingContainer } from '../../views/list/MaterialListConstantsStyle';
import {
  DropdownContentContainer,
  DueDate,
  FullMaterialTypeContainer,
  MaterialCardRowGridContainer,
  MaterialFamily,
  MaterialText,
  MaterialType,
} from './MaterialConstantsStyle';
import { MaterialDisplayTypes } from './MaterialDisplayTypes';

export const tableConstants = (
  organizationId: string | undefined,
  handleDropdownToggle: (id: number) => void,
  opened: number,
  setOpened: (id: number) => void,
  openDeletePopin: (rowData: any) => void,
  openSummaryPopin: (id: number) => void,
  readOnly: boolean | undefined,
  mode: MaterialDisplayTypes,
  downloadDocument: (id: number) => void,
  employees: Employee[]
): ((rowData: MaterialSheet) => JSX.Element)[] => {
  return [
    // *  Nom et qualification
    (rowData) => {
      return (
        <>
          <CellLinkContainer as="a" style={{ width: '200px' }} onClick={() => openSummaryPopin(rowData.id)}>
            <FullMaterialTypeContainer>
              <MaterialType
                isScrapped={rowData.isScrapped}
                data-for={`tooltip-type-${rowData.id}`}
                data-tip={rowData.materialType?.name}
              >
                {rowData.materialType?.name}
              </MaterialType>
              <MaterialFamily isScrapped={rowData.isScrapped}>{rowData.materialFamily?.name}</MaterialFamily>
              {rowData.materialType?.name.length > 25 && (
                <ReactTooltip id={`tooltip-type-${rowData.id}`} {...tooltipStyle} place="bottom" />
              )}
            </FullMaterialTypeContainer>
          </CellLinkContainer>
        </>
      );
    },
    // * Matriculation
    (rowData) => {
      return (
        <BasicCellContainer>
          <MaterialText isScrapped={rowData.isScrapped}>{rowData.matriculation}</MaterialText>
        </BasicCellContainer>
      );
    },
    // * Date prévue
    (rowData) => {
      return (
        <BasicCellContainer>
          <DueDate
            isScrapped={rowData.isScrapped}
            over={
              rowData.lastPlannedMaterialChecking &&
              typeof rowData.lastPlannedMaterialChecking.plannedDate === 'string' &&
              DateUtility.isInThePast(
                dayjs(new Date(rowData.lastPlannedMaterialChecking.plannedDate)).add(-31, 'day').toDate()
              )
            }
          >
            {rowData.lastPlannedMaterialChecking && rowData.lastPlannedMaterialChecking.plannedDate
              ? dayjs(rowData.lastPlannedMaterialChecking.plannedDate).locale(fr).format('DD/MM/YYYY')
              : '-'}
          </DueDate>
        </BasicCellContainer>
      );
    },
    // *  Type d'événement
    (rowData) => {
      return (
        <BasicCellContainer>
          <MaterialText
            isScrapped={rowData.isScrapped}
            data-for={`tooltip-type-${rowData.id}-event`}
            data-tip={rowData.lastPlannedMaterialChecking?.eventRef?.name}
          >
            <Ellipsis>
              {rowData.lastPlannedMaterialChecking ? rowData.lastPlannedMaterialChecking.eventRef?.name : '-'}
            </Ellipsis>
          </MaterialText>
          {rowData.lastPlannedMaterialChecking?.eventRef?.name &&
            rowData.lastPlannedMaterialChecking?.eventRef?.name.length > 13 && (
              <ReactTooltip id={`tooltip-type-${rowData.id}-event`} {...tooltipStyle} />
            )}
        </BasicCellContainer>
      );
    },
    // *  Attribué ā
    (rowData) => {
      const content = getEmployeeContent(rowData.remoteEmployeeIds, employees);
      return (
        <BasicCellContainer>
          <MaterialText
            isScrapped={rowData.isScrapped}
            data-for={`tooltip-type-${rowData.id}-employee`}
            data-tip={content}
          >
            <Ellipsis>{content}</Ellipsis>
          </MaterialText>
          {content.length > 15 && (
            <ReactTooltip id={`tooltip-type-${rowData.id}-employee`} {...tooltipStyle} />
          )}
        </BasicCellContainer>
      );
    },
    // * Statut
    (rowData) => {
      return (
        <EndContainer>
          <TextCell>
            <MaterialText isScrapped={rowData.isScrapped}>
              <SVGWrapper>
                {rowData.isScrapped ? (
                  <>
                    <MiseAuRebut /> Inactif
                  </>
                ) : (
                  <>
                    <EnCours /> Actif
                  </>
                )}
              </SVGWrapper>
            </MaterialText>
          </TextCell>
          <EndActions>
            <div className="action-dropdown-button">
              <Button
                variant={'lightNoBorder'}
                callback={(e) => {
                  e.stopPropagation();
                  handleDropdownToggle(rowData.id);
                }}
                widthSize="25px"
                heightSize="25px"
                icon={DotsBlack}
                id={`employe-${rowData.id}-button`}
              />
              <div style={{ position: 'absolute' }}>
                <Dropdown
                  isOpen={opened === rowData.id}
                  attachedId={`employe-${rowData.id}-button`}
                  down={false}
                  onClose={() => handleDropdownToggle(-1)}
                  menuWidth={148}
                >
                  <DropdownContentContainer>
                    <Link
                      to={routeToLink('/:organizationId/modifier/:materialSheetId', {
                        organizationId,
                        materialSheetId: rowData.id,
                      })}
                    >
                      {readOnly ? (
                        <ElementCentered>
                          <Catalogue />
                          <HSpacer size={'4px'} />
                          Consulter
                        </ElementCentered>
                      ) : (
                        <ElementCentered>
                          <Pencil />
                          <HSpacer size={'4px'} />
                          Modifier
                        </ElementCentered>
                      )}
                    </Link>
                    <a
                      onClick={() => {
                        setOpened(-1);
                        openSummaryPopin(rowData.id);
                      }}
                    >
                      <ElementCentered>
                        <Recap />
                        <HSpacer size={'4px'} />
                        Voir le récapitulatif
                      </ElementCentered>
                    </a>
                    {!readOnly && (
                      <a
                        onClick={() => {
                          setOpened(-1);
                          openDeletePopin(rowData);
                        }}
                      >
                        <ElementCentered>
                          <TrashBin />
                          <HSpacer size={'4px'} />
                          Supprimer
                        </ElementCentered>
                      </a>
                    )}
                    <a
                      onClick={() => {
                        setOpened(-1);
                        downloadDocument(rowData.id);
                      }}
                    >
                      <ElementCentered>
                        <ArrowDownBar />
                        <HSpacer size={'4px'} />
                        Exporter (.doc)
                      </ElementCentered>
                    </a>
                  </DropdownContentContainer>
                </Dropdown>
              </div>
            </div>
          </EndActions>
        </EndContainer>
      );
    },
  ];
};

/**
 * Each CardFormat returned describes a way to render the Card Row considered
 *
 * @returns CardFormat[] the card formating of the data
 */
export const cardConstants = (
  organizationId: string | undefined,
  handleDropdownToggle: (id: number) => void,
  opened: number,
  setOpened: (id: number) => void,
  openDeletePopin: (rowData: any) => void,
  openSummaryPopin: (id: number) => void,
  readOnly: boolean | undefined,
  mode: MaterialDisplayTypes,
  downloadDocument: (id: number) => void,
  employees: Employee[]
): ((rowData: MaterialSheet) => JSX.Element)[] => {
  return [
    (rowData) => {
      return (
        <>
          <PaddingContainer>
            <GenericContainer>
              <CellLinkContainer as="a" onClick={() => openSummaryPopin(rowData.id)}>
                <div className="text-container">
                  <MaterialType isScrapped={rowData.isScrapped}>{rowData.materialType?.name}</MaterialType>
                  <MaterialFamily isScrapped={rowData.isScrapped}>
                    {rowData.materialFamily?.name}
                  </MaterialFamily>
                </div>
              </CellLinkContainer>
              <Button
                variant={'lightNoBorder'}
                callback={() => handleDropdownToggle(rowData.id)}
                widthSize="16px"
                heightSize="16px"
                icon={DotsBlack}
                id={`employe-${rowData.id}-button`}
              />
              <Dropdown
                isOpen={opened === rowData.id}
                attachedId={`employe-${rowData.id}-button`}
                down={true}
                menuWidth={145}
                hasLongLabel={true}
                positionTweaks={{ x: -5 }}
                onClose={() => handleDropdownToggle(-1)}
              >
                <>
                  <Link
                    to={routeToLink('/:organizationId/modifier/:materialSheetId', {
                      organizationId,
                      materialSheetId: rowData.id,
                    })}
                  >
                    {readOnly ? (
                      <ElementCentered>
                        <Catalogue />
                        <HSpacer size={'4px'} />
                        Consulter
                      </ElementCentered>
                    ) : (
                      <ElementCentered>
                        <Pencil />
                        <HSpacer size={'4px'} />
                        Modifier
                      </ElementCentered>
                    )}
                  </Link>
                  <a
                    onClick={() => {
                      setOpened(-1);
                      openSummaryPopin(rowData.id);
                    }}
                  >
                    <ElementCentered>
                      <Recap />
                      <HSpacer size={'4px'} />
                      Voir le récapitulatif
                    </ElementCentered>
                  </a>
                  {!readOnly && (
                    <a
                      onClick={() => {
                        setOpened(-1);
                        openDeletePopin(rowData);
                      }}
                    >
                      <ElementCentered>
                        <TrashBin />
                        <HSpacer size={'4px'} />
                        Supprimer
                      </ElementCentered>
                    </a>
                  )}
                  <a
                    onClick={() => {
                      setOpened(-1);
                      downloadDocument(rowData.id);
                    }}
                  >
                    <ElementCentered>
                      <ArrowDownBar />
                      <HSpacer size={'4px'} />
                      Exporter (.doc)
                    </ElementCentered>
                  </a>
                </>
              </Dropdown>
            </GenericContainer>
          </PaddingContainer>
        </>
      );
    },
    (rowData) => {
      return (
        <>
          <MaterialCardRowGridContainer isScrapped={rowData.isScrapped}>
            <div>
              <Text color="inherit">Immatriculation / Identification</Text>
              <div>
                <Text color="inherit" fontWeight="600">
                  <Ellipsis>{rowData.matriculation}</Ellipsis>
                </Text>
              </div>
            </div>
            <div>
              <Text color="inherit">Date prévue</Text>
              <VSpacer size={'2px'} />
              <Text>
                <DueDate
                  isScrapped={rowData.isScrapped}
                  over={
                    rowData.lastPlannedMaterialChecking &&
                    typeof rowData.lastPlannedMaterialChecking.plannedDate === 'string' &&
                    DateUtility.isInThePast(
                      dayjs(new Date(rowData.lastPlannedMaterialChecking.plannedDate))
                        .add(-31, 'day')
                        .toDate()
                    )
                  }
                >
                  {rowData.lastPlannedMaterialChecking && rowData.lastPlannedMaterialChecking.plannedDate
                    ? dayjs(rowData.lastPlannedMaterialChecking.plannedDate).locale(fr).format('DD/MM/YYYY')
                    : '-'}
                </DueDate>
              </Text>
            </div>
          </MaterialCardRowGridContainer>
        </>
      );
    },
    (rowData) => {
      const employeeContent = getEmployeeContent(rowData.remoteEmployeeIds, employees);
      return (
        <>
          <MaterialCardRowGridContainer isScrapped={rowData.isScrapped}>
            <div>
              <Text color="inherit">Type d'évènement</Text>
              <Text color="inherit">
                {rowData.lastPlannedMaterialChecking
                  ? rowData.lastPlannedMaterialChecking.eventRef?.name
                  : '-'}
              </Text>
            </div>
            <div>
              <Text color="inherit">Attribué à</Text>
              <Text color="inherit">
                <Ellipsis>{employeeContent}</Ellipsis>
              </Text>
            </div>
          </MaterialCardRowGridContainer>
        </>
      );
    },
    (rowData) => {
      return (
        <>
          <MaterialCardRowGridContainer isScrapped={rowData.isScrapped}>
            <div>
              <Text color="inherit">Statut</Text>
              <Text color="inherit">
                <SVGWrapper>
                  {rowData.isScrapped ? (
                    <>
                      <MiseAuRebut /> Inactif
                    </>
                  ) : (
                    <>
                      <EnCours /> Actif
                    </>
                  )}
                </SVGWrapper>
              </Text>
            </div>
          </MaterialCardRowGridContainer>
        </>
      );
    },
  ];
};

/**
 * @param materialEmployees The employees associated to the material.
 * @param employees All the fetched employees.
 * @returns A string describing employees.
 */
function getEmployeeContent(materialEmployees: MaterialSheet['remoteEmployeeIds'], employees: Employee[]) {
  const employeesStr = (
    materialEmployees !== undefined
      ? ArrayUtils.onlyKeepDefined(
          materialEmployees.map((remoteEmployeeId) => {
            return employees.find((employee) => employee.id === remoteEmployeeId)?.fullname;
          })
        )
      : []
  ).join(', ');
  return employeesStr.length > 0 ? employeesStr : '-';
}

const SVGWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  svg {
    max-width: 20px;
    max-height: 40px;
  }
`;
