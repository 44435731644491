import styled from 'styled-components';
import StyleData from '../../../utilities/StyleData';

export const InputStack = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  > * {
    grid-row: 1;
    grid-column: 1;
  }

  > input {
    visibility: hidden;
  }
`;

export const FileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  max-width: 100%;
  overflow-x: hidden;
`;

export const FileElement = styled.span<{ isDisabled: boolean; multiple?: boolean }>`
  ${(props) => {
    return `
  display: flex;
  align-items: center;
  border-radius: 30px;
  padding: 5px 8px 5px 10px;
  background-color: ${props.isDisabled ? StyleData.color.lightText : StyleData.color.secondary};
  margin: 2px 2px 2px 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  z-index: 10000000;
  flex: 0 1 min-content;
  justify-content: space-between;
  cursor: default;
  
  > span {
    color: ${StyleData.color.base};
    font-family: ${StyleData.font.primary};
    font-size: ${StyleData.fsize.xxs};
    font-weight: 600;
    margin-right: 3.5px;
    flex: 1 1 0;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  > svg {
    fill: white;
    width: 12px;
    height: 12px;
    stroke: white;
    stroke-width: 1;
    cursor: pointer;
  }
  
  `;
  }}
`;
