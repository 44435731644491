/**
 * Helper class to work with flag-like enum.
 */
export class Flags {
  /**
   * Checks {@link flag} is into {@link flags}.
   * @returns `true` if {@link flag} is in {@link flags}, `false` otherwise.
   */
  static has<T extends number>(flags: T, flag: T) {
    return Boolean(flags & flag);
  }

  /**
   * Adds {@link flag} to {@link flags}. Is {@link flag} was already there, this has no effect.
   * @warning Use this in an effectation as {@link flags} will remained unchanged by this function call.
   * @example
   * enum Rights {
   *   None = 0 << 0,
   *   Read = 1 << 0,
   *   Write = 1 << 1,
   *   Execute = 1 << 2,
   *   All = ~(~0 << 3),
   * }
   *
   * let rights = Rights.Read;
   * console.log(Flags.has(rights, Rights.Read), Flags.has(rights, Rights.Write)); // Outputs "true false"
   *
   * rights = Flags.add(rights, Rights.Write); // Add "Write" right. Note the mandatory affectation.
   * console.log(Flags.has(rights, Rights.Read), Flags.has(rights, Rights.Write)); // Outputs "true true"
   * @returns The new value to affect to the flags.
   */
  static add<T extends number>(flags: T, flag: T): T {
    return (flags | flag) as unknown as T;
  }

  /**
   * Removes {@link flag} to {@link flags}. Is {@link flag} was not there, this has no effect.
   * @warning Use this in an effectation as {@link flags} will remained unchanged by this function call.
   * @example
   * enum Rights {
   *   None = 0 << 0,
   *   Read = 1 << 0,
   *   Write = 1 << 1,
   *   Execute = 1 << 2,
   *   All = ~(~0 << 3),
   * }
   *
   * let rights = Rights.Read | Rights.Write;
   * console.log(Flags.has(rights, Rights.Read), Flags.has(rights, Rights.Write)); // Outputs "true true"
   *
   * rights = Flags.remove(rights, Rights.Write); // Removes "Write" right. Note the mandatory affectation.
   * console.log(Flags.has(rights, Rights.Read), Flags.has(rights, Rights.Write)); // Outputs "true false"
   * @returns The new value to affect to the flags.
   */
  static remove<T extends number>(flags: T, flag: T): T {
    return (flags & ~flag) as unknown as T;
  }
}
