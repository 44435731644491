import styled from 'styled-components';
import { ChevronRightLightGrey, HomeGrey } from '../icons';
import StyleData from '../utilities/StyleData';
import Breadcrumb from './Breadcrumb';
import Text from './Text';

const { REACT_APP_URL_EXT_PREVENTION_SERVICE, REACT_APP_URL_PREVENTIONBTP_PREFIXE } = process.env;

// type ModalProps = {
//   name: string;
//   isOpen: boolean;
//   onClose: () => unknown;
//   modalSize?: ModalSize;
// };

// const { REACT_APP_YTB_NAVBAR_TOOL } = process.env;

// function HelpModal(props: ModalProps): ReactElement {
//   const { isMobile } = useResponsiveContext();
//   const [youtubeNavbarToolModalOpen, setYoutubeNavbarToolModalOpen] = useState<boolean>(false);

//   const helpTilesContent: HelpTileProps[] = [
//     {
//       title:
//         'Principales vérifications des équipements de travail, des EPI et des installations pour les entreprises du BTP',
//       type: 'Focus',
//       body: "Les vérifications périodiques des équipements de travail, de leurs installations et de leurs équipements de protection individuelle relèvent de la réglementation. Différents textes fixent les obligations de vérification et de contrôle applicables à des types de matériels, d'équipements et d'installation bien définis...",
//       thumbnailSrc: Bonhomme2x,
//       knowMoreLink:
//         'https://www.preventionbtp.fr/ressources/focus/principales-verifications-des-equipements-de-travail-des-epi-et-des-installations-pour-les-entreprises-du-btp_C5nnyU7LewnANNqB8s4m25',
//     },
//     {
//       title: "Appareils et accessoires de levage: l'essentiel sur les vérifications obligatoires",
//       type: 'Focus',
//       body: "Les machines et appareils utilisés pour le levage de charges, l'élévation des postes de travail ou le transport en élévation de personnes font l'objet de nombreuses vérifications lors de la mise ou remise en service et avant chaque utilisation. Ce point pratique sous forme de questions-réponses vous aide à sécuriser...",
//       thumbnailSrc: Bonhomme2x,
//       knowMoreLink:
//         'https://www.preventionbtp.fr/ressources/focus/appareils-et-accessoires-de-levage-l-essentiel-sur-les-verifications-obligatoires_oYv5vCnPGzV4NEitpLPuBE',
//     },
//     {
//       title: 'Entretenir et vérifier les équipements de protection individuelle (EPI)',
//       type: 'Solution',
//       body: "Comme tous les équipements de chantier, les équipements de protection individuelle (EPI) sont soumis à l'usure, aux dégradations, aux souillures, etc. Un entretien et un contrôle réguliers de ces matériels garantissent leur efficacité sur la durée afin de préserver la santé et la sécurité de vos compagnons...",
//       thumbnailSrc: Bonhomme2x,
//       knowMoreLink:
//         'https://www.preventionbtp.fr/ressources/solutions/entretenir-et-verifier-les-equipements-de-protection-individuelle-epi_Z2WKGnUKncurSH2KT8h3nJ',
//     },
//   ];

//   return (
//     <>
//       <Modal
//         name={props.name}
//         isOpen={props.isOpen}
//         onClose={props.onClose}
//         modalSize={props.modalSize}
//         hasScroll={true}
//       >
//         <HelpModalContainer>
//           <HelpSynthesis isMobile={isMobile}>
//             <Text>
//               Retrouvez à votre disposition un service pour gérer facilement votre gestion du matériel.
//             </Text>
//             {REACT_APP_YTB_NAVBAR_TOOL && (
//               <CardLink
//                 name="Découvrir l'outil en vidéo"
//                 link="#"
//                 callback={() => setYoutubeNavbarToolModalOpen(true)}
//                 image={`https://img.youtube.com/vi/${REACT_APP_YTB_NAVBAR_TOOL}/mqdefault.jpg`}
//                 hasVideo={true}
//                 borderLeftOnly={true}
//                 widthSize="320px"
//                 widthMobileSize="min(95%,320px)"
//                 squareImage={true}
//               />
//             )}
//           </HelpSynthesis>
//           <VSpacer size={StyleData.spacing.xl} />
//           <Title name="Aller plus loin" level={3} />
//           <VSpacer size={StyleData.spacing.lg} />
//           <Text>
//             Pour avoir plus d'informations sur les vérifications de votre matériel, consultez nos fiches de
//             prévention dédiées.
//           </Text>
//           <VSpacer size={StyleData.spacing.lg} />

//           <HelpTilesContainer>
//             {helpTilesContent.map((tileContent, index) => (
//               <HelpTile key={index} {...tileContent} />
//             ))}
//           </HelpTilesContainer>
//         </HelpModalContainer>
//       </Modal>
//       {youtubeNavbarToolModalOpen && REACT_APP_YTB_NAVBAR_TOOL && (
//         <YoutubeViewer
//           isOpen={youtubeNavbarToolModalOpen}
//           onClose={() => {
//             setYoutubeNavbarToolModalOpen(false);
//           }}
//           youtubeSlug={REACT_APP_YTB_NAVBAR_TOOL}
//         />
//       )}
//     </>
//   );
// }

// const HelpModalContainer = styled.div`
//   ul {
//     list-style-position: inside;
//     li {
//       p {
//         display: inline;
//       }
//     }
//   }
// `;

// const HelpTilesContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: ${StyleData.spacing.xl};
// `;

// interface HelpTileProps {
//   type: string;
//   title: string;
//   body: string;
//   thumbnailSrc?: string;
//   knowMoreLink: string;
// }

// function HelpTile(props: HelpTileProps): ReactElement {
//   return (
//     <HelpTileContainer>
//       <img src={props.thumbnailSrc} alt="" />
//       <div>
//         <Text fsize="xs" color={StyleData.color.grey}>
//           {props.type}
//         </Text>
//         <VSpacer size={StyleData.spacing.sm} />
//         <Text fsize="sm" fontWeight="bolder">
//           {props.title}
//         </Text>
//         <VSpacer size={StyleData.spacing.md} />
//         <Text fsize="sm">{props.body}</Text>
//         <VSpacer size={StyleData.spacing.md} />
//         <a href={props.knowMoreLink} target="_blank" rel="help noreferrer">
//           <KnowMoreButton variant={'lightNoBorderUnderline'}>En savoir plus</KnowMoreButton>
//         </a>
//       </div>
//     </HelpTileContainer>
//   );
// }

// const HelpTileContainer = styled.div`
//   display: flex;
//   gap: ${StyleData.spacing.lg};
//   font-size: ${StyleData.fsize.sm};

//   img {
//     width: 66px;
//     height: 66px;
//     min-width: 66px;
//     min-height: 66px;
//     border-radius: ${StyleData.borderRadius.sm};
//   }
// `;

// const KnowMoreButton = styled(Button)`
//   padding-left: 0px !important;
//   padding-right: 0px;
// `;

interface Props {
  children?: JSX.Element;
}

function Navbar(props: Props): JSX.Element {
  // const [helpModalOpen, setHelpModalOpen] = useState(false);
  return (
    <NavBar>
      <Breadcrumb>{props.children}</Breadcrumb>
      {/* <Help onClick={() => setHelpModalOpen(!helpModalOpen)}>
        <ScreenRedLight />
        <HSpacer size={'0.6rem'}></HSpacer>
        <span>Ressources associées</span>
      </Help> */}
      {/* <HelpModal
        name="Ressources associées"
        isOpen={helpModalOpen}
        onClose={() => setHelpModalOpen(false)}
        modalSize="sm"
      /> */}
    </NavBar>
  );
}

const NavBar = styled.div`
  height: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 12px 0 12px 0;

  .breadcrumb-buttons {
    display: flex;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    width: fit-content;
    float: right;
    margin: 5px 0 0 0;
  }
`;

// const Help = styled.a`
//   display: flex;
//   align-items: center;
//   margin-left: 19px;
//   span {
//     color: #ef3340;
//     font-family: ${StyleData.font.primary};
//     font-size: ${StyleData.fsize.xs};
//     font-weight: 600;
//     line-height: 16px;
//     text-decoration: underline;
//     :hover {
//       color: #000000;
//       transition: color 0.15s ease-in;
//     }
//   }
//   svg {
//     height: 20px;
//   }
//   :hover {
//     cursor: pointer;
//   }
//   @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
//     margin-left: 10px;
//     span {
//       display: none;
//     }
//   }
// `;

// const HelpSynthesis = styled.div<{ isMobile: boolean }>`
//   padding: ${StyleData.spacing.lg};
//   display: grid;
//   grid-template-columns: repeat(${(p) => (p.isMobile ? '1' : '2')}, 1fr);
//   grid-template-rows: repeat(${(p) => (p.isMobile ? '2' : '1')}, min-content)
//   align-items: center;
//   gap: ${(p) => (p.isMobile ? StyleData.spacing.lg : StyleData.spacing.xl)};
//   background: ${StyleData.color.lightGrey};
//   border-radius: ${StyleData.borderRadius.xl};

//   > *:first-child {
//     line-height: 1.5;
//   }

//   > * {
//     width: auto;
//   }
// `;

export default Navbar;
export function DefaultNavbar(props: Omit<Props, 'children'>): JSX.Element {
  return (
    <Navbar {...props}>
      <>
        <a href={REACT_APP_URL_PREVENTIONBTP_PREFIXE}>
          <HomeGrey />
        </a>
        <ChevronRightLightGrey />
        <a href={REACT_APP_URL_EXT_PREVENTION_SERVICE}>Mon espace</a>
        <ChevronRightLightGrey />
        <Text fontWeight={'bold'} color={'#92999C'} fsize={'xxs'}>
          Suivi de matériel
        </Text>
      </>
    </Navbar>
  );
}
