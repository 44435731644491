import http from '../../ApiHttp';
import User from '../../models/User';

const { REACT_APP_URL_API_AUTHENTICATION, REACT_APP_URL_API_CURRENT_USER, REACT_APP_URL_API_ORGANIZATIONS } =
  process.env;

export class UserHttp {
  /**
   * Check if the current user in authenticated in API.
   */
  public static async check(): Promise<boolean> {
    const result = await http.get(`${REACT_APP_URL_API_AUTHENTICATION}`);

    return result.data.authentication === 'granted';
  }

  /**
   * Get currently connected user info.
   */
  public static async fetchCurrentUser(): Promise<User> {
    const result = await http.get(`${REACT_APP_URL_API_CURRENT_USER}`);

    return new User(result.data);
  }

  /**
   * Track current user.
   */
  public static async track(organizationId: string): Promise<any> {
    return await http.head(`${REACT_APP_URL_API_ORGANIZATIONS}/${organizationId}/track-connection`);
  }
}
