import React from 'react';
import styled from 'styled-components';

function Line(): JSX.Element {
  return <StyledLine />;
}

export const StyledLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: #c1c6c8;
`;

export default Line;
