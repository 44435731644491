import styled from 'styled-components';
import logo from '../../assets/images/logo-footer.png';
import StyleData from '../../utilities/StyleData';

function Footer(): JSX.Element {
  const {
    REACT_APP_URL_EXT_PREVENTION_ACTUALITE,
    REACT_APP_URL_EXT_PREVENTION_RESSOURCES,
    REACT_APP_URL_EXT_PREVENTION_MENTION_LEGALES,
    REACT_APP_URL_EXT_PREVENTION_CONTACT,
    REACT_APP_URL_OPPBTP_PREFIXE,
  } = process.env;

  return (
    <>
      <footer className="footer">
        <FooterContainer>
          <Link href={REACT_APP_URL_EXT_PREVENTION_RESSOURCES}>Ressources</Link>
          <Line />
          <Link href={REACT_APP_URL_EXT_PREVENTION_ACTUALITE}>Actualités</Link>
          <Line />
          <Link href={REACT_APP_URL_EXT_PREVENTION_MENTION_LEGALES}>Mentions légales</Link>
          <Line />
          <Link href={REACT_APP_URL_EXT_PREVENTION_CONTACT}>Contact</Link>
          <Line last={true} />
          <FooterLogo href={REACT_APP_URL_OPPBTP_PREFIXE}>
            <img src={logo} alt="oppbtp logo" />
          </FooterLogo>
        </FooterContainer>
      </footer>
    </>
  );
}

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin: 20px 0;
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    gap: 20px;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.sm}px) {
    flex-wrap: wrap;
    gap: 2%;
  }
`;

const Link = styled.a`
  text-decoration: underline;
  font-weight: bold;
  font-size: ${StyleData.fsize.md};
  color: ${StyleData.color.text};
  :hover {
    cursor: pointer;
    color: ${StyleData.color.primary};
    transition: color 0.15s ease-in;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    font-size: ${StyleData.fsize.sm};
  }
`;

type StyleProps = {
  last?: boolean;
};

const Line = styled.div<StyleProps>`
  background-color: #c1c6c8;
  height: 30px;
  width: 1px;
  @media screen and (max-width: ${StyleData.breakpoints.max.sm}px) {
    display: ${(props) => (props.last ? 'none' : 'block')};
  }
`;

const FooterLogo = styled.a`
  img {
    height: 22px;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.sm}px) {
    margin: 10px 150px 0 150px;
  }
`;

export default Footer;
