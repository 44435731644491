import create from 'zustand';

export type ListStoreState = {
  visitTypes: { value: string; label: string }[];
  setVisitTypes: (visitTypes: { value: string; label: string }[]) => void;
  materialFamilies: {
    value: string;
    label: string;
    isIpe: boolean;
    isInstallation: boolean;
    isMaterial: boolean;
  }[];
  setMaterialFamilies: (
    materialFamilies: {
      value: string;
      label: string;
      isIpe: boolean;
      isInstallation: boolean;
      isMaterial: boolean;
    }[]
  ) => void;

  totalMaterialSheets: number;
  setTotalMaterialSheets: (value: number) => void;
  incrementTotalMaterialSheets: (value: number) => void;

  onPageEmployeeCount: number;
  setOnPageEmployeeCount: (value: number) => void;
  incrementOnPageEmployeeCount: (value: number) => void;

  totalDeadlines: number;
  setTotalDeadlines: (value: number) => void;
  incrementTotalDeadlines: (value: number) => void;
};

export const useListStore = create<ListStoreState>((set) => ({
  visitTypes: [],
  setVisitTypes: (visitTypes: { value: string; label: string }[]) =>
    set(() => ({
      visitTypes,
    })),
  materialFamilies: [],
  setMaterialFamilies: (
    materialFamilies: {
      value: string;
      label: string;
      isIpe: boolean;
      isInstallation: boolean;
      isMaterial: boolean;
    }[]
  ) =>
    set(() => ({
      materialFamilies,
    })),
  // ? ------- totalMaterialSheets ----------
  totalMaterialSheets: 0,
  setTotalMaterialSheets: (value: number) =>
    set(() => ({
      totalMaterialSheets: value,
    })),
  incrementTotalMaterialSheets: (value: number) =>
    set((state) => ({
      totalMaterialSheets: state.totalMaterialSheets + value,
    })),
  // ? ------- onPageEmployeeCount
  onPageEmployeeCount: 0,
  setOnPageEmployeeCount: (value: number) =>
    set(() => ({
      onPageEmployeeCount: value,
    })),
  incrementOnPageEmployeeCount: (value: number) =>
    set((state) => ({
      onPageEmployeeCount: state.onPageEmployeeCount + value,
    })),
  // ? ------- totalDeadlines
  totalDeadlines: 0,
  setTotalDeadlines: (value: number) =>
    set(() => ({
      totalDeadlines: value,
    })),
  incrementTotalDeadlines: (value: number) =>
    set((state) => ({
      totalDeadlines: state.totalDeadlines + value,
    })),
}));

export type EvaluationRecordStoreState = {
  evaluationRecords: { value: string; label: string }[];
  setEvaluationRecords: (visitTypes: { value: string; label: string }[]) => void;
};

export const useEvaluationRecordStore = create<EvaluationRecordStoreState>((set) => ({
  // ? ------- evaluationRecords
  evaluationRecords: [],
  setEvaluationRecords: (providedEvaluationRecords: { value: string; label: string }[]) =>
    set(() => ({
      evaluationRecords: providedEvaluationRecords,
    })),
}));
