import dayjs, { Dayjs } from 'dayjs';
import { HydraMemberInterface } from '../interfaces/HydraMember';
import { unwrap } from '../utilities/Assertions';
import { entityBaseDataFromIri } from '../utilities/Entity';
import { DelayAct } from './DelayAct';
import { UserAct } from './UserAct';

export interface NotificationActFromAPI extends HydraMemberInterface {
  '@id': string; // iri
  destinataires: string[]; // UserACT iri
  delai: string; // iri of delay
  dateRappel: string;
  organizationId?: number;
}

export interface NotificationActToAPI {
  effectiveDate: string;
  receiverIris: string[];
  delayIri: string;
  organizationId: number;
  context: string;
}

export class NotificationAct {
  public '@id'?: string;
  public id!: number;
  public effectiveDate!: Dayjs;
  public delay!: DelayAct;
  public receivers!: UserAct[];
  public organizationId?: number;
  public rappelEnvoye?: boolean;
  public context?: string;

  constructor(data?: Partial<NotificationAct>) {
    Object.assign(this, data);
  }

  static fromAPI(entity: NotificationActFromAPI): NotificationAct {
    return new NotificationAct(
      Object.assign({}, entityBaseDataFromIri(entity['@id']), {
        receivers: entity.destinataires.map((iri) => new UserAct(entityBaseDataFromIri(iri))),
        delay: new DelayAct(entityBaseDataFromIri(entity.delai)),
        effectiveDate: dayjs(entity.dateRappel),
        organizationId: entity.organizationId,
      })
    );
  }

  toAPI(): NotificationActToAPI {
    return {
      effectiveDate: this.effectiveDate?.format('YYYY-MM-DD'),
      receiverIris: this.receivers.map((user) => user.iri),
      delayIri: this.delay.iri,
      organizationId: unwrap(
        this.organizationId,
        'NotificationAct.toAPI() - organizationId must be defined.'
      ),
      context: unwrap(this.context, 'NotificationAct.toAPI() - context must be defined.'),
    };
  }

  /**
   * Attempts to hydrate all {@link receivers} of this notification
   * by adding missing information from the given {@link users}.
   * @param users The users with full information.
   */
  hydrateUsers(users: UserAct[]): void {
    this.receivers = this.receivers.map((dryUser) => {
      const fullUser = users.find((aFullUser) => aFullUser.iri === dryUser.iri);
      return fullUser ?? dryUser;
    });
  }

  /**
   * Attempts to hydrate {@link delay} of this notification
   * by adding missing information from the given {@link delays}.
   * @param delays The delays with full information.
   */
  hydrateDelay(delays: DelayAct[]): void {
    this.delay = delays.find((aFullDelay) => aFullDelay.iri === this.delay.iri) ?? this.delay;
  }

  clone(): NotificationAct {
    return new NotificationAct({ ...this });
  }
}
