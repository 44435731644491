import React, { Component, ErrorInfo } from 'react';
import { PopinType } from './types/PopinType';

type Props = Record<string, unknown>;

type State = {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
};

export const POPIN_BASE_ERROR: PopinType = {
  title: 'Une erreur a eu lieu',
  closable: false,
  onAction: () => {
    window.location.reload();
  },
  onActionTitle: 'Rafraichir la page',
};

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: undefined, errorInfo: undefined };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      // setState({
      //   popin: {
      //     ...POPIN_BASE_ERROR,
      //     component: (
      //       <div className="is-left">
      //         <p>
      //           <strong>{this.state.error?.name}</strong>
      //         </p>
      //         <p>
      //           <pre>{this.state.error?.message}</pre>
      //         </p>
      //         <pre style={{ maxHeight: '300px', overflow: 'auto' }}>
      //           {this.state.errorInfo?.componentStack.toString()}
      //         </pre>
      //       </div>
      //     ),
      //   },
      // });
      // return <></>;
    }

    return this.props.children as React.ReactNode;
  }
}
