/**
 * Get the index of each entity if those entities were sorted by {@link sortFunction}.
 * @param entities The entities to retrieve the sorting indices.
 * @param sortFunction The sorting function.
 * @returns An array of object containing the entity and its sorting index. The array keeps the same order as the one passed in parameter.
 */
export function getSortIndices<T>(
  entities: T[],
  sortFunction: (a: T, b: T) => number
): { entity: T; sortIndex: number }[] {
  const indexedSortFunction = (a: [T, number], b: [T, number]) => sortFunction(a[0], b[0]);

  const sortedEntities = entities.map<[T, number]>((el, index) => [el, index]).sort(indexedSortFunction);

  const entitiesWithSortIndices = entities.map((entity) => ({ entity, sortIndex: 0 }));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sortedEntities.forEach(([_entity, originalIndex], sortIndex) => {
    entitiesWithSortIndices[originalIndex].sortIndex = sortIndex;
  });

  return entitiesWithSortIndices;
}
