import { HydraMemberInterface } from '../interfaces/HydraMember';

export class DelayAct {
  id!: number;
  public '@id'?: string;
  iri!: string;
  code!: string;
  name!: string;
  duree!: string;

  constructor(data?: Partial<HydraMemberInterface>) {
    if (data) {
      if (data['@id']) {
        this.iri = data['@id'];

        const id = data['@id'].split(/[/]+/).pop();

        if (id) {
          this.id = +id;
        }
      }

      if (data?.name) {
        this.name = data.name;
      }
    }
  }

  toPlainObj(): any {
    return Object.assign({}, this);
  }
}
