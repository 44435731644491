import styled from 'styled-components';
import { Container } from '../../components/List.style';
import StyleData from '../../utilities/StyleData';

export const TitleContainer = styled.div`
  text-align: center;
`;

export const MaterialUpdateValidationContainer = styled(Container)``;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${StyleData.spacing.xl};
  align-items: center;
`;

// TODO Find max width on figma for the texts
export const DescriptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${StyleData.spacing.md};
  align-items: center;
  line-height: 1.5;
  max-width: 512px;
`;

export const ActionContainer = styled.div`
  display: flex;
  gap: ${StyleData.spacing.lg};

  @media all and (max-width: ${StyleData.breakpoints.max.sm}px) {
    flex-direction: column;
    align-self: stretch;
  }
`;
