import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import StyleData from '../../utilities/StyleData';

export type RadioClassicVariant = 'vertical' | 'horizontal';

interface Props {
  name: string;
  defaultValue?: string;
  label?: string;
  values: RadioProps[];
  onChange?: (value: string) => void;
  error?: string;
  disabled?: boolean;
  variant?: RadioClassicVariant;
  tabIndex?: number;
  title?: string;
}

export type RadioProps = { value: string; label: string };

export function RadioClassic(props: Props): ReactElement {
  const { control } = useFormContext();

  const variant = props.variant ?? 'vertical';

  return (
    <InputWrapper className={props.variant ?? 'vertical'}>
      {props.label && <LabelText>{props.label}</LabelText>}
      <Controller
        name={props.name}
        control={control}
        defaultValue={props.defaultValue}
        render={({ field: { value, onChange } }) => {
          return (
            <>
              <RadioContainer className={variant} title={props.title}>
                {Object.values(props.values).map((radioProps: RadioProps) => {
                  return (
                    <RadioWrapper key={radioProps.value}>
                      <input
                        type="radio"
                        id={props.name + radioProps.value}
                        value={radioProps.value}
                        onChange={(e) => {
                          if (e.target.value && props.onChange) {
                            props.onChange(e.target.value);
                          }
                          onChange(e);
                        }}
                        name={props.name}
                        checked={value === radioProps.value}
                        disabled={props.disabled}
                        tabIndex={props.tabIndex}
                      />
                      <label htmlFor={props.name + radioProps.value}>{radioProps.label}</label>
                    </RadioWrapper>
                  );
                })}
              </RadioContainer>
              {props.error && <RedMessage>{props.error}</RedMessage>}
            </>
          );
        }}
      />
    </InputWrapper>
  );
}

const RadioContainer = styled.div`
  display: flex;
  gap: 10px;

  &.vertical {
    flex-direction: column;
  }

  &.horizontal {
    flex-direction: row;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;

  > input {
    margin-right: 5px;
    flex-shrink: 0;
    cursor: pointer;
  }

  > label {
    font-family: 'Work Sans', sans-serif;
    font-size: ${StyleData.fsize.md};
    cursor: pointer;
  }
`;

const RedMessage = styled.span`
  color: red;
  font-family: 'Work Sans', Helvetica, sans-serif;
  font-size: 11px;
`;

const LabelText = styled.div`
  display: flex;
  font-size: ${StyleData.fsize.md};
  font-family: 'Work Sans', Helvetica, sans-serif;
`;
