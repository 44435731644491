import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

interface Props {
  name: string;
  label?: string;
  onChange?: (value: string) => void;
  error?: string;
  disabled?: boolean;
}

export function CheckboxField(props: Props): ReactElement {
  const { control } = useFormContext();

  return (
    <InputWrapper>
      <Controller
        name={props.name}
        control={control}
        defaultValue={false}
        render={({ field: { value, onChange } }) => {
          return (
            <>
              <CheckboxWrapper>
                <input
                  type="checkbox"
                  id={props.name + value}
                  value={value}
                  onChange={(e) => {
                    if (e.target.value && props.onChange) {
                      props.onChange(e.target.value);
                    }
                    onChange(e);
                  }}
                  name={props.name}
                  checked={value === true}
                  disabled={props.disabled}
                />
                <label htmlFor={props.name + value}>{props.label}</label>
              </CheckboxWrapper>
              {props.error && <RedMessage>{props.error}</RedMessage>}
            </>
          );
        }}
      />
    </InputWrapper>
  );
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  > input {
    margin-right: 5px;
  }
  > label {
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
  }
`;

const RedMessage = styled.span`
  color: red;
  font-family: "'Work Sans',Helvetica,sans-serif";
  font-size: 11px;
`;
