import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Button } from '../../../components/Button';
import { VSpacer } from '../../../components/layout/Spacer';
import { ColumnCentered } from '../../../components/List.style';
import Text from '../../../components/Text';
import Title from '../../../components/Title';
import { useEventFamilyContext } from '../../../contexts/EventFamilyContext';
import { EventTypesContext } from '../../../contexts/EventTypesContext';
import { ChevronDownBlack, ChevronUpBlack } from '../../../icons';
import EventType from '../../../models/EventType';
import MaterialChecking from '../../../models/MaterialChecking';
import EventTypeHttp from '../../../services/http/EventTypeHttp';
import { useMaterialSheetStore } from '../../../store/MaterialSheetStore';
import { unwrap } from '../../../utilities/Assertions';
import { getSortIndices } from '../../../utilities/Sort';
import styleData from '../../../utilities/StyleData';
import { MaterialCheckingLine } from '../material-checking-line/MaterialCheckingLine';
import { Body, CancelFilter, Events, Header, HeaderCellRoot, Root } from './EventsFamilyBlockStyle';

export interface EventFamilyBlockProps {}

/**
 * Maximum number of MaterialChecking items initially displayed.
 */
const ALWAYS_VISIBLE_ITEM_NUMBER: number = 3;

/** Types of element ordering. */
const enum SortingOrder {
  EffectiveDateAscending,
  EffectiveDateDescending,
  PlannedDateAscending,
  PlannedDateDescending,
  /** Keep the original order (given by the backend). */
  None,
}

/** Displays the {@link MaterialCheckingLine} of an {@link EventFamily}. */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function EventFamilyBlock(props: EventFamilyBlockProps) {
  const { materialType, eventFamily } = unwrap(useEventFamilyContext());
  const dateCommissioning = useMaterialSheetStore((state) => state.dateCommissioning);
  const materialCheckings = useMaterialSheetStore((state) => state.getMaterialCheckings)(eventFamily);
  const addMaterialChecking = useMaterialSheetStore((state) => state.addMaterialChecking);
  const [removeMaterialChecking, replaceMaterialChecking, replaceMaterialCheckingIfDifferent] =
    useMaterialSheetStore((state) => [
      state.removeMaterialChecking,
      state.replaceMaterialChecking,
      state.replaceMaterialCheckingIfDifferent,
    ]);

  const [eventTypes, setEventTypes] = useState<EventType[] | undefined>(undefined);
  const eventTypesFetchParams = useMemo(
    () => ({
      'materialType.id': unwrap(materialType).id,
      'eventFamily.id': unwrap(eventFamily).id,
    }),
    [eventFamily, materialType]
  );
  const [displayAll, setDisplayAll] = useState(false);
  const [sortOrder, setSortOrder] = useState(SortingOrder.None);

  // Fetch the event types
  useEffect(() => {
    (async () => {
      const fetchedEventTypes = await new EventTypeHttp().get(eventTypesFetchParams);
      setEventTypes(fetchedEventTypes);
    })();
  }, [eventTypesFetchParams]);

  // Initialize lines to complete if needed (after event types are fetched)
  useEffect(() => {
    if (!eventTypes || !dateCommissioning) return;

    // Add a temporary first material checking.
    if (materialCheckings.length === 0) {
      // Search if there is a default event type
      addMaterialChecking(
        MaterialChecking.computeFirst(dateCommissioning?.toDateString(), eventTypes, eventFamily)
      );
      return;
    }

    // Regenerate if incomplete as eventTypes may change and this could not match anymore.
    if (materialCheckings.length === 1 && materialCheckings[0].notBeingFilledYet()) {
      replaceMaterialCheckingIfDifferent(
        materialCheckings[0],
        MaterialChecking.computeFirst(dateCommissioning?.toDateString(), eventTypes, eventFamily)
      );
      return;
    }

    // Already present material checkings
    {
      const lastMaterialChecking = unwrap(materialCheckings.at(-1));
      // If last line is provided from back then we need to generate an incomplete event line to follow this one.
      // Except if the last line is a line involving scrapping.
      if (
        lastMaterialChecking.id !== undefined &&
        lastMaterialChecking.isFilled() &&
        lastMaterialChecking.eventResult?.isInvolvingScrapping !== true
      ) {
        addMaterialChecking(lastMaterialChecking.computeNext(materialType, eventTypes));
      }
    }
  }, [
    addMaterialChecking,
    dateCommissioning,
    eventFamily,
    eventTypes,
    materialCheckings,
    materialCheckings.length,
    materialType,
    replaceMaterialCheckingIfDifferent,
  ]);

  //#region UI Callbacks
  const loadMoreLinkClicked = () => {
    setDisplayAll(true);
  };

  const deleteLine = (index: number) => {
    removeMaterialChecking(materialCheckings[index]);
    const strippedMaterial = new MaterialChecking(Object.assign({}, materialCheckings[index - 1]));
    strippedMaterial.clear();
    replaceMaterialChecking(materialCheckings[index - 1], strippedMaterial);
  };

  const changeSortOrder = (newSortOrder: SortingOrder) => {
    setDisplayAll(true);
    setSortOrder(newSortOrder);
  };

  const togglePlannedDateSortOrder = () => {
    switch (sortOrder) {
      case SortingOrder.PlannedDateAscending:
        changeSortOrder(SortingOrder.PlannedDateDescending);
        break;
      case SortingOrder.PlannedDateDescending:
      default:
        changeSortOrder(SortingOrder.PlannedDateAscending);
        break;
    }
  };

  const toggleEffectiveDateSortOrder = () => {
    switch (sortOrder) {
      case SortingOrder.EffectiveDateAscending:
        changeSortOrder(SortingOrder.EffectiveDateDescending);
        break;
      case SortingOrder.EffectiveDateDescending:
      default:
        changeSortOrder(SortingOrder.EffectiveDateAscending);
        break;
    }
  };

  const resetFilters = () => {
    setSortOrder(SortingOrder.None);
  };
  //#endregion

  // Compute sorting indices of material checkings to display the element in the desired order.
  const displayedMaterialCheckings = useMemo<{ entity: MaterialChecking; sortIndex: number }[]>(() => {
    if (sortOrder === SortingOrder.None) {
      // Reverse to display the line to complete above the others
      const lastIndex = materialCheckings.length - 1;
      return materialCheckings.map((mc, index) => ({ entity: mc, sortIndex: lastIndex - index }));
    }
    return getMaterialCheckingsSortIndices(materialCheckings, sortOrder);
  }, [materialCheckings, sortOrder]);

  return (
    <Root className="EventsSummary">
      <Title level={2} name={eventFamily.name}></Title>
      <VSpacer size={styleData.spacing.lg} />
      <Text>{eventFamily.description}</Text>
      <VSpacer size={styleData.spacing.xl} />
      {eventTypes ? (
        (materialCheckings.length > 0 && (
          <EventTypesContext.Provider value={{ eventTypes }}>
            <Events className="EventsSummary">
              <Header containsPlannedDate={eventFamily.hasPlannedDate}>
                <HeaderCell text="Type d'évènement" />
                {eventFamily.hasPlannedDate && (
                  <OrderFilterHeaderCell
                    text="Date prévue"
                    filterMode={sortOrder === SortingOrder.PlannedDateAscending ? 'ascending' : 'descending'}
                    active={
                      sortOrder === SortingOrder.PlannedDateAscending ||
                      sortOrder === SortingOrder.PlannedDateDescending
                    }
                    onClick={togglePlannedDateSortOrder}
                    onReset={resetFilters}
                  ></OrderFilterHeaderCell>
                )}
                <OrderFilterHeaderCell
                  text="Date de réalisation"
                  filterMode={sortOrder === SortingOrder.EffectiveDateAscending ? 'ascending' : 'descending'}
                  active={
                    sortOrder === SortingOrder.EffectiveDateAscending ||
                    sortOrder === SortingOrder.EffectiveDateDescending
                  }
                  onClick={toggleEffectiveDateSortOrder}
                  onReset={resetFilters}
                ></OrderFilterHeaderCell>
                <HeaderCell text="Vérificateur" />
                <HeaderCell text="Docs" />
                <HeaderCell text="Résultats" />
              </Header>
              <Body>
                {displayedMaterialCheckings.map(
                  ({ entity, sortIndex }, index) =>
                    (displayAll || sortIndex < ALWAYS_VISIBLE_ITEM_NUMBER) && (
                      <MaterialCheckingLine
                        key={entity.uuid}
                        materialChecking={entity}
                        canBeDeleted={materialCheckings.length > 1}
                        prevMaterialChecking={index > 0 ? materialCheckings[index - 1] : null}
                        nextMaterialChecking={
                          materialCheckings.length <= index + 1 ? null : materialCheckings[index + 1]
                        }
                        onDeletionRequested={() => deleteLine(index)}
                        style={{ order: sortIndex }}
                      />
                    )
                )}
              </Body>
            </Events>
          </EventTypesContext.Provider>
        )) || (
          <Text textAlign="center" color={styleData.color.lightText}>
            Aucun évènement
          </Text>
        )
      ) : (
        <Text>Chargement en cours...</Text>
      )}
      {!displayAll && materialCheckings.length > ALWAYS_VISIBLE_ITEM_NUMBER && (
        <>
          <VSpacer size={styleData.spacing.xl}></VSpacer>

          <ColumnCentered>
            <Button variant="lightNoBorderUnderline" icon={ChevronDownBlack} callback={loadMoreLinkClicked}>
              Afficher l'historique
            </Button>
          </ColumnCentered>
        </>
      )}
    </Root>
  );
}

/** Basic header cell. */
function HeaderCell(props: { text: string; children?: React.ReactNode; onClick?: () => void }) {
  return (
    <HeaderCellRoot onClick={props.onClick}>
      <Text fsize="xs">{props.text}</Text>
      {props.children}
    </HeaderCellRoot>
  );
}

/** Header cell including filter buttons. */
function OrderFilterHeaderCell(props: {
  text: string;
  filterMode: 'ascending' | 'descending';
  /** Is filter in use? */
  active: boolean;
  /** Callback triggered when there is a click on the cell. */
  onClick: () => void;
  /** Callback triggered when the user asks to clear the filter. */
  onReset: () => void;
}) {
  return (
    <HeaderCell text={props.text} onClick={props.onClick}>
      {props.filterMode === 'ascending' ? <ChevronUpBlack /> : <ChevronDownBlack />}
      <CancelFilter
        $visible={props.active}
        onClick={
          props.active
            ? (e) => {
                e.stopPropagation();
                props.onReset();
              }
            : undefined
        }
      />
    </HeaderCell>
  );
}

/**
 * Compares two dates. An undefined date is always considered to be after the other.
 */
function chronologicalComparison(date1: string | undefined, date2: string | undefined): number {
  if (!date1) {
    return 1;
  } else if (!date2) {
    return -1;
  } else {
    const dayJs1 = dayjs(date1);
    if (dayJs1.isAfter(date2)) {
      return 1;
    } else if (dayJs1.isSame(date2)) {
      return 0;
    } else {
      return -1;
    }
  }
}

/** Gets the comparison function to use with the given {@link sortOrder}. */
function getComparisonFunction(
  sortOrder: SortingOrder
): (mc1: MaterialChecking, mc2: MaterialChecking) => number {
  switch (sortOrder) {
    case SortingOrder.EffectiveDateAscending:
      return (mc1, mc2) => chronologicalComparison(mc1.effectiveDate, mc2.effectiveDate);
    case SortingOrder.EffectiveDateDescending:
      return (mc1, mc2) => -chronologicalComparison(mc1.effectiveDate, mc2.effectiveDate);
    case SortingOrder.PlannedDateAscending:
      return (mc1, mc2) => chronologicalComparison(mc1.plannedDate, mc2.plannedDate);
    case SortingOrder.PlannedDateDescending:
      return (mc1, mc2) => -chronologicalComparison(mc1.plannedDate, mc2.plannedDate);
    default:
      throw new Error('UnhandledSwitchCaseException');
  }
}

/** Gets sorting indices of the {@link materialCheckings} with the given {@link sortOrder}. */
function getMaterialCheckingsSortIndices(
  materialCheckings: MaterialChecking[],
  sortOrder: SortingOrder
): { entity: MaterialChecking; sortIndex: number }[] {
  const sortFunction = getComparisonFunction(sortOrder);
  return getSortIndices(materialCheckings, sortFunction);
}
