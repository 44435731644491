import React, { ReactElement, useEffect, useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { routeToLink } from '../../App.routing';
import { Button } from '../../components/Button';
import NavigationButton from '../../components/NavigationButton';
import Tabs from '../../components/tabs/Tabs';
import { useResponsiveContext } from '../../contexts/ResponsiveContext';
import {
  ArrowLeft,
  ArrowRight,
  ArrowRightBlack,
  CalendarFill,
  CalendarWire,
  ScrewLight,
  ScrewSolid,
} from '../../icons';
import MaterialSheetHttp from '../../services/http/MaterialSheetHttp';
import { useListStore } from '../../store/ListStore';
import HasChildrenProps from '../../types/props/HasChildrenProps.type';
import StyleData from '../../utilities/StyleData';

interface ToolbarProps {
  childrenTabs: JSX.Element;
  childrenActions?: JSX.Element;
  noLinearGradient?: boolean;
  modal?: boolean;
  showArrows?: boolean;
}

function Toolbar({
  childrenTabs,
  childrenActions,
  noLinearGradient,
  modal,
  showArrows,
}: ToolbarProps): ReactElement {
  const ref = useRef(document.createElement('div'));
  const scroll = (scrollOffset: number) => {
    if (ref.current != null) {
      ref.current.scrollLeft += scrollOffset;
    }
  };
  const { organizationId, employeeId } = useParams<{ organizationId: string; employeeId: string }>();
  useLayoutEffect(() => {
    setTimeout(() => {
      if (
        window.location.pathname === routeToLink('epi_list', { organizationId, employeeId }) ||
        window.location.pathname === routeToLink('health_page', { organizationId, employeeId })
      ) {
        scroll(260);
      }
    }, 500);
  }, []);

  return (
    <Container>
      <SlideArrowLeftContainer show={showArrows || false} noLinearGradient={noLinearGradient}>
        <SlideArrow callback={() => scroll(-80)} variant="arrowLeft" icon={ArrowLeft} />
      </SlideArrowLeftContainer>
      <ToolbarContainer modal={modal} ref={ref}>
        <ToolbarTabsContainer>{childrenTabs}</ToolbarTabsContainer>
        {childrenActions && <ToolbarActionsContainer>{childrenActions}</ToolbarActionsContainer>}
      </ToolbarContainer>
      <SlideArrowRightContainer show={showArrows || false} noLinearGradient={noLinearGradient}>
        <SlideArrow callback={() => scroll(80)} variant="arrowRight" icon={ArrowRight} />
      </SlideArrowRightContainer>
    </Container>
  );
}

/**
 * @description specific toolbar for pages : "Liste de Matériel" / "Mes échéances"
 * You can customize actions by passing children elements
 */
export function MainToolbar({ children }: HasChildrenProps): ReactElement {
  const location = useLocation();
  const { organizationId } = useParams<{ organizationId: string }>();

  const [totalMaterialSheets, setTotalMaterialSheets] = useListStore((state) => [
    state.totalMaterialSheets,
    state.setTotalMaterialSheets,
  ]);

  const [totalMaterialDeadlines, setTotalMaterialDeadlines] = useListStore((state) => [
    state.totalDeadlines,
    state.setTotalDeadlines,
  ]);
  const responsive = useResponsiveContext();

  useEffect(() => {
    new MaterialSheetHttp()
      .count({
        'group.organizationId': organizationId,
      })
      .then((value) => {
        setTotalMaterialSheets(value);
      })
      .catch((error) => {
        console.error(error);
      });

    new MaterialSheetHttp()
      .count({
        'group.organizationId': organizationId,
        'exists[lastPlannedMaterialChecking]': true,
      })
      .then((materialCount) => {
        setTotalMaterialDeadlines(materialCount);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const listRoute = routeToLink('/:organizationId/liste', { organizationId });
  const deadlinesRoute = routeToLink('/:organizationId/echeances', { organizationId });

  // ! TODO : Appel api "link stat" pour avoir les valeurs des compteurs
  return (
    <Toolbar
      showArrows={false}
      childrenTabs={
        <Tabs>
          <NavigationButton
            href={listRoute}
            name="Liste de matériel"
            description={
              !responsive.isMobile
                ? totalMaterialSheets
                  ? `${totalMaterialSheets} matériel${totalMaterialSheets > 1 ? 's' : ''}`
                  : 'Aucun matériel'
                : ''
            }
            icon={location.pathname === listRoute ? ScrewSolid : ScrewLight}
            variant={location.pathname === listRoute ? 'active' : 'inactive'}
          />
          <NavigationButton
            href={deadlinesRoute}
            name="Mes échéances"
            description={
              !responsive.isMobile
                ? totalMaterialDeadlines
                  ? `${totalMaterialDeadlines} échéance${totalMaterialDeadlines > 1 ? 's' : ''}`
                  : 'Aucune échéance'
                : ''
            }
            icon={location.pathname === deadlinesRoute ? CalendarFill : CalendarWire}
            variant={location.pathname === deadlinesRoute ? 'active' : 'inactive'}
          />
        </Tabs>
      }
      childrenActions={children}
    />
  );
}

export type ToolbarItem = {
  title: string;
  icon?: React.ElementType;
  description: string;
  link?: string;
  callback?: ((e: Event) => unknown) | (() => unknown);
  isActive: boolean;
  isError?: boolean;
};
interface Props {
  children?: JSX.Element;
  items: ToolbarItem[];
  noLinearGradient?: boolean;
  modal?: boolean;
}
/**
 * @description customizable toolbar
 * You can customize links and actions by passing props and children elements
 */
export function CustomToolbar({ children, items, noLinearGradient, modal }: Props): ReactElement {
  return (
    <Toolbar
      noLinearGradient={noLinearGradient}
      modal={modal}
      childrenTabs={
        <Tabs>
          {items.map((item, index) => {
            return (
              item && (
                <NavigationButton
                  key={index}
                  href={item.link ? item.link : '#'}
                  callback={item.callback}
                  name={item.title}
                  description={item.description}
                  icon={item.isError ? ArrowRightBlack : item.icon}
                  variant={item.isError ? 'error' : item.isActive ? 'active' : 'inactive'}
                />
              )
            );
          })}
        </Tabs>
      }
      childrenActions={children}
    />
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    justify-content: center;
    overflow: hidden;
  }
`;

type StyleProps = {
  modal?: boolean;
};

const ToolbarContainer = styled.div<StyleProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: ${StyleData.breakpoints.max.lg}px) {
    width: ${(props) => (props.modal ? '70vw' : '100%')};
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    overflow-x: scroll;
    scroll-behavior: smooth;
    height: ${(props) => (props.modal ? '50px' : '38px')};
  }
`;

const ToolbarTabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
`;

const ToolbarActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SlideArrow = styled(Button)``;

type ArrowProps = {
  noLinearGradient?: boolean;
  show: boolean;
};

const SlideArrowRightContainer = styled.div<ArrowProps>`
  ${(props) => {
    return props.noLinearGradient
      ? ''
      : 'background: linear-gradient(90deg,rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0.8) 50%,rgba(255, 255, 255, 1) 100%);';
  }}
  top: 0;
  right: 0;
  display: ${(props) => {
    return props.show ? 'flex' : 'none';
  }};
  justify-content: flex-end;
  @media screen and (min-width: ${StyleData.breakpoints.min.md}px) {
    display: none;
  }
`;

const SlideArrowLeftContainer = styled.div<ArrowProps>`
  ${(props) => {
    return props.noLinearGradient
      ? ''
      : 'background: linear-gradient(90deg,rgba(255, 255, 255, 1) 0%,rgba(255, 255, 255, 0.8) 50%,rgba(255, 255, 255, 0) 100%);';
  }}
  top: 0;
  left: 0;
  display: ${(props) => {
    return props.show ? 'flex' : 'none';
  }};
  justify-content: flex-start;
  @media screen and (min-width: ${StyleData.breakpoints.min.md}px) {
    display: none;
  }
`;
