import { createContext, useContext } from 'react';
import User from '../models/User';
import { ACTRight } from '../types/ACTRight';

export type AuthContextType = {
  authenticated: boolean;
  readOnly?: boolean;
  ACTAuthorization?: ACTRight;
  loading: boolean;
  user?: User;
};

/**
 * Provide an auth context.
 */
export const AuthContext = createContext<AuthContextType>({
  authenticated: false,
  readOnly: true,
  ACTAuthorization: undefined,
  loading: true,
  user: undefined,
});

export function useAuthContext(): AuthContextType {
  return useContext(AuthContext);
}
