import { ReactElement } from 'react';
import styled from 'styled-components';
import StyleData from '../../utilities/StyleData';

interface Props {
  error?: string;
}

export default function InputError(props: Props): ReactElement {
  return props.error ? <InputErrorContainer>{props.error}</InputErrorContainer> : <></>;
}

const InputErrorContainer = styled.span`
  color: ${StyleData.color.primary};
  font-family: ${StyleData.font.secondary};
  font-size: ${StyleData.fsize.xs};
  line-height: 16px;
  margin-top: 4px;
`;
