import { ReactElement, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import StyleData from '../../utilities/StyleData';
import { VSpacer } from '../layout/Spacer';
import InputError from './InputError';

export type Option = { value: string; label: string };

interface Props {
  name: string;
  label?: string;
  options: Option[] | { label: string; options: Option[] }[];
  placeholder?: string;
  error?: string;
  maxWidth?: string;
  /** Defaults to `true` if not mobile or creatable, false otherwise. */
  useTextInput?: boolean;
  textSelectedMaxWidth?: string;
  creatable?: boolean;
  hasLimitedHeight?: boolean;
  disabled?: boolean;
  background?: boolean;
  tabIndex?: number;
  onChange?: (value: any) => void;
}

function SingleSelect(props: Props): ReactElement {
  const { control } = useFormContext();
  const responsive = useContext(ResponsiveContext);
  const useTextInput = props.useTextInput ?? (!responsive.isMobile || props.creatable === true);
  const [maxHeight, setMaxHeight] = useState<undefined | number>();
  const [focused, setFocused] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  // Determine max height automatically when focusing the element
  useEffect(() => {
    if (!props.hasLimitedHeight) {
      setMaxHeight(undefined);
      return;
    }
    if (!focused) return;
    if (containerRef.current === null) return;

    const container = containerRef.current;
    setMaxHeight(
      document.documentElement.getBoundingClientRect().bottom - container.getBoundingClientRect().bottom
    );
  }, [focused, props.hasLimitedHeight]);

  const customStyles = useCallback(
    (isError: boolean) => {
      return {
        option: () => ({
          color: StyleData.color.text,
          fontFamily: StyleData.font.secondary,
          fontSize: StyleData.fsize.md,
          lineHeight: '16px',
          padding: '5px 10px 5px 10px',
          cursor: 'pointer',
        }),

        indicatorSeparator: () => ({
          display: 'none',
        }),
        indicatorsContainer: () => ({
          padding: '0 0 0 0',
          display: 'flex',
          alignItems: 'strech',
        }),
        clearIndicator: () => ({
          paddingLeft: '6px',
          paddingRight: '6px',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }),

        dropdownIndicator: () => ({
          display: 'flex',
          color: props.disabled ? StyleData.color.lightText : StyleData.color.text,
          alignItems: 'center',
          cursor: 'pointer',
          paddingLeft: '6px',
          paddingRight: '12px',
        }),

        placeholder: () => ({
          color: StyleData.color.lightText,
          fontFamily: StyleData.font.secondary,
          fontSize: StyleData.fsize.md,
          lineHeight: '16px',
        }),

        input: () => ({
          color: props.disabled ? StyleData.color.lightText : StyleData.color.text,
          fontFamily: StyleData.font.secondary,
          fontSize: StyleData.fsize.md,
          lineHeight: '16px',
          display: 'inline-block',
          flexBasis: '25%',
          flexGrow: 1,
        }),

        singleValue: () => ({
          color: props.disabled ? StyleData.color.lightText : StyleData.color.text,
          fontFamily: StyleData.font.secondary,
          fontSize: StyleData.fsize.md,
          fontWeight: 600,
          lineHeight: '16px',
          textOverflow: 'ellipsis',
          maxWidth: props.textSelectedMaxWidth ? props.textSelectedMaxWidth : '200px',
          whiteSpace: 'nowrap' as any,
          overflow: 'hidden',
          display: 'inline-block',
        }),

        valueContainer: () => ({
          display: 'flex',
          backgroundColor: props.disabled ? '#f5f5f5' : props.background ? StyleData.color.base : 'initial',
          alignItems: 'center',
          padding: '9px 0 9px 14px',
        }),
        control: () => ({
          display: 'flex',
          backgroundColor: props.disabled ? '#f5f5f5' : props.background ? StyleData.color.base : 'initial',
          // gridTemplate:
          //   value !== null && value !== undefined && Object.keys(value).length !== 0 && !props.disabled
          //     ? '100% / 1fr 65px'
          //     : '100% / 1fr 34px',
          justifyContent: 'space-between',
          border: '1px solid #92999C',
          borderRadius: '3px',
          minHeight: '36px',
          borderColor: isError ? 'red' : props.disabled ? StyleData.color.lightText : 'none',
          maxWidth: responsive.isMobile ? 'unset' : props.maxWidth,
        }),

        groupHeading: () => ({
          color: StyleData.color.text,
          fontFamily: StyleData.font.secondary,
          fontSize: StyleData.fsize.md,
          padding: '0px 10px 5px 10px',
          fontWeight: '600',
        }),

        menu: (provided: any) => {
          if (props.hasLimitedHeight) {
            return {
              ...provided,
              marginBottom: '0',
              marginTop: '0',
              maxHeight,
            };
          }
          return {
            ...provided,
            marginBottom: '0',
            marginTop: '0',
          };
        },

        menuList: (provided: any) => {
          if (props.hasLimitedHeight) {
            return {
              ...provided,
              maxHeight,
            };
          }
          return provided;
        },
      };
    },
    [
      props.disabled,
      props.textSelectedMaxWidth,
      props.background,
      props.maxWidth,
      props.hasLimitedHeight,
      responsive.isMobile,
      maxHeight,
    ]
  );

  return (
    <SingleSelectContainer ref={containerRef}>
      {props.label && (
        <>
          <label>{props.label}</label> <VSpacer size="6px" />
        </>
      )}
      {props.creatable ? (
        <Controller
          control={control}
          name={props.name}
          render={({ field: { onChange, value, onBlur, ref } }) => (
            <CreatableSelect
              isClearable
              value={value}
              onInputChange={onChange}
              onChange={(value: any) => onChange?.(value)}
              onFocus={() => setFocused(true)}
              onBlur={() => {
                onBlur();
                setFocused(false);
              }}
              ref={ref}
              isMulti={false}
              options={props.options}
              tabIndex={props.tabIndex}
              styles={customStyles(Boolean(props.error))}
              placeholder={props.placeholder}
              noOptionsMessage={(obj) => {
                if (!obj || !obj.inputValue) {
                  return 'Aucune option';
                } else {
                  return 'Aucune option ne correspond à ' + obj.inputValue;
                }
              }}
              isDisabled={props.disabled}
              isSearchable={useTextInput}
            />
          )}
        />
      ) : (
        <Controller
          control={control}
          name={props.name}
          render={({ field: { onChange, value, onBlur, ref } }) => {
            return (
              <div title={value ? value.label : ''}>
                <Select
                  isClearable
                  onChange={(...params) => {
                    onChange(...params);
                    props.onChange?.(params[0]);
                  }}
                  value={value}
                  onFocus={() => setFocused(true)}
                  onBlur={() => {
                    onBlur();
                    setFocused(false);
                  }}
                  ref={ref}
                  isMulti={false}
                  options={props.options}
                  tabIndex={props.tabIndex}
                  styles={customStyles(Boolean(props.error))}
                  placeholder={props.placeholder}
                  noOptionsMessage={(obj) => {
                    if (!obj || !obj.inputValue) {
                      return 'Aucune option';
                    } else {
                      return 'Aucune option ne correspond à ' + obj.inputValue;
                    }
                  }}
                  isDisabled={props.disabled}
                  isSearchable={useTextInput}
                />
              </div>
            );
          }}
        />
      )}
      <InputError error={props.error} />
    </SingleSelectContainer>
  );
}

const SingleSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${StyleData.color.text};
  font-family: ${StyleData.font.secondary};
  font-size: ${StyleData.fsize.md};
  label {
    line-height: 21px;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    label {
      font-size: ${StyleData.fsize.sm};
      line-height: 18px;
    }
  }
`;

export default SingleSelect;
