import styled from 'styled-components';
import StyleData from '../../utilities/StyleData';

export const Checkbox = styled.div`
  font-size: 11px;
  font-weight: normal;
  font-family: ${StyleData.font.secondary};
  span {
    position: relative;
    top: 3px;
  }
  display: flex;
  width: fit-content;
  height: 100%;
  padding-left: 12px;
`;

export const GenericContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 ${StyleData.spacing.md};
  span {
    margin: auto 7px auto 0;
  }
`;

export const FilterButton = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  svg {
    margin-top: 2px;
    width: 16px;
    height: 16px;
    padding: 2px;
  }
  :hover {
    cursor: pointer;
    .icon {
      .st0-fill {
        fill: ${StyleData.color.secondary};
        transition: color 0.15s ease-in;
      }
    }
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  .form-checkbox {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .form-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
  }
  p {
    margin: 0;
    text-align: left;
    width: 100%;
    color: ${StyleData.color.text};
    font-family: ${StyleData.font.secondary};
    font-size: ${StyleData.fsize.md};
    line-height: 21px;
    font-weight: normal;
    white-space: break-spaces;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    p,
    label {
      font-size: ${StyleData.fsize.sm};
    }
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  input {
    margin-left: 12px;
  }
  .entries,
  .select-all {
    font-family: ${StyleData.font.secondary};
    font-size: ${StyleData.fsize.xs};
    line-height: 16px;
    font-weight: normal;
    margin: auto 0;
    margin-right: 7.5px;
  }
  .entries {
    margin-left: auto;
  }
  .select-all {
    margin-left: 12px;
  }
  button {
    margin: 0 4.5px;
  }
`;

export const CheckboxInput = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  input {
    :checked {
      background-color: ${StyleData.color.success};
    }
  }
`;
