import styled, { css } from 'styled-components';
import StyleData from '../utilities/StyleData';
import { HSpacer } from './layout/Spacer';

interface Props {
  children?: JSX.Element;
  level: 1 | 2 | 3 | 4;
  name: string;
  image?: string;
  svg?: JSX.Element;
  inline?: boolean;
  pushRight?: boolean;
}

function Title(props: Props): JSX.Element {
  const as: Record<string, string> | undefined = { as: `h${props.level}` };
  return (
    <TitleMainContainer pushRight={props.pushRight}>
      <TitleContainer {...as} pushRight={props.pushRight} inline={props.inline}>
        {props.image && <img className="title-image" src={props.image} alt={props.name} />}
        {props.svg && (
          <>
            {props.svg}
            <HSpacer size="12px"></HSpacer>
          </>
        )}
        <div>
          <span className="title-content">{props.name}</span>
          {props.children && <div className="button-block-container">{props.children}</div>}
        </div>
      </TitleContainer>
    </TitleMainContainer>
  );
}

const TitleMainContainer = styled.div<{ pushRight?: boolean }>`
  width: ${(props) => {
    return props.pushRight ? '100%' : 'initial';
  }};
  h1,
  h2,
  h3,
  h4,
  h5 {
    width: ${(props) => {
      return props.pushRight ? '100%' : 'initial';
    }};
    font-family: ${StyleData.font.primary};
    font-weight: 600;
    margin: 0;
  }
  h1 {
    font-size: ${StyleData.fsize.xxxl};
    line-height: 42px;
    margin: 0;
    @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
      font-size: ${StyleData.fsize.xxl};
      line-height: 28px;
    }
  }
  h2 {
    font-size: ${StyleData.fsize.xxl};
    line-height: 30px;
    margin: 0;
    @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
      font-size: ${StyleData.fsize.xl};
      line-height: 21px;
    }
  }
  h3 {
    font-size: ${StyleData.fsize.xl};
    line-height: 23px;
    margin: 0;
    @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
      font-size: ${StyleData.fsize.lg};
      line-height: 21px;
    }
  }
  h4 {
    font-size: ${StyleData.fsize.lg};
    line-height: 20px;
    margin: 0;
    @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
      font-size: ${StyleData.fsize.lg};
      line-height: 18px;
    }
  }
`;
const TitleContainer = styled.div<{ pushRight?: boolean; inline?: boolean }>`
  ${(props) =>
    css`
      display: ${props.pushRight ? 'block' : 'flex'};
      align-items: ${props.pushRight ? 'initial' : 'center'};
      div {
        display: ${props.pushRight || props.inline ? 'flex' : 'block'};
        justify-content: ${props.pushRight || props.inline ? 'space-between' : 'initial'};
      }
    `};
  .title-image {
    border-radius: 50%;
    width: auto;
    height: 64px;
    margin-right: 10px;
    @media screen and (max-width: ${StyleData.breakpoints.max.sm}px) {
      display: none;
    }
  }

  .title-content {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
  }

  .button-block-container {
    display: flex;
  }
`;

TitleContainer.displayName = 'TitleContainer';
TitleMainContainer.displayName = 'TitleMainContainer';
export default Title;
