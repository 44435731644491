import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { useResponsiveContext } from '../../contexts/ResponsiveContext';
import StyleData from '../../utilities/StyleData';
import { VSpacer } from '../layout/Spacer';
import InputError from './InputError';

interface Props {
  id?: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  height?: string;
  error?: string;
  tabIndex?: number;
}

export default function TextArea(props: Props): ReactElement {
  const { control } = useFormContext();
  const responsive = useResponsiveContext();

  return (
    <TextAreaContainer>
      {props.label && (
        <>
          <label>{props.label}</label> <VSpacer size="6px" />
        </>
      )}
      <Controller
        name={props.name}
        control={control}
        defaultValue=""
        render={({ field: { onChange, onBlur, value, ref } }) => {
          return (
            <StyledTextArea
              error={props.error ? true : false}
              required={props.required}
              disabled={props.disabled}
              placeholder={props.placeholder}
              ref={ref}
              onBlur={onBlur}
              value={value}
              tabIndex={props.tabIndex}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              onFocus={() => {
                if (responsive.isMobile) {
                  window.scrollTo(0, 0);
                  document.body.scrollTop = 0;
                }
              }}
              height={props.height}
            />
          );
        }}
      />
      <InputError error={props.error} />
    </TextAreaContainer>
  );
}

const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${StyleData.color.text};
  font-family: ${StyleData.font.secondary};
  font-size: ${StyleData.fsize.md};
  label {
    line-height: 21px;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    label {
      font-size: ${StyleData.fsize.sm};
      line-height: 18px;
    }
  }
`;

type StyleProps = {
  height?: string;
  error: boolean;
};

const StyledTextArea = styled.textarea<StyleProps>`
  height: ${(props) => {
    return props.height ? props.height : '100px';
  }};
  input {
    color: ${(props) => (props.disabled ? StyleData.color.lightText : StyleData.color.text)};
  }
  background-color: ${(props) => (props.disabled ? '#f5f5f5' : StyleData.color.base)};
  width: 100%;
  resize: none;
  font-size: ${StyleData.fsize.md};
  line-height: 16px;
  font-weight: 600;
  font-family: ${StyleData.font.secondary};
  box-sizing: border-box;
  border: 1px solid
    ${(props) => {
      return props.error ? StyleData.color.primary : '#92999c';
    }};
  border-radius: 3px;
  padding: 10px 34px 10px 14px;
  &:focus {
    outline: none;
  }
  ::placeholder {
    font-weight: 400;
  }
`;
