export function debounce(fn: () => void, time: number): () => void {
  let timeoutId: NodeJS.Timeout | null;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  };
}

export function dayDiff(date1: Date, date2: Date): number {
  return Math.trunc(date1.getTime() / (24 * 3600 * 1000) - date2.getTime() / (24 * 3600 * 1000));
}
