import { createContext, useContext } from 'react';
import MaterialTypeInterface from '../interfaces/MaterialType';
import EventFamily from '../models/EventFamily';
import EventResult from '../models/EventResult';

export type EventFamilyContextType =
  | {
      eventFamily: EventFamily;
      eventResults: EventResult[];
      materialType: MaterialTypeInterface;
    }
  | undefined;

/**
 * Provide a context allowing to access the employees.
 */
export const EventFamilyContext = createContext<EventFamilyContextType>(undefined);

export function useEventFamilyContext(): EventFamilyContextType {
  return useContext(EventFamilyContext);
}
