import { AxiosResponse } from 'axios';
import http from '../../ApiHttp';
import MaterialSheet from '../../models/MaterialSheet';
import { UserAct } from '../../models/UserAct';
import { extractFilename } from '../../utilities/AxiosResponse';
import HttpAbstract from './AbstractHttp';

const ROUTE = process.env.REACT_APP_URL_API_MATERIAL_SHEET;

export default class MaterialSheetHttp extends HttpAbstract<MaterialSheet> {
  constructor() {
    super(`${ROUTE}`, MaterialSheet);
  }

  public static async find(id: number | string): Promise<MaterialSheet> {
    const result = await http.get(`${ROUTE}/${id}`);

    return new MaterialSheet(result?.data);
  }

  public static async postImage(sheetId: number, file?: File): Promise<AxiosResponse> {
    const formData = new FormData();

    if (file) {
      formData.append('picture', file);
    } else {
      formData.append('delete', 'true');
    }

    return await http.post(`${ROUTE}/${sheetId}`, formData);
  }

  public static async getReceivers(organizationID: number): Promise<UserAct[]> {
    const routeCollaborators = process.env.REACT_APP_URL_API_MATERIAL_SHEET_COLLABORATORS;

    if (routeCollaborators) {
      const route = routeCollaborators?.replace(/_organizationID_/gi, `${organizationID}`);

      if (route) {
        const result = await http.get(route, {
          params: {
            isPartOfTheOrganization: true,
          },
        });
        return result.data['member']?.map((d: Record<string, unknown>) => new UserAct(d));
      }
    }

    return [];
  }

  public async exportMaterialSheetDocument(id: number, params: Record<string, unknown> = {}): Promise<any> {
    const routeExport = process.env.REACT_APP_URL_API_MATERIAL_SHEET_WORD;
    if (routeExport) {
      const route = routeExport.replace(':id', `${id}`);

      if (route) {
        const result = await http.get(route, {
          params: {
            ...params,
          },
          responseType: 'blob',
        });
        return { filename: extractFilename(result), data: result.data };
      }
    }
  }

  /**
   * Exports the material sheet list matching the query {@link params} to data ready
   * to be downloaded into a file.
   * @param format The format of the file to generate.
   * @param params The additional parameters of the request (mostly filtering).
   * @returns The filename if the server gave one, and the file data.
   */
  public async exportMaterialSheetListDocument(
    format: 'xlsx' | 'csv',
    params: Record<string, unknown>
  ): Promise<{ filename: string | undefined; data: object }> {
    const routeExport = process.env.REACT_APP_URL_API_MATERIAL_SHEET_LIST_WORD;
    if (routeExport === undefined) {
      throw new Error('Export route for material sheet list is undefined.');
    }

    const parameterizedRoute = routeExport.replace(':_format', format);

    const result = await http.get(parameterizedRoute, {
      params: {
        ...params,
      },
      responseType: 'blob',
    });

    return { filename: extractFilename(result), data: result.data };
  }
}
