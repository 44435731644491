import axios, { Canceler } from 'axios';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type HttpQueryHookReturnType<T> = {
  loading: boolean;
  error: any;
  items: T[];
  setItems: Dispatch<SetStateAction<T[]>>;
  hasMore: boolean;
  trigger: (setFullQuery: Dispatch<SetStateAction<{ query: string; pageNumber: number }>>) => boolean;
  alreadyFetchData: boolean;
};

/**
 * T: Model class, U: Http class
 * @param query
 * @param pageNumber
 */
export function useHttpQuery<T>(
  httpClass: new () => any,
  query: string,
  pageNumber: number,
  setItemCount?: (count: number) => void
): HttpQueryHookReturnType<T> {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(false);
  const [items, setItems] = useState<T[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [alreadyFetchData, setAlreadyFetchData] = useState<boolean>(false);
  const [needUpdate, setNeedUpdate] = useState<number>(0);
  const [canceler, setCanceler] = useState<Canceler>(() => {
    return;
  });

  useEffect(() => {
    setItems([]);
  }, [query]);

  useEffect(() => {
    if (!query) {
      // ? Must have at least group.organizationId to be valid
      return;
    }
    setLoading(true);
    setError(false);
    const queryPage = transformParameters(query) + '&page=' + pageNumber;
    new httpClass()
      .getInfiniteScroll(queryPage)
      .then((result: { data: T[]; last: number; total: number; cancel: Canceler }) => {
        setItems((prevItems) => {
          return pageNumber > 1 ? [...prevItems, ...result.data] : result.data;
        });
        setItemCount && setItemCount(result.total);
        setHasMore(result.last > pageNumber);
        setLoading(false);
        setCanceler(result.cancel);
        setAlreadyFetchData(true);
      })
      .catch((e: any) => {
        if (axios.isCancel(e)) return;
        setError(e);
        setLoading(false);
        setAlreadyFetchData(true);
      });

    return () => {
      if (canceler) {
        canceler();
      }
    };
  }, [query, pageNumber, needUpdate]);

  /**
   * re-call page when updating or deleting an item for example
   */
  const trigger = (
    setFullQuery: Dispatch<SetStateAction<{ query: string; pageNumber: number }>>
  ): boolean => {
    if (pageNumber !== 1) {
      setFullQuery((prevFullQuery) => {
        return {
          query: prevFullQuery.query,
          pageNumber: 1,
        };
      });

      return false;
    }
    setNeedUpdate((prevState) => {
      return prevState + 1;
    });

    return true;
  };

  return { loading, error, items, setItems, hasMore, trigger, alreadyFetchData };
}

function transformParameters(query: string) {
  // * For the first two replaces, we suppose we don't get query params ending with 'f' or 'l'.
  return query;
}
