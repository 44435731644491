import { Employee } from '../../models/Employee';
import { unwrap } from '../../utilities/Assertions';
import HttpAbstract from './AbstractHttp';

const ROUTE = process.env.REACT_APP_URL_API_MATERIAL_SHEET_COLLABORATORS;

export interface Parameters {
  'group.organizationId': string;
}

export default class EmployeeHttp extends HttpAbstract<Employee> {
  constructor() {
    super(unwrap(ROUTE), Employee);
  }
}
