import MaterialTypeInterface from '../../interfaces/MaterialType';
import MaterialType from '../../models/MaterialType';
import HttpAbstract from './AbstractHttp';

const ROUTE = process.env.REACT_APP_URL_API_MATERIAL_TYPE;

export default class MaterialTypeHttp extends HttpAbstract<MaterialTypeInterface> {
  constructor() {
    super(`${ROUTE}`, MaterialType);
  }
}
