import { useLoading } from '@agney/react-loading';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { routeToLink } from '../../App.routing';
import { Button } from '../../components/Button';
import { HSpacer, VSpacer } from '../../components/layout/Spacer';
import Line from '../../components/Line';
import Modal, { ModalSize } from '../../components/Modal';
import Title from '../../components/Title';
import { useAuthContext } from '../../contexts/AuthContext';
import { useResponsiveContext } from '../../contexts/ResponsiveContext';
import LoadingScreen from '../../core/LoadingScreen';
import { CalendarRed, News, PencilWhite, Rappel, UploadWhite } from '../../icons';
import { DelayAct } from '../../models/DelayAct';
import MaterialSheet from '../../models/MaterialSheet';
import { NotificationAct } from '../../models/NotificationAct';
import { UserAct } from '../../models/UserAct';
import MaterialSheetHttp from '../../services/http/MaterialSheetHttp';
import NotificationActHttp from '../../services/http/NotificationActHttp';
import { UserActHttp } from '../../services/http/UserActHttp';
import { useDelayStore } from '../../store/DelayStore';
import { useUserActStore } from '../../store/UserActStore';
import { ACTRight } from '../../types/ACTRight';
import { unwrap } from '../../utilities/Assertions';
import DateUtility from '../../utilities/DateUtility';
import { Flags } from '../../utilities/Flags';
import { SentenceFormatter } from '../../utilities/SentenceFormatter';
import StyleData from '../../utilities/StyleData';
import SummaryDeadline from './SummaryDeadline';

interface ModalProps {
  isOpen: boolean;
  onClose: () => unknown;
  modalSize?: ModalSize;
  name: string;
  summaryId?: number | undefined;
  readOnly: boolean;
  downloadDocument: (id: number) => void;
}

function Summary(props: ModalProps): JSX.Element {
  const { readOnly, ACTAuthorization } = useAuthContext();
  const navigate = useNavigate();
  const { isMobile } = useResponsiveContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [notificationLoading, setNotificationLoading] = useState<boolean>(false);
  const [materialSheet, setMaterialSheet] = useState<MaterialSheet | null>(null);
  const [notification, setNotification] = useState<NotificationAct | null>(null);
  const getUsersAct = useUserActStore((state) => state.fetch);
  const getDelays = useDelayStore((state) => state.fetch);

  const { organizationId } = useParams<{ organizationId?: string }>();

  useEffect(() => {
    if (props.summaryId !== undefined) {
      setLoading(true);
      setNotificationLoading(true);

      const promises: Promise<void>[] = [];
      const promisesSlow: Promise<void>[] = [];

      promises.push(
        new MaterialSheetHttp().getById(props.summaryId).then((data) => {
          setMaterialSheet(data);
        })
      );

      if (organizationId && ACTAuthorization && Flags.has(ACTAuthorization, ACTRight.Show)) {
        // ? Notification check
        (async () => {
          const fetchedNotificationPromise = fetchNotification(
            unwrap(props.summaryId),
            parseInt(organizationId),
            getUsersAct({ organizationId: unwrap(organizationId) }),
            getDelays()
          );
          const usersActPromise = new UserActHttp()
            .getReceivers(organizationId, {
              pagination: 'false',
            })
            .then(({ data }) => data);

          // Hydrate notification and set it ready when notification and user are fetched
          const fetchedNotification = await fetchedNotificationPromise;
          fetchedNotification?.hydrateUsers(await usersActPromise);

          setNotification(fetchedNotification);
          setNotificationLoading(false);
        })();
      }

      Promise.all(promises).then(() => setLoading(false));
      Promise.all(promisesSlow);
    }
  }, [ACTAuthorization, getDelays, getUsersAct, organizationId, props.summaryId]);

  const { indicatorEl: loader } = useLoading({
    loading: true,
    indicator: <div className="lds-dual-ring-small" />,
  });

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={() => {
        props.onClose();
        setLoading(false);
      }}
      modalSize={props.modalSize}
      name={props.name}
      hasScroll={true}
      actionButton={
        props.summaryId ? (
          <>
            {isMobile ? (
              <Button
                variant="secondaryRound"
                icon={UploadWhite}
                callback={() => props.downloadDocument(props.summaryId || 0)}
              ></Button>
            ) : (
              <Button
                variant="secondary"
                icon={UploadWhite}
                callback={() => props.downloadDocument(props.summaryId || 0)}
              >
                Exporter
              </Button>
            )}
            {materialSheet && (
              <>
                <HSpacer size="10px"></HSpacer>
                {isMobile ? (
                  <Button
                    variant="primaryRound"
                    icon={readOnly ? News : PencilWhite}
                    callback={() =>
                      navigate(
                        routeToLink('/:organizationId/modifier/:materialSheetId', {
                          organizationId,
                          materialSheetId: materialSheet.id,
                        })
                      )
                    }
                  ></Button>
                ) : (
                  <Button
                    variant="primaryNoIconSmall"
                    callback={() =>
                      navigate(
                        routeToLink('/:organizationId/modifier/:materialSheetId', {
                          organizationId,
                          materialSheetId: materialSheet.id,
                        })
                      )
                    }
                  >
                    {(readOnly ? 'Consulter' : 'Modifier') + ' le matériel'}
                  </Button>
                )}
                <HSpacer size="5px"></HSpacer>
              </>
            )}
          </>
        ) : (
          <></>
        )
      }
    >
      <>
        {props.summaryId === undefined || loading ? (
          <LoadingScreen />
        ) : materialSheet !== null ? (
          <>
            <InfoZone>
              <div>
                <div className="info-element">
                  <div className="info-label">Famille de matériel</div>
                  <div className="info-data">{materialSheet.materialFamily?.name}</div>
                </div>
                <div className="info-element">
                  <div className="info-label">Type de matériel</div>
                  <div className="info-data">{materialSheet.materialType?.name}</div>
                </div>
                <div className="info-element">
                  <div className="info-label">Immatriculation / Identification</div>
                  <div className="info-data">{materialSheet.matriculation}</div>
                </div>
                <div className="info-element">
                  <div className="info-label">Marque</div>
                  <div className="info-data">{materialSheet.brand}</div>
                </div>
                <div className="info-element">
                  <div className="info-label">Modèle</div>
                  <div className="info-data">{materialSheet.model}</div>
                </div>
                <div className="info-element">
                  <div className="info-label">Attribué à</div>
                  <div className="info-data">
                    {materialSheet.remoteEmployees &&
                      SentenceFormatter.personList(
                        materialSheet.remoteEmployees?.map((collaborator) => {
                          return collaborator.fullname;
                        })
                      )}
                  </div>
                </div>
              </div>
              <div>
                <div className="info-element">
                  <div className="info-label">Date de mise en service</div>
                  <div className="info-data">
                    {materialSheet.dateCommissioning
                      ? DateUtility.toFrenchString(materialSheet.dateCommissioning)
                      : ''}
                  </div>
                </div>
                <div className="info-element">
                  <div className="info-label">Certificat de mise en conformité</div>
                  <div className="info-data">{materialSheet.conformityCertificate?.name}</div>
                </div>
                <div className="info-element">
                  <div className="info-label">Mise au rebut</div>
                  <div className="info-data">{materialSheet.isScrapped ? 'Oui' : 'Non'}</div>
                </div>
                <div className="info-element">
                  <div className="info-label">Commentaire</div>
                  <div className="info-data">{materialSheet.comment}</div>
                </div>
                {ACTAuthorization !== undefined && Flags.has(ACTAuthorization, ACTRight.Show) && (
                  <div className="rappel-element">
                    <span className="rappel-label">
                      <div className="rappel-icon">
                        <Rappel />
                      </div>
                      <div className="rappel-text">Rappel :</div>
                    </span>
                    {notificationLoading === false ? (
                      <>
                        {notification !== null ? (
                          <div className="rappel-data">
                            Un rappel {notification.rappelEnvoye ? 'a été' : 'sera'} envoyé{' '}
                            <strong>{notification.delay.name.toLowerCase()}</strong> l'échéance du{' '}
                            <strong>
                              {DateUtility.toFrenchString(new Date(notification.effectiveDate.toDate()))}
                            </strong>{' '}
                            à{' '}
                            <strong>
                              {SentenceFormatter.personList(notification.receivers.map((ua) => ua.fullname))}
                            </strong>
                            .
                          </div>
                        ) : (
                          <span> Pas de rappel prévu.</span>
                        )}
                      </>
                    ) : (
                      <span style={{ width: '20px', height: '20px' }}> {loader}</span>
                    )}
                  </div>
                )}
              </div>
            </InfoZone>
            <VSpacer size="10px"></VSpacer>
            <Line />
            <VSpacer size="5px"></VSpacer>
            <DeadlineZone>
              <div className="content">
                <VSpacer size="16px"></VSpacer>
                <Title level={3} name="Échéances" svg={<CalendarRed />}></Title>
                <VSpacer size="20px"></VSpacer>
                {materialSheet.materialCheckings ? (
                  <SummaryDeadline
                    materialCheckings={materialSheet.materialCheckings}
                    materialSheet={materialSheet}
                  ></SummaryDeadline>
                ) : (
                  <></>
                )}
              </div>
            </DeadlineZone>
            <ActionButtons>
              <Button variant="bigLight" callback={() => props.onClose()}>
                Fermer
              </Button>
            </ActionButtons>
            <VSpacer size={StyleData.spacing.xl} />
          </>
        ) : (
          <>Aucune donnée n'a pu être chargée. Veuillez réessayer.</>
        )}
      </>
    </Modal>
  );
}

async function fetchNotification(
  materialSheetId: MaterialSheet['id'],
  organizationId: number,
  usersAct: Promise<UserAct[]>,
  delays: Promise<DelayAct[]>
): Promise<NotificationAct | null> {
  const notification = (await NotificationActHttp.getNotification(materialSheetId, organizationId)) ?? null;

  notification?.hydrateUsers(await usersAct);
  notification?.hydrateDelay(await delays);

  return notification;
}

const InfoZone = styled.div`
  display: grid;
  font-family: 'Work Sans', sans-serif;
  font-size: 0.8rem;
  margin-bottom: 1.25rem;

  .info-label:after {
    content: '\u00a0:';
  }

  .info-data {
    display: -webkit-box;
    font-weight: 600;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .info-element {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 0.5rem;
  }

  .rappel-label {
    font-weight: 600;
    font-size: 1rem;
    display: inline-flex;
    gap: 0.75rem;
  }

  .rappel-text {
    display: inline;
  }

  .rappel-data {
    display: inline;
    padding-left: 0.5rem;
  }

  @media all and (min-width: 840px) {
    grid-template-columns: 1fr 1fr;
  }
`;

InfoZone.displayName = 'InfoZone';

const DeadlineZone = styled.div`
  font-family: 'Work Sans', sans-serif;
  font-size: 0.8rem;
  /* background-color: #f7f7f8; */
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default Summary;
