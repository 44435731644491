import create from 'zustand';
import { unwrap } from './Assertions';

/** The state model of immutable data fetch stores. */
export interface ImmutableFetchedDataState<T, P> {
  /** The data. May be `undefined` if {@link fetch} has not been called or has not finished its process. */
  data?: T;
  /** Lazily load data if it is not loaded yet. */
  fetch: (p: P) => Promise<T>;
  /** The promise representing the work of the {@link fetch} function. May be `undefined` if {@link fetch} has never been called. */
  _promise?: Promise<T>;
}

/**
 * Creates a store for data fetch that should only happens once (for immutable data that are not changed by the current app for example).
 */
export function createImmutableDataFetchStore<T, P>(
  fetchFunction: (parameters: P) => Promise<T>,
  initialData?: T
) {
  return create<ImmutableFetchedDataState<T, P>>((set, get) => ({
    data: initialData,
    fetch(p): Promise<T> {
      if (get()._promise !== undefined) return unwrap(get()._promise);
      set(() => ({
        _promise: fetchFunction(p).then((fetchedData) => {
          set(() => ({ data: fetchedData }));
          return unwrap(get().data);
        }),
      }));
      return unwrap(get()._promise);
    },
    _promise: undefined,
  }));
}
