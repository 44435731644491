import http from '../ApiHttp';
import { Datalayer } from '../models/Datalayer';

const ROUTE = process.env.REACT_APP_URL_API_DATALAYER || '';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export class DatalayerService {
  private route;
  constructor() {
    this.route = ROUTE;
    window.dataLayer = window.dataLayer || [];
    if (!Object.hasOwnProperty.call(window, 'dataLayer')) {
      throw new Error('`window.dataLayer` is not defined.');
    }
  }

  public async getCustomizationData(organizationId: string | null): Promise<Datalayer> {
    const data = await http.get(this.route || '', { params: { organizationId } });
    return data as unknown as Datalayer;
  }

  public push(data: Datalayer) {
    return window.dataLayer.push(data);
  }
}
