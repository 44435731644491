import styled from 'styled-components';
import StyleData from '../../../utilities/StyleData';

export const Root = styled.div`
  hr {
    color: ${StyleData.color.lightGrey};
    height: 1px;
    width: 100%;
    border-left: none;
    border-right: none;
  }

  max-height: 100%;

  display: grid;
  grid-template-rows: min-content 1fr min-content;
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: 50% 50%;
  font-family: ${StyleData.font.primary};
  align-items: start;
  overflow-y: auto;

  @media (max-width: ${StyleData.breakpoints.max.smd}px) {
    grid-template-columns: 100%;
  }

  > * {
    padding-top: ${StyleData.spacing.md};
    padding-bottom: ${StyleData.spacing.lg};
    display: flex;
    flex-direction: column;
    gap: ${StyleData.spacing.lg};
  }

  > *:first-child {
    @media (min-width: ${StyleData.breakpoints.min.mmd}px) {
      padding-right: ${StyleData.spacing.lg};
    }
  }

  > *:nth-child(2) {
    padding-left: ${StyleData.spacing.lg};
    background: ${StyleData.color.lightGrey};
    border-radius: ${StyleData.borderRadius.lg};
    padding-right: ${StyleData.spacing.lg};

    @media (min-width: ${StyleData.breakpoints.min.mmd}px) {
      margin-top: 241px; /* Align next event type to result. */
    }
  }
`;

export const HorizontalFieldsRow = styled.div`
  display: flex;
  gap: ${StyleData.spacing.lg};

  > * {
    flex-grow: 1;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${StyleData.spacing.lg};
  justify-content: flex-end;
  padding: ${StyleData.spacing.md} 0px;
`;
