import Entity from './Entity';

export default class Periodicity extends Entity {
  public id!: number;

  public name!: string;
  public value!: number;

  constructor(data?: Partial<Periodicity>) {
    super();
    Object.assign(this, data);
  }
}
