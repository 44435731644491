import styled from 'styled-components';

export interface ConditionalVisibilityProps {
  /** Are the children visible? (Default to `true`) */
  visible?: boolean;
  children?: React.ReactNode;
}

export function ConditionalVisibility(props: ConditionalVisibilityProps) {
  return (
    <Root className="ConditionalVisibility" visible={props.visible ?? true}>
      {props.children}
    </Root>
  );
}

const Root = styled.div<{ visible: boolean }>`
  ${(p) => (p.visible ? '' : 'display: none;')}
`;
