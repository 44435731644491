import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import StyleData from '../../utilities/StyleData';
import { ErrorIcon } from '../ErrorIcon';
import { VSpacer } from '../layout/Spacer';
import InputError from './InputError';

const ALLOWED_TYPES = {
  text: '',
  number: null,
  email: '',
};
interface Props {
  name: string;
  type: keyof typeof ALLOWED_TYPES;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  error?: string;
  defaultValue?: string;
  tabIndex?: number;
}

export default function TextField(props: Props): ReactElement {
  const { control } = useFormContext();

  return (
    <TextFieldContainer>
      {props.label && (
        <>
          <label>{props.label}</label> <VSpacer size="6px" />
        </>
      )}
      <InputContainer error={!!props.error} disabled={!!props.disabled}>
        <Controller
          name={props.name}
          control={control}
          defaultValue={props.defaultValue ? props.defaultValue : ALLOWED_TYPES[props.type]}
          render={({ field: { onChange, onBlur, value, ref } }) => {
            return (
              <StyledTextField
                type={props.type}
                name={props.name}
                required={props.required}
                disabled={props.disabled}
                onBlur={onBlur}
                placeholder={props.placeholder}
                value={value}
                tabIndex={props.tabIndex}
                ref={ref}
                onChange={(e) => {
                  onChange(props.type === 'number' ? e.target.valueAsNumber : e.target.value);
                }}
              />
            );
          }}
        />
        {props.error && <ErrorIcon />}
      </InputContainer>
      <InputError error={props.error} />
    </TextFieldContainer>
  );
}

const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${StyleData.color.text};
  font-family: ${StyleData.font.secondary};
  font-size: ${StyleData.fsize.md};
  label {
    line-height: 21px;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    label {
      font-size: ${StyleData.fsize.sm};
      line-height: 18px;
    }
  }
`;
type StyleProps = {
  error: boolean;
  disabled: boolean;
};

const InputContainer = styled.div<StyleProps>`
  display: grid;
  grid-template: 100% / 1fr 34px;
  background-color: ${(props) => (props.disabled ? '#f5f5f5' : StyleData.color.base)};
  ${StyleData.color.base};
  border: 1px solid ${(props) => (props.error ? StyleData.color.primary : StyleData.color.lightText)};
  input {
    background-color: unset;
    color: ${(props) => (props.disabled ? StyleData.color.lightText : StyleData.color.text)};
  }
  border-radius: 3px;
  svg {
    margin: auto;
  }
`;

const StyledTextField = styled.input`
  line-height: 16px;
  min-width: 0;
  font-weight: 600;
  box-sizing: border-box;
  height: 36px;
  border: none;
  padding: 10px 0 10px 14px;
  font-size: ${StyleData.fsize.md};
  font-family: ${StyleData.font.secondary};

  &:focus {
    outline: none;
  }
  ::placeholder {
    font-weight: 400;
    color: ${StyleData.color.lightText};
    opacity: 1;
  }
`;
