import styled from 'styled-components';
import { FileOn, Pencil, TrashBin } from '../../../icons';
import { default as StyleData, default as styleData } from '../../../utilities/StyleData';

export const cellPadding = { x: '15px', y: '10px' };
export const colTemplate = '3fr 2.5fr 2.5fr 2fr 64px 3fr';
export const colTemplateNoPlannedDate = '3fr 2fr 2fr 64px 3fr';

export const Cell = styled.div.attrs((props) => ({ className: 'cell ' + (props.className ?? '') }))`
  display: flex;
  align-items: center;
  padding: ${cellPadding.y} ${cellPadding.x};
`;

export const CompleterCell = styled.div`
  &.desktop {
    grid-column: span 4;

    > div {
      border-radius: 0px ${styleData.borderRadius.sm} ${styleData.borderRadius.sm} 0px;
    }
  }

  &.mobile {
    > div {
      border-radius: 0px 0px ${styleData.borderRadius.sm} ${styleData.borderRadius.sm};
    }
  }

  display: flex;
  align-items: stretch;
  justify-content: stretch;

  > div {
    display: grid;
    padding: 10px ${styleData.spacing.lg};
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    flex-grow: 1;
    justify-items: center;
    align-items: center;
    background: ${styleData.color.lightGrey};
  }

  > div > * {
    grid-row: 1;
    grid-column: 1;
  }

  > div > .deleteIconContainer {
    justify-self: end;
  }
`;

export const ActionIcons = styled.div`
  display: flex;
  gap: 0.5em;
`;

export const ResponsiveRoot = styled.div`
  @media all and (max-width: ${styleData.breakpoints.max.smd}px) {
    > .desktop {
      display: none;
    }
  }

  @media all and (min-width: ${styleData.breakpoints.max.smd}px) {
    > .mobile {
      display: none;
    }
  }
`;

export const RootDesktop = styled(styled.div<{ containsPlannedDate: boolean }>``).attrs((props) => ({
  className: 'desktop ' + (props.className ?? ''),
}))`
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.18);
  border-radius: ${styleData.borderRadius.sm};

  display: grid;
  grid-template-columns: ${(p) => (p.containsPlannedDate ? colTemplate : colTemplateNoPlannedDate)};
  gap: 0;

  .Text {
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-wrap: anywhere;
  }

  > .cell:not(:last-child) {
    border-right: 2px solid ${StyleData.color.lightGrey};
  }
`;

export const RootMobile = styled.div.attrs((props) => ({ className: 'mobile  ' + (props.className ?? '') }))`
  display: grid;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.18);
  border-radius: ${StyleData.borderRadius.sm};

  grid-template-columns: 50% 50%;

  hr {
    color: rgba(0, 0, 0, 0.18);
  }

  > *:first-child,
  > *:last-child {
    grid-column: span 2;
    align-items: stretch;
    justify-content: stretch;
    text-align: center;
    flex-direction: column;
  }

  > *:first-child {
    padding-bottom: 0px;
  }

  > hr {
    grid-column: span 2;
    width: calc(100% - ${styleData.spacing.lg} * 2);
  }
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  gap: ${styleData.spacing.md};

  > svg {
    flex-shrink: 0;
  }
`;

export const SmallCellColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${styleData.spacing.md};
  min-height: 46px;
  overflow: hidden;

  .Text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const SmallDocCellColumn = styled(SmallCellColumn)`
  gap: ${styleData.spacing.sm};
`;

export const EditIcon = styled(Pencil)`
  cursor: pointer;
`;

export const DeleteIcon = styled(TrashBin)`
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: initial;
  }
`;

export const MobileHeader = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  justify-items: start;
  align-items: center;

  > * {
    grid-row: 1;
    grid-column: 1;
  }

  > .action-icons,
  > svg {
    justify-self: end;
    align-self: center;
    padding-top: 5px; /* to align with the left event type text */
  }
`;

export const DocRow = styled.div`
  display: flex;
  gap: ${styleData.spacing.md};
  align-items: center;
  max-width: 100%;
  > .Text {
    flex-grow: 1;
  }
`;

export const FileOnWhite = styled(FileOn)`
  fill: white;
`;

export const MobileEventTypeNameBox = styled.div`
  padding: 20px 5px 10px 0;
  div {
    font-size: ${styleData.fsize.lg};
    display: flex;
    gap: 10px;
  }

  svg {
    width: 20px;
  }
`;
