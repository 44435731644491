import React, { useCallback } from 'react';
import { debounce } from '../utilities/Time';

type ViewportData = () => { width: number; height: number };

const useViewport: ViewportData = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    handleWindowResize();
  }, []);

  const handleWindowResize = useCallback(
    debounce(() => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }, 200),
    []
  );
  return { width, height };
};

export default useViewport;
