import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Params, useParams } from 'react-router-dom';
import SingleSelect, { Option } from '../../../components/input/SingleSelect';
import Text from '../../../components/Text';
import { useAuthContext } from '../../../contexts/AuthContext';
import MaterialTypeInterface from '../../../interfaces/MaterialType';
import MaterialTypeHttp from '../../../services/http/MaterialTypeHttp';
import { unwrap } from '../../../utilities/Assertions';
import { GreyTextContainer } from '../MaterialSheetDetailsStyle';

interface MaterialTypeSelectProps {
  materialFamilyId?: number;
  error?: string;
  disabled?: boolean;
  tabIndex: number;
  onChange: (materialType: MaterialTypeInterface | undefined) => void;
}

export function materialTypeToSelectOption(materialType: MaterialTypeInterface): Option {
  return { label: materialType.name, value: unwrap(materialType.id).toString() };
}

export function MaterialTypeSelect(props: MaterialTypeSelectProps) {
  const { user, readOnly } = useAuthContext();
  const { organizationId } = useParams<Params>();

  const [materialTypes, setMaterialTypes] = useState<MaterialTypeInterface[]>([]);
  const [description, setDescription] = useState<string>();
  const [fetched, setFetched] = useState(false);
  const { watch } = useFormContext();
  const value = watch('materialType');

  const sortedMaterialTypes = useMemo(() => {
    return sortMaterialTypes(materialTypes);
  }, [materialTypes]);

  const onChange = (value: any) => {
    if (value === undefined || value === null) {
      props.onChange?.(undefined);
      return;
    }
    const id = parseInt(value.value);
    const matchingMaterialType = materialTypes.find((mt) => mt.id === id);
    props.onChange?.(matchingMaterialType);
  };

  // Load description when the value changes.
  useEffect(() => {
    if (value === undefined || value === null) {
      setDescription(undefined);
      return;
    }
    const id = parseInt(value.value);
    const matchingMaterialType = materialTypes.find((mt) => mt.id === id);
    setDescription(matchingMaterialType?.description);
  }, [materialTypes, value]);

  useEffect(() => {
    if (user?.groupId && props.materialFamilyId !== undefined) {
      new MaterialTypeHttp()
        .get({ 'group.organizationId': organizationId || '', 'materialFamily.id': props.materialFamilyId })
        .then((fetchedMaterialTypes) => {
          setMaterialTypes(fetchedMaterialTypes);
          setFetched(true);
        });
    } else {
      setMaterialTypes([]);
    }
  }, [user?.groupId, organizationId, props.materialFamilyId]);

  return (
    <>
      <SingleSelect
        name="materialType"
        options={sortedMaterialTypes.map(materialTypeToSelectOption)}
        label="Type de matériel *"
        placeholder="Sélectionnez..."
        tabIndex={props.tabIndex}
        disabled={readOnly || !fetched || props.disabled}
        error={props.error}
        onChange={onChange}
      />
      {description && materialTypes.length > 0 && (
        <GreyTextContainer>
          <Text fsize="sm">
            <div style={{ whiteSpace: 'pre-wrap' }}>{description}</div>
          </Text>
        </GreyTextContainer>
      )}
    </>
  );
}

//#region Sort functions
function sortMaterialTypes(materialTypes: MaterialTypeInterface[]): MaterialTypeInterface[] {
  return materialTypes.sort(materialTypesComparison);
}

function materialTypesComparison(mt1: MaterialTypeInterface, mt2: MaterialTypeInterface): number {
  // Compute base comparison based on isRepo and isCustom.
  const baseComparison = baseComparisonClue(mt1) - baseComparisonClue(mt2);

  return baseComparison === 0 ? mt1.name.localeCompare(mt2.name, 'fr') : baseComparison;
}

function baseComparisonClue(mt: MaterialTypeInterface): number {
  if (!mt.isCustomizable) return 0;
  return mt.isRepository ? 2 : 1;
}
//#endregion
