import { MouseEvent, useEffect, useState } from 'react';
import { Accept, FileRejection, FileWithPath, useDropzone } from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { CloseBlack, File } from '../../../icons';
import StyleData from '../../../utilities/StyleData';
import { VSpacer } from '../../layout/Spacer';
import Text from '../../Text';
import InputError from '../InputError';
import { FileContainer, FileElement } from './FileInputStyle';

interface FileInputProps {
  name: string;
  placeholder?: string;
  label?: string;
  /** https://react-dropzone.js.org/#!/Accepting%20specific%20file%20types */
  accept?: Accept;
  error?: string;
  onDelete?: any;
  isPictureDeleted?: boolean;
  order?: string;
  format?: string;
  disabled?: boolean;
  maxSize?: number;
  multiple?: boolean;
  maxFiles?: number;
}

interface DropzoneProps {
  placeholder?: string;
  files?: FileWithPath[];
  accept?: Accept;
  handleChange: (
    event: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLSpanElement> | MouseEvent<SVGElement>,
    path?: string
  ) => unknown;
  // eslint-disable-next-line
  onDrop: any;
  hasError: boolean;
  fileValues?: string[];
  disabled?: boolean;
  maxSize?: number;
  multiple?: boolean;
  maxFiles?: number;
}

export const FileInput = ({
  name,
  placeholder,
  label,
  accept,
  error,
  order,
  format,
  disabled,
  multiple,
  maxSize,
}: FileInputProps): JSX.Element => {
  const { control, getValues } = useFormContext();
  const [files, setFiles] = useState<any[]>([]);
  const [fileValues, setFileValues] = useState<string[]>([]);
  const initialValue = getValues(name);

  useEffect(() => {
    setFiles(initialValue);
  }, [initialValue]);

  const [hasError, setHasError] = useState<string>();

  useEffect(
    function () {
      setHasError(error ? error : '');
    },
    [error]
  );

  return (
    <InputFileContainer error={hasError ? true : false} isImage={false}>
      {label && (
        <>
          <label>{label}</label>
          <VSpacer size="6px" />
        </>
      )}
      <Controller
        render={({ field: { onChange } }) => {
          return (
            <Dropzone
              handleChange={(event, path) => {
                const tempFiles = files?.slice();
                tempFiles?.forEach((file, index) => {
                  if (file.path === path) {
                    tempFiles.splice(index, 1);
                  }
                });
                setFiles(tempFiles);
                onChange(tempFiles);
                event.stopPropagation();
              }}
              hasError={hasError ? true : false}
              onDrop={(dropedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
                if (dropedFiles.length > 0) {
                  const getUpdatedFiles = () => {
                    if (!multiple) {
                      return dropedFiles;
                    }

                    const updatedFiles: any[] = files ?? [];

                    for (const dropedFile of dropedFiles) {
                      let fileExist = false;
                      if (dropedFile.path !== undefined) {
                        for (const file of updatedFiles) {
                          if (dropedFile.path === file.path) {
                            fileExist = true;
                            break;
                          }
                        }
                      }

                      if (!fileExist) {
                        updatedFiles.push(dropedFile);
                      }
                    }

                    return updatedFiles;
                  };

                  const updatedFiles = getUpdatedFiles();
                  setFileValues(updatedFiles.map((dropedFile) => URL.createObjectURL(dropedFile)));
                  setFiles(updatedFiles);
                  onChange(updatedFiles);
                  setHasError('');
                } else {
                  let firstErrorCode = '';
                  let fileName = '';
                  for (const fileRejection of fileRejections) {
                    if (fileRejection.errors.length > 0) {
                      firstErrorCode = fileRejection.errors[0].code;
                      fileName = fileRejection.file.name;
                      for (const error of fileRejection.errors) {
                        console.warn(error.code, error.message);
                      }
                      break;
                    }
                  }

                  const fileNameDisplay = multiple ? `"${fileName}" ` : '';

                  if (firstErrorCode === 'file-too-large') {
                    setHasError(
                      'Fichier {}rejeté, le fichier est trop volumineux'.replace('{}', fileNameDisplay)
                    );
                  } else if (firstErrorCode === 'file-invalid-type') {
                    setHasError(
                      'Fichier {}rejeté, les extensions acceptées sont : '.replace('{}', fileNameDisplay) +
                        Object.values(accept ?? {})
                          .flatMap((typeGroup) => typeGroup)
                          .join(', ') +
                        '.'
                    );
                  } else {
                    setHasError('Fichier{}rejeté, Erreur inconnue'.replace('{}', fileNameDisplay));
                  }
                }
              }}
              accept={accept}
              files={files}
              placeholder={placeholder}
              fileValues={fileValues}
              disabled={disabled}
              multiple={multiple}
              maxSize={maxSize ? maxSize : Infinity}
            />
          );
        }}
        name={name}
        control={control}
        defaultValue=""
      />
      <InputError error={hasError} />
      {(order || format) && (
        <>
          <VSpacer />
          <Text fsize={'xs'}>{order}</Text>
          <Text fsize={'xs'}>{format}</Text>
        </>
      )}
    </InputFileContainer>
  );
};

type StyleInputFileProps = {
  isImage: boolean;
  error: boolean;
};

const InputFileContainer = styled.div<StyleInputFileProps>`
  ${(props) => {
    if (props.isImage) {
      return (
        `
        display: flex;
        flex-direction: column;
        align-items : center;
        img{
          border-radius: 50%;
          object-fit: cover;
          width: 90px;
          height: 90px;
          border: solid 1px` +
        (props.error ? StyleData.color.primary : '#000000') +
        `;
        }
      `
      );
    } else {
      return `
        display: flex;
        flex-direction: column;
        img {
          height: 40px;
        }
        label {
          color: ${StyleData.color.text};
          font-family: ${StyleData.font.secondary};
          font-size: 14px;
          line-height: 21px;
        }
        @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
          label {
            font-size: ${StyleData.fsize.sm};
            line-height: 18px;
          }
        }
      `;
    }
  }}
`;

const Dropzone = ({
  handleChange,
  files,
  accept,
  placeholder,
  onDrop,
  hasError,
  maxSize,
  disabled,
  multiple,
  maxFiles,
  ...rest
}: DropzoneProps): JSX.Element => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple,
    accept,
    onDrop,
    maxSize,
    maxFiles,
    disabled,
    // noClick: multiple ?? false,
    ...rest,
  });

  return (
    <DropZoneContainer {...getRootProps()} error={hasError} isImage={false} isDisabled={disabled ?? false}>
      {files && files.length > 0 ? (
        <FileContainer>
          {files.map((file) => (
            <FileElement
              key={file.path}
              isDisabled={disabled ?? false}
              onClick={(ev) => ev.stopPropagation()}
              multiple={multiple}
            >
              <span>{file.path}</span>
              <CloseBlack onClick={disabled ? undefined : (ev) => handleChange(ev, file.path)} />
            </FileElement>
          ))}
        </FileContainer>
      ) : placeholder ? (
        <span className="placeholder">{placeholder ?? 'Selectionnez un fichier'}</span>
      ) : (
        <span className="placeholder"></span>
      )}
      {!disabled && (
        <>
          <input {...getInputProps({ onDrop })} />
          <File />
        </>
      )}
    </DropZoneContainer>
  );
};
type StyleDropZoneProps = {
  isImage: boolean;
  error: boolean;
  isDisabled: boolean;
};
const DropZoneContainer = styled.div<StyleDropZoneProps>`
  padding: 2px 5px 2px 2px;
  ${(props) => {
    if (props.isImage) {
      return `
        span {
          color: ${StyleData.color.primary};
          font-family: ${StyleData.font.primary};
          font-size: ${StyleData.fsize.xxs};
          line-height: '15px';
          text-decoration: underline;
          font-weight: 600;
            ${!props.isDisabled ? 'cursor: pointer;' : ''}
        }
        svg{
          display: none;
        }
      `;
    } else {
      return (
        `
      display: grid;
      align-items: center;
      grid-template: 100% / 1fr auto;
      box-sizing: border-box;
      min-height: 38px;
      border: 1px solid ` +
        (props.error ? StyleData.color.primary : '#92999c') +
        `;
        ` +
        (props.isDisabled ? 'background-color: #f5f5f5;' : '') +
        `
      border-radius: 3px;
      .placeholder{
        font-family: ${StyleData.font.secondary};
        font-size: ${StyleData.fsize.md};
        line-height: '16px';
        margin: auto auto auto 0;
        padding: 0 0 0 15px;
        color: ${StyleData.color.lightText};
      }
      > svg {
        height: 20px;
      }
      ${!props.isDisabled ? 'cursor: pointer;' : ''}
      )`
      );
    }
  }}
`;

export default FileInput;
