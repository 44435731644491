import http from '../../ApiHttp';
import { UserAct } from '../../models/UserAct';
import HttpAbstract from './AbstractHttp';

const ROUTE = process.env.REACT_APP_URL_API_RECEIVER || '';

export class UserActHttp extends HttpAbstract<UserAct> {
  constructor() {
    super(`${ROUTE}`, UserAct);
  }
  public async getReceivers(
    organisationId: string,
    params: Record<string, unknown> = {}
  ): Promise<{ data: UserAct[] }> {
    const result = await http.get(`${ROUTE + '/' + organisationId}/receivers`, {
      params: {
        ...params,
      },
    });
    const data = result.data['hydra:member'].map((data: Record<string, unknown>) => new this.instance(data));

    return { data };
  }
}
