import styled from 'styled-components';
import StyleData from '../../utilities/StyleData';

export const cardPadding = { x: '15px', y: '10px' };

export const EventsSummaries = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${StyleData.spacing.xxl};
`;

export const TwoColumnsForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem ${StyleData.spacing['5xl']};
`;

export const InnerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${StyleData.spacing.lg};
`;

export const OneColumnForm = InnerColumn;

export const TopContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;

  @media all and (max-width: 960px) {
    grid-template-columns: 1fr min-content;
  }
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  gap: ${StyleData.spacing.lg};
  @media all and (max-width: ${StyleData.breakpoints.max.md}px) {
    justify-content: space-between;
  }
  @media all and (min-width: ${StyleData.breakpoints.max.md}px) {
    justify-content: flex-end;
  }
`;

export const EndContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${StyleData.spacing.lg};
  @media all and (max-width: ${StyleData.breakpoints.max.md}px) {
    flex-direction: column;
  }
  @media all and (min-width: ${StyleData.breakpoints.max.md}px) {
    > *:only-child {
      margin-left: auto;
    }
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  gap: ${StyleData.spacing.lg};
  align-items: center;
  @media all and (max-width: ${StyleData.breakpoints.max.md}px) {
    flex-direction: column;
    gap: ${StyleData.spacing.md};
  }
`;

export const GreyTextContainer = styled.div`
  background-color: ${StyleData.color.lightGrey};
  padding: 20px 15px;
`;
