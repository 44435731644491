import { RadioProps } from '../../components/input/RadioClassic';
import { Option } from '../../components/input/SingleSelect';
import { ConformityCertificate } from '../../models/ConfirmityCertificate';
import MaterialFamily from '../../models/MaterialFamily';
import Periodicity from '../../models/Periodicity';

/**
 * @param materialSheetIdParam The parameters retrieved from the route.
 * @returns The material sheet id as a number if relevant.
 */
export function parseMaterialSheetId(materialSheetIdParam: string | undefined): number | undefined {
  if (materialSheetIdParam === undefined) {
    return undefined;
  } else {
    const result = parseInt(materialSheetIdParam);
    if (isNaN(result)) {
      console.warn('materialSheetId param could not be parsed to a number.');
      return undefined;
    } else {
      return result;
    }
  }
}

export function conformityCertificateToRadioProps(conformityCertificate: ConformityCertificate): RadioProps {
  return { label: conformityCertificate.name, value: conformityCertificate.id.toString() };
}

export function materialFamilyToSelectOption(materialFamily: MaterialFamily): Option {
  return {
    label: materialFamily.name,
    value: materialFamily.id.toString(),
  };
}

export function optionToId(option?: Option): number | undefined {
  const value = option?.value;
  if (typeof value == 'string') {
    return parseInt(value);
  }
  return undefined;
}

/**
 * @param conformityCertificates The list of available conformity certificates.
 * @returns The default ConformityCertificate if found.
 */
export function getDefaultConformityCertificate(conformityCertificates: ConformityCertificate[]) {
  return conformityCertificates.find((cc) => cc.name.toLowerCase() === 'neuf');
}

/**
 * @param conformityCertificates The list of available conformity certificates.
 * @returns The default ConformityCertificate radio value if found.
 */
export function getDefaultConformityCertificateValue(
  conformityCertificates: ConformityCertificate[]
): string | undefined {
  const cc = getDefaultConformityCertificate(conformityCertificates);
  if (cc === undefined) return undefined;

  return conformityCertificateToRadioProps(cc).value;
}

/**
 * Extract the {@link MaterialFamily} (from {@link materialFamilies}) corresponding to the {@link option}.
 */
export function getMaterialFamilyFromOption(
  option: Option | null | undefined,
  materialFamilies: MaterialFamily[]
): MaterialFamily | undefined {
  if (option === null || option === undefined) return undefined;
  const id = parseInt(option.value);
  if (isNaN(id)) return undefined;

  return materialFamilies.find((mf) => mf.id === id);
}

/**
 * Converts {@link periodicity} to an {@link Option} for {@link SingleSelect}.
 * @param periodicity The periodicity in month.
 */
export function periodicityToOption(periodicity: Periodicity): Option {
  return { label: `${periodicity.value} mois`, value: `${periodicity.id}` };
}

export function optionToPeriodicity(
  option: Pick<Option, 'value'> | undefined | null,
  periodicities: Periodicity[]
): Periodicity | undefined {
  if (option === undefined || option === null) return undefined;

  const periodicityId = parseInt(option.value);

  return periodicities.find((p) => p.id === periodicityId);
}
