import dayjs from 'dayjs';
import fr from 'dayjs/locale/fr';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import EventFamily from '../../models/EventFamily';
import EventRef from '../../models/EventRef';
import EventType from '../../models/EventType';
import MaterialChecking from '../../models/MaterialChecking';
import MaterialSheet from '../../models/MaterialSheet';
import EventFamilyHttp from '../../services/http/EventFamilyHttp';
import EventTypeHttp from '../../services/http/EventTypeHttp';
import { unwrap } from '../../utilities/Assertions';
import { categorize } from '../../utilities/Collections';
import StyleData from '../../utilities/StyleData';

type Props = {
  materialCheckings: MaterialChecking[];
  materialSheet: MaterialSheet;
};

function SummaryDeadline(props: Props) {
  const [materialCheckingsDisplay, setMaterialCheckingsDisplay] = useState<Record<string, any>>({});

  useEffect(() => {
    if (props.materialCheckings) {
      new EventFamilyHttp()
        .get({ 'eventTypes.materialType.id': props.materialSheet.materialType.id })
        .then(async (data: EventFamily[]) => {
          const eventTypes: EventType[][] = await Promise.all(
            data.map(async (eventFamily) => {
              return await new EventTypeHttp().get({
                'materialType.id': props.materialSheet.materialType.id,
                'eventFamily.id': eventFamily.id,
              });
            })
          );
          const orderedMaterialCheckings = categorize(
            props.materialCheckings,
            (mc) => unwrap(mc.eventFamily).id
          );
          const materialCheckingsFormatted: MaterialCheckingsFormatted = {};
          data.forEach((eventFamily) => {
            // * On ne prend pas en compte le cas où il n'y a pas de date prévue
            if (!orderedMaterialCheckings.has(eventFamily.id)) {
              // S'il n'y a pas de MaterialChecking pour une famille mais que cette famille compte dans les date prévues on rajoute.
              if (eventFamily.hasPlannedDate) {
                orderedMaterialCheckings.set(eventFamily.id, []);
              } else {
                return;
              }
            }

            const familyMaterialCheckings = unwrap(orderedMaterialCheckings.get(eventFamily.id));

            // * Get last checking to perform calculation of periodicity
            const lastCheckingForFamily = familyMaterialCheckings.at(-1) ?? null;
            const secondToLastCheckingForFamily = familyMaterialCheckings.at(-2) ?? null;
            const familyEventTypes = eventTypes
              .flat()
              .filter((eventType) => eventType.eventFamily.id === eventFamily.id);
            if (lastCheckingForFamily) {
              materialCheckingsFormatted[eventFamily.name] = {};
              const nextDeadline = lastCheckingForFamily.computeNext(
                props.materialSheet.materialType,
                familyEventTypes
              );

              // Display planned date if the family has it, otherwise display second to last element.
              if (eventFamily.hasPlannedDate) {
                materialCheckingsFormatted[eventFamily.name]['planned'] = {
                  date: dayjs(nextDeadline.plannedDate).locale(fr).format('DD/MM/YYYY').toString(),
                  eventType: nextDeadline.eventRef?.name,
                };
              } else if (secondToLastCheckingForFamily !== null) {
                materialCheckingsFormatted[eventFamily.name]['secondToLast'] = {
                  date: dayjs(secondToLastCheckingForFamily.effectiveDate)
                    .locale(fr)
                    .format('DD/MM/YYYY')
                    .toString(),
                  eventType: unwrap(secondToLastCheckingForFamily.eventRef).name,
                };
              }

              materialCheckingsFormatted[eventFamily.name]['last'] = {
                date: dayjs(lastCheckingForFamily.effectiveDate).locale(fr).format('DD/MM/YYYY').toString(),
                eventType: unwrap(lastCheckingForFamily.eventRef).name,
              };
            } else if (eventFamily.hasPlannedDate) {
              // ! Pas d'ancienne échéance et famille avec des évènements prévus

              const defaultMaterialChecking = MaterialChecking.computeFirst(
                props.materialSheet.dateCommissioning,
                familyEventTypes,
                eventFamily
              );

              materialCheckingsFormatted[eventFamily.name] = {};
              materialCheckingsFormatted[eventFamily.name]['planned'] = {
                date: defaultMaterialChecking.plannedDate
                  ? dayjs(defaultMaterialChecking.plannedDate).locale(fr).format('DD/MM/YYYY').toString()
                  : '',
                eventType: defaultMaterialChecking.eventRef?.name
                  ? defaultMaterialChecking.eventRef?.name
                  : 'Non renseigné',
              };
              delete materialCheckingsFormatted[eventFamily.name]['last'];
            }
          });

          setMaterialCheckingsDisplay(materialCheckingsFormatted);
        });
    }
  }, [props.materialCheckings, props.materialSheet.dateCommissioning, props.materialSheet.materialType]);

  function generateDoneDeadlineColumn(data: MaterialCheckingsFormattedElement, label: string) {
    if (!data) return undefined;
    return (
      <div className="deadline-column">
        <span className="deadline-data-label">{label}</span>
        <div className="deadline-data-info">
          <span className="deadline-info">
            {'eventType' in data && data.eventType !== undefined ? data.eventType : 'Non renseigné'}
          </span>
          <span className="deadline-info">
            {'date' in data && data.date !== '' ? 'Le ' + data.date : '-'}
          </span>
        </div>
      </div>
    );
  }

  return (
    <>
      {Object.keys(materialCheckingsDisplay).map((key: string) => (
        <DeadlineElement key={key}>
          <div className="deadline-label">{key}</div>
          <div className="deadline-data">
            {materialCheckingsDisplay[key]['planned'] && (
              <div className="deadline-column">
                <span className="deadline-data-label">Prévu</span>
                <div className="deadline-data-info">
                  <span className="deadline-info">
                    {'eventType' in materialCheckingsDisplay[key]['planned'] &&
                    materialCheckingsDisplay[key]['planned'].eventType !== undefined
                      ? materialCheckingsDisplay[key]['planned'].eventType
                      : 'Non renseigné'}
                  </span>
                  <span className="deadline-info">
                    {'date' in materialCheckingsDisplay[key]['planned'] &&
                    materialCheckingsDisplay[key]['planned'].date !== ''
                      ? 'Le ' + materialCheckingsDisplay[key]['planned'].date
                      : '-'}
                  </span>
                </div>
              </div>
            )}
            {generateDoneDeadlineColumn(
              materialCheckingsDisplay[key]['secondToLast'],
              'Avant-dernière réalisation'
            )}
            {generateDoneDeadlineColumn(materialCheckingsDisplay[key]['last'], 'Dernière réalisation')}
          </div>
        </DeadlineElement>
      ))}
    </>
  );
}

type MaterialCheckingsFormatted = Record<
  EventFamily['name'],
  { [displayType in 'planned' | 'secondToLast' | 'last']?: MaterialCheckingsFormattedElement }
>;

type MaterialCheckingsFormattedElement = {
  date: string;
  eventType: EventRef['name'] | undefined;
};

const DeadlineElement = styled.div`
  padding-bottom: 8px;
  border-bottom: 1px solid gray;
  margin-bottom: 16px;

  //! replace
  /* :not(:last-child) {
    border-bottom: 1px solid #d6d6d6;
  } */

  .deadline-data {
    display: grid;
    grid-gap: 0 10px;
    grid-template-columns: repeat(2, 1fr);

    @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
    }
  }

  .deadline-data-label:after {
    content: ' :';
  }

  .deadline-column {
    display: flex;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 16px;

    @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  .deadline-label {
    font-weight: 600;
    margin-bottom: 12px;
  }

  .deadline-data-label {
    grid-row: 1 / 3;
    margin-right: 8px;
  }

  .deadline-info {
    display: block;
    font-weight: 600;
  }
`;

export default SummaryDeadline;
