import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { FieldValues, FormProvider, useForm, useWatch } from 'react-hook-form';
import { Link, Params, useNavigate, useParams } from 'react-router-dom';
import { routeToLink } from '../../App.routing';
import { Button } from '../../components/Button';
import { ErrorIcon } from '../../components/ErrorIcon';
import { CheckboxField } from '../../components/input/CheckboxField';
import DateField from '../../components/input/date-field/DateField';
import { RadioClassic } from '../../components/input/RadioClassic';
import SingleSelect from '../../components/input/SingleSelect';
import TextArea from '../../components/input/TextArea';
import TextField from '../../components/input/TextField';
import { Divider } from '../../components/layout/Divider';
import { VSpacer } from '../../components/layout/Spacer';
import { Container } from '../../components/List.style';
import Navbar from '../../components/Navbar';
import Text from '../../components/Text';
import Title from '../../components/Title';
import { useAuthContext } from '../../contexts/AuthContext';
import { useResponsiveContext } from '../../contexts/ResponsiveContext';
import { ChevronRightLightGrey, HomeGrey } from '../../icons';
import MaterialTypeInterface from '../../interfaces/MaterialType';
import { ConformityCertificate } from '../../models/ConfirmityCertificate';
import { DatalayerEventLabel } from '../../models/Datalayer';
import { DelayAct } from '../../models/DelayAct';
import { Employee } from '../../models/Employee';
import MaterialFamily from '../../models/MaterialFamily';
import MaterialSheet from '../../models/MaterialSheet';
import MaterialType from '../../models/MaterialType';
import { NotificationAct } from '../../models/NotificationAct';
import Periodicity from '../../models/Periodicity';
import { UserAct } from '../../models/UserAct';
import { DatalayerService } from '../../services/DatalayerService';
import ConformityCertificateHttp from '../../services/http/ConformityCertificateHttp';
import MaterialCheckingHttp from '../../services/http/MaterialCheckingHttp';
import MaterialFamilyHttp from '../../services/http/MaterialFamilyHttp';
import MaterialSheetHttp from '../../services/http/MaterialSheetHttp';
import MaterialTypeHttp from '../../services/http/MaterialTypeHttp';
import NotificationActHttp from '../../services/http/NotificationActHttp';
import PeriodicityHttp from '../../services/http/PeriodicityHttp';
import { documentNeedsUpdate, useMaterialSheetStore } from '../../store/MaterialSheetStore';
import { ACTRight } from '../../types/ACTRight';
import { ArrayUtils } from '../../utilities/Array';
import { unwrap } from '../../utilities/Assertions';
import { entityBaseDataFromIri } from '../../utilities/Entity';
import { Flags } from '../../utilities/Flags';
import { PromisedObject, Status } from '../../utilities/PromisedObject';
import { default as StyleData, default as styleData } from '../../utilities/StyleData';
import { EventsBlock } from './events-block/EventsBlock';
import {
  employeesToMultiSelectOptions,
  MaterialEmployeeSelect,
} from './material-employee-select/MaterialEmployeeSelect';
import { MaterialImage } from './material-image/MaterialImage';
import { MaterialTypeSelect, materialTypeToSelectOption } from './material-type-select/MaterialTypeSelect';
import {
  ActionButtonContainer,
  EndContainer,
  ErrorContainer,
  InnerColumn,
  OneColumnForm,
  TopContainer,
  TwoColumnsForm,
} from './MaterialSheetDetailsStyle';
import { errorMap, schema, TypedFieldValues } from './MaterialSheetSchema';
import {
  conformityCertificateToRadioProps,
  getDefaultConformityCertificateValue,
  getMaterialFamilyFromOption,
  materialFamilyToSelectOption,
  optionToId,
  optionToPeriodicity,
  parseMaterialSheetId,
  periodicityToOption,
} from './MaterialSheetUtility';
import { ReminderSettings } from './reminder-settings/ReminderSettings';
import { ScrappedRadio } from './scrapped-radio/ScrappedRadio';

const {
  REACT_APP_URL_EXT_PREVENTION_SERVICE,
  REACT_APP_URL_PREVENTIONBTP_PREFIXE,
  REACT_APP_MAT_NOTIFICATIONS_DISABLED,
} = process.env;

/**
 * Composant principal pour l'ajout de matériel
 */
function MaterialSheetDetails() {
  //#region Context retrieval
  const { user, readOnly, ACTAuthorization } = useAuthContext();
  const { isMobile } = useResponsiveContext();
  const navigate = useNavigate();
  const params = useParams<Params>();
  const [fillStoreFromMaterialSheet, setStoreDateCommissioning, emptyStore] = useMaterialSheetStore(
    (state) => [state.fillFromMaterialSheet, state.setDateCommissioning, state.empty]
  );

  const organizationId = params.organizationId;
  const materialSheetIdParam = params.materialSheetId;
  // If undefined, we considered the widget is considered to be in "creation mode", otherwise it is in "edit mode".
  const materialSheetId = parseMaterialSheetId(materialSheetIdParam);
  //#endregion

  const [materialFamilies, setMaterialFamilies] = useState<MaterialFamily[]>([]);
  const [materialFamiliesFetched, setMaterialFamiliesFetched] = useState(false);

  const [conformityCertificates, setConformityCertificates] = useState<ConformityCertificate[]>([]);
  const [conformityCertificatesFetched, setConformityCertificatesFetched] = useState(false);

  const [periodicities, setPeriodicities] = useState<Periodicity[]>([]);
  const [periodicitiesFetched, setPeriodicitiesFetched] = useState<boolean>(false);

  const [materialEditFirstRendered, setMaterialEditFirstRendered] = useState(false);

  const [reminder, setReminder] = useState(false);
  const [defaultDelayIriValue, setDefaultDelayIriValue] = useState<string>();

  const [hasAlreadyNotif, setHasAlreadyNotif] = useState(false);
  // undefined is for loading state.
  const [notification, setNotification] = useState<NotificationAct | null | undefined>(
    materialSheetId === undefined ? null : undefined
  );

  //#region MaterialFamily Fetch
  useEffect(() => {
    if (user?.groupId) {
      new MaterialFamilyHttp()
        .get({ 'group.organizationId': organizationId || '' })
        .then((fetchedMaterialFamilies) => {
          setMaterialFamilies(fetchedMaterialFamilies);
          setMaterialFamiliesFetched(true);
        });
    }
  }, [user?.groupId, organizationId]);
  //#endregion

  //#region ConformityCertificate Fetch
  useEffect(() => {
    if (user?.groupId) {
      new ConformityCertificateHttp()
        .get({ 'group.organizationId': organizationId || '' })
        .then((fetchedConformityCertificates) => {
          setConformityCertificates(fetchedConformityCertificates);
          formMethods.setValue(
            'conformityCertificate',
            getDefaultConformityCertificateValue(fetchedConformityCertificates)
          );
          setConformityCertificatesFetched(true);
        });
    }
  }, [user?.groupId, organizationId]);
  //#endregion

  //#region Periodicities Fetch
  useEffect(() => {
    new PeriodicityHttp().get().then((fetchedPeriodicities) => {
      setPeriodicities(fetchedPeriodicities);
      setPeriodicitiesFetched(true);
    });
  }, []);
  //#endregion

  //#region Form handling
  const materialSheetFetchHasBegun = useRef<boolean>(false);
  const [initialImageUri, setInitialImageUri] = useState<PromisedObject<string | undefined>>({
    status: materialSheetId === undefined ? Status.Succeeded : Status.Loading,
  });

  const formMethods = useForm({
    mode: 'onTouched',
    resolver: zodResolver(schema, { errorMap }),
  });
  const [backendError, setBackendError] = useState<string | undefined>(undefined);
  const formErrors: any = formMethods.formState.errors;
  const submitInputRef = useRef<HTMLInputElement>(null);

  const materialFamily = getMaterialFamilyFromOption(formMethods.watch('materialFamily'), materialFamilies);
  const watchDateComissioning = formMethods.watch('dateCommissioning');

  useEffect(() => {
    setStoreDateCommissioning(watchDateComissioning);
  }, [setStoreDateCommissioning, watchDateComissioning]);

  // Handle field initialisation for modification mode.
  useEffect(() => {
    if (materialSheetFetchHasBegun.current) return;
    if (!(materialSheetId !== undefined && materialFamiliesFetched && conformityCertificatesFetched)) return;

    materialSheetFetchHasBegun.current = true;
    MaterialSheetHttp.find(unwrap(materialSheetId)).then((materialSheet) => {
      formMethods.setValue(
        'materialFamily',
        materialFamilyToSelectOption(unwrap(materialSheet.materialFamily))
      );
      formMethods.setValue('materialType', materialTypeToSelectOption(unwrap(materialSheet.materialType)));
      if (materialSheet.dateCommissioning)
        formMethods.setValue('dateCommissioning', new Date(materialSheet.dateCommissioning));
      formMethods.setValue('matriculation', materialSheet.matriculation);
      formMethods.setValue('model', materialSheet.model);
      formMethods.setValue('brand', materialSheet.brand);
      formMethods.setValue('comment', materialSheet.comment ?? '');
      formMethods.setValue(
        'conformityCertificate',
        materialSheet.conformityCertificate?.id.toString() ?? conformityCertificates[0].id.toString()
      );
      formMethods.setValue('scrapped', materialSheet.isScrapped ? 'yes' : 'no');
      formMethods.setValue(
        'remoteCollaborators',
        employeesToMultiSelectOptions(materialSheet.remoteEmployees ?? [])
      );
      setInitialImageUri({
        object: materialSheet.pictureUri
          ? process.env.REACT_APP_URL_API + materialSheet.pictureUri
          : undefined,
        status: Status.Succeeded,
      });

      setMaterialType(materialSheet.materialType); // onChange is only triggered by user.

      fillStoreFromMaterialSheet(materialSheet);

      setTimeout(() => {
        // Allow deletion of MaterialType when new MaterialFamily is selected.
        setMaterialEditFirstRendered(true);
      }, 500);

      if (organizationId && ACTAuthorization !== undefined && ACTAuthorization & ACTRight.Show) {
        // ? Notification check
        NotificationActHttp.getNotification(unwrap(materialSheetId), Number.parseInt(organizationId)).then(
          (notification) => {
            if (notification) {
              setHasAlreadyNotif(true);
              setReminder(true);
              setNotification(notification);
            } else {
              setNotification(null);
            }
          }
        );
      }
    });
  }, [
    materialSheetId,
    materialFamiliesFetched,
    formMethods,
    materialSheetFetchHasBegun,
    conformityCertificatesFetched,
    conformityCertificates,
    fillStoreFromMaterialSheet,
    organizationId,
  ]);

  const onSubmit = async (rawData: FieldValues) => {
    const data = rawData as TypedFieldValues;

    const group = unwrap(user).getEntityGroup(parseInt(unwrap(organizationId)));
    //#region Handle notification data
    let notifPayload: NotificationAct | undefined = undefined;
    if (REACT_APP_MAT_NOTIFICATIONS_DISABLED !== 'true' && reminder) {
      let errorReminder = false;
      if (!data.notifyEmployee || data.notifyEmployee.length === 0) {
        formMethods.setError('notifyEmployee', { message: 'Sélectionnez vos destinataires' });
        errorReminder = true;
      }
      if (!data.dateNotif) {
        formMethods.setError('dateNotif', { message: 'Sélectionnez une date' });
        errorReminder = true;
      }
      if (!data.delay) {
        formMethods.setError('delay', { message: 'Sélectionnez un délai' });
        errorReminder = true;
      }
      if (errorReminder) {
        return;
      }
      notifPayload = new NotificationAct({
        effectiveDate: dayjs(data.dateNotif),
        delay: new DelayAct(
          entityBaseDataFromIri(
            data.delay && data.delay.value !== '' ? data.delay.value : unwrap(defaultDelayIriValue)
          )
        ),
        receivers: data.notifyEmployee.map(
          ({ value: employeeIri }) => new UserAct(entityBaseDataFromIri(employeeIri))
        ),
        organizationId: Number(organizationId),
        context: 'ficheMateriel',
      });
    }

    const shouldUpdateNotification = (() => {
      if (REACT_APP_MAT_NOTIFICATIONS_DISABLED === 'true') return false;

      if (notifPayload === undefined) {
        return Boolean(hasAlreadyNotif && notification);
      } else {
        if (!hasAlreadyNotif || !notification) return true;
        const matching = [
          () => dayjs(notification.effectiveDate).set('hour', 0).isSame(notifPayload?.effectiveDate),
          () => notification.delay.iri === unwrap(notifPayload).delay.iri,
          () =>
            ArrayUtils.equivalent(
              notification.receivers.map((r) => r.iri),
              unwrap(notifPayload).receivers.map((r) => r.iri)
            ),
          () => notification.organizationId === notifPayload?.organizationId,
        ];

        for (const predicate of matching) {
          if (!predicate()) {
            return true;
          }
        }

        return false;
      }
    })();
    //#endregion

    const materialSheet = new MaterialSheet({
      id: materialSheetId,
      materialFamily: data.materialFamily
        ? new MaterialFamily({
            id: parseInt(data.materialFamily.value),
          })
        : undefined,
      matriculation: data.matriculation,
      model: data.model,
      brand: data.brand,
      comment: data.comment,
      dateCommissioning: data.dateCommissioning,
      conformityCertificate: conformityCertificates.find(
        (el) => el.id.toString() === data.conformityCertificate
      ),
      isScrapped: data.scrapped === 'yes',
      remoteEmployees: data.remoteCollaborators?.map(
        (formEl) => new Employee({ id: parseInt(formEl.value) })
      ),
      materialCheckings: useMaterialSheetStore
        .getState()
        .getCompletedMaterialCheckings()
        .map((mc) => mc.prepareToPost()),
      group,
    });

    let backendError: string | undefined = undefined;

    //#region Form data upload
    try {
      //#region Send custom material type if necessary
      let selectedMaterialType = Object.assign({}, unwrap(materialType));
      if (selectedMaterialType.isCustomizable) {
        selectedMaterialType.name = unwrap(data.customMaterialTypeName);
        selectedMaterialType.periodicity = unwrap(
          optionToPeriodicity(unwrap(data.customMaterialTypePeriodicity), periodicities)
        );
        selectedMaterialType.materialFamily = unwrap(materialFamily);

        // Add new material type
        if (selectedMaterialType.isRepository) {
          selectedMaterialType.id = undefined;
          selectedMaterialType.isRepository = false;
          selectedMaterialType.group = group;

          try {
            selectedMaterialType = (await new MaterialTypeHttp().post(selectedMaterialType)).data;
          } catch (e) {
            console.error(e);
            if (
              e?.response?.status === 422 &&
              e?.response?.data?.['hydra:description']?.startsWith('name:')
            ) {
              setBackendError(
                "Erreur lors de l'ajout du type de matériel personnalisé : Ce nom est déjà pris. Veuillez en choisir un autre."
              );
            } else {
              setBackendError(`Erreur lors de l'ajout du type de matériel personnalisé : ${e}`);
            }
            return;
          }
        }
        // Update custom material type
        else {
          try {
            selectedMaterialType = (
              await new MaterialTypeHttp().put(unwrap(selectedMaterialType.id), selectedMaterialType)
            ).data;
          } catch (e) {
            console.error(e);
            if (
              e?.response?.status === 422 &&
              e?.response?.data?.['hydra:description']?.startsWith('name:')
            ) {
              setBackendError(
                'Erreur lors de la mise à jour du type de matériel : Ce nom est déjà pris. Veuillez en choisir un autre.'
              );
            } else {
              setBackendError(`Erreur lors de la mise à jour du type de matériel : ${e}`);
            }
            return;
          }
        }
      }
      materialSheet.materialType = new MaterialType(selectedMaterialType);
      //#endregion

      //#region Send material sheet
      const sentMaterialSheet = (
        materialSheetId !== undefined
          ? await new MaterialSheetHttp().put(materialSheetId, materialSheet)
          : await new MaterialSheetHttp().post(materialSheet)
      ).data;

      if (materialSheetId === undefined && sentMaterialSheet) {
        new DatalayerService().push({
          event: 'GAEvent',
          eventCategory: 'Votre espace',
          eventAction: 'MAT',
          eventLabel: DatalayerEventLabel.ADD_MATERIAL,
        });
      }
      //#endregion

      //#region Image upload
      try {
        if (data.image) {
          await MaterialSheetHttp.postImage(sentMaterialSheet.id, data.image);
        }
        // Remove image if this was asked by user
        else if (data.image === null && materialSheetId !== undefined) {
          await MaterialSheetHttp.postImage(sentMaterialSheet.id, undefined);
        }
      } catch (e) {
        console.error(e);
        setBackendError(
          `Erreur lors de ${
            materialSheetId !== undefined ? 'la modification' : "l'ajout"
          } de l'image du matériel : ${e}`
        );
        return;
      }
      //#endregion

      //#region Document upload
      for (const materialCheckingIndex in sentMaterialSheet.materialCheckings) {
        const localMaterialChecking = materialSheet.materialCheckings[materialCheckingIndex];
        const remoteMaterialCheckingId = unwrap(
          sentMaterialSheet.materialCheckings[materialCheckingIndex].id
        );

        if (!documentNeedsUpdate(localMaterialChecking)) continue;

        const document = localMaterialChecking.document;
        try {
          await MaterialCheckingHttp.postDocument(remoteMaterialCheckingId, document);
        } catch (e) {
          console.error(e);
          if (document) {
            setBackendError(`Erreur lors du téléversement du document ${document.name}: ${e}`);
          } else {
            setBackendError(`Erreur lors de la suppression d'un document: ${e}`);
          }
          return;
        }
      }
      //#endregion

      //#region Notification upload
      if (shouldUpdateNotification) {
        if (ACTAuthorization !== undefined && Flags.has(ACTAuthorization, ACTRight.Edit)) {
          // ! Need to check if add or update (update = id exists)
          if (notifPayload) {
            try {
              await NotificationActHttp.persist(
                hasAlreadyNotif ? 'put' : 'post',
                sentMaterialSheet.id,
                notifPayload as unknown as any
              );
            } catch (e) {
              backendError = `Erreur lors de la création de la notification : ${e}`;
              throw e;
            }
          } else if (hasAlreadyNotif && notification) {
            try {
              await NotificationActHttp.delete(notification);
            } catch (e) {
              backendError = `Erreur lors de la suppression de la notification : ${e}`;
              throw e;
            }
          }
        } else {
          // Only malicious user should be able to trigger this message.
          // And the ultimate right check to update a notification stands in the backend anyway.
          console.warn(
            'The notification appears to have changed but will not be send/updated as you have no rights to do it.'
          );
        }
      }
      //#endregion

      setBackendError(undefined);

      navigate(
        routeToLink('/:organizationId/validation/:isNewMaterial', {
          organizationId,
          isNewMaterial: materialSheetId === undefined,
        })
      ); // Navigate to completion pages.
      //#endregion
    } catch (e) {
      console.error(e);
      setBackendError(
        backendError ?? `Erreur lors de ${materialSheetId ? 'la modification' : "l'ajout"} du matériel : ${e}`
      );
      return;
    }
    //#endregion
  };
  //#endregion

  //#region Form watchers and actions flowing from those.
  const materialFamilyId = optionToId(useWatch({ control: formMethods.control, name: 'materialFamily' }));
  const [materialType, setMaterialType] = useState<MaterialTypeInterface | undefined>(undefined);
  const customPeriodicity = useWatch({ control: formMethods.control, name: 'customMaterialTypePeriodicity' });
  const scrapped = useWatch({ control: formMethods.control, name: 'scrapped' }) === 'yes';
  const isUsingCustomizableType = materialType?.isCustomizable ?? false;
  const customMaterialName: string | undefined = useWatch({
    control: formMethods.control,
    name: 'customMaterialTypeName',
  });

  // Clear material type when material family changes.
  useEffect(() => {
    // If in add mode or in edit mode and the material has already loaded.
    if (materialSheetId === undefined || materialEditFirstRendered) {
      formMethods.setValue('materialType', null, { shouldValidate: false });
      setMaterialType(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMethods, materialFamilyId]);

  // Register in form if the material type is customizable or not.
  useEffect(() => {
    formMethods.setValue('customMaterialType', isUsingCustomizableType);
  }, [formMethods, isUsingCustomizableType]);

  // Fill custom material type field when !isRepo and isCustomizable
  useEffect(() => {
    if (materialType === undefined) return;
    if (!materialType.isCustomizable) return;

    formMethods.setValue('customMaterialTypeName', materialType.isRepository ? '' : materialType.name);
    formMethods.setValue(
      'customMaterialTypePeriodicity',
      materialType.isRepository ? null : periodicityToOption(unwrap(materialType.periodicity))
    );
  }, [formMethods, materialType]);
  //#endregion

  // Empty store when the component unmounts
  useEffect(() => {
    return () => {
      emptyStore();
    };
  }, []);

  //#region Rendering
  const formGroups = ArrayUtils.onlyKeepDefined([
    <SingleSelect
      key="materialFamily"
      name="materialFamily"
      options={materialFamilies.map(materialFamilyToSelectOption)}
      label={'Famille de matériel *'}
      placeholder="Sélectionnez..."
      tabIndex={1}
      disabled={readOnly || !materialFamiliesFetched || scrapped}
      error={formErrors.materialFamily?.message}
    />,
    <MaterialTypeSelect
      key="materialType"
      materialFamilyId={materialFamilyId}
      tabIndex={2}
      error={formErrors.materialType?.message}
      onChange={setMaterialType}
      disabled={scrapped}
    />,
    isUsingCustomizableType ? (
      <TextField
        key="customMaterialTypeName"
        name="customMaterialTypeName"
        type="text"
        label="Nom du type de matériel *"
        tabIndex={3}
        error={formErrors.customMaterialTypeName?.message}
        disabled={readOnly || scrapped}
      />
    ) : undefined,
    isUsingCustomizableType ? (
      <SingleSelect
        key="customMaterialTypePeriodicity"
        name="customMaterialTypePeriodicity"
        options={periodicities.map(periodicityToOption)}
        label="Périodicité *"
        placeholder="Sélectionnez..."
        error={formErrors.customMaterialTypePeriodicity?.message}
        disabled={readOnly || !periodicitiesFetched || scrapped}
      />
    ) : undefined,
    <TextField
      key="matriculation"
      name="matriculation"
      type="text"
      label="Immatriculation / Identification *"
      tabIndex={5}
      error={formErrors.matriculation?.message}
      disabled={readOnly || scrapped}
    />,
    <TextField
      key="brand"
      name="brand"
      type="text"
      label="Marque"
      tabIndex={6}
      disabled={readOnly || scrapped}
    />,
    <TextField
      key="model"
      name="model"
      type="text"
      label="Modèle"
      tabIndex={7}
      disabled={readOnly || scrapped}
    />,
    <MaterialEmployeeSelect key="remoteCollaborators" disabled={readOnly || scrapped} />,
    <InnerColumn key="dateCommissioning-conformityCertificate-status">
      <DateField
        name="dateCommissioning"
        label="Date de mise en service *"
        defaultValue={dayjs(new Date()).toDate()}
        tabIndex={8}
        disabled={readOnly || scrapped}
      />
      <RadioClassic
        name="conformityCertificate"
        values={conformityCertificates.map(conformityCertificateToRadioProps)}
        label="Certificat de conformité"
        variant="horizontal"
        tabIndex={9}
        error={formErrors.conformityCertificate?.message}
        disabled={readOnly || !conformityCertificatesFetched || scrapped}
      />
      <ScrappedRadio
        tabIndex={10}
        name="scrapped"
        valueSetter={(val) => formMethods.setValue('scrapped', val)}
        error={formErrors.scrapped?.message}
      />
    </InnerColumn>,
    <TextArea
      key="comment"
      name="comment"
      label="Commentaire"
      placeholder="Écrivez votre commentaire..."
      tabIndex={11}
      disabled={readOnly}
    />,
    <div style={{ display: 'none' }} key="customMaterialType">
      <CheckboxField name="customMaterialType" disabled />
    </div>,
  ]);

  return (
    <>
      <Container>
        <Navbar>
          <>
            <a href={REACT_APP_URL_PREVENTIONBTP_PREFIXE}>
              <HomeGrey />
            </a>
            <ChevronRightLightGrey />
            <a href={REACT_APP_URL_EXT_PREVENTION_SERVICE}>Mon espace</a>
            <ChevronRightLightGrey />
            <Link
              to={routeToLink('/:organizationId/liste', {
                organizationId,
              })}
            >
              Suivi de matériel
            </Link>
            <ChevronRightLightGrey />
            <Text fontWeight={'bold'} color={'#92999C'} fsize={'xxs'}>
              {`${readOnly ? 'Consultation' : materialSheetId ? 'Modification' : 'Ajout'} de matériel`}
            </Text>
          </>
        </Navbar>
        <Title
          level={1}
          name={`${readOnly ? 'Consulter' : materialSheetId ? 'Modifier' : 'Ajouter'} un matériel`}
        ></Title>

        <VSpacer size={styleData.spacing.lg}></VSpacer>
        <FormProvider {...formMethods}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formMethods.handleSubmit(onSubmit)(e);
            }}
          >
            <TopContainer>
              <Text>
                {readOnly
                  ? `Consultez ici les informations générales du matériel. Vous trouverez aussi des précisions concernant les
                    vérifications périodiques, la conformité du CE ainsi que les entretiens du matériel.`
                  : `Renseignez ici les informations générales du matériel. Vous pourrez préciser ensuite les
                  vérifications périodiques, la conformité du CE du matériel ainsi que les entretiens.`}
              </Text>
              <MaterialImage
                name="image"
                initialImageUrl={initialImageUri.object}
                loading={initialImageUri.status === Status.Loading}
                materialTypeDetails={
                  materialType
                    ? {
                        name: isUsingCustomizableType ? customMaterialName ?? '' : materialType.name,
                        custom: isUsingCustomizableType === true,
                      }
                    : undefined
                }
              />
            </TopContainer>
            <VSpacer size={styleData.spacing.xl} />
            {backendError && (
              <>
                <ErrorContainer>
                  <ErrorIcon />
                  <Text color={StyleData.color.primary}>{backendError}</Text>
                </ErrorContainer>
                <VSpacer size={styleData.spacing.lg} />
              </>
            )}
            {(isMobile && <OneColumnForm>{formGroups}</OneColumnForm>) || (
              <TwoColumnsForm>
                <InnerColumn>{formGroups.filter((_val, index) => index % 2 === 0)}</InnerColumn>
                <InnerColumn>{formGroups.filter((_val, index) => index % 2 !== 0)}</InnerColumn>
              </TwoColumnsForm>
            )}
            <VSpacer></VSpacer>

            <VSpacer size={styleData.spacing.lg}></VSpacer>

            <VSpacer size={styleData.spacing.xl}></VSpacer>

            {/* Part two */}

            <EventsBlock
              materialTypeId={materialType?.id}
              materialTypeOverride={
                isUsingCustomizableType
                  ? {
                      isRepository: false,
                      periodicity: optionToPeriodicity(customPeriodicity, periodicities),
                    }
                  : undefined
              }
            />
            <VSpacer size="2rem" />
            {REACT_APP_MAT_NOTIFICATIONS_DISABLED !== 'true' && materialType?.id && (
              <>
                <Divider />
                <VSpacer size="3rem" />
                {ACTAuthorization !== undefined && Flags.has(ACTAuthorization, ACTRight.Show) && (
                  <ReminderSettings
                    materialSheetId={materialSheetId || 0}
                    disabled={
                      ACTAuthorization === undefined ||
                      !Flags.has(ACTAuthorization, ACTRight.Edit) ||
                      scrapped
                    }
                    existingNotification={notification}
                    reminder={reminder}
                    setReminder={setReminder}
                    setDefaultDelayIriValue={setDefaultDelayIriValue}
                  />
                )}
              </>
            )}
            <input type="submit" ref={submitInputRef} style={{ display: 'none' }} />
          </form>
        </FormProvider>
        <VSpacer size="16px" />

        <VSpacer size={styleData.spacing.xl}></VSpacer>

        <Divider />

        <VSpacer size={styleData.spacing.lg}></VSpacer>

        <EndContainer>
          {backendError && (
            <ErrorContainer>
              <ErrorIcon />
              <Text color={StyleData.color.primary}>{backendError}</Text>
            </ErrorContainer>
          )}
          <ActionButtonContainer>
            <Button
              variant="bigLight"
              as="a"
              callback={() =>
                navigate(
                  routeToLink('/:organizationId/liste', {
                    organizationId,
                  })
                )
              }
            >
              {readOnly ? 'Revenir à la liste des matériels' : 'Annuler'}
            </Button>
            {!readOnly && (
              <Button
                variant="primary"
                callback={() => {
                  submitInputRef.current?.click();
                }}
              >
                {`${materialSheetId ? 'Modifier' : 'Ajouter'} le matériel`}
              </Button>
            )}
          </ActionButtonContainer>
        </EndContainer>
      </Container>
    </>
  );
  //#endregion
}

export default MaterialSheetDetails;
