/**
 *
 * @param collection The collection to categorize.
 * @param determineCategory The function determining the category of each element.
 * @returns A map composed of:
 *   * [category] => [element0_matching_category, element1_matching_category...]
 */
export function categorize<T, C>(collection: T[], determineCategory: (el: T) => C): Map<C, T[]> {
  const categorizedCollection: Map<C, T[]> = new Map<C, T[]>();

  for (const el of collection) {
    const category = determineCategory(el);
    const categoryBox = categorizedCollection.get(category) ?? [];
    categoryBox.push(el);
    categorizedCollection.set(category, categoryBox);
  }

  return categorizedCollection;
}
