import { useEffect, useState } from 'react';
import { browserName, browserVersion, isMobile } from 'react-device-detect';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { createGlobalStyle } from 'styled-components';
import { z } from 'zod';
import './App.css';
import ErrorBoundary from './App.error';
import { Router } from './App.routing';
import { AuthContext, AuthContextType } from './contexts/AuthContext';
import { ResponsiveContext } from './contexts/ResponsiveContext';
import useMediaQuery from './hooks/UseMediaQuery';
import { ErrorModal } from './shared/error-modal/ErrorModal';
import MainLayout from './shared/layout/MainLayout';
import { versionCompare } from './utilities/String';
import StyleData from './utilities/StyleData';

export const defaultErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    return { message: 'Le champ est requis.' };
  }
  if (issue.code === z.ZodIssueCode.custom) {
    return { message: `less-than-${(issue.params || {}).minimum}` };
  }
  return { message: ctx.defaultError };
};

z.setErrorMap(defaultErrorMap);

const GlobalStyle = createGlobalStyle`
  body {
    // font-face loaded in index.css to prevent re-render in dev mode
    font-family: ${StyleData.font.primary};
    font-synthesis: none;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  `;
export const BROWSERS_COMPATIBILITY = [
  { subString: 'Edge', identity: 'Edge', supportedVersion: 94 }, // https://en.wikipedia.org/wiki/Microsoft_Edge#Release_history
  { subString: 'edg', identity: 'Edge', supportedVersion: 99 }, // https://docs.microsoft.com/en-us/microsoft-edge/web-platform/user-agent-string
  { subString: 'Firefox', identity: 'Firefox', supportedVersion: 94 }, // https://www.mozilla.org/en-US/firefox/releases/
  { subString: 'Opera', identity: 'Opera', supportedVersion: Number.POSITIVE_INFINITY }, // https://help.opera.com/en/opera-version-history/
  { subString: 'OPR', identity: 'Opera', supportedVersion: Number.POSITIVE_INFINITY },
  { subString: 'Chrome', identity: 'Chrome', supportedVersion: 95 }, // https://en.wikipedia.org/wiki/Google_Chrome_version_history
  { subString: 'Safari', identity: 'Safari', supportedVersion: 14.1 }, // https://en.wikipedia.org/wiki/Safari_version_history
];

export default function App(): JSX.Element {
  const isMobileSize = useMediaQuery(StyleData.breakpoints.max.md, 'down');

  const [authContext, setAuthContext] = useState<AuthContextType>({
    authenticated: false,
    readOnly: true,
    loading: true,
    user: undefined,
  });

  useEffect(() => {
    !(function checkBrowser() {
      if (isMobile) {
        return true;
      }
      if (!browserName || !browserVersion) {
        return false;
      }
      // We strip characters because we don't do lexicographical order
      let currentBrowserVersion = browserVersion;
      currentBrowserVersion = currentBrowserVersion.replaceAll(/[a-zA-Z-]/g, '');
      for (let i = 0; i < BROWSERS_COMPATIBILITY.length; i++) {
        const browser = BROWSERS_COMPATIBILITY[i];

        if (browser.identity === browserName) {
          return (
            versionCompare(browser.supportedVersion.toString(), currentBrowserVersion, {
              lexicographical: false,
              zeroExtend: true,
            }) <= 0
          );
        }
      }
    })() && (window.location.href = '/update_browser.html');
  }, []);

  return (
    <>
      <ErrorBoundary>
        <GlobalStyle />
        <ResponsiveContext.Provider value={{ isMobile: isMobileSize }}>
          <AuthContext.Provider value={authContext}>
            <MainLayout>
              <BrowserRouter>
                <Router setAuthContext={setAuthContext} />
              </BrowserRouter>
            </MainLayout>
            <ToastContainer autoClose={5000} />
          </AuthContext.Provider>
        </ResponsiveContext.Provider>
        <ErrorModal modalSize={'md'} actionText="Fermer" />
      </ErrorBoundary>
    </>
  );
}
