import EntityInterface from '../interfaces/Entity';
import Entity from './Entity';

export class Employee extends Entity implements EntityInterface {
  public id!: number;

  public name!: string;
  public firstname?: string;
  public lastname?: string;

  public isPartOfTheOrganization!: boolean;

  public get fullname(): string {
    return this.firstname + ' ' + this.lastname;
  }

  constructor(data?: Partial<Employee>) {
    super();
    Object.assign(this, data);

    const nameArray = [this.firstname, this.lastname].filter((part) => part !== undefined) as string[];
    this.name = nameArray.join(' ');
  }
}
