import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { RadioClassic } from '../../../components/input/RadioClassic';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import { useAuthContext } from '../../../contexts/AuthContext';
import EventResult from '../../../models/EventResult';
import { useMaterialSheetStore } from '../../../store/MaterialSheetStore';
import StyleData from '../../../utilities/StyleData';

export interface ScrappedRadioProps {
  tabIndex?: number;
  error?: string;
  name: string;
  valueSetter: (value: any) => void;
}

export function ScrappedRadio(props: ScrappedRadioProps) {
  const { readOnly } = useAuthContext();
  const [materialCheckingInvolvingScrapping, isScrapped, setIsScrapped] = useMaterialSheetStore((state) => [
    state.getMaterialCheckingInvolvingScrapping(),
    state.isScrapped,
    state.setIsScrapped,
  ]);
  const resultInvolvingScrapping = useRef<EventResult | undefined>(
    materialCheckingInvolvingScrapping?.eventResult
  );
  const [scrapModalOpened, setScrapModalOpened] = useState(false);
  const [unscrapModalOpened, setUnscrapModalOpened] = useState(false);
  const [disabled, setDisabled] = useState(resultInvolvingScrapping.current !== undefined);

  useEffect(() => {
    const newResultInvolvingScrapping = materialCheckingInvolvingScrapping?.eventResult;

    if (!!newResultInvolvingScrapping !== !!resultInvolvingScrapping.current) {
      setDisabled(!!newResultInvolvingScrapping);

      if (newResultInvolvingScrapping && !isScrapped) {
        props.valueSetter('yes');
        setIsScrapped(true);
        setScrapModalOpened(true);
      } else if (!newResultInvolvingScrapping && isScrapped) {
        props.valueSetter('no');
        setIsScrapped(false);
        setUnscrapModalOpened(true);
      }
    }
    resultInvolvingScrapping.current = newResultInvolvingScrapping;
  }, [materialCheckingInvolvingScrapping, props]);

  const changed = (val: string) => {
    setIsScrapped(val === 'yes');
  };

  return (
    <>
      <RadioClassic
        name={props.name}
        values={[
          { label: 'Non', value: 'no' },
          { label: 'Oui', value: 'yes' },
        ]}
        defaultValue={'no'}
        label={"Mise en rebut (destruction)\u00a0/ Revente d'occasion"}
        variant="horizontal"
        tabIndex={props.tabIndex}
        disabled={readOnly || disabled}
        onChange={changed}
        title={
          disabled
            ? `Une des vérifications matérielles comportent «\u00A0${resultInvolvingScrapping.current?.name}\u00A0». Le champ est par conséquent activé et non modifiable.`
            : undefined
        }
        error={props.error}
      />
      <Modal name="Mise en rebut" onClose={() => setScrapModalOpened(false)} isOpen={scrapModalOpened}>
        <ModalRoot>
          <Text>
            Une de vos vérifications matérielles comporte un résultat à{' '}
            <Text inline fontWeight="bold">
              {resultInvolvingScrapping.current?.name}
            </Text>
            .
          </Text>
          <Text>
            La case «&nbsp;Mise en rebut&nbsp;/ Revente d'occasion&nbsp;» a donc été cochée automatiquement.
          </Text>
        </ModalRoot>
      </Modal>
      <Modal
        name="Annulation de la mise en rebut"
        onClose={() => setUnscrapModalOpened(false)}
        isOpen={unscrapModalOpened}
      >
        <ModalRoot>
          <Text>
            Suite aux modifications que vous venez d'apporter aux vérifications matérielles, la case
            «&nbsp;Mise en rebut&nbsp;/ Revente d'occasion&nbsp;» a été décochée automatiquement.
          </Text>
        </ModalRoot>
      </Modal>
    </>
  );
}

const ModalRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${StyleData.spacing.lg};
  padding: ${StyleData.spacing.lg} 0px;
`;
