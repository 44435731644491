import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useResponsiveContext } from '../contexts/ResponsiveContext';
import { MathUtils } from '../utilities/Math';
import { fillOptions } from '../utilities/Options';
import StyleData from '../utilities/StyleData';

export interface Coordinate {
  x: number;
  y: number;
}

const positionTweaksDefault: Coordinate = {
  x: 0,
  y: 0,
};

const defaultMenuWidth = 128;
const defaultPointerSize = 10;

interface Props {
  children?: JSX.Element;
  menuWidth?: number;
  pointerSize?: number;
  isOpen: boolean;
  attachedId: string;
  down?: boolean;
  menuPadding?: string;
  onClose?: () => unknown;
  endYOverride?: number; // used to prevent click bug on growing dropdown content
  hasLongLabel?: boolean;
  /** Adjusts the position of the dropdown origin. **/
  positionTweaks?: Partial<Coordinate>;
  /** Centers the dropdown horizontally when using mobile. */
  centerOnMobile?: boolean;
}

function Dropdown(props: Props): JSX.Element {
  const dropdownRef = useRef(null);
  const dropdownRoot = document.getElementById(props.attachedId);
  const [height, setHeight] = useState<number>();
  const { onClose, isOpen } = props;
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [pointerTranslationX, setPointerTranslationX] = useState(0);
  const [visible, setVisible] = useState(false);
  const prevBodyWidth = useRef(-1);
  const { isMobile } = useResponsiveContext();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        isOpen &&
        dropdownRef &&
        dropdownRef.current &&
        !(dropdownRef.current as unknown as HTMLDivElement).contains(event.target as Node) &&
        (event.target as Node).parentElement?.className.toString().indexOf('MenuList') === -1 // ! get react-select in dropdown working
      ) {
        onClose && onClose();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, isOpen, onClose]);

  useEffect(() => {
    setHeight(document.getElementById(props.attachedId)?.clientHeight);
  }, [props.attachedId]);

  useEffect(() => {
    if (dropdownRoot === undefined || dropdownRoot === null || !isOpen) {
      setVisible(false);
      return;
    }

    const { content: contentPosition, pointerTranslationX } = getPositions(
      dropdownRoot,
      props.down,
      props.menuWidth ?? defaultMenuWidth,
      props.pointerSize ?? defaultPointerSize,
      isMobile && props.centerOnMobile === true,
      props.positionTweaks
    );
    setPosition(contentPosition);
    setPointerTranslationX(pointerTranslationX);
    setVisible(true);
  }, [
    dropdownRoot,
    isMobile,
    isOpen,
    props.centerOnMobile,
    props.down,
    props.menuWidth,
    props.pointerSize,
    props.positionTweaks,
  ]);

  // Adapt position on resize
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const width = document.body.clientWidth;
      if (width === prevBodyWidth.current) return;

      prevBodyWidth.current = width;

      if (!dropdownRoot) return;

      const { content: contentPosition } = getPositions(
        dropdownRoot,
        props.down,
        props.menuWidth ?? defaultMenuWidth,
        props.pointerSize ?? defaultPointerSize,
        isMobile && props.centerOnMobile === true,
        props.positionTweaks
      );

      setPosition(contentPosition);
    });

    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, [
    dropdownRoot,
    isMobile,
    props.centerOnMobile,
    props.down,
    props.menuWidth,
    props.pointerSize,
    props.positionTweaks,
  ]);

  return ReactDOM.createPortal(
    <Blocker className="Blocker" visible={isOpen}>
      <Container className="Dropdown" style={{ left: position.x, top: position.y }}>
        <DropdownContainer
          className="DropdownContainer"
          visible={visible}
          menuWidth={props.menuWidth ?? 128}
          pointerSize={props.pointerSize}
          down={props.down}
          menuHeight={height}
          menuPadding={props.menuPadding}
          hasLongLabel={props.hasLongLabel}
        >
          <div className="dropdown-content" ref={dropdownRef}>
            {!props.down && <nav className="dropdown-menu">{props.children}</nav>}
            <div
              className="dropdown-pointer-container"
              style={{ transform: `translateX(${pointerTranslationX}px)` }}
            >
              <div className="dropdown-pointer" />
            </div>
            {props.down && <nav className="dropdown-menu">{props.children}</nav>}
          </div>
        </DropdownContainer>
      </Container>
    </Blocker>,
    document.body
  );
}

/**
 * @param rootElement The dropdown triggering element
 * @param down Is the dropdown a down or a up appearing dropdown.
 * @param contentWidth The dropdown width.
 * @param pointerSize The size of the pointer.
 * @param centerHorizontally Should the dropdown be centered horizontally independently of the rootElement horizontal position.
 * @param positionTweaks The position tweaks given by the user.
 * @returns
 * * `content`: Where to place the dropdown whole content (in absolute position).
 * * `pointerTranslationX`: The translation to apply the the pointer, may only be non-zero if {@link centerHorizontally} is `true`.
 */
function getPositions(
  rootElement: HTMLElement,
  down: Props['down'],
  contentWidth: number,
  pointerSize: number,
  centerHorizontally: boolean,
  positionTweaks: Props['positionTweaks']
): { content: Coordinate; pointerTranslationX: number } {
  const bbox = rootElement.getBoundingClientRect();

  if (centerHorizontally) {
    const bodyRect = document.body.getBoundingClientRect();
    bbox.width = bodyRect.width;
    bbox.x = bodyRect.x;
  }

  bbox.y += window.scrollY;
  bbox.x += window.scrollX;
  const posTweaks = fillOptions(positionTweaks, positionTweaksDefault);

  const content: Coordinate = {
    x: bbox.x + bbox.width / 2 + posTweaks.x,
    y: (down ? bbox.y + bbox.height : bbox.y) + posTweaks.y,
  };

  let pointerTranslationX: number = 0;

  if (centerHorizontally) {
    const bbox = rootElement.getBoundingClientRect();
    const targetPosition = bbox.x + bbox.width / 2 + window.scrollX;
    const rotatedHalfPointerSize = (pointerSize * 1.4142) / 2.0;

    pointerTranslationX = MathUtils.clamp(
      targetPosition - content.x,
      -contentWidth / 2.0 + rotatedHalfPointerSize,
      contentWidth / 2.0 - rotatedHalfPointerSize
    );
  }

  return {
    content,
    pointerTranslationX,
  };
}

const Container = styled.div`
  position: absolute;
`;

const Blocker = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'initial' : 'none')};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  top: 0;
  left: 0;
`;

interface StyleProps {
  visible: boolean;
  menuWidth: number;
  pointerSize?: number;
  down?: boolean;
  menuPadding?: string;
  menuHeight?: number; //height est optionnel car l'id passé est possiblement un mauvais id mais cela ne doit pas être le cas pour le bon fonctionnement du composant
  hasLongLabel?: boolean;
}

const DropdownContainer = styled.div<StyleProps>`
  position: relative;
  background-color: transparent;
  border: 0;
  padding: 0;

  .dropdown-content {
    display: ${(props) => {
      if (props.visible) {
        return 'flex';
      } else {
        return 'none';
      }
    }};
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    width: ${(props) => {
      return props.menuWidth + 'px';
    }};
    height: fit-content;

    ${(props) => {
      return props.down ? 'top : 15px' : 'bottom : 0';
    }};

    left: calc(
      50% -
        ${(props) => {
          return props.menuWidth
            ? props.hasLongLabel
              ? props.menuWidth > 130
                ? props.menuWidth / 1.5
                : props.menuWidth / 1.25
              : props.menuWidth / 2
            : 65;
        }}px
    );

    bottom: 0;
    border-radius: 2px;
    background-color: ${StyleData.color.base};
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.26);
    z-index: 2000;
  }
  .dropdown-pointer-container {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin: auto;
  }
  .dropdown-menu {
    height: min-content;
    display: flex;
    flex-direction: column;
    padding: ${(props) => {
      return props.menuPadding ? props.menuPadding : '10px 12px 10px 12px';
    }};
    align-items: flex-start;
    justify-content: space-around;
    @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
      padding: 8px 10px 8px 10px;
    }
    a {
      color: ${StyleData.color.text};
      font-family: ${StyleData.font.primary};
      font-size: ${StyleData.fsize.xs};
      text-align: left;
      margin: 4px 0 4px 0;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 16px;
      text-decoration: underline;
      @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
        font-size: ${StyleData.fsize.xs};
        line-height: 12px;
      }
    }
  }
  .dropdown-pointer {
    display: ${(props) => {
      return props.visible ? 'flex' : 'none';
    }};
    position: absolute;
    transform: rotate(45deg);
    width: ${(props) => {
      return props.pointerSize ? props.pointerSize : 10;
    }}px;
    height: ${(props) => {
      return props.pointerSize ? props.pointerSize : 10;
    }}px; // height = width
    top: -${(props) => {
        return props.pointerSize ? props.pointerSize / 2 : 5;
      }}px; // 1/2 height
    ${(props) => {
      if (props.hasLongLabel) {
        return 'left: 25px;';
      }
      return `
      left: calc(
      50% -
          ${props.pointerSize ? (props.pointerSize / 2) * 1.4142356 - 2 : 5.07106781}px
    );`;
    }}
    background-color: ${StyleData.color.base};
    z-index: 0;
  }
`;

export default Dropdown;
