import styled from 'styled-components';
import { CardRowGridContainer } from '../../components/card-deck/CardDeckStyle';
import StyleData from '../../utilities/StyleData';

interface MayBeScrapped {
  isScrapped: boolean;
}

export const FullMaterialTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const MaterialType = styled.span<MayBeScrapped>`
  font-family: ${StyleData.font.primary};
  color: ${({ isScrapped }) => (isScrapped ? StyleData.color.disabledText : StyleData.color.lightBlue)};
  font-size: ${StyleData.fsize.ml};
  line-height: 1.3;
  max-width: 100%;
  @media screen and (min-width: ${StyleData.breakpoints.min.xl}px) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const MaterialFamily = styled.span<MayBeScrapped>`
  font-family: ${StyleData.font.secondary};
  font-size: ${StyleData.fsize.xs};
  color: ${({ isScrapped }) => (isScrapped ? StyleData.color.disabledText : StyleData.color.lightText)};
  @media screen and (min-width: ${StyleData.breakpoints.min.xl}px) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 170px;
  }
`;

export const MaterialText = styled.span<MayBeScrapped>`
  color: ${({ isScrapped }) => (isScrapped ? StyleData.color.disabledText : 'initial')};
`;

interface DueDateInterface extends MayBeScrapped {
  over: boolean;
}

export const DueDate = styled.span<DueDateInterface>`
  color: ${({ over, isScrapped }) =>
    isScrapped ? StyleData.color.disabledText : over ? StyleData.color.primary : 'currentcolor'};
`;

export const MaterialCardRowGridContainer = styled(CardRowGridContainer)<MayBeScrapped>`
  color: ${({ isScrapped }) => (isScrapped ? StyleData.color.disabledText : 'initial')};
`;

export const DropdownContentContainer = styled.div`
  & > * {
    cursor: pointer;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
