import { useEffect, useRef, useState } from 'react';
import { Params, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { routeToLink } from '../../App.routing';
import { Button } from '../../components/Button';
import { CardDeck } from '../../components/card-deck/CardDeck';
import { DataGrid } from '../../components/data-grid/DataGrid';
import { TableFooter } from '../../components/data-grid/DataGridStyle';
import { VSpacer } from '../../components/layout/Spacer';
import { Container } from '../../components/List.style';
import MessagePage from '../../components/MessagePage';
import Navbar from '../../components/Navbar';
import Text from '../../components/Text';
import Title from '../../components/Title';
import { useAuthContext } from '../../contexts/AuthContext';
import { useResponsiveContext } from '../../contexts/ResponsiveContext';
import LoadingScreen from '../../core/LoadingScreen';
import { useMutateModals } from '../../hooks/UseMutateModals';
import { useHttpQuery } from '../../hooks/UsePagination';
import { useQuery } from '../../hooks/UseQuery';
import {
  AddWhite,
  Bonhomme2x,
  ChevronDownBlack,
  ChevronRightLightGrey,
  FilterBlack,
  HomeGrey,
  SearchBlack,
} from '../../icons';
import MaterialSheet from '../../models/MaterialSheet';
import MaterialSheetHttp from '../../services/http/MaterialSheetHttp';
import { cardConstants, tableConstants } from '../../shared/material-overview/MaterialConstants';
import { MaterialDisplayTypes } from '../../shared/material-overview/MaterialDisplayTypes';
import { TableHeader } from '../../shared/material-overview/TableHeader';
import { MainToolbar } from '../../shared/toolbar/Toolbar';
import { useEmployeeStore } from '../../store/EmployeeStore';
import { useListStore } from '../../store/ListStore';
import { downloadFile } from '../../utilities/DownloadFile';
import StyleData from '../../utilities/StyleData';
import Summary from '../summary/Summary';
import MaterialDelete from './delete/MaterialDelete';
import { SearchContainer, SearchMobileContainer, VerticalCenterContainer } from './MaterialListStyle';
import MaterialListToolbarActions from './MaterialListToolbarActions';
import MobileFilters from './MobileFilters';

const { REACT_APP_URL_EXT_PREVENTION_SERVICE, REACT_APP_URL_PREVENTIONBTP_PREFIXE } = process.env;

function MaterialList() {
  const { user, readOnly } = useAuthContext();
  const { organizationId } = useParams<Params>();
  const responsive = useResponsiveContext();

  const [materialCount, setMaterialCount] = useState<number>();
  const [searchInput, setSearchInput] = useState('');
  const [hasMobileFilters, setHasMobileFilters] = useState<boolean>(false);
  const [mobileFiltersIsOpen, setMobileFiltersIsOpen] = useState(false);

  const [opened, setOpened] = useState(-1);
  const [paramsLoaded, setParamsLoaded] = useState<boolean>(false);
  const [summaryModalOpened, setSummaryModalOpened] = useState<boolean>(false);
  const [summaryId, setSummaryId] = useState<number | undefined>(undefined);

  const [employees, fetchEmployees] = useEmployeeStore((state) => [state.data, state.fetch]);

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.split('?')[0];
  const params = useQuery();

  // Allow to make combined changes and trigger call only once.
  const [fullQuery, setFullQuery] = useState<{ query: string; pageNumber: number }>({
    query: '',
    pageNumber: 1,
  });

  const { items, setItems, hasMore, trigger, alreadyFetchData } = useHttpQuery<MaterialSheet>(
    MaterialSheetHttp,
    fullQuery.query,
    fullQuery.pageNumber,
    setMaterialCount
  );

  const [incrementTotalMaterialSheets] = useListStore((state) => [state.incrementTotalMaterialSheets]);

  // Fetch employees
  useEffect(() => {
    if (organizationId === undefined) return;
    fetchEmployees({ organizationId });
  }, [fetchEmployees, organizationId]);

  // * First request
  useEffect(() => {
    if (user?.groupId) {
      // The params must contains more than "group.organizationId" to have an active filter.
      if (Array.from(params.keys()).length > 1) {
        setHasMobileFilters(true);
      }
      const filter = new URLSearchParams(params.toString());
      filter.set('group.organizationId', organizationId || '');
      if (filter.get('materialCategory') === 'ipe') {
        filter.set('materialType.materialFamily.isIpe', 'true');
        filter.delete('materialType.materialFamily.isMaterial');
        filter.delete('materialType.materialFamily.isInstallation');
      } else if (filter.get('materialCategory') === 'materiel') {
        filter.set('materialType.materialFamily.isMaterial', 'true');
        filter.delete('materialType.materialFamily.isIpe');
        filter.delete('materialType.materialFamily.isInstallation');
      } else if (filter.get('materialCategory') === 'installation') {
        filter.set('materialType.materialFamily.isInstallation', 'true');
        filter.delete('materialType.materialFamily.isIpe');
        filter.delete('materialType.materialFamily.isMaterial');
      }
      setFullQuery(() => {
        return {
          query: filter.toString(),
          pageNumber: 1,
        };
      });
    }
  }, [user?.groupId]);

  function handleDropdownToggle(id: number) {
    opened !== id ? setOpened(id) : setOpened(-1);
  }

  const triggerQuery = () => {
    return trigger(setFullQuery);
  };

  function deleteMaterial(id: number): void {
    //eslint-disable-next-line
    new MaterialSheetHttp().delete(id).then((data: any) => {
      incrementTotalMaterialSheets(-1);
      setItems(items.filter((material) => material.id !== id));

      window.scrollTo({ top: 0 });
    });
  }

  const { DisplayMutateModals, openDeletePopin } = useMutateModals(
    undefined,
    undefined,
    undefined,
    undefined,
    MaterialDelete,
    { modalSize: 'xs', name: 'Supprimer un matériel', trigger: triggerQuery },
    deleteMaterial
  );

  function handleFilters(filterForm: Record<string, string[]>) {
    const filter = new URLSearchParams();
    for (const [key, values] of Object.entries(filterForm)) {
      for (const value of values) {
        filter.append(key, value);
      }
    }

    //#region Handle material category filtering
    filter.delete('materialType.materialFamily.isMaterial');
    filter.delete('materialType.materialFamily.isInstallation');
    filter.delete('materialType.materialFamily.isIpe');
    if (filter.get('materialCategory') === 'ipe') {
      filter.set('materialType.materialFamily.isIpe', 'true');
    } else if (filter.get('materialCategory') === 'materiel') {
      filter.set('materialType.materialFamily.isMaterial', 'true');
    } else if (filter.get('materialCategory') === 'installation') {
      filter.set('materialType.materialFamily.isInstallation', 'true');
    }
    filter.delete('materialCategory');
    //#endregion

    if (user?.groupId) {
      filter.set('group.organizationId', user?.groupId.toString());
      setFullQuery(() => {
        return {
          query: filter.toString(),
          pageNumber: 1,
        };
      });
    }
    setParamsLoaded(true); // params set in Table Header or Mobile Filters Component are ready
  }

  function openSummaryPopin(id: number) {
    setSummaryModalOpened(true);
    setSummaryId(id);
  }

  const searchInputLengthRef = useRef<number>();

  useEffect(() => {
    if (
      searchInputLengthRef.current &&
      searchInputLengthRef.current >= 3 &&
      searchInput.length < 3 &&
      user?.groupId
    ) {
      const filters = new URLSearchParams();
      filters.delete('materialType.name');
      filters.set('group.organizationId', organizationId || '');
      navigate({
        pathname,
        search: filters.toString().replaceAll('%5B', '[').replaceAll('%5D', ']'),
      });
      setFullQuery(() => {
        return {
          query: filters.toString(),
          pageNumber: 1,
        };
      });
    }
    if (searchInput.length >= 3 && user?.groupId) {
      params.set('materialType.name', searchInput);
      navigate({
        pathname,
        search: params.toString().replaceAll('%5B', '[').replaceAll('%5D', ']'),
      });
      params.set('group.organizationId', user?.groupId.toString());

      setFullQuery(() => {
        return {
          query: params.toString(),
          pageNumber: 1,
        };
      });
    } else if (paramsLoaded && user?.groupId && searchInput) {
      params.delete('materialType.name');
      navigate({
        pathname,
        search: params.toString().replaceAll('%5B', '[').replaceAll('%5D', ']'),
      });
      params.set('group.organizationId', user?.groupId.toString());

      setFullQuery(() => {
        return {
          query: params.toString(),
          pageNumber: 1,
        };
      });
    }
    searchInputLengthRef.current = searchInput.length;
  }, [searchInput]);

  // * First load parameters / create new parameters
  useEffect(() => {
    if (params.get('materialType.name') && params.get('materialType.name') !== searchInput) {
      setSearchInput(params.get('materialType.name') || '');
    }
  }, [paramsLoaded]);

  const handleNextPage = function () {
    if (hasMore) {
      setFullQuery((prevFullQuery) => {
        return { query: prevFullQuery.query, pageNumber: prevFullQuery.pageNumber + 1 };
      });
    }
  };

  function downloadDocument(id: number) {
    new MaterialSheetHttp().exportMaterialSheetDocument(id).then(({ data, filename }) => {
      downloadFile(data, filename, 'docx');
    });
  }

  return (
    <>
      <Container>
        <Navbar>
          <>
            <a href={REACT_APP_URL_PREVENTIONBTP_PREFIXE}>
              <HomeGrey />
            </a>
            <ChevronRightLightGrey />
            <a href={REACT_APP_URL_EXT_PREVENTION_SERVICE}>Mon espace</a>
            <ChevronRightLightGrey />
            {user && (
              <Text fontWeight={'bold'} color={'#92999C'} fsize={'xxs'}>
                Suivi du matériel
              </Text>
            )}
          </>
        </Navbar>

        <Title
          level={1}
          name={responsive.isMobile ? 'Suivi du matériel' : 'Bienvenue dans l’outil de suivi du matériel'}
          inline={true}
        />
        {responsive.isMobile ? <VSpacer size="15px" /> : <VSpacer size="28px" />}
        {alreadyFetchData && employees !== undefined ? (
          <>
            {items.length < 1 && params.toString().length === 0 ? (
              <>
                <MainToolbar>
                  <VerticalCenterContainer>
                    <Button
                      variant="primary"
                      icon={AddWhite}
                      callback={() =>
                        navigate(routeToLink('/:organizationId/ajouter', { organizationId: user?.groupId }))
                      }
                      disabled={readOnly}
                    >
                      {responsive.isMobile ? '' : 'Ajouter'}
                    </Button>
                  </VerticalCenterContainer>
                </MainToolbar>
                <VSpacer size={StyleData.spacing.xxl} />
                <MessagePage
                  title="Vous n'avez actuellement aucun matériel"
                  description={''}
                  image={Bonhomme2x}
                >
                  <>
                    <Button
                      variant="primary"
                      icon={AddWhite}
                      callback={() =>
                        navigate(routeToLink('/:organizationId/ajouter', { organizationId: user?.groupId }))
                      }
                      disabled={readOnly}
                    >
                      Ajouter mon premier matériel
                    </Button>
                  </>
                </MessagePage>
                <VSpacer size="64px"></VSpacer>
                <TierContainer>
                  <Text>
                    L'OPPBTP met à votre disposition un outil permettant de suivre facilement les
                    vérifications de votre matériel.
                  </Text>
                  {/* <CardLink
                    name="Découvrir les ressources associées à votre disposition"
                    image={ScreenRed}
                    widthSize="310px"
                  ></CardLink> */}
                </TierContainer>
              </>
            ) : (
              <>
                <MainToolbar>
                  <MaterialListToolbarActions query={fullQuery.query} trigger={triggerQuery} />
                </MainToolbar>
                {!responsive.isMobile && <VSpacer size="38px" />}

                {!responsive.isMobile ? (
                  <>
                    <SearchContainer>
                      <div className="search-input">
                        <input
                          type="text"
                          name="search"
                          placeholder="Rechercher un matériel par son type"
                          value={searchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                        />
                        <button className="search-submit" type="submit">
                          <SearchBlack />
                        </button>
                      </div>
                    </SearchContainer>
                    <VSpacer size={!responsive.isMobile ? '32px' : '24px'} />
                    <Text fontWeight="bold" fsize="md">
                      {materialCount
                        ? `${materialCount} matériel${materialCount > 1 ? 's' : ''}`
                        : 'Aucun matériel'}
                    </Text>
                    <VSpacer size={!responsive.isMobile ? '32px' : '12px'} />
                    <DataGrid
                      format={tableConstants(
                        organizationId,
                        handleDropdownToggle,
                        opened,
                        setOpened,
                        openDeletePopin,
                        openSummaryPopin,
                        readOnly,
                        MaterialDisplayTypes.List,
                        downloadDocument,
                        employees
                      )}
                      data={items}
                      style={{
                        gridTemplateColumn: '250px 220px 128px 128px 128px 1fr',
                        gridColumnNumber: 6,
                      }}
                    >
                      <TableHeader handleFilters={handleFilters} mode={MaterialDisplayTypes.List} />
                    </DataGrid>
                  </>
                ) : (
                  <>
                    <SearchMobileContainer>
                      <div className="search-mobile-input">
                        <input
                          type="text"
                          name="search"
                          placeholder="Rechercher"
                          value={searchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                        />
                        <Button
                          variant="lightNoBorderNoText"
                          callback={() => undefined}
                          icon={SearchBlack}
                          widthSize={'20px'}
                          heightSize={'20px'}
                        />
                      </div>
                      <MobileFilters
                        isOpen={mobileFiltersIsOpen}
                        onClose={() => setMobileFiltersIsOpen(false)}
                        handleFilters={handleFilters}
                        setHasMobileFilters={setHasMobileFilters}
                      />
                      <div style={{ display: 'flex', gap: '12px' }}>
                        <Button
                          variant="primaryRound"
                          icon={AddWhite}
                          callback={() =>
                            navigate(
                              routeToLink('/:organizationId/ajouter', { organizationId: user?.groupId })
                            )
                          }
                          widthSize={'18px'}
                          heightSize={'18px'}
                          disabled={readOnly}
                        />
                        <Button
                          variant={
                            hasMobileFilters
                              ? 'secondaryRoundMobileFilterOn'
                              : 'secondaryRoundMobileFilterOff'
                          }
                          callback={() => setMobileFiltersIsOpen(!mobileFiltersIsOpen)}
                          widthSize={'18px'}
                          heightSize={'18px'}
                          icon={FilterBlack}
                        />
                      </div>
                      {/* Popin des filtres mobiles */}
                    </SearchMobileContainer>
                    <VSpacer size={!responsive.isMobile ? '32px' : '24px'} />
                    <Text fontWeight="bold" fsize="md">
                      {materialCount
                        ? `${materialCount} matériel${materialCount > 1 ? 's' : ''}`
                        : 'Aucun matériel'}
                    </Text>
                    <VSpacer size={!responsive.isMobile ? '32px' : '12px'} />
                    <CardDeck
                      format={cardConstants(
                        organizationId,
                        handleDropdownToggle,
                        opened,
                        setOpened,
                        openDeletePopin,
                        openSummaryPopin,
                        readOnly,
                        MaterialDisplayTypes.List,
                        downloadDocument,
                        employees
                      )}
                      data={items}
                    ></CardDeck>
                  </>
                )}
                <VSpacer size="1rem"></VSpacer>
                <TableFooter>
                  <div>
                    <Text fontWeight="bold" fsize="md">
                      {materialCount
                        ? `${materialCount} matériel${materialCount > 1 ? 's' : ''}`
                        : 'Aucun matériel'}
                    </Text>
                    <Button
                      variant="primary"
                      icon={AddWhite}
                      callback={() => {
                        navigate(routeToLink('/:organizationId/ajouter', { organizationId: user?.groupId }));
                      }}
                      disabled={readOnly}
                    >
                      Ajouter un matériel
                    </Button>
                  </div>
                  <div>
                    {hasMore ? (
                      <button className="more-results" onClick={() => handleNextPage()}>
                        Afficher plus de résultats <ChevronDownBlack />{' '}
                      </button>
                    ) : (
                      <Text fsize="sm">Vous n'avez pas d’autres matériels d’enregistrés.</Text>
                    )}
                  </div>
                </TableFooter>
              </>
            )}
          </>
        ) : (
          <LoadingScreen></LoadingScreen>
        )}
      </Container>
      <VSpacer size="32px" />
      <GreyContainer>
        <Container>
          <VSpacer size="24px" />
          <Title level={2} name="Comment ça marche"></Title>
          <Workflow>
            <ol>
              <li>
                <div>Ajoutez un matériel à votre suivi</div>
              </li>
              <li>
                <div>Indiquez sa date de mise en service</div>
              </li>
              <li>
                <div>Enregistrez le matériel et ajoutez la prochaine échéance aux actions de prévention</div>
              </li>
              <li>
                <div>Le tableau récapitulatif vous liste le matériel et les échéances</div>
              </li>
              <li>
                <div>Cliquez sur le nom du matériel pour accéder aux informations détaillées</div>
              </li>
            </ol>
          </Workflow>
          <VSpacer size="64px" />
        </Container>
      </GreyContainer>
      <Summary
        isOpen={summaryModalOpened}
        onClose={() => setSummaryModalOpened(false)}
        summaryId={summaryId}
        name="Récapitulatif"
        readOnly={false}
        downloadDocument={downloadDocument}
      ></Summary>
      <DisplayMutateModals />
    </>
  );
}

const Workflow = styled.div`
  ol {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin: 0;
    padding: 0;
    position: relative;
    &:before {
      content: '';
      /* width: 100%; */
      height: 2px;
      border-bottom: 2px dashed #c1c6c8;
      left: 10%; // ! Ajuster taille du trait
      right: 10%; // ! Ajuster taille du trait
      top: 28px;
      position: absolute;
    }
  }
  li {
    list-style: none;
    counter-increment: elems;
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    &:before {
      content: counter(elems);
      display: grid;
      place-content: center;
      position: relative;
      z-index: 2;
      width: 32px;
      height: 32px;
      color: white;
      font-size: 12px;
      font-weight: bold;
      border-radius: 50%;
      background-color: ${StyleData.color.secondary};
    }
  }
  div {
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
    text-align: center;
  }

  @media all and (max-width: 960px) {
    ol {
      grid-template-columns: initial;
      grid-template-rows: repeat(5, 1fr);
      &:before {
        content: '';
        border-left: 2px dashed #c1c6c8;
        border-bottom: 0;
        height: auto;
        left: 30px;
        right: unset;
        top: 10%; // ! Ajuster taille du trait
        bottom: 10%; // ! Ajuster taille du trait
      }
    }

    li {
      flex-direction: row;
      align-items: initial;
      justify-content: flex-start;

      &:before {
        flex: 0 0 33px;
      }
    }
    div {
      text-align: left;
    }
  }
`;

const TierContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 3fr;

  @media all and (max-width: 960px) {
    grid-template-columns: auto;
  }
`;

const GreyContainer = styled.div`
  background-color: ${StyleData.color.lightGrey};
`;

export default MaterialList;
