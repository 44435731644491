import fr from 'date-fns/locale/fr';
import { ReactElement, useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFormContext } from 'react-hook-form';
import { useResponsiveContext } from '../../../contexts/ResponsiveContext';
import { CalendarWire, CloseBlack } from '../../../icons';
import { VSpacer } from '../../layout/Spacer';
import InputError from '../InputError';
import { DatePickerContainer, InputController } from './DateFieldStyle';

registerLocale('fr', fr);

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  error?: string;
  defaultValue?: any;
  required?: boolean;
  disabled?: boolean;
  tabIndex?: number;
  inline?: boolean;
}

function DateField(props: Props): ReactElement {
  const { control } = useFormContext();
  const responsive = useResponsiveContext();
  useEffect(() => {
    if (responsive.isMobile) {
      const datePickers = document.getElementsByClassName('react-datepicker__input-container');
      Array.from(datePickers).forEach((el) => (el.childNodes as any)[0].setAttribute('readOnly', true));
    }
  }, []);
  return (
    <DatePickerContainer>
      {props.label && (
        <>
          <label>{props.label}</label> <VSpacer size="6px" />
        </>
      )}
      <InputController
        error={props.error ? true : false}
        disabled={props.disabled}
        inline={props.inline ?? false}
      >
        <Controller
          control={control}
          name={props.name}
          defaultValue={props.defaultValue}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <DatePicker
                locale="fr"
                placeholderText={props.placeholder}
                onChange={onChange}
                onBlur={onBlur}
                selected={value}
                dateFormat="dd/MM/yyyy"
                required={props.required}
                disabled={props.disabled}
                tabIndex={props.tabIndex}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            );
          }}
        />
        {props.error ? <CloseBlack /> : <CalendarWire />}
      </InputController>
      <InputError error={props.error} />
    </DatePickerContainer>
  );
}

export default DateField;
