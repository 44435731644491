import styled from 'styled-components';
import { CloseBlack } from '../../../icons';
import { unwrap } from '../../../utilities/Assertions';
import StyleData from '../../../utilities/StyleData';
import { colTemplate, colTemplateNoPlannedDate } from '../material-checking-line/MaterialCheckingLineStyle';

export const Root = styled.article`
  font-size: ${StyleData.fsize.md};
`;

export const Header = styled.div<{ containsPlannedDate: boolean }>`
  display: grid;
  grid-template-columns: ${(p) => (p.containsPlannedDate ? colTemplate : colTemplateNoPlannedDate)};

  > div {
    padding-left: 15px;
  }

  @media all and (max-width: ${StyleData.breakpoints.max.smd}px) {
    display: none;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export const Events = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export const HeaderCellRoot = styled.div`
  display: flex;
  align-items: center;
  gap: ${StyleData.spacing.md};
  user-select: none;

  svg {
    width: 16px;
    height: 16px;
    padding: 2px;
    cursor: pointer;
  }
`;

export const CancelFilter = styled(CloseBlack)<{ $visible: boolean }>`
  transition-property: opacity;
  transition-timing-function: ${unwrap(StyleData.transition.timingFunction.default)};
  transition-duration: ${unwrap(StyleData.transition.duration.default)};

  ${(p) =>
    p.$visible
      ? `
    opacity: 1;
  `
      : `
    opacity: 0;
    pointer-events: none;
  `}
`;
