import { useState } from 'react';

// eslint-disable-line
export function useMutateModals( // eslint-disable-line
  DetailsModalClass: any | undefined, // eslint-disable-line
  detailsModalProps: any | undefined, // eslint-disable-line
  addModalName: string | undefined,
  updateModalName: string | undefined,
  DeleteModalClass: any | undefined, // eslint-disable-line
  deleteModalProps: any | undefined, // eslint-disable-line
  deleteCallback: (id: number) => void | undefined
) {
  const [detailsModalData, setDetailsModalData] = useState<{ opened: boolean; data: any | null }>({
    opened: false,
    data: null,
  });
  const [deleteModalData, setDeleteModalData] = useState<{ opened: boolean; data: any | null }>({
    opened: false,
    data: null,
  });

  function openDetailsPopin(rowData: any): void {
    setDetailsModalData({
      opened: true,
      data: rowData,
    });
  }
  function openDeletePopin(rowData: any): void {
    setDeleteModalData({
      opened: true,
      data: rowData,
    });
  }

  const DisplayMutateModals = () => {
    return (
      <div>
        {/* CAS SUPPRESSION */}
        {DeleteModalClass !== undefined && deleteModalData.opened && (
          <DeleteModalClass
            isOpen={deleteModalData.opened}
            onClose={() => {
              setDeleteModalData(() => {
                return { opened: false, data: null };
              });
            }}
            data={deleteModalData.data}
            deleteCallback={deleteCallback}
            {...deleteModalProps}
          />
        )}
        {/* CAS EDITION */}
        {DetailsModalClass != undefined && detailsModalData.opened && detailsModalData.data !== null && (
          <DetailsModalClass
            isOpen={detailsModalData.opened}
            onClose={() => setDetailsModalData({ opened: false, data: null })}
            name={updateModalName}
            data={detailsModalData.data}
            {...detailsModalProps}
          />
        )}
        {/* CAS AJOUT */}
        {DetailsModalClass != undefined && detailsModalData.opened && detailsModalData.data === null && (
          <DetailsModalClass
            isOpen={detailsModalData.opened}
            onClose={() => setDetailsModalData({ opened: false, data: null })}
            name={addModalName}
            // data={detailsModalData.data}
            {...(detailsModalData.data ? { data: detailsModalData.data } : {})}
            {...detailsModalProps}
          />
        )}
      </div>
    );
  };

  return { DisplayMutateModals, openDetailsPopin, openDeletePopin };
}
