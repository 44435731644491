import styled from 'styled-components';

export const VSpacer = styled.div<{ size?: string }>`
  width: 100%;
  height: ${(props) => (props.size ? props.size : '8px')};
`;

export const HSpacer = styled.div<{ size?: string }>`
  width: ${(props) => (props.size ? props.size : '8px')};
  height: 100%;
`;

export const Spacer = styled.div<{ size?: string }>`
  width: ${(props) => (props.size ? props.size : '8px')};
  height: ${(props) => (props.size ? props.size : '8px')};
`;
