import styled from 'styled-components';
import Cone from '../../assets/images/cone.png';
import InsufficientAccess from '../../assets/images/403.svg';
import { Button } from '../../components/Button';
import { VSpacer } from '../../components/layout/Spacer';
import Modal, { ModalSize } from '../../components/Modal';
import { useModalStore } from '../../store/ErrorModalStore';
import StyleData from '../../utilities/StyleData';
import Text, { Uppercase } from '../../components/Text';

const { REACT_APP_URL_API_LOGOUT } = process.env;
export interface ErrorModalProps {
  modalSize?: ModalSize;
  actionText: string;
}

export function ErrorModal(props: ErrorModalProps): JSX.Element {
  const [data, isOpen, setIsOpen] = useModalStore((state) => [state.data, state.isOpen, state.setIsOpen]);

  if (data.name === "Droits d'accès insuffisants") {
    return (
      <Modal
        isOpen={isOpen}
        name="Droits d'accès insuffisants"
        onClose={() => setIsOpen(false)}
        modalSize={props.modalSize}
      >
        <ModalContainer>
          <ImgContainer>
            <img src={InsufficientAccess} alt="403" />
          </ImgContainer>
          <Text fsize={'lg'} lineHeight={'24px'}>
            {/* Remove all html tags from error description is there is any. */}
            {data?.description?.replace(/(<([^>]+)>)/gi, '')}
          </Text>
        </ModalContainer>
        <ButtonWrapper access={true}>
          <Button
            variant="primary"
            callback={() => {
              if (data.action) {
                data.action();
              }
              setIsOpen(false);
            }}
          >
            {data.actionText}
          </Button>
        </ButtonWrapper>
        <VSpacer size="20px"></VSpacer>
        <ModalText>
          <Text fsize={'lg'} lineHeight={'24px'}>
            <Uppercase color={'red'} fontWeight={'600'}>
              Attention :
            </Uppercase>{' '}
            si vous avez déjà créé une entreprise ou modifié vos droits, afin que le système prenne en compte
            cette modification, veuillez <a href={REACT_APP_URL_API_LOGOUT}>vous déconnecter</a> puis vous
            connecter à nouveau à l'outil.
          </Text>
        </ModalText>
      </Modal>
    );
  }
  return (
    <>
      {data.subtitle && !data.name && !data.description ? (
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          modalSize={props.modalSize}
          name={'Une erreur a eu lieu'}
          subtitle={data.subtitle}
        >
          <Columns>
            <div>
              <ImgContainer>
                <img src={Cone} alt="Cone OPP" />
              </ImgContainer>
            </div>
            <div>
              <VSpacer size="20px"></VSpacer>
              <ModalText>
                <ul>
                  <li>Vérifiez votre connexion à internet</li>
                  <li>Tentez de recharger la page</li>
                  <li>Contactez le support</li>
                </ul>
              </ModalText>
            </div>
          </Columns>
          <ButtonWrapper>
            <Button
              variant="primaryRound"
              callback={() => {
                window.location.reload();
                setIsOpen(false);
              }}
            >
              Recharger la page
            </Button>
          </ButtonWrapper>
        </Modal>
      ) : (
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          modalSize={props.modalSize}
          name={data.name}
          subtitle={data.subtitle}
        >
          <>
            <Columns>
              <div>
                <ImgContainer>
                  <img src={Cone} alt="Cone OPP" />
                </ImgContainer>
              </div>
              <div>
                <VSpacer size="20px" />
                <ModalText>
                  {/* Remove all html tags from error description is there is any. */}
                  <div>{data?.description?.replace(/(<([^>]+)>)/gi, '')}</div>
                </ModalText>
              </div>
            </Columns>
            <ButtonWrapper>
              <Button
                variant="primaryRound"
                callback={() => {
                  if (data.action) {
                    data.action();
                  }
                  setIsOpen(false);
                }}
              >
                {data.actionText}
              </Button>
            </ButtonWrapper>
          </>
        </Modal>
      )}
    </>
  );
}

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
`;

const ModalText = styled.div`
  color: ${StyleData.color.text};
  font-family: ${StyleData.font.secondary};
  font-size: ${StyleData.fsize.md};
  margin: 0;
  a {
    text-decoration: underline;
    color: ${StyleData.color.text};
    font-weight: 600;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    font-size: ${StyleData.fsize.sm};
  }

  ul > li {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
  }
`;

const ImgContainer = styled.div`
  img {
    height: auto;
  }
`;

const ButtonWrapper = styled.div<{ access?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: ${(props) => (props.access ? 'fit-content' : '50%')};
    justify-content: center;
    font-size: ${StyleData.fsize.md};
  }
`;

const ModalContainer = styled.div`
  display: flex;
  gap: 16px;
`;
