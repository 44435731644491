import styled, { css } from 'styled-components';
import StyleData from '../../utilities/StyleData';

export const Cards = styled.section<{ hasCarousel?: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.hasCarousel &&
    css`
      max-width: 90vw;
    `}
`;

export const Card = styled.article<{ hasCarousel?: boolean; isDisable?: boolean }>`
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.18);
  border-radius: 3px;
  padding: 15px;
  margin-bottom: 20px;
  ${(props) =>
    props.hasCarousel &&
    css`
      max-width: 85%;
    `}
  ${(props) =>
    props.isDisable &&
    css`
      background-color: ${StyleData.color.lightGrey};
    `}
`;

export const CardRow = styled.div`
  padding: 8px 0;
  :not(:last-child) {
    border-bottom: 1px solid #eee;
  }
  :first-child {
    padding-top: unset;
  }
  :last-child {
    padding-bottom: unset;
  }
`;

export const CardRowContainer = styled.div`
  display: flex;
  /* justify-content: space-between; */
  > div {
    display: flex;
    flex-direction: column;
    padding: 5px;
    &:not(:last-child) {
      max-width: 200px;
      margin-right: 10px;
    }
    > div:first-child {
      line-height: 15px;
      font-size: ${StyleData.fsize.xxs};
      font-weight: normal;
      font-family: ${StyleData.font.secondary};
    }
    > div:last-child {
      font-weight: bold;
      font-family: ${StyleData.font.primary};
      font-size: ${StyleData.fsize.xs};
    }
  }
`;

export const CardRowGridContainer = styled(CardRowContainer)`
  display: grid;
  grid-template-columns: 50% 50%;
`;
