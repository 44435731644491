import EntityInterface from '../interfaces/Entity';
import Entity from './Entity';

export class ConformityCertificate extends Entity implements EntityInterface {
  public id!: number;
  public name!: string;

  constructor(data?: Partial<ConformityCertificate>) {
    super();
    if (data?.id) {
      this.id = data.id;
    }
    if (data?.name) {
      this.name = data.name;
    }
  }
}
