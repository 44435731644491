export enum DatalayerEventLabel {
  DEFAULT = '',
  CREATE_REMINDER = 'Créer un rappel',
  DELETE_REMINDER = 'Supprimer le rappel',
  VERIFICATION_PERIODIQUE = 'Compléter une Vérification Périodique',
  AUTRE_EXAMEN = 'Compléter un autre examen',
  ENTRETIEN = 'Compléter un entretien',
  ADD_MATERIAL = 'Ajouter le matériel',
}

export interface Datalayer {
  eventLabel: DatalayerEventLabel;
  [key: string]: any;
}
