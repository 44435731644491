import { createContext, useContext } from 'react';
import EventType from '../models/EventType';

export type EventTypesContextType = { eventTypes: EventType[] } | undefined;

/**
 * Provide a context allowing to access the employees.
 */
export const EventTypesContext = createContext<EventTypesContextType>(undefined);

export function useEventTypesContext(): EventTypesContextType {
  return useContext(EventTypesContext);
}
