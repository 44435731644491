import { ReactElement } from 'react';
import styled from 'styled-components';
import StyleData from '../utilities/StyleData';

type Props = {
  children?: React.ReactNode;
  widthContainer?: string;
  fontWeight?:
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900'
    | 'lighter'
    | 'normal'
    | 'bold'
    | 'bolder';
  color?: string;
  fsize?: 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'ml' | 'lg' | 'xl' | 'xxl' | 'xxxl';
  fsizeMobile?: 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'ml' | 'lg' | 'xl' | 'xxl' | 'xxxl';
  font?: 'primary' | 'secondary';
  textAlign?: 'left' | 'right' | 'center';
  inline?: boolean;
  lineHeight?: string;
};

function Text(props: Props): ReactElement {
  return (
    <StyledText
      className="Text"
      widthContainer={props.widthContainer}
      color={props.color}
      fontWeight={props.fontWeight}
      fsize={props.fsize}
      fsizeMobile={props.fsizeMobile}
      font={props.font}
      textAlign={props.textAlign}
      inline={props.inline}
      lineHeight={props.lineHeight}
    >
      {props.children}
    </StyledText>
  );
}

type StyleProps = {
  widthContainer?: string;
  fontWeight?:
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900'
    | 'lighter'
    | 'normal'
    | 'bold'
    | 'bolder';
  color?: string;
  fsize?: 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'ml' | 'lg' | 'xl' | 'xxl' | 'xxxl';
  fsizeMobile?: 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'ml' | 'lg' | 'xl' | 'xxl' | 'xxxl';
  font?: 'primary' | 'secondary';
  textAlign?: 'left' | 'right' | 'center';
  inline?: boolean;
  lineHeight?: string;
};
const StyledText = styled.div<StyleProps>`
  font-synthesis: none;
  margin: 0;
  color: ${(props) => (props.color ? props.color : StyleData.color.text)};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  font-family: ${(props) => (props.font === 'primary' ? StyleData.font.primary : StyleData.font.secondary)};
  display: ${(props) => (props.inline ? 'inline' : 'block')};
  font-size: ${(props) => {
    switch (props.fsize) {
      case 'xxxs':
        return StyleData.fsize.xxxs;
        break;
      case 'xxs':
        return StyleData.fsize.xxs;
        break;
      case 'xs':
        return StyleData.fsize.xs;
        break;
      case 'sm':
        return StyleData.fsize.sm;
        break;
      case 'md':
        return StyleData.fsize.md;
        break;
      case 'ml':
        return StyleData.fsize.ml;
        break;
      case 'lg':
        return StyleData.fsize.lg;
        break;
      case 'xl':
        return StyleData.fsize.xl;
        break;
      case 'xxl':
        return StyleData.fsize.xxl;
        break;
      case 'xxxl':
        return StyleData.fsize.xxxl;
        break;
      default:
        return StyleData.fsize.md;
        break;
    }
  }};
  width: ${(props) => props.widthContainer && props.widthContainer};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : 'normal')};
  a {
    text-decoration: underline;
    color: ${StyleData.color.text};
    font-weight: 600;
  }
  .tooltip {
    max-width: 700px;
    white-space: pre-line;
    word-break: break-word;
  }
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    font-size: ${(props) => {
      switch (props.fsizeMobile) {
        case 'xxxs':
          return StyleData.fsize.xxxs;
          break;
        case 'xxs':
          return StyleData.fsize.xxs;
          break;
        case 'xs':
          return StyleData.fsize.xs;
          break;
        case 'sm':
          return StyleData.fsize.sm;
          break;
        case 'md':
          return StyleData.fsize.md;
          break;
        case 'ml':
          return StyleData.fsize.ml;
          break;
        case 'lg':
          return StyleData.fsize.lg;
          break;
        case 'xl':
          return StyleData.fsize.xl;
          break;
        case 'xxl':
          return StyleData.fsize.xxl;
          break;
        case 'xxxl':
          return StyleData.fsize.xxxl;
          break;
        default:
          return StyleData.fsize.sm;
          break;
      }
    }};
  }
`;

export default Text;

export const Ellipsis = styled.div<{ maxWidth?: string }>`
  text-overflow: ellipsis;
  max-width: ${(props) => {
    return props.maxWidth ? props.maxWidth : '200px';
  }};
  overflow: hidden;
  white-space: nowrap;
`;

export const Uppercase = styled.span<StyleProps>`
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : StyleData.color.text)};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
`;
