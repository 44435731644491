import Periodicity from '../../models/Periodicity';
import HttpAbstract from './AbstractHttp';

const ROUTE = process.env.REACT_APP_URL_API_PERIODICITY;

export default class PeriodicityHttp extends HttpAbstract<Periodicity> {
  constructor() {
    super(`${ROUTE}`, Periodicity);
  }
}
