import MaterialFamily from '../../models/MaterialFamily';
import HttpAbstract from './AbstractHttp';

const ROUTE = process.env.REACT_APP_URL_API_MATERIAL_FAMILY;

export default class MaterialFamilyHttp extends HttpAbstract<MaterialFamily> {
  constructor() {
    super(`${ROUTE}`, MaterialFamily);
  }
}
