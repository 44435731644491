import useViewport from './UseViewport';

type MediaQueryData = (breakpoint: number, check: 'up' | 'down') => boolean;

const useMediaQuery: MediaQueryData = (breakpoint, check) => {
  const { width } = useViewport();

  return check === 'up' ? width > breakpoint : width < breakpoint;
};

export default useMediaQuery;
