import EventResult from '../../models/EventResult';
import HttpAbstract from './AbstractHttp';

const ROUTE = process.env.REACT_APP_URL_API_EVENT_RESULT;

export default class EventResultHttp extends HttpAbstract<EventResult> {
  constructor() {
    super(`${ROUTE}`, EventResult);
  }
}
