import create from 'zustand';

type State = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  data: { name: string; subtitle?: string; description?: string; action?: () => void; actionText?: string };
  setData: (data: {
    name: string;
    subtitle?: string;
    description?: string;
    action?: () => void;
    actionText?: string;
  }) => void;
};

export const useModalStore = create<State>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen: boolean) =>
    set(() => ({
      isOpen: isOpen,
    })),
  toggleIsOpen: () =>
    set((state) => ({
      isOpen: !state.isOpen,
    })),
  data: { name: '' },
  setData: (data: {
    name: string;
    subtitle?: string;
    description?: string;
    action?: () => void;
    actionText?: string;
  }) =>
    set(() => ({
      data: data,
    })),
}));
