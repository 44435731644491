import { AxiosResponse } from 'axios';
import http from '../../ApiHttp';

const ROUTE = process.env.REACT_APP_URL_API_MATERIAL_CHECKINGS;

export default class MaterialCheckingHttp {
  public static async postDocument(checkingId: number, file?: File): Promise<AxiosResponse> {
    const formData = new FormData();

    if (file) {
      formData.append('documentFile', file);
    } else {
      formData.append('delete', 'true');
    }

    return await http.post(`${ROUTE}/${checkingId}`, formData);
  }
}
