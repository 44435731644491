import styled from 'styled-components';
import StyleData from '../../utilities/StyleData';

export const HeaderFixed = styled.div`
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1000;
  background-color: ${StyleData.color.base};
  box-shadow: 0 8px 12px 0 rgb(0 0 0 / 18%);
  max-width: 100vw;
  top: 0;
  width: 100%;
  font-family: ${StyleData.font.secondary};
  line-height: 1.5;
  font-weight: 500;
  @media screen and (max-width: ${StyleData.breakpoints.max.lg}px) {
    padding-top: 4px;
  }
`;
export const HeaderContainer = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;
export const HeaderContainerItems = styled.div`
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
  margin: 0;
  padding: 0;
  .header-container-items {
    padding-bottom: 15px;
    padding-top: 15px;
    transition: all 0.5s;
    justify-content: space-between;
    display: flex;
    position: relative;
    @media screen and (max-width: ${StyleData.breakpoints.max.lg}px) {
      flex-direction: column;
      padding: 7px 0 0;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
    }
  }
`;
export const LogoContainer = styled.nav`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 205px;
  height: 24px;
  z-index: 2;
  @media screen and (max-width: ${StyleData.breakpoints.max.lg}px) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    margin: 0 15px;
    position: relative;
    width: auto;
    height: auto;
  }
`;
export const LogoMobileContainer = styled.div`
  display: none;
  @media screen and (max-width: ${StyleData.breakpoints.max.lg}px) {
    display: flex;
    margin-top: 7px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    position: relative;
    width: auto;
    height: auto;
  }
`;
export const LogoLinkMobile = styled.a`
  @media screen and (max-width: ${StyleData.breakpoints.max.lg}px) {
    :first-of-type {
      margin-right: 14px;
    }
    font-size: 18px;
    align-items: center;
    display: flex;
    vertical-align: middle;
  }
`;
export const Logo = styled.img`
  width: 205px;
  height: 24px;
  max-width: unset;
  align-self: center;
  @media screen and (max-width: ${StyleData.breakpoints.max.lg}px) {
    width: 154px;
    height: auto;
  }
`;
export const LogoLink = styled.a`
  flex-direction: column;
  position: relative;
  display: flex;
  font-weight: 400;
  color: ${StyleData.color.text};
  text-decoration: none;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  @media screen and (max-width: ${StyleData.breakpoints.max.lg}px) {
    line-height: 1.38;
    margin-bottom: 3px;
    align-self: center;
  }
`;
export const ElementContainer = styled.div`
  background-color: ${StyleData.color.base};
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  overflow: visible;
  padding-bottom: 2px;
  @media screen and (max-width: ${StyleData.breakpoints.max.lg}px) {
    background-color: ${StyleData.color.lightGrey};
    margin-top: 6px;
  }
`;
export const ElementContainerItems = styled.nav`
  position: relative;
  align-self: center;
  @media screen and (max-width: ${StyleData.breakpoints.max.smd}px) {
    width: 100%;
  }
  @media screen and (min-width: ${StyleData.breakpoints.min.mmd}px) and (max-width: ${StyleData.breakpoints
      .max.lg}px) {
    margin: auto;
  }
`;
export const ElementContainerItemsNavigation = styled.div`
  max-width: 850px;
  margin-left: auto;
  transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  will-change: transform;
  white-space: nowrap;
  @media screen and (max-width: ${StyleData.breakpoints.max.smd}px) {
    background-color: ${StyleData.color.lightGrey} !important;
    padding: 6px 0;
    overflow-x: auto;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
`;
export const HeaderButton = styled.a`
  background: transparent;
  text-decoration: none;
  border: 2px solid ${StyleData.color.secondary};
  border-radius: 30px;
  padding: 7px 12px;
  color: ${StyleData.color.secondary};
  margin-right: 0;
  margin-left: 10px;
  line-height: normal;
  font-size: 14px;
  :hover {
    background: ${StyleData.color.secondary};
    color: ${StyleData.color.base};
    cursor: pointer;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.lg}px) {
    display: none;
  }
`;

export const SpaceButton = styled.a`
  background: ${StyleData.color.primary};
  border-radius: 30px;
  padding: 7px 10px;
  color: ${StyleData.color.base} !important;
  margin-left: 10px;
  font-size: 14px;
  position: relative;
  border: 2px solid ${StyleData.color.primary};
  line-height: normal;
  font-size: 14px;
  text-decoration: none;
  .arrow-down {
    border-color: ${StyleData.color.base};
    margin-right: 0;
    height: 6px;
    width: 6px;
    position: relative;
    display: inline-block;
    border-bottom: 2px solid ${StyleData.color.base};
    border-right: 2px solid ${StyleData.color.base};
    border-left: none;
    border-top: none;
    transform: rotate(45deg);
    margin-bottom: 2px;
    bottom: 1px;
  }
  .text {
    position: relative;
    bottom: 1px;
    margin-right: 5px;
  }
`;

const enabledDropdownCss = `
.dropdown-pointer-container {
  position: relative;
  width: fit-content;
  height: fit-content;
  margin: auto;
}
.dropdown-pointer {
  position: absolute;
  transform: rotate(45deg);
  width: 20px;
  height: 20px;
  top: -31px;
  left: 45px;
  background-color: ${StyleData.color.lightGrey};
  z-index: 0;
  box-sizing: border-box;
  border-color: transparent transparent ${StyleData.color.lightGrey} ${StyleData.color.lightGrey} !important;
  border-style: solid !important;
  border-width: 10px !important;
  transform-origin: 0 0;
  box-shadow: -3px -3px 3px 0 rgb(0 0 0 / 10%);
}
.header-dropdown {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.3s linear;
}
`;

export const SpaceArea = styled.div`
  position: absolute;
  width: 140px;
  height: 14px;
  top: 100%;
  z-index: 203;
  .header-dropdown {
    visibility: hidden;
    position: absolute;
    z-index: 200;
    display: block;
    visibility: hidden;
    opacity: 0;
    width: auto;
    background-color: ${StyleData.color.lightGrey};
    border-top: none;
    padding: 20px 30px;
    width: 195px;
    top: 14px;
    left: auto;
    right: -2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.28) !important;
    font-size: 11px;
    transition: visibility 0s, opacity 0.3s linear;
  }
  :hover {
    ${enabledDropdownCss}
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.lg}px) {
    display: none;
  }
`;
export const SpaceContainer = styled.div`
  display: flex;
  @media screen and (max-width: ${StyleData.breakpoints.max.lg}px) {
    display: none;
  }

  .SpaceButton:hover + .SpaceArea {
    ${enabledDropdownCss}
  }
`;
export const HeaderDropdownContainer = styled.ul`
  list-style-type: none;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 0;
  li {
    padding: 5px 0;
  }
`;
export const HeaderLinkStyle = styled.a<{ tabletOnly?: boolean }>`
  ${(props) => {
    if (props.tabletOnly) {
      return `@media screen and (min-width: ${StyleData.breakpoints.min.xl}px) {
          display: none;
      }`;
    }
  }}
  display: flex;
  align-items: center;
  margin: 0 7px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  vertical-align: middle;
  text-decoration: none;
  color: ${StyleData.color.text};
  padding: 3px 0;
  border-bottom: 2px solid transparent;
  :hover {
    color: ${StyleData.color.primary};
    svg {
      fill: ${StyleData.color.primary};
      g {
        fill: ${StyleData.color.primary};
      }
      path {
        color: ${StyleData.color.primary};
      }
    }
    span {
      text-decoration: underline;
    }
  }
  svg {
    margin-right: 5px;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.smd}px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 10px;
    margin: 0;
    flex-grow: 1;
  }
  .desktop-text {
    @media screen and (max-width: ${StyleData.breakpoints.max.smd}px) {
      display: none;
    }
  }
  .mobile-tablet-text {
    @media screen and (min-width: ${StyleData.breakpoints.min.mmd}px) {
      display: none;
    }
  }
`;

export const HeaderLinkDropdownStyle = styled.a`
  font-size: 14px;
  display: inline-flex;
  flex-direction: row;
  align-content: center;
  text-decoration: none;
  color: ${StyleData.color.text};
  font-weight: normal;
  span {
    font-size: 14px;
    margin-left: 10px;
  }
  :hover {
    span {
      color: ${StyleData.color.primary};
    }
  }
`;
