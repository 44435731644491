import EventFamily from '../../models/EventFamily';
import HttpAbstract from './AbstractHttp';

const ROUTE = process.env.REACT_APP_URL_API_EVENT_FAMILY;

export default class EventFamilyHttp extends HttpAbstract<EventFamily> {
  constructor() {
    super(`${ROUTE}`, EventFamily);
  }
}
