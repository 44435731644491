import styled from 'styled-components';
import StyleData from '../../utilities/StyleData';

export const FieldWithCaptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${StyleData.spacing.sm};
`;

export const FieldCaption = styled.div`
  display: flex;
  gap: ${StyleData.spacing.sm};

  svg {
    min-width: 18px;
  }
`;
