import React from 'react';
import styled from 'styled-components';
import StyleData from '../utilities/StyleData';
import Title from './Title';

interface Props {
  children?: JSX.Element;
  title: string;
  subtitle?: string;
  description: string;
  image?: string;
  svg?: React.ElementType;
}

function MessagePage(props: Props): JSX.Element {
  const Icon = props.svg || React.Fragment;

  return (
    <FullWidthContainer>
      <MessagePageContainer>
        {props.image && !props.svg && <img src={props.image} alt="illustration" />}
        {props.svg && !props.image && <Icon width="145px" height="145px" />}
        <div className="text-wrapper">
          <div className="title-wrapper">
            <Title name={props.title} level={1} inline={false} />
            {props.subtitle && <Title name={props.subtitle} level={1} inline={false} />}
          </div>
          <p className="message-page-description">{props.description}</p>
        </div>
        {props.children && <div className="message-page-buttons">{props.children}</div>}
      </MessagePageContainer>
    </FullWidthContainer>
  );
}
const FullWidthContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const MessagePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  img {
    border-radius: 100%;
    width: 180px;
    height: 180px;
    margin-bottom: 10px;
  }
  svg {
    height: auto;
    border-radius: 50%;
  }
  h1 {
    justify-content: center;
    text-align: center;
  }
  .message-page-description {
    color: ${StyleData.color.text};
    font-family: ${StyleData.font.secondary};
    font-size: ${StyleData.fsize.md};
    line-height: 21px;
    text-align: center;
    margin: 0 0 11px 0;
  }
  .message-page-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 10px;
    margin-top: 15px;
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-wrapper {
      margin: 14px 0;
    }
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    width: 100%;
    .message-page-description {
      font-size: ${StyleData.fsize.sm};
      line-height: 18px;
    }
  }
`;

export default MessagePage;
