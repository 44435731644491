import dayjs from 'dayjs';
import EntityInterface from '../interfaces/Entity';
import MaterialSheetInterface from '../interfaces/MaterialSheet';
import { ConformityCertificate } from './ConfirmityCertificate';
import { Employee } from './Employee';
import Group from './Group';
import MaterialChecking from './MaterialChecking';
import MaterialFamily from './MaterialFamily';
import MaterialType from './MaterialType';

export enum MaterialSheetStatus {
  DatePassed,
  Scrapped,
  None,
}

export default class MaterialSheet implements EntityInterface, MaterialSheetInterface {
  public remoteEmployees?: Employee[] = [];
  public remoteEmployeeIds?: Employee['id'][];
  public id!: number;

  public brand!: string;
  public model!: string;
  public comment!: string;
  public matriculation!: string;

  public picture!: File;
  public pictureUri!: string | null;

  public dateLastCheck!: Date;
  public dateNextVerification!: Date;
  public plannedDate!: Date;
  public updatedAt!: Date | string;

  public dateCommissioning?: Date | string; // mise en service

  public isScrapped!: boolean;

  public materialType!: MaterialType;
  public materialFamily!: MaterialFamily;
  public conformityCertificate!: ConformityCertificate;

  public group!: Group;

  public materialCheckings!: MaterialChecking[];

  public lastPlannedMaterialChecking!: MaterialChecking;

  /**
   * Necessary in component view
   */
  public name!: string;
  public checked? = false;
  public status!: MaterialSheetStatus;

  toApi(): Record<string, unknown> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = { ...this };

    delete data.id;
    delete data['@context'];
    delete data['@id'];
    delete data['@type'];

    data.materialCheckings = this.materialCheckings.map((mc) => mc.toApi()).filter((mc) => mc !== undefined);
    data.materialType = `/api/material-types/${this.materialType?.id}`;
    data.materialFamily = `/api/material-families/${this.materialFamily?.id}`;
    data.conformityCertificate = `/api/conformity-certificates/${this.conformityCertificate?.id}`; // ? Should be refactored like MaterialType?

    data.remoteEmployeeIds = (this.remoteEmployees as Employee[])?.map((u) => u.id);
    data.group = this.group['@id'] ?? `api/groups/${this.group.id}`;

    return data;
  }

  constructor(data?: Partial<MaterialSheetInterface>) {
    Object.assign(this, data);

    if (data) {
      /**
       * Set name as type name for sorting in list view.
       */
      if (data.materialType?.name) {
        this.name = data.materialType?.name;
      }

      if (data.lastCheckDate) {
        this.dateLastCheck = new Date(data.lastCheckDate);
      }

      if (data.dateNextVerification) {
        this.dateNextVerification = new Date(data.dateNextVerification);
      }

      if (data.updatedAt) {
        this.updatedAt = new Date(data.updatedAt);
      }

      if (data.dateCommissioning) {
        this.dateCommissioning = dayjs(data.dateCommissioning).toDate();
      }

      this.materialCheckings = (data.materialCheckings || []).map((mc) => new MaterialChecking(mc));

      this.remoteEmployees = (data.remoteEmployees || []).map((user) => new Employee(user));

      if (this.isScrapped) {
        this.status = MaterialSheetStatus.Scrapped;
      } else if (this.dateLastCheck && this.dateLastCheck.getTime() < new Date().getTime()) {
        this.status = MaterialSheetStatus.DatePassed;
      } else {
        this.status = MaterialSheetStatus.None;
      }
    }
  }
}
