const StyleData: StyleDataType = {
  color: {
    primary: '#EF3340', // Red
    secondary: '#44499C', // Dark Purple-blue
    warning: '#FFBF3F', // Yellow
    success: '#ADD233', // Light Green
    text: '#000', // Full Black
    grey: '#53565A', // Topbar Grey
    lightText: '#92999C', // Middle Grey
    lightGrey: '#F7F7F8', // lLight Grey
    smoothGrey: '#C1C6C8',
    disabledText: '#C1C6C8', // same as smoothGrey
    lightBlue: '#4ec3e0',
    linkBlue: 'rgb(78, 195, 224)',
    base: '#FFFFFF',
  },
  font: {
    primary: 'Morandi, sans-serif',
    secondary: "'Work Sans',Helvetica,sans-serif",
  },
  fsize: {
    xxxs: '9px',
    xxs: '10px',
    xs: '11px',
    sm: '12px',
    md: '14px',
    ml: '15px',
    lg: '16px',
    xl: '18px',
    xxl: '22px',
    xxxl: '32px',
  },
  breakpoints: {
    min: {
      sm: 0,
      md: 601,
      mmd: 768,
      lg: 961,
      xl: 1024,
    },
    max: {
      sm: 600,
      smd: 767,
      md: 960,
      lg: 1023,
      llg: 1180,
      xl: 1920,
    },
  },
  spacing: {
    none: '0',
    xxs: '0.0625rem', //1px
    xs: '0.125rem', // 2px
    sm: '0.25rem', // 4px
    md: '0.5rem', //8px
    lg: '1rem', // 16px
    xl: '2rem', //32px
    xxl: '4rem', //64px
    xxxl: '5rem', //80px
    '4xl': '6rem', //80px
    '5xl': '7rem', //80px
    '6xl': '8rem', //80px
  },
  borderRadius: {
    xs: '0.125rem', // 2px
    sm: '0.1875rem', // 3px
    md: '0.25rem', // 4px
    lg: '0.5rem', // 8px
    xl: '0.75rem', // 12px
  },
  transition: {
    duration: {
      short: '150ms',
      default: '150ms',
    },
    timingFunction: {
      short: 'cubic-bezier(0.4, 0, 0.2, 1)',
      default: 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
  },
};

type StyleDataType = {
  color: Record<string, string>;
  font: Record<string, string>;
  fsize: Record<string, string>;
  breakpoints: Record<string, Record<string, number>>;
  spacing: Record<string, string>;
  borderRadius: Record<string, string>;
  transition: {
    duration: Record<TransitionKey, string>;
    timingFunction: Record<TransitionKey, string>;
  };
};

type TransitionKey = 'short' | 'default';

export default StyleData;
