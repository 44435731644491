import styled from 'styled-components';
import StyleData from '../../../utilities/StyleData';

type StypeProps = {
  error: boolean;
  disabled?: boolean;
  inline: boolean;
};

export const DatePickerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${StyleData.color.text};
  font-family: ${StyleData.font.secondary};
  font-size: ${StyleData.fsize.md};
  label {
    line-height: 21px;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    label {
      font-size: ${StyleData.fsize.sm};
      line-height: 18px;
    }
  }
`;

export const InputController = styled.div<StypeProps>`
  ${(props) => {
    if (!props.inline) {
      return `
    border: 1px solid
      ${props.error ? StyleData.color.primary : '#92999c'};
    border-radius: 3px;
    padding: 10px 0 10px 14px;
    background-color: ${props.disabled ? '#f5f5f5' : StyleData.color.base};
    color: ${props.disabled ? StyleData.color.lightText : StyleData.color.text};
    `;
    }
  }}
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 36px;

  .react-datepicker-wrapper {
    width: 100%;
  }
  input {
    width: 100%;
    margin: 0;
    font-size: ${StyleData.fsize.md};
    line-height: 16px;
    font-weight: 600;
    border: none;
    padding: none;
    background: none;
    ${(props) => (props.inline ? 'text-decoration: underline;' : '')}
    ${(props) => (props.inline ? 'font-family: ' + StyleData.font.primary + ';' : '')}
    &:focus {
      outline: none;
    }
    ::placeholder {
      font-weight: 400;
      color: ${StyleData.color.lightText};
    }
  }

  svg {
    margin-right: 5px;
    margin-left: ${(props) => (props.inline ? '7px' : '15.25px')};
    ${(props) => {
      return props.error
        ? `
    border-radius: 50%;
    background-color: ${StyleData.color.primary};
    .st0-fill {
      fill: white;
    }`
        : '';
    }}

    width: ${(props) => {
      return props.error ? '24.3px' : '16px';
    }};
    height: auto;
  }
`;
