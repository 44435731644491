import React, { FunctionComponent } from 'react';
import { Link as RawLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import StyleData from '../utilities/StyleData';

type Props = {
  href: string;
  callback?: ((e: any) => unknown) | (() => unknown);
  icon?: React.ElementType;
  name: string;
  description: string;
  variant: 'error' | 'active' | 'inactive';
};

export const NavigationButton: FunctionComponent<Props> = (props) => {
  const Icon = props.icon || React.Fragment;
  return (
    <Link to={props.href} onClick={props.callback} variant={props.variant}>
      <LinkContainer>
        {props.icon && (
          <IconContainer variant={props.variant}>
            <Icon width={'18px'} height={'18px'} />
          </IconContainer>
        )}
        <TextContainer>
          <NavButtonName variant={props.variant}>{props.name}</NavButtonName>
          <NavButtonDesc>{props.description}</NavButtonDesc>
        </TextContainer>
      </LinkContainer>
    </Link>
  );
};

const Link = styled(RawLink)`
  display: flex;
  text-decoration: none;
  color: #000000;
  pointer-events: ${(props) => (props.variant === 'active' ? 'none' : 'auto')};
  ${({ variant }: StyleProps) =>
    variant &&
    variantOptions[variant] &&
    css`
      color: ${variantOptions[variant].color};
    `};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const IconContainer = styled.div<{ variant: 'error' | 'active' | 'inactive' }>`
  position: relative;
  top: 3px;
  margin-right: 6px;
  @media screen and (max-width: ${StyleData.breakpoints.max.sm}px) {
    display: none;
  }
  ${(props) =>
    props.variant === 'error' &&
    css`
      svg {
        background-color: ${StyleData.color.primary};
        border-radius: 50%;
        margin: auto;
        height: 22px;
        width: auto;
        g {
          fill: white;
        }
      }
    `}
  ${(props) =>
    props.variant === 'inactive' &&
    css`
      svg {
        path {
          fill: ${StyleData.color.lightText};
        }
      }
    `}
`;

const NavButtonName = styled.span<{ variant: 'error' | 'active' | 'inactive' }>`
  font-family: ${StyleData.font.secondary};
  font-size: ${StyleData.fsize.lg};
  font-weight: ${(props) => (props.variant === 'inactive' ? 'normal' : '600')};
  @media screen and (max-width: ${StyleData.breakpoints.max.sm}px) {
    font-size: ${StyleData.fsize.lg};
    white-space: nowrap;
  }
`;

const NavButtonDesc = styled.span`
  font-family: ${StyleData.font.secondary};
  font-size: ${StyleData.fsize.xs};
  @media screen and (max-width: ${StyleData.breakpoints.max.sm}px) {
    font-size: ${StyleData.fsize.xxs};
  }
`;

/**
 * Custom variants. Each must implement all style rules.
 *
 * Ex: <Button variant="primary" as="li"></Button>
 */
const variantOptions: Record<string, Record<string, string>> = {
  active: {
    color: StyleData.color.text,
  },
  inactive: {
    color: StyleData.color.lightText,
  },
  error: {
    color: StyleData.color.primary,
  },
};

type StyleProps = {
  variant: 'error' | 'active' | 'inactive';
};

export default NavigationButton;
