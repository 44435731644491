import { useContext } from 'react';
import { ResponsiveContext } from '../contexts/ResponsiveContext';
import { UploadWhite } from '../icons';
import { notify } from '../utilities/Notify';
import { Button } from './Button';

interface ExportButtonProps {
  label: string;
  exportFunction: () => void;
  disabled?: boolean;
}

export function ExportButton(props: ExportButtonProps): JSX.Element {
  const responsive = useContext(ResponsiveContext);

  return (
    <Button
      variant={!responsive.isMobile ? 'secondary' : 'secondaryRound'}
      callback={() => {
        notify();
        props.exportFunction();
      }}
      widthSize="16px"
      heightSize="16px"
      icon={UploadWhite}
      disabled={props.disabled}
    >
      {!responsive.isMobile ? props.label : ''}
    </Button>
  );
}
