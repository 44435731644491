import styled from 'styled-components';

export const Todo = styled.div`
  background: bisque;
  font-family: monospace;
  font-size: 14px;

  ::before {
    font-weight: bold;
    color: #e74c3c;
    content: 'TODO ';
  }
`;

export const VerticalCenterContainer = styled.div`
  display: flex;
  align-items: center;
`;
