export const XLSX_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const PDF_TYPE = 'application/pdf';
export const DOCX_TYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const CSV_TYPE = 'text/csv';

export function downloadFile(
  data: any,
  filename: string,
  filetype: string,
  passRawFiletype: boolean = false
): void {
  let type = '';
  if (!passRawFiletype) {
    type = eval(filetype.toLocaleUpperCase() + '_TYPE');
  } else {
    type = filetype;
  }
  const blob = window.URL.createObjectURL(
    new Blob([data], {
      type,
    })
  );
  const link = document.createElement('a');
  link.href = blob;
  link.download = filename;
  link.click();
  link.remove();
}

export function downloadFileByURL(path: string): void {
  //eslint-disable-line
  const link = document.createElement('a');
  link.href = path;
  link.download = path.substring(path.lastIndexOf('/') + 1);
  link.target = '_blank';
  link.click();
  link.remove();
}
