import React, { useContext } from 'react';
import logo from '../../assets/images/logo-small.png';
import Text from '../../components/Text';
import { useAuthContext } from '../../contexts/AuthContext';
import { ResponsiveContext } from '../../contexts/ResponsiveContext';
import {
  ArrowBackLine,
  Avatar,
  Casque,
  Catalogue,
  ChatBlack,
  DashboardBlack,
  Email,
  Favoris,
  Formation,
  News,
  Plan,
  Solution,
} from '../../icons';
import {
  ElementContainer,
  ElementContainerItems,
  ElementContainerItemsNavigation,
  HeaderButton,
  HeaderContainer,
  HeaderContainerItems,
  HeaderDropdownContainer,
  HeaderFixed,
  HeaderLinkDropdownStyle,
  HeaderLinkStyle,
  Logo,
  LogoContainer,
  LogoLink,
  LogoLinkMobile,
  LogoMobileContainer,
  SpaceArea,
  SpaceButton,
  SpaceContainer,
} from './HeaderStyle';

const {
  REACT_APP_URL_PREVENTIONBTP_PREFIXE,
  REACT_APP_URL_API_LOGOUT,
  REACT_APP_URL_EXT_PREVENTION_RESSOURCES,
  REACT_APP_URL_EXT_PREVENTION_ACTUALITE,
  REACT_APP_URL_EXT_PREVENTION_CHANTIER,
  REACT_APP_URL_EXT_PREVENTION_ENTREPRISE,
  REACT_APP_URL_EXT_PREVENTION_FORMATION,
  REACT_APP_URL_EXT_PREVENTION_SERVICE,
  REACT_APP_URL_EXT_PREVENTION_ABONNEMENTS,
  REACT_APP_URL_EXT_PREVENTION_FAVORIS,
  REACT_APP_URL_EXT_PREVENTION_SOLUTIONS,
  REACT_APP_URL_EXT_PREVENTION_DEMANDES,
  REACT_APP_URL_EXT_PREVENTION_MON_PROFIL,
} = process.env;

interface HeaderElementProps {
  text: string;
  mobileText?: string;
  icon: any;
  link?: string;
  size?: string;
  tabletOnly?: boolean;
}

export default function Header(): JSX.Element {
  const { authenticated } = useAuthContext();
  const responsive = useContext(ResponsiveContext);
  return (
    <header className="header">
      <HeaderFixed>
        <HeaderContainer>
          <HeaderContainerItems>
            <div className={'header-container-items'}>
              <LogoContainer>
                <LogoLink href={REACT_APP_URL_PREVENTIONBTP_PREFIXE}>
                  <Logo src={logo} alt={'logo'} />
                  {!responsive.isMobile && <Text fsize={'xs'}>Un service OPPBTP</Text>}
                </LogoLink>
                <LogoMobileContainer>
                  <LogoLinkMobile href={REACT_APP_URL_EXT_PREVENTION_RESSOURCES}>
                    <Catalogue width={'24px'} height={'24px'} />
                  </LogoLinkMobile>
                  <LogoLinkMobile href={REACT_APP_URL_API_LOGOUT}>
                    <ArrowBackLine width={'21px'} height={'24px'} />
                  </LogoLinkMobile>
                </LogoMobileContainer>
              </LogoContainer>
              <ElementContainer>
                <ElementContainerItems>
                  <ElementContainerItemsNavigation>
                    <HeaderLink
                      text={'Sur les chantiers'}
                      mobileText={'Chantiers'}
                      icon={Casque}
                      link={REACT_APP_URL_EXT_PREVENTION_CHANTIER}
                      size={responsive.isMobile ? '22px' : '20px'}
                    />
                    <HeaderLink
                      text={"Dans l'entreprise"}
                      mobileText={'Entreprise'}
                      icon={Plan}
                      link={REACT_APP_URL_EXT_PREVENTION_ENTREPRISE}
                      size={responsive.isMobile ? '22px' : '20px'}
                    />
                    <HeaderLink
                      text={'Formation'}
                      mobileText={'Formation'}
                      icon={Formation}
                      link={REACT_APP_URL_EXT_PREVENTION_FORMATION}
                      size={responsive.isMobile ? '22px' : '20px'}
                    />
                    <HeaderLink
                      text={'Actualités'}
                      mobileText={'Actualités'}
                      icon={News}
                      link={REACT_APP_URL_EXT_PREVENTION_ACTUALITE}
                      size={responsive.isMobile ? '22px' : '20px'}
                    />
                    <HeaderLink
                      text={'Mon espace'}
                      mobileText={'Mon espace'}
                      icon={DashboardBlack}
                      link={REACT_APP_URL_EXT_PREVENTION_SERVICE}
                      size={'22px'}
                      tabletOnly={true}
                    />
                    <HeaderButton href={REACT_APP_URL_EXT_PREVENTION_RESSOURCES}>Ressources</HeaderButton>
                    <SpaceContainer>
                      <SpaceButton className="SpaceButton" href={REACT_APP_URL_EXT_PREVENTION_SERVICE}>
                        <span className={'text'}>Mon espace</span>
                        <span className={'arrow-down'} />
                      </SpaceButton>
                      <SpaceArea className="SpaceArea">
                        <div className={'header-dropdown'}>
                          <div className="dropdown-pointer-container">
                            <div className="dropdown-pointer" />
                          </div>
                          <HeaderDropdownContainer>
                            <HeaderLinkDropdown
                              text={'Mon espace'}
                              icon={DashboardBlack}
                              link={REACT_APP_URL_EXT_PREVENTION_SERVICE}
                            />
                            <HeaderLinkDropdown
                              text={'Mes abonnements'}
                              icon={Email}
                              link={REACT_APP_URL_EXT_PREVENTION_ABONNEMENTS}
                            />
                            <HeaderLinkDropdown
                              text={'Mes favoris'}
                              icon={Favoris}
                              link={REACT_APP_URL_EXT_PREVENTION_FAVORIS}
                            />
                            <HeaderLinkDropdown
                              text={'Mes solutions'}
                              icon={Solution}
                              link={REACT_APP_URL_EXT_PREVENTION_SOLUTIONS}
                            />
                            <HeaderLinkDropdown
                              text={'Mes demandes'}
                              icon={ChatBlack}
                              link={REACT_APP_URL_EXT_PREVENTION_DEMANDES}
                            />
                            <HeaderLinkDropdown
                              text={'Mon profil'}
                              icon={Avatar}
                              link={REACT_APP_URL_EXT_PREVENTION_MON_PROFIL}
                            />
                            <HeaderLinkDropdown
                              text={authenticated ? 'Se déconnecter' : 'Se connecter'}
                              icon={ArrowBackLine}
                              link={authenticated ? REACT_APP_URL_API_LOGOUT : ''}
                            />
                          </HeaderDropdownContainer>
                        </div>
                      </SpaceArea>
                    </SpaceContainer>
                  </ElementContainerItemsNavigation>
                </ElementContainerItems>
              </ElementContainer>
            </div>
          </HeaderContainerItems>
        </HeaderContainer>
      </HeaderFixed>
    </header>
  );
}

function HeaderLink(props: HeaderElementProps): JSX.Element {
  const Icon = props.icon || React.Fragment;
  return (
    <HeaderLinkStyle href={props.link} tabletOnly={props.tabletOnly}>
      <Icon width={props.size} height={props.size} />
      <span className={'desktop-text'}>{props.text}</span>
      <span className={'mobile-tablet-text'}>{props.mobileText}</span>
    </HeaderLinkStyle>
  );
}
function HeaderLinkDropdown(props: HeaderElementProps): JSX.Element {
  const Icon = props.icon || React.Fragment;
  return (
    <li>
      <HeaderLinkDropdownStyle href={props.link}>
        <Icon width={'20px'} height={'20px'} />
        <span>{props.text}</span>
      </HeaderLinkDropdownStyle>
    </li>
  );
}
