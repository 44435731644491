import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { useContext, useEffect, useRef, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Button } from '../../../components/Button';
import { ConditionalVisibility } from '../../../components/ConditionalVisibility';
import { CheckboxField } from '../../../components/input/CheckboxField';
import DateField from '../../../components/input/date-field/DateField';
import FileInput from '../../../components/input/FileInput/FileInput';
import SingleSelect, { Option } from '../../../components/input/SingleSelect';
import TextArea from '../../../components/input/TextArea';
import TextField from '../../../components/input/TextField';
import { FieldCaption, FieldWithCaptionContainer } from '../../../components/layout/FormLayouts';
import { Spacer } from '../../../components/layout/Spacer';
import Modal from '../../../components/Modal';
import Text from '../../../components/Text';
import Title from '../../../components/Title';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useEventFamilyContext } from '../../../contexts/EventFamilyContext';
import { useEventTypesContext } from '../../../contexts/EventTypesContext';
import { ResponsiveContext } from '../../../contexts/ResponsiveContext';
import { Info } from '../../../icons';
import EventRef from '../../../models/EventRef';
import EventResult from '../../../models/EventResult';
import MaterialChecking from '../../../models/MaterialChecking';
import { useMaterialSheetStore } from '../../../store/MaterialSheetStore';
import { unwrap } from '../../../utilities/Assertions';
import { ONE_MO_SIZE } from '../../../utilities/ByteSizes';
import DateUtility from '../../../utilities/DateUtility';
import { devDebug, devStringify } from '../../../utilities/DevMessages';
import StyleData from '../../../utilities/StyleData';
import {
  schema,
  TypedFieldValues,
  valuesToMaterialCheckings,
} from './MaterialCheckingFinalizationPopinSchema';
import { ButtonContainer, Form, HorizontalFieldsRow, Root } from './MaterialCheckingFinalizationPopinStyle';

/** The edition mode of the popin. */
export enum Mode {
  /** The material checking is incomplete and the purpose of the popin is to complete it. */
  Completion,
  /** The material checking is already complete but some informations may be changed in the popin. */
  LateEdit,
}

interface MaterialCheckingFinalizationPopinProps {
  /** Is the popin opened? */
  isOpen: boolean;
  /** The edition mode of the popin. Check {@link Mode} properties for more information. */
  mode: Mode;
  /** The previous material checking if it exists. */
  prevMaterialChecking: MaterialChecking | null;
  /** The current material checking to edit. */
  materialChecking: MaterialChecking;
  /** The next material checking if it exists. */
  nextMaterialChecking: MaterialChecking | null;
  /** Called when the popin finished its work and changed the material checkings. */
  onFinalized: () => void;
  /** Called when the user canceled the popin. The material checkings remains unchanged. */
  onCanceled: () => void;
}

const debug = false;

export function MaterialCheckingFinalizationPopin(props: MaterialCheckingFinalizationPopinProps) {
  const { readOnly } = useAuthContext();
  const { materialChecking, nextMaterialChecking } = props;
  const { eventTypes } = unwrap(useEventTypesContext());
  const { materialType, eventResults, eventFamily } = unwrap(useEventFamilyContext());
  const replaceMaterialChecking = useMaterialSheetStore((state) => state.replaceMaterialChecking);
  const addMaterialChecking = useMaterialSheetStore((state) => state.addMaterialChecking);
  const dateCommissioning = useMaterialSheetStore((state) => state.dateCommissioning);
  const responsive = useContext(ResponsiveContext);
  const [willBeScrapped, setWillBeScrapped] = useState(false);
  const submitInputRef = useRef<HTMLInputElement>(null);

  //#region Form handling
  const formMethods = useForm({
    mode: 'onTouched',
    resolver: zodResolver(schema),
  });
  const formErrors = formMethods.formState.errors;

  const onSubmit = async (rawData: FieldValues) => {
    const data = rawData as TypedFieldValues;

    const { finalized, next } = valuesToMaterialCheckings(
      data,
      { eventFamily },
      eventTypes.map((eventType) => eventType.eventRef),
      eventResults
    );

    replaceMaterialChecking(materialChecking, finalized);
    if (next) {
      // Do not allow next modification is it already exists.
      if (!nextMaterialChecking) {
        addMaterialChecking(next);
      }
      // Except if event ref is undefined, in this case allow event ref and planned date replacement.
      else if (nextMaterialChecking.eventRef === undefined) {
        nextMaterialChecking.eventRef = next.eventRef;
        if (nextMaterialChecking.plannedDate === undefined) {
          nextMaterialChecking.plannedDate = next.plannedDate;
        }
      }
    }
    props.onFinalized();
  };
  //#endregion

  //#region Current form values
  const selectedEventRefId = optionToId(formMethods.watch('eventRef'));
  const selectedEventType = eventTypes.find((eventType) => eventType.eventRef.id === selectedEventRefId);
  const selectedEffectiveDate = formMethods.watch('effectiveDate') as Date;
  const selectedResultId = optionToId(formMethods.watch('eventResult'));
  const selectedNextEventRefId = optionToId(formMethods.watch('nextEventRef'));
  //#endregion

  // Computation of next event ref.
  useEffect(() => {
    let nextEventRef: EventRef | undefined = undefined;
    if (nextMaterialChecking) {
      nextEventRef = nextMaterialChecking?.eventRef;
    } else {
      if (selectedEventType && selectedEffectiveDate && selectedResultId) {
        const computedNextMaterialChecking = new MaterialChecking({
          eventRef: eventTypes
            .flatMap((et) => et.eventRef)
            .find((eventRef) => eventRef.id === selectedEventRefId),
          eventFamily,
          eventResult: eventResults.find((el) => el.id === selectedResultId),
          effectiveDate: selectedEffectiveDate.toString(),
        }).computeNext(materialType, eventTypes);
        nextEventRef = computedNextMaterialChecking.eventRef;
      }
    }

    formMethods.setValue('nextEventRef', nextEventRef ? eventRefToOption(nextEventRef) : undefined);
  }, [
    eventTypes,
    selectedEventRefId,
    eventFamily,
    selectedResultId,
    materialType,
    selectedEventType,
    nextMaterialChecking,
    formMethods,
    selectedEffectiveDate,
    eventResults,
  ]);

  //#region Utility methods
  /**
   * Changes the reference and manages its scrapping.
   * @param eventResult The new event result chosen by the user or given by the material checking.
   */
  const changeReferenceEventResultScrapping = (eventResult?: EventResult) => {
    const newWillBeScrapped = eventResult?.isInvolvingScrapping === true;
    if (willBeScrapped !== newWillBeScrapped) {
      setWillBeScrapped(newWillBeScrapped);
      formMethods.setValue('willBeScrapped', newWillBeScrapped);
    }
  };
  //#endregion

  //#region Compute planned date according to eventType if needed.
  useEffect(() => {
    if (props.mode === Mode.LateEdit) return;

    let date: Date | undefined = undefined;
    if (selectedEventRefId && selectedEventType) {
      if (props.prevMaterialChecking !== null) {
        const periodicity = MaterialChecking.computeNextPeriodicity(materialType, selectedEventType);
        const refDate = unwrap(props.prevMaterialChecking.effectiveDate);
        date = DateUtility.addPeriodicity(dayjs(refDate), periodicity).toDate();
      } else {
        date = dayjs(
          MaterialChecking.computeFirst(
            unwrap(dateCommissioning ?? undefined),
            eventTypes,
            eventFamily,
            selectedEventType
          ).plannedDate
        ).toDate();
      }
    }

    formMethods.setValue('plannedDate', date);
  }, [
    props.mode,
    selectedEventRefId,
    selectedEventType,
    materialType,
    props.prevMaterialChecking,
    dateCommissioning,
    formMethods,
    eventTypes,
    eventFamily,
  ]);
  //#endregion

  //#region Setting of next planned date in form
  useEffect(() => {
    let date: Date | undefined = undefined;
    if (nextMaterialChecking) {
      date = nextMaterialChecking?.plannedDate ? new Date(nextMaterialChecking.plannedDate) : undefined;
    } else if (selectedNextEventRefId) {
      const periodicity = MaterialChecking.computeNextPeriodicity(
        materialType,
        unwrap(eventTypes.find((et) => et.eventRef.id === selectedNextEventRefId))
      );

      date = DateUtility.addPeriodicity(dayjs(selectedEffectiveDate), periodicity).toDate();
    }

    formMethods.setValue('nextPlannedDate', date);
  }, [
    eventTypes,
    formMethods,
    materialType,
    nextMaterialChecking,
    selectedEffectiveDate,
    selectedEventType,
    selectedNextEventRefId,
  ]);
  //#endregion

  // Keeps record in form if family has planned date
  useEffect(() => {
    formMethods.setValue('eventFamilyHasPlannedDate', eventFamily.hasPlannedDate);
  }, [eventFamily, formMethods]);

  // Reset result fields when event ref goes null
  useEffect(() => {
    if (!selectedEventType) {
      formMethods.setValue('eventResult', null);
    }
  }, [formMethods, selectedEventType]);

  // Initialize fields with materialChecking value when it changes
  useEffect(() => {
    if (materialChecking.eventRef) {
      formMethods.setValue('eventRef', eventRefToOption(materialChecking.eventRef));
    }
    if (materialChecking.eventResult) {
      formMethods.setValue('eventResult', eventResultToOption(materialChecking.eventResult));
      changeReferenceEventResultScrapping(materialChecking.eventResult);
    }
    if (materialChecking.checker) {
      formMethods.setValue('checker', materialChecking.checker);
    }
    if (materialChecking.comment) {
      formMethods.setValue('comment', materialChecking.comment);
    }
    if (materialChecking.document) {
      formMethods.setValue('document', [materialChecking.document]);
    }
    if (materialChecking.effectiveDate) {
      formMethods.setValue('effectiveDate', new Date(materialChecking.effectiveDate));
    }
  }, [eventResults, formMethods, materialChecking]);

  //#region Event result involving scrapping handling
  useEffect(() => {
    const selectedEventResult = eventResults.find((er) => er.id === selectedResultId);
    changeReferenceEventResultScrapping(selectedEventResult);
  }, [selectedResultId]);
  //#endregion

  // The next planned part is shown when date and result are selected
  const showNextPlanned = selectedEffectiveDate !== undefined && selectedResultId !== undefined;

  return (
    <Modal
      name={
        "Finalisation de l'évènement" +
        (selectedEventType?.eventRef ? ` «\xa0${selectedEventType.eventRef.name}\xa0»` : '')
      }
      subtitle={responsive.isMobile ? undefined : "Les champs marqués d'un astérisque (*) sont obligatoires."}
      isOpen={props.isOpen}
      onClose={props.onCanceled}
      hasScroll={responsive.isMobile}
    >
      <Root>
        {!responsive.isMobile && <Spacer size={StyleData.spacing.xl} />}
        <FormProvider {...formMethods}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              formMethods.handleSubmit(onSubmit)(e);
            }}
          >
            <div>
              {responsive.isMobile && <Spacer size={StyleData.spacing.md} />}
              <Title level={3} name="Détail de la visite"></Title>
              <ConditionalVisibility visible={debug}>
                {devDebug(devStringify(formErrors))}
                <CheckboxField name="eventFamilyHasPlannedDate" disabled label="Has planned date?" />
              </ConditionalVisibility>
              <SingleSelect
                name="eventRef"
                label="Type d'évènement *"
                options={eventTypes.map((eventType) => eventType.eventRef).map(eventRefToOption)}
                placeholder="Sélectionnez..."
                disabled={readOnly || props.mode === Mode.LateEdit}
                error={formErrors['eventRef']?.message?.toString()}
              />
              <HorizontalFieldsRow>
                {eventFamily.hasPlannedDate && (
                  <DateField
                    name="plannedDate"
                    label="Date prévue *"
                    disabled
                    defaultValue={
                      materialChecking.plannedDate ? new Date(materialChecking.plannedDate) : undefined
                    }
                    error={formErrors['plannedDate']?.message?.toString()}
                  />
                )}
                <DateField
                  name="effectiveDate"
                  label="Date de réalisation *"
                  disabled={readOnly || props.mode === Mode.LateEdit}
                  error={formErrors['effectiveDate']?.message?.toString()}
                />
              </HorizontalFieldsRow>
              <TextField
                name="checker"
                label="Ajouter un vérificateur"
                placeholder="Ajouter un ou plusieurs vérificateurs..."
                error={formErrors['checker']?.message?.toString()}
                type={'text'}
                disabled={readOnly}
              />
              <SingleSelect
                name="eventResult"
                options={eventResults.map((eventResult) => eventResultToOption(eventResult))}
                label="Résultat *"
                placeholder="Sélectionner un résultat..."
                error={formErrors['eventResult']?.message?.toString()}
                disabled={readOnly || props.mode === Mode.LateEdit}
              />
              <FieldWithCaptionContainer>
                <FileInput
                  name="document"
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'application/pdf': ['.pdf'],
                    'application/msword': ['.doc'],
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                    'application/vnd.oasis.opendocument.text': ['.odt'],
                    'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
                  }}
                  label="Télécharger un document"
                  maxSize={ONE_MO_SIZE * 2}
                  multiple={false}
                  placeholder="Séléctionnez un fichier..."
                  error={formErrors['document']?.message?.toString()}
                  disabled={readOnly}
                />
                <FieldCaption>
                  <Info />
                  <Text fsize="sm">
                    Le poids maximum du document ne doit pas excéder 2 Mo. Les formats acceptés sont : .pdf,
                    .doc, .docx, .odt, .ods, .jpg (2 Mo maximum)
                  </Text>
                </FieldCaption>
              </FieldWithCaptionContainer>
              <TextArea
                name="comment"
                label="Commentaire"
                placeholder="Écrivez votre commentaire"
                error={formErrors['comment']?.message?.toString()}
                disabled={readOnly}
              />
            </div>
            {showNextPlanned && (
              <div>
                <Title level={3} name="Prochaine échéance"></Title>
                <ConditionalVisibility visible={debug}>
                  <CheckboxField name="willBeScrapped" disabled label="Will be scrapped?" />
                </ConditionalVisibility>
                {!willBeScrapped && (
                  <SingleSelect
                    name="nextEventRef"
                    label="Type d'évènement *"
                    options={eventTypes.map((eventType) => eventType.eventRef).map(eventRefToOption)}
                    placeholder="Sélectionnez..."
                    background
                    disabled={props.mode === Mode.LateEdit && nextMaterialChecking?.eventRef !== undefined}
                    error={formErrors['nextEventRef']?.message?.toString()}
                  />
                )}
                {eventFamily.hasPlannedDate && !willBeScrapped && (
                  <DateField
                    name="nextPlannedDate"
                    label="Date prévue *"
                    disabled
                    error={formErrors['nextPlannedDate']?.message?.toString()}
                  />
                )}
                {willBeScrapped && (
                  <Text>
                    Aucune autre échéance ne peut être programmée car ce résultat implique la mise au rebut ou
                    la revente du matériel.
                  </Text>
                )}
              </div>
            )}
            <input type="submit" style={{ display: 'none' }} ref={submitInputRef} />
          </Form>
          {!readOnly && (
            <div>
              <hr />
              <ButtonContainer>
                <Button variant="bigLight" callback={props.onCanceled}>
                  Annuler
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  callback={() => {
                    // HACK We cannot use requestSubmit from form element because of Safari that does not support it at the moment (08/2022).
                    submitInputRef.current?.click();
                  }}
                >
                  Valider la vérification
                </Button>
              </ButtonContainer>
            </div>
          )}
        </FormProvider>
      </Root>
    </Modal>
  );
}

/**
 * Converts an {@link EventRef} to an {@link Option} for {@link SingleSelect} component.
 */
function eventRefToOption(eventRef: EventRef): Option {
  return {
    label: eventRef.name,
    value: eventRef.id.toString(),
  };
}

/**
 * Converts an {@link EventResult} to an {@link Option} for {@link SingleSelect} component.
 */
function eventResultToOption(eventResult: EventResult): Option {
  return {
    label: eventResult.name,
    value: eventResult.id.toString(),
  };
}

/**
 * Retrieves an id from an {@link Option} (assumes id is stringified into {@link Option.value} field).
 */
function optionToId(option: Option | undefined): number | undefined {
  return option ? parseInt(option.value) : undefined;
}
