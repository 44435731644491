import { ConformityCertificate } from '../../models/ConfirmityCertificate';
import HttpAbstract from './AbstractHttp';

const ROUTE = process.env.REACT_APP_URL_API_CONFORMITY_CERTIFICATES;

export default class ConfirmityCertificateHttp extends HttpAbstract<ConformityCertificate> {
  constructor() {
    super(`${ROUTE}`, ConformityCertificate);
  }
}
