import { z } from 'zod';
import EventRef from '../../../models/EventRef';
import EventResult from '../../../models/EventResult';
import MaterialChecking from '../../../models/MaterialChecking';
import { unwrap } from '../../../utilities/Assertions';

/** Form schema for classic {@link MaterialChecking} edition. */
export const schema = z
  .object({
    eventFamilyHasPlannedDate: z.boolean(),
    eventRef: z.object({ value: z.string() }),
    plannedDate: z.date().optional(),
    effectiveDate: z.date(),
    checker: z.string(),
    eventResult: z.object({ value: z.string() }),
    comment: z.string().optional(),
    willBeScrapped: z.boolean(),
    nextEventRef: z.object({ value: z.string() }).optional(),
    nextPlannedDate: z.date().optional(),
    document: z.array(z.any()).max(1, 'Seul un document peut être sélectionné.').or(z.string().length(0)),
  })
  .superRefine((data, ctx) => {
    // Ensure planned date is filled when it is necessary.
    if (data.eventFamilyHasPlannedDate && !data.plannedDate) {
      ctx.addIssue({
        code: 'custom',
        message: 'Le champ est requis.',
        path: ['plannedDate'],
      });
    }

    // Ensure next planned date is filled when it is necessary.
    if (data.eventFamilyHasPlannedDate && !data.willBeScrapped && !data.nextPlannedDate) {
      ctx.addIssue({
        code: 'custom',
        message: 'Le champ est requis.',
        path: ['nextPlannedDate'],
      });
    }

    // Ensure next event ref is filled when it is necessary
    if (!data.willBeScrapped && !data.nextEventRef) {
      ctx.addIssue({ code: 'custom', message: 'Le champ est requis.', path: ['nextEventRef'] });
    }
  });

/** Type for {@link schema} */
export type TypedFieldValues = z.infer<typeof schema>;

/** Return type of {@link valuesToMaterialCheckings} function. */
export type MaterialCheckings = {
  finalized: MaterialChecking;
  next?: MaterialChecking;
};

/**
 * Use the form values to create two matching {@link MaterialChecking}s.
 * @param values The values of the form.
 * @param completer The properties to add to the material to make it complete.
 * @param availableEventRefs The available {@link EventRef}s to retrieve an event ref object from the form values.
 * @param availableEventResults The available {@link EventResult}s to retrieve an event result object from the form values.
 * @returns The finalized and the next {@link MaterialChecking}.
 */
export function valuesToMaterialCheckings(
  values: TypedFieldValues,
  completer: Pick<MaterialChecking, 'eventFamily'>,
  availableEventRefs: EventRef[],
  availableEventResults: EventResult[]
): MaterialCheckings {
  const eventResultId = parseInt(values.eventResult.value);

  const finalized = new MaterialChecking({
    eventResult: availableEventResults.find((el) => el.id === eventResultId),
    eventRef: unwrap(availableEventRefs.find((el) => el.id === parseInt(values.eventRef.value))),
    plannedDate: values.plannedDate?.toDateString(),
    effectiveDate: values.effectiveDate.toDateString(),
    checker: values.checker,
    comment: values.comment,
    document: typeof values.document === 'string' ? undefined : values.document[0],
  });
  Object.assign(finalized, completer);

  if (values.willBeScrapped) {
    return { finalized };
  }

  const next = new MaterialChecking({
    eventRef: unwrap(availableEventRefs.find((el) => el.id === parseInt(unwrap(values.nextEventRef).value))),
    plannedDate: values.nextPlannedDate?.toDateString(),
  });
  Object.assign(next, completer);

  return { finalized, next };
}
