import styled from 'styled-components';
import StyleData from '../../utilities/StyleData';

export const SearchContainer = styled.div`
  display: block;
  width: 100%;
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    svg {
      height: 22px;
    }
  }
  input {
    border: none;
    color: ${StyleData.color.text};
    font-family: ${StyleData.font.secondary};
    font-size: ${StyleData.fsize.md};
    line-height: 16px;
    ::-ms-clear {
      display: none;
    }
    :focus {
      outline: none;
    }
  }
  span {
    color: #000000;
    font-family: ${StyleData.font.secondary};
    font-size: ${StyleData.fsize.xs};
    line-height: 16px;
    text-align: right;
    margin: auto 0 auto auto;
    font-weight: normal;
  }

  .search-input {
    grid-area: 1 / 1;
    display: grid;
    grid-template: 100% / 1fr max-content 0;
    box-sizing: border-box;
    background-color: #f7f7f8;
    border-radius: 30px;
    padding: 11px 22px 11px 19px;
    input {
      background-color: transparent;
    }

    .search-submit {
      width: fit-content;
      padding: 0;
      margin-left: auto;
    }
  }
  .search-cancel {
    grid-area: 1 / 2;
    margin: auto;
  }
  .search-cancel:hover,
  .search-submit:hover {
    .st0-fill {
      fill: ${StyleData.color.primary};
      transition: fill 0.15s ease-in;
    }
  }
`;

export const SearchMobileContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px 10px 20px;
  margin-left: -20px;
  .cancel-mobile-search {
    border: none;
    background-color: transparent;
    padding: 0;
    font-size: 0;
    svg {
      margin: auto;
    }
    :hover {
      svg {
        .st0-fill {
          fill: ${StyleData.color.primary};
          transition: fill 0.15s ease-in;
        }
      }
    }
  }
  .search-mobile-input {
    width: 100%;
    grid-area: 1 / 1;
    display: grid;
    grid-template: 100% / auto 16px;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 11px 15px 11px 15px;
    background-color: ${StyleData.color.lightGrey};
    color: ${StyleData.color.text};
    input {
      width: 100%;
      border: none;
      background-color: ${StyleData.color.lightGrey};
      color: ${StyleData.color.text};
      font-family: ${StyleData.font.secondary};
      font-size: ${StyleData.fsize.md};
      line-height: 16px;
      ::-ms-clear {
        display: none;
      }
      :focus {
        outline: none;
      }
    }
    .search-mobile-submit {
      width: fit-content;
      padding: 0;
      margin-left: auto;
    }
  }
`;

export const VerticalCenterContainer = styled.div`
  display: flex;
  align-items: center;
`;
