import { unwrap } from './Assertions';

export class SentenceFormatter {
  /**
   * Returns a sentence piece composed of the given {@link names}.
   * @param names The names of the persons. For example `["Georges R. R. Martin", "J. K. Rowling", "Jeanne d'Arc"]`.
   *
   * @example
   * // Example 1
   * const personSentence = SentenceFormatter.personList(["Georges R. R. Martin", "J. K. Rowling", "Jeanne d'Arc"]);
   * console.log(personSentence); // Georges R. R. Martin, J. K. Rowling et Jeanne d'Arc
   *
   * // Example 2
   * const personSentence = SentenceFormatter.personList(["Jeanne d'Arc"]);
   * console.log(personSentence); // Jeanne d'Arc
   */
  static personList(names: string[]): string {
    if (names.length === 0) return '';
    if (names.length === 1) return unwrap(names.at(0));
    return `${names.slice(0, -1).join(',')} et ${names.at(-1)}`;
  }
}
