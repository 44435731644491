import { createContext, useContext } from 'react';

type Context = {
  isMobile: boolean;
};

export const ResponsiveContext = createContext<Context>({
  isMobile: false,
});

ResponsiveContext.displayName = 'ResponsiveContext';

export function useResponsiveContext(): Context {
  return useContext(ResponsiveContext);
}
