import { ACTRight } from '../types/ACTRight';
import { Flags } from '../utilities/Flags';
import Group from './Group';

export type UserFromApiType = {
  id: number;
  firstname: string;
  lastname: string;
  roles: Array<string>;
  groups: string[];
};

export default class User {
  public id!: number;
  public firstname!: string;
  public lastname!: string;
  public roles!: Array<string>;
  public groupId!: number;
  public groupIri!: string;
  public groups!: Group[];

  /**
   * Set the current group from the user organization.
   */
  public assignCurrentGroup(organizationId: number): void {
    const groupsForOrganization = this.groups?.filter((g) => +g.organizationId === organizationId)[0];
    if (groupsForOrganization) {
      this.groupId = groupsForOrganization.organizationId;
      this.groupIri = groupsForOrganization['@id'];
    }
  }

  /**
   * returns true if the read only role is assigned to this user.
   */
  public isReadonly(organizationId: number): boolean {
    const rolesThatCanWrite = this.roles?.filter(
      (role: string) => role === `ROLE_ORG_${organizationId}_MAT_EDIT`
    );
    const rolesThatCanShow = this.roles?.filter(
      (role: string) => role === `ROLE_ORG_${organizationId}_MAT_SHOW`
    );

    return rolesThatCanWrite.length <= 0 && rolesThatCanShow.length > 0;
  }

  /**
   * returns true if the user can write.
   */
  public hasWriteRights(organizationId: number): boolean {
    const rolesThatCanWrite = this.roles?.filter(
      (role: string) => role === `ROLE_ORG_${organizationId}_MAT_EDIT`
    );

    return rolesThatCanWrite.length > 0;
  }

  /**
   * Check if the user has some role for this organization.
   */
  public isPartOfTheOrganization(organizationId: string): boolean {
    // added MAT_SHOW replace to allow absence of the role "ROLE_ORG_{orgID}"
    const rolesInThisOrganization =
      this.roles?.filter(
        (role: string) => role.replaceAll('ROLE_ORG_', '').replaceAll('_MAT_SHOW', '') == organizationId
      ) || [];
    const rolesThatCanShow = this.roles?.filter(
      (role: string) => role === `ROLE_ORG_${organizationId}_MAT_SHOW`
    );

    return rolesInThisOrganization.length > 0 && rolesThatCanShow.length > 0;
  }

  /**
   * Check if the user has an organization.
   */
  public hasAnOrganization(): boolean {
    const hasAnOrganization = this.roles?.filter((role: string) => role.includes('ROLE_ORG')) || [];

    return hasAnOrganization.length > 0;
  }

  public getDefaultGroup(): number {
    const hasWriteRights = this.groups.some((group: Group) => {
      if (this.hasWriteRights(group.organizationId)) {
        this.groupId = group.organizationId;

        return true;
      }

      return false;
    });
    const isPartOfTheOrg = !hasWriteRights
      ? this.groups.some((group: Group) => {
          if (this.isPartOfTheOrganization(group.organizationId.toString())) {
            this.groupId = group.organizationId;

            return true;
          }

          return false;
        })
      : false;

    if (!hasWriteRights && !isPartOfTheOrg) {
      const groupChosen = this.groups[0];
      this.groupId = groupChosen.organizationId;
    }

    return this.groupId;
  }

  public getEntityGroup(organizationId: number): Group {
    return this.groups.filter((element: Group) => element.organizationId === organizationId)[0];
  }

  /**
   * @returns The user authorization for ACT as {@link ACTRight}s.
   */
  public getACTAuthorization(organizationId: number): ACTRight {
    const rolesThatCanEdit = this.roles?.filter(
      (role: string) => role === `ROLE_ORG_${organizationId}_MAT_EDIT`
    );
    const rolesThatCanShow = this.roles?.filter(
      (role: string) => role === `ROLE_ORG_${organizationId}_MAT_SHOW`
    );

    let flags: ACTRight = ACTRight.None;
    if (rolesThatCanEdit.length > 0) {
      flags = Flags.add(flags, ACTRight.Edit);
    }
    if (rolesThatCanShow.length > 0) {
      flags = Flags.add(flags, ACTRight.Show);
    }

    return flags;
  }

  constructor(data?: Partial<UserFromApiType>) {
    Object.assign(this, data);
  }
}
