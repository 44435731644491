import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router';
import http from '../ApiHttp';
import { routeToLink } from '../App.routing';
import { AuthContextType } from '../contexts/AuthContext';
import User from '../models/User';
import { DatalayerService } from '../services/DatalayerService';
import { UserHttp } from '../services/http/UserHttp';
import { useModalStore } from '../store/ErrorModalStore';

interface Props {
  children: JSX.Element;
  setAuthContext: (value: AuthContextType) => void;
  printReadonly: () => void;
}

const {
  REACT_APP_URL_EXT_LOGIN,
  REACT_APP_URL_EXT_PREVENTION_SERVICE,
  REACT_APP_URL_EXT_PREVENTION_AJOUT_ENTREPRISE,
  REACT_APP_URL_EXT_PREVENTION_GESTIONNAIRES_ENTREPRISE,
} = process.env;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function AuthGuard({ setAuthContext, printReadonly }: Props) {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [infoGathered, setInfoGathered] = useState<boolean>(false);
  const [user, setUser] = useState<User>();

  const [setErrorModalData, setErrorModalOpened] = useModalStore((state) => [state.setData, state.setIsOpen]);

  const routeParams = useParams<{ organizationId?: string }>();
  const organizationId = routeParams?.organizationId;

  useEffect(() => {
    http.interceptors.response.use(
      function (response: AxiosResponse) {
        return response;
      },
      function (error: AxiosError<any>) {
        if (
          (error?.response?.status !== 400 &&
            error?.response?.status !== 404 &&
            error?.response?.status !== 422 &&
            error?.response?.status !== 401 &&
            (error?.response?.status || 0) > 400 &&
            (error?.response?.status || 0) < 527) ||
          error.message === 'Network Error'
        ) {
          let debugMessage = '';
          debugMessage += error.config && error.config.url ? error.config.url : '';
          debugMessage += ' : ';
          debugMessage +=
            error.response && error.response.data && 'hydra:description' in error.response.data
              ? error.response.data['hydra:description']
              : error.message;
          setErrorModalData({
            name: '',
            subtitle: debugMessage,
          });
          setErrorModalOpened(true);
        } else if (error?.response?.status == 401) {
          requestOidcLogin();
        }
        return Promise.reject(error);
      }
    );
  }, []);

  //#region Read-only handling
  const readOnly = useMemo(() => {
    if (user && organizationId) {
      const value: boolean = user?.isReadonly(+organizationId);
      return value;
    }
  }, [user, organizationId]);

  useEffect(() => {
    if (readOnly) {
      printReadonly();
    }
  }, [readOnly]);
  //#endregion

  const isPartOfTheOrganization = useMemo(() => {
    if (user && organizationId) {
      return user?.isPartOfTheOrganization(organizationId);
    }
  }, [user, organizationId]);

  const ACTAuthorization = useMemo(() => {
    if (user && organizationId) {
      return user.getACTAuthorization(+organizationId);
    }
  }, [user, organizationId]);

  function requestOidcLogin() {
    // Non authentifié
    window.location.href = `${REACT_APP_URL_EXT_LOGIN}` + '?target_path=' + encodeURI(window.location.href);
    setLoading(false);
    setInfoGathered(true);
  }

  useEffect(() => {
    async function doAuth(): Promise<void> {
      const isAuthenticated = await UserHttp.check();

      setAuthenticated(isAuthenticated);
      if (isAuthenticated) {
        UserHttp.fetchCurrentUser()
          .then((userApi: User) => {
            setUser(userApi);
            if (!userApi.hasAnOrganization()) {
              setErrorModalData({
                name: 'Information',
                description:
                  'Pour accéder à cet outil, vous devez finaliser la création de votre compte en ajoutant une entreprise à votre profil.',
                actionText: 'Ajouter une entreprise',
                action: () => {
                  if (REACT_APP_URL_EXT_PREVENTION_AJOUT_ENTREPRISE) {
                    window.location.replace(REACT_APP_URL_EXT_PREVENTION_AJOUT_ENTREPRISE);
                  }
                },
              });
              setErrorModalOpened(true);
              setLoading(false);
              setInfoGathered(true);
            } else if (organizationId && !userApi.isPartOfTheOrganization(organizationId)) {
              setErrorModalData({
                name: "Droits d'accès insuffisants",
                subtitle: 'Note : accès non autorisé',
                description:
                  "Vos droits ne vous permettent pas d'avoir accès à l'entreprise demandée. Pour pouvoir accéder à cette entreprise, " +
                  "<a href='" +
                  `${REACT_APP_URL_EXT_PREVENTION_GESTIONNAIRES_ENTREPRISE}` +
                  "'>contactez un gestionnaire</a> pouvant modifier vos droits.",
                actionText: 'Retourner à mon espace',
                action: () => {
                  if (REACT_APP_URL_EXT_PREVENTION_SERVICE) {
                    window.location.replace(REACT_APP_URL_EXT_PREVENTION_SERVICE);
                  }
                },
              });
              setErrorModalOpened(true);
              setLoading(false);
              setInfoGathered(true);
            } else if (organizationId) {
              userApi.assignCurrentGroup(+organizationId);
              if (
                window.location.pathname.split('/')[2] === 'liste' &&
                window.location.pathname.split('/').length < 4
              ) {
                UserHttp.track(organizationId);
              }
              setLoading(false);
              setInfoGathered(true);
            } else if (!organizationId) {
              window.location.href = routeToLink(':organizationId/liste', {
                organizationId: userApi.getDefaultGroup(),
              });
              setLoading(false);
              setInfoGathered(true);
            }
            const datalayerService = new DatalayerService();
            datalayerService.getCustomizationData(organizationId || null).then((res) => {
              if (!datalayerService.push(res.data)) {
                console.error('Error: datalayer push failed');
              }
            });
          })
          .catch((e) => {
            console.error(e);
            if (e?.response?.status == 401) {
              requestOidcLogin();
            } else {
              setErrorModalData({
                name: 'Erreur de connexion',
                subtitle: "Nous n'avons pas pu récupérer vos données utilisateur.",
                description:
                  "Nous n'avons pas pu récupérer vos données utilisateur. Veuillez vérifier que vous êtes connecté.",
                actionText: 'Recharger la page',
                action: () => {
                  window.location.reload();
                },
              });
              setErrorModalOpened(true);
            }
          });
      } else {
        requestOidcLogin();
      }
    }
    if (!user) {
      doAuth();
    }
  }, []);

  useEffect(() => {
    // * Prevents triggering sub page reloads when only partial information has been added
    if (infoGathered) {
      setAuthContext({ authenticated, readOnly, ACTAuthorization, loading, user });
    }
  }, [infoGathered]);

  return <>{user && isPartOfTheOrganization && <Outlet />}</>;
}

AuthGuard.defaultProps = {
  children: <></>,
};

export default AuthGuard;
