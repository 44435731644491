export class UserAct {
  id!: number;
  '@id': string;
  iri!: string;
  email!: string;
  phone!: string;

  firstname!: string;
  lastname!: string;
  fullname!: string;

  checksum!: string;

  remoteId!: number;

  constructor(data?: Partial<UserAct>) {
    Object.assign(this, data);

    if (data?.firstname && data?.lastname) {
      this.fullname = `${this.firstname} ${this.lastname.toUpperCase()}`;
    }

    const id = data && data['@id']?.split(/[/]+/).pop();
    if (id) {
      this.id = +id;
    }

    if (data && data['@id']) {
      this.iri = data['@id'];
    }
  }
}
