import EntityInterface from '../interfaces/Entity';
import MaterialTypeInterface from '../interfaces/MaterialType';
import PersistableInterface from '../interfaces/Persistable';
import Group from './Group';
import Periodicity from './Periodicity';

export default class MaterialType implements EntityInterface, MaterialTypeInterface, PersistableInterface {
  public id!: number;

  public name!: string;
  public description!: string;
  public periodicity!: Periodicity;

  public organizationId!: string;
  public group!: Group;

  public isRepository!: boolean;
  public isCustomizable!: boolean;

  toApi(): Record<string, unknown> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = { ...this };

    data.materialFamily = `/api/material-families/${data.materialFamily.id}`;
    data.periodicity = `/api/periodicities/${data.periodicity.id}`;

    if (this.group) {
      data.group = this.group['@id'] ?? `api/groups/${this.group.id}`;
    }

    return data;
  }

  constructor(data?: Partial<MaterialTypeInterface>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
