export class ArrayUtils {
  /**
   * @param array The source array.
   * @param predicate The predicate to verify.
   * @returns `true` if all element of array satisfies the {@link predicate}. `false` otherwise.
   */
  static all<T>(array: T[], predicate: (el: T) => boolean): boolean {
    for (const el of array) {
      if (!predicate(el)) {
        return false;
      }
    }
    return true;
  }

  /**
   * @param array The source array.
   * @param predicate The predicate to verify.
   * @returns `true` if at least one element of the array satisfies the {@link predicate}.
   */
  static any<T>(array: T[], predicate: (el: T) => boolean): boolean {
    for (const el of array) {
      if (predicate(el)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns the minimum of the array (if several values matches, the first one is returned). Returns `undefined` if {@link array} is empty.
   * @param array The array to retrieve to search the minimum into.
   * @param comparer The comparer used to compare two values of the array.
   */
  static min<T>(array: T[], comparer: (el1: T, el2: T) => number): T | undefined {
    if (array.length === 0) return undefined;
    let currentMin: T = array[0];

    for (const value of array) {
      if (!currentMin || comparer(value, currentMin) < 0) {
        currentMin = value;
      }
    }

    return currentMin;
  }

  /**
   * Returns the maximum of the array (if several values matches, the first one is returned). Returns `undefined` if {@link array} is empty.
   * @param array The array to retrieve to search the minimum into.
   * @param comparer The comparer used to compare two values of the array.
   */
  static max<T>(array: T[], comparer: (a: T, b: T) => number): T | undefined {
    if (array.length === 0) return undefined;
    let currentMax: T = array[0];

    for (const value of array) {
      if (!currentMax || comparer(value, currentMax) > 0) {
        currentMax = value;
      }
    }

    return currentMax;
  }

  /**
   * Replace an element in the array. The original array is left untouched (a fresh array will be returned).
   * @example
   * console.log(ArrayUtils.replace(["A", "B", "C", "D"], "C", "Z"));
   * // ["A", "B", "Z", "D"]
   * @param array The source array.
   * @param replacee The element to replace.
   * @param replacer The element replacing {@link replacee}.
   * @param equalityMatcher An optional custom equality matcher.
   * @returns The fresh array with the element replaced.
   * @throws An error if {@link replacee} is not found in {@link array}.
   */
  static replace<T>(
    array: T[],
    replacee: T,
    replacer: T,
    equalityMatcher: (el1: T, el2: T) => boolean = (el1, el2) => el1 === el2
  ): T[] {
    const index = array.findIndex((el) => equalityMatcher(el, replacee));
    if (index === -1) {
      throw new Error(`Replacee (${replacee}) could not be found into array.`);
    }
    return ArrayUtils.replaceAtIndex(array, index, replacer);
  }

  /**
   * Replaces an element of {@link array} at {@link index}.
   * @param array The source array.
   * @param index The index of the value that will be replaced.
   * @param replacer The element replacing the element at {@link index}.
   * @returns A fresh array with the element replaced.
   */
  static replaceAtIndex<T>(array: T[], index: number, replacer: T): T[] {
    const start = array.slice(0, index);
    const end = array.slice(index + 1);

    return start.concat([replacer], end);
  }

  /**
   * @param array The array that may contain undefined element.
   * @returns An array without the undefined elements.
   */
  static onlyKeepDefined<T>(array: (T | undefined)[]): T[] {
    return array.filter((el) => el !== undefined) as T[];
  }

  /**
   * Checks {@link encapsulator} contains all elements of {@link encapsulated}.
   * @param equalityOperator Overrides the comparison function used to compare elements.
   */
  static encapsulate<T>(
    encapsulator: T[],
    encapsulated: T[],
    equalityOperator?: (a: T, b: T) => boolean
  ): boolean {
    if (encapsulated.length > encapsulator.length) return false;
    const eq = this.getEqualityOperator(equalityOperator);

    for (const encapsulatedEl of encapsulated) {
      if (encapsulator.findIndex((encapsulatorEl) => eq(encapsulatorEl, encapsulatedEl)) === -1) {
        return false;
      }
    }
    return true;
  }

  /**
   * Checks {@link array1} contains the same element as {@link array2} (strictly, without any extra or missing element).
   * @param equalityOperator Overrides the comparison function used to compare elements.
   */
  static equivalent<T>(array1: T[], array2: T[], equalityOperator?: (a: T, b: T) => boolean): boolean {
    if (array1.length !== array2.length) return false;
    return this.encapsulate(array1, array2, equalityOperator);
  }

  /**
   * Checks {@link array1} contains the same element as {@link array2} (strictly, without any extra or missing element) and in the exact same order.
   * @param equalityOperator Overrides the comparison function used to compare elements.
   */
  static equals<T>(array1: T[], array2: T[], equalityOperator?: (a: T, b: T) => boolean): boolean {
    if (array1.length !== array2.length) return false;
    const eq = this.getEqualityOperator(equalityOperator);
    for (let i = 0; i < array1.length; ++i) {
      if (!eq(array1[i], array2[i])) return false;
    }
    return true;
  }

  /**
   * @param equalityOperator A user passed equality operator option.
   * @returns The option if defined or the default equality operator.
   */
  private static getEqualityOperator<T>(equalityOperator?: (a: T, b: T) => boolean): (a: T, b: T) => boolean {
    return equalityOperator ?? ((a, b) => a === b);
  }
}
