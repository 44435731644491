import styled from 'styled-components';
import { useAuthContext } from '../contexts/AuthContext';
import { DefaultCompany } from '../icons';
import Group from '../models/Group';
import StyleData from '../utilities/StyleData';
interface Props {
  children?: JSX.Element;
}

function Breadcrumb(props: Props): JSX.Element {
  const { user } = useAuthContext();
  const group: Group | undefined = user?.getEntityGroup(user?.groupId);

  const prefixe = process.env.REACT_APP_URL_PREVENTIONBTP_PREFIXE || '';
  return (
    <>
      <BreadcrumbTree>{props.children && props.children}</BreadcrumbTree>
      {group && (
        <>
          <Logo
            src={group.logoRemotePath ? prefixe + '/' + group?.logoRemotePath : DefaultCompany}
            width={17}
            height={18}
            onError={(e: any) => {
              if (DefaultCompany) e.target.src = DefaultCompany;
            }}
          />
          <CompanyText>{group?.name}</CompanyText>
        </>
      )}
    </>
  );
}

const Logo = styled.img`
  padding-right: 5px;
`;

const CompanyText = styled.div`
  font-size: ${StyleData.fsize.md};
  font-family: ${StyleData.font.secondary};
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    max-width: 60px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: ${StyleData.fsize.sm};
    font-family: ${StyleData.font.secondary};
  }
`;

const BreadcrumbTree = styled.div`
  height: 11px;
  display: flex;
  align-items: center;
  margin-right: auto;
  a {
    display: flex;
    font-family: ${StyleData.font.secondary};
    font-size: ${StyleData.fsize.xxs};
    color: ${StyleData.color.lightText};
    text-decoration: none;
    align-items: center;
    justify-content: center;
    svg {
      margin: 0 -5px 0 -5px;
    }
  }
  svg {
    height: 11px;
  }
  @media screen and (max-width: ${StyleData.breakpoints.max.md}px) {
    display: none;
  }
`;

export default Breadcrumb;
