/**
 * The different values of loading object can have.
 */
export enum Status {
  NotStarted,
  Loading,
  Failed,
  Succeeded,
}

/**
 * Holds a loading object and its loading state.
 */
export interface PromisedObject<T> {
  object?: T;
  status: Status;
}
