import { ReactElement, useState } from 'react';
import { Button } from '../../../components/Button';
import { FlexButtonContainer } from '../../../components/ButtonContainer';
import { VSpacer } from '../../../components/layout/Spacer';
import Line from '../../../components/Line';
import Modal, { ModalSize } from '../../../components/Modal';
import Text from '../../../components/Text';
import MaterialSheet from '../../../models/MaterialSheet';
import StyleData from '../../../utilities/StyleData';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  modalSize?: ModalSize;
  name: string;
  data: MaterialSheet;
  deleteCallback: (id: number) => void;
}

export function MaterialDelete(props: Props): ReactElement {
  const [submitted, setSubmitted] = useState<boolean>(false);

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} modalSize={props.modalSize} name={props.name}>
      <>
        <VSpacer size="12px" />
        <Text>Voulez-vous vraiment supprimer cet élément ?</Text>
        <VSpacer size="12px" />
        <Text fontWeight={'bold'}>
          <>{props.data.materialType?.name}</>
        </Text>
        <VSpacer size="16px" />
        <Text color={StyleData.color.primary}>
          Toutes les actions et notifications en lien avec ce matériel seront définitivement supprimées.
        </Text>
        <VSpacer size="28px" />
        <Line />
        <VSpacer size="16px" />
        <FlexButtonContainer>
          <Button type="reset" variant="bigLight" callback={props.onClose}>
            Annuler la suppression
          </Button>
          <Button
            variant="primary"
            type="submit"
            callback={() => {
              setSubmitted(true);
              props.deleteCallback(props.data.id);
              props.onClose();
            }}
            disabled={submitted}
          >
            Confirmer la suppression
          </Button>
        </FlexButtonContainer>
      </>
    </Modal>
  );
}

export default MaterialDelete;
