/**
 * @returns `a - b`:
 *   * A negative number if {@link a} is smaller than {@link b},
 *   * `0` if {@link a} equals {@link b},
 *   * a positive number otherwise.
 */
export function numberComparer(a: number, b: number): number {
  return a - b;
}

/**
 * @returns
 *   * A negative number if {@link a} is older than {@link b},
 *   * `0` if {@link a} represents the same date as {@link b},
 *   * a positive number if {@link a} is most recent than {@link b}.
 */
export function dateComparer(a: Date, b: Date): number {
  return numberComparer(a.getTime(), b.getTime());
}
