import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Params, useLocation, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import { Button } from '../../components/Button';
import Dropdown, { Coordinate } from '../../components/Dropdown';
import DateField from '../../components/input/date-field/DateField';
import MultiSelect from '../../components/input/MultiSelect';
import { RadioClassic } from '../../components/input/RadioClassic';
import { VSpacer } from '../../components/layout/Spacer';
import Line from '../../components/Line';
import { Element, FilterButton, GridHeaderContainer } from '../../components/List.style';
import { useQuery } from '../../hooks/UseQuery';
import {
  CheckedWhite,
  ChevronDownBlack,
  ChevronUpBlack,
  ChevronUpDownBlack,
  FilterBlack,
  FilterFillBlue,
} from '../../icons';
import EventRefHttp from '../../services/http/EventRefHttp';
import MaterialFamilyHttp from '../../services/http/MaterialFamilyHttp';
import { useListStore } from '../../store/ListStore';
import { ArrayUtils } from '../../utilities/Array';
import { toAPIDateFormat } from '../../utilities/Date';
import { MaterialDisplayTypes } from './MaterialDisplayTypes';
import { FormContainer, GenericContainer } from './TableHeaderStyle';
import EmployeeHttp from '../../services/http/EmployeeHttp';

const schema = z.late.object(() => ({
  materialCategory: z.string().optional().nullable(),
  materialFamily: z
    .object({
      value: z.string(),
      label: z.string(),
    })
    .array()
    .optional(),
  minDateNextVerificationCheck: z
    .instanceof(Date, { message: 'Veuillez entrer une date valide' })
    .optional()
    .nullable(),
  maxDateNextVerificationCheck: z
    .instanceof(Date, { message: 'Veuillez entrer une date valide' })
    .optional()
    .nullable(),
  eventType: z
    .object({
      value: z.string(),
      label: z.string(),
    })
    .array()
    .or(z.null())
    .optional(),
  linkedTo: z
    .object({
      value: z.string(),
      label: z.string(),
    })
    .array()
    .or(z.null())
    .optional(),
  isScrapped: z.string().optional().nullable(),
}));

export type MaterialHeaderTableFormType = {
  materialFamily?: { value: string; label: string }[];
  materialCategory?: string;
  minDateNextVerificationCheck?: Date;
  maxDateNextVerificationCheck?: Date;
  eventType?: { value: string; label: string }[];
  isScrapped?: string;
  linkedTo?: { value: string; label: string }[];
};

type Props = {
  handleFilters: (headerForm: Record<string, string[]>) => unknown;
  mode: MaterialDisplayTypes;
};

export function TableHeader(props: Props): JSX.Element {
  // Navigation
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.split('?')[0];
  const { organizationId } = useParams<Params>();

  // Filter buttons states
  const [eventTypeIsOpen, setEventTypeIsOpen] = useState(false);
  const [linkedToIsOpen, setLinkedToIsOpen] = useState(false);
  const [plannedDateIsOpen, setPlannedDateIsOpen] = useState(false);
  const [isScrappedIsOpen, setIsScrappedIsOpen] = useState(false);
  const [materialFamilyIsOpen, setMaterialFamilyIsOpen] = useState(false);

  // Filter fields state
  const [materialFamilyFieldActive, setMaterialFamilyFieldActive] = useState(false);
  const [plannedDateFieldActive, setPlannedDateFieldActive] = useState(false);
  const [eventTypeFieldsActive, setEventTypeFieldsActive] = useState(false);
  const [linkedToFieldsActive, setLinkedToFieldsActive] = useState(false);
  const [isScrappedFieldsActive, setIsScrappedFieldsActive] = useState(false);

  const [eventTypeOptions, setEventTypeOptions] = useState<{ label: string; value: string }[]>([]);
  const [linkedToOptions, setLinkedToOptions] = useState<{ label: string; value: string }[]>([]);

  const [sortings, setSortings] = useState<{
    'materialType.name': 'off' | 'asc' | 'desc';
    matriculation: 'off' | 'asc' | 'desc';
    'lastPlannedMaterialChecking.plannedDate': 'off' | 'asc' | 'desc';
    [key: string]: string; // for typing key and dereferencing as an usual array
  }>({
    'materialType.name': 'off',
    matriculation: 'off',
    'lastPlannedMaterialChecking.plannedDate': 'off',
  });

  const [paramsLoaded, setParamsLoaded] = useState<boolean>(false);

  const [materialFamilies, setMaterialFamilies] = useListStore((state) => [
    state.materialFamilies,
    state.setMaterialFamilies,
  ]);

  const params = useQuery();

  // Style
  const dropdownPosTweaks = useRef<Partial<Coordinate>>({ y: -10 });

  // Form
  const methods = useForm({
    mode: 'onTouched',
    resolver: zodResolver(schema),
  });

  const { watch } = methods;

  const errors: any = methods.formState.errors;

  // Watch filter fields
  const watchMaterialFamily = watch('materialFamily');
  const watchMaterialCategory: string = watch('materialCategory');
  const watchEventTypeFields = watch('eventType');
  const watchLinkedToFields = watch('linkedTo');
  const watchDateNextVerificationFields = watch([
    'minDateNextVerificationCheck',
    'maxDateNextVerificationCheck',
  ]);
  const watchIsScrappedFields = watch('isScrapped');

  const onSubmit: any = (
    data: MaterialHeaderTableFormType,
    event: any,
    newSort?: {
      'materialType.name': 'off' | 'asc' | 'desc';
      matriculation: 'off' | 'asc' | 'desc';
      'lastPlannedMaterialChecking.plannedDate': 'off' | 'asc' | 'desc';
      [key: string]: string; // for typing key and dereferencing as an usual array
    }
  ) => {
    if (
      data.maxDateNextVerificationCheck &&
      data.minDateNextVerificationCheck &&
      data.minDateNextVerificationCheck.getTime() > data.maxDateNextVerificationCheck.getTime()
    ) {
      methods.setError('maxDateNextVerificationCheck', {
        message: 'La date de fin doit être supérieure à la date de début',
      });
      return;
    }

    setMaterialFamilyIsOpen(false);
    setPlannedDateIsOpen(false);
    setEventTypeIsOpen(false);
    setIsScrappedIsOpen(false);
    setLinkedToIsOpen(false);

    data.materialCategory
      ? params.set('materialCategory', data.materialCategory)
      : params.delete('materialCategory');

    data.materialFamily && data.materialFamily.length > 0
      ? (() => {
          params.delete('materialType.materialFamily.id[]');
          data.materialFamily.forEach((item) => {
            params.append('materialType.materialFamily.id[]', item.value);
          });
        })()
      : params.delete('materialType.materialFamily.id[]');

    data.minDateNextVerificationCheck
      ? params.set(
          'lastPlannedMaterialChecking.plannedDate[after]',
          toAPIDateFormat(data.minDateNextVerificationCheck)
        )
      : params.delete('lastPlannedMaterialChecking.plannedDate[after]');

    data.maxDateNextVerificationCheck
      ? params.set(
          'lastPlannedMaterialChecking.plannedDate[before]',
          toAPIDateFormat(data.maxDateNextVerificationCheck)
        )
      : params.delete('lastPlannedMaterialChecking.plannedDate[before]');

    data.eventType && data.eventType.length > 0
      ? (() => {
          params.delete('lastPlannedMaterialChecking.eventRef.id[]');
          data.eventType.forEach((item) => {
            params.append('lastPlannedMaterialChecking.eventRef.id[]', item.value);
          });
        })()
      : params.delete('lastPlannedMaterialChecking.eventRef.id[]');

    data.isScrapped ? params.set('isScrapped', data.isScrapped) : params.delete('isScrapped');

    data.linkedTo && data.linkedTo.length > 0
      ? (() => {
          params.delete('remoteEmployeeIds[]');
          data.linkedTo.forEach((item) => {
            params.append('remoteEmployeeIds[]', item.value);
          });
        })()
      : params.delete('remoteEmployeeIds[]');

    const sort = newSort ?? sortings;
    sort['materialType.name'] && sort['materialType.name'] !== 'off'
      ? params.set('order[materialType.name]', sort['materialType.name'])
      : params.delete('order[materialType.name]');
    sort['lastPlannedMaterialChecking.plannedDate'] &&
    sort['lastPlannedMaterialChecking.plannedDate'] !== 'off'
      ? params.set(
          'order[lastPlannedMaterialChecking.plannedDate]',
          sort['lastPlannedMaterialChecking.plannedDate']
        )
      : params.delete('order[lastPlannedMaterialChecking.plannedDate]');
    sort['matriculation'] && sort['matriculation'] !== 'off'
      ? params.set('order[matriculation]', sort['matriculation'])
      : params.delete('order[matriculation]');

    navigate({
      pathname,
      search: '?' + params.toString().replaceAll('%5B', '[').replaceAll('%5D', ']'),
    });

    props.handleFilters(
      Array.from(params.entries()).reduce((result: Record<string, string[]>, [paramKey, paramVal]) => {
        const values = result[paramKey] ?? [];
        values.push(paramVal);
        result[paramKey] = values;
        return result;
      }, {})
    );
    return data;
  };

  useEffect(() => {
    if (watchIsScrappedFields) {
      setIsScrappedFieldsActive(true);
    } else {
      setIsScrappedFieldsActive(false);
    }
  }, [watchIsScrappedFields]);

  useEffect(() => {
    const shouldEventTypeFieldsBeActive =
      watchEventTypeFields && ArrayUtils.any(watchEventTypeFields, (el) => el !== null && el !== undefined);
    if (shouldEventTypeFieldsBeActive !== eventTypeFieldsActive) {
      setEventTypeFieldsActive(shouldEventTypeFieldsBeActive);
    }
  }, [watchEventTypeFields]);
  useEffect(() => {
    const shouldLinkedToFieldsBeActive =
      watchLinkedToFields && ArrayUtils.any(watchLinkedToFields, (el) => el !== null && el !== undefined);
    if (shouldLinkedToFieldsBeActive !== linkedToFieldsActive) {
      setLinkedToFieldsActive(shouldLinkedToFieldsBeActive);
    }
  }, [watchLinkedToFields]);

  useEffect(() => {
    const shouldPlannedDateFieldBeActive = ArrayUtils.any(
      watchDateNextVerificationFields,
      (item) => item !== null && item !== undefined && item !== ''
    );
    if (shouldPlannedDateFieldBeActive !== plannedDateFieldActive) {
      setPlannedDateFieldActive(shouldPlannedDateFieldBeActive);
    }
  }, [watchDateNextVerificationFields]);

  useEffect(() => {
    const hasMaterialFamily = watchMaterialFamily && watchMaterialFamily.length > 0;
    const hasSpecificMaterialCategory = watchMaterialCategory && watchMaterialCategory !== 'all';
    setMaterialFamilyFieldActive(hasMaterialFamily || hasSpecificMaterialCategory);
  }, [watchMaterialFamily, watchMaterialCategory]);

  // set params in URL
  useEffect(() => {
    // * wait for having params
    if (paramsLoaded) {
      methods.trigger();
      // onSubmit(methods.getValues());
    }
  }, [sortings, paramsLoaded]);

  // init parameters
  useEffect(() => {
    if (!paramsLoaded) {
      if (params.get('materialCategory') === null) {
        params.set('materialCategory', 'all');
      }

      initSorting();

      navigate({
        pathname,
        search: '?' + params.toString().replaceAll('%5B', '[').replaceAll('%5D', ']'),
      });
    }
  }, [paramsLoaded]);

  // * Fetch material families values
  useEffect(() => {
    if (materialFamilies.length === 0) {
      new MaterialFamilyHttp().get().then((data) => {
        setMaterialFamilies(
          data.map((item) => {
            return {
              value: item.id.toString(),
              label: item.name,
              isIpe: item.isIpe,
              isInstallation: item.isInstallation,
              isMaterial: item.isMaterial,
            };
          })
        );
      });
    }
    if (eventTypeOptions.length === 0) {
      new EventRefHttp().get().then((data) => {
        setEventTypeOptions(
          data.map((item) => {
            return { value: item.id.toString(), label: item.name };
          })
        );
      });
    }
    if (linkedToOptions.length === 0) {
      new EmployeeHttp().get({ 'group.organizationId': organizationId }).then((data) => {
        setLinkedToOptions(
          data.map((item) => {
            return { value: item.id.toString(), label: item.name };
          })
        );
      });
    }
  }, []);

  // * Initial simple param load
  useEffect(() => {
    if (materialFamilies.length === 0 || eventTypeOptions.length === 0 || linkedToOptions.length === 0) {
      return;
    }
    if (params.get('materialCategory') === null) {
      methods.setValue('materialCategory', 'all');
    } else {
      methods.setValue('materialCategory', params.get('materialCategory'));
    }
    if (params.get('materialType.materialFamily.id[]')) {
      const ids = params.getAll('materialType.materialFamily.id[]');
      const selectedMaterialFamily = materialFamilies.filter((item) => {
        return ids.includes(item.value);
      });
      methods.setValue('materialFamily', selectedMaterialFamily);
    }
    if (params.get('lastPlannedMaterialChecking.plannedDate[after]')) {
      methods.setValue(
        'minDateNextVerificationCheck',
        new Date(params.get('lastPlannedMaterialChecking.plannedDate[after]') + 'T00:00:00.000Z' || '')
      );
    }
    if (params.get('lastPlannedMaterialChecking.plannedDate[before]')) {
      methods.setValue(
        'maxDateNextVerificationCheck',
        new Date(params.get('lastPlannedMaterialChecking.plannedDate[before]') + 'T00:00:00.000Z' || '')
      );
    }
    if (params.get('lastPlannedMaterialChecking.eventRef.id[]')) {
      const ids = params.getAll('lastPlannedMaterialChecking.eventRef.id[]');
      const selectedEventType = eventTypeOptions.filter((item) => {
        return ids.includes(item.value);
      });
      methods.setValue('eventType', selectedEventType);
    }
    if (params.get('remoteEmployeeIds[]')) {
      const ids = params.getAll('remoteEmployeeIds[]');
      const selectedRemoteEmployeeIds = linkedToOptions.filter((item) => {
        return ids.includes(item.value);
      });
      methods.setValue('linkedTo', selectedRemoteEmployeeIds);
    }
    setParamsLoaded(true);
  }, [materialFamilies, eventTypeOptions, linkedToOptions]);

  function handleSortingFilter(selected: string[]) {
    const newSort: {
      'materialType.name': 'off' | 'asc' | 'desc';
      matriculation: 'off' | 'asc' | 'desc';
      'lastPlannedMaterialChecking.plannedDate': 'off' | 'asc' | 'desc';
      [key: string]: string; // for typing key and dereferencing as an usual array
    } = {
      'materialType.name': 'off',
      matriculation: 'off',
      'lastPlannedMaterialChecking.plannedDate': 'off',
    };
    for (const selection of selected) {
      newSort[selection] = (sortings[selection] as string) === 'asc' ? 'desc' : 'asc';
    }
    setSortings(() => newSort);
    onSubmit(methods.getValues(), undefined, newSort);
  }

  function initSorting() {
    const newSort: {
      'materialType.name': 'off' | 'asc' | 'desc';
      matriculation: 'off' | 'asc' | 'desc';
      'lastPlannedMaterialChecking.plannedDate': 'off' | 'asc' | 'desc';
      [key: string]: string; // for typing key and dereferencing as an usual array
    } = {
      'materialType.name': 'off',
      matriculation: 'off',
      'lastPlannedMaterialChecking.plannedDate': 'off',
    };
    params.forEach((value, key) => {
      if (key.includes('order')) {
        newSort[key.replace('order[', '').replace(']', '')] = value;
      }
    });
    setSortings(newSort);
  }

  return (
    <>
      <GridHeaderContainer>
        <Element>
          <GenericContainer>
            <FilterButton
              onClick={() => {
                handleSortingFilter(['materialType.name']);
              }}
            >
              <span className="filter-span">Type de matériel</span>
              {sortings['materialType.name'] === 'desc' ? (
                <ChevronUpBlack />
              ) : sortings['materialType.name'] === 'asc' ? (
                <ChevronDownBlack />
              ) : (
                <ChevronUpDownBlack />
              )}
            </FilterButton>
            <FilterButton
              isActive={materialFamilyFieldActive}
              onClick={(e) => {
                e.stopPropagation();
                setMaterialFamilyIsOpen(!materialFamilyIsOpen);
              }}
              id="material-family-filter"
            >
              {materialFamilyIsOpen ? <FilterFillBlue /> : <FilterBlack className="icon" />}
            </FilterButton>
            <Dropdown
              isOpen={materialFamilyIsOpen}
              attachedId="material-family-filter"
              down={true}
              menuWidth={360}
              menuPadding="19px"
              onClose={() => setMaterialFamilyIsOpen(false)}
              endYOverride={150}
              positionTweaks={dropdownPosTweaks.current}
            >
              <FormContainer>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <RadioClassic
                      name="materialCategory"
                      label="Voir seulement la catégorie:"
                      values={[
                        {
                          value: 'all',
                          label: 'Tout',
                        },
                        {
                          value: 'installation',
                          label: 'Installation',
                        },
                        {
                          value: 'materiel',
                          label: 'Matériel',
                        },
                        {
                          value: 'ipe',
                          label: 'EPI',
                        },
                      ]}
                      onChange={() => {
                        methods.setValue('materialFamily', [], { shouldTouch: true });
                      }}
                    />
                    <VSpacer size="10px"></VSpacer>
                    <Line></Line>
                    <VSpacer size="6px"></VSpacer>
                    <MultiSelect
                      label="Filtrer par famille :"
                      placeholder="Choisir une famille"
                      name="materialFamily"
                      options={materialFamilies.filter((item) => {
                        if (methods.getValues().materialCategory === 'installation') {
                          return item.isInstallation;
                        }
                        if (methods.getValues().materialCategory === 'materiel') {
                          return item.isMaterial;
                        }
                        if (methods.getValues().materialCategory === 'ipe') {
                          return item.isIpe;
                        }
                        return true;
                      })}
                    ></MultiSelect>
                    <VSpacer size="12.5px" />
                    <div className="form-buttons">
                      <Button
                        variant="bigLight"
                        callback={() => {
                          methods.setValue('materialCategory', 'all', { shouldTouch: false });
                          methods.setValue('materialFamily', [], { shouldTouch: true });
                        }}
                        type="submit"
                      >
                        Supprimer le filtre
                      </Button>
                      <Button variant="primary" icon={CheckedWhite} type="submit">
                        Appliquer
                      </Button>
                    </div>
                  </form>
                </FormProvider>
              </FormContainer>
            </Dropdown>
          </GenericContainer>
        </Element>
      </GridHeaderContainer>
      <GridHeaderContainer>
        <Element>
          <GenericContainer>
            <FilterButton onClick={() => handleSortingFilter(['matriculation'])}>
              <span className="filter-span">Immatriculation / Identification</span>
              {sortings['matriculation'] === 'desc' ? (
                <ChevronUpBlack />
              ) : sortings['matriculation'] === 'asc' ? (
                <ChevronDownBlack />
              ) : (
                <ChevronUpDownBlack />
              )}
            </FilterButton>
          </GenericContainer>
        </Element>
      </GridHeaderContainer>
      <GridHeaderContainer>
        <Element>
          <GenericContainer>
            <FilterButton
              isActive={plannedDateFieldActive}
              onClick={() => handleSortingFilter(['lastPlannedMaterialChecking.plannedDate'])}
            >
              <span className="filter-span">Date prévue</span>
              {sortings['lastPlannedMaterialChecking.plannedDate'] === 'desc' ? (
                <ChevronUpBlack />
              ) : sortings['lastPlannedMaterialChecking.plannedDate'] === 'asc' ? (
                <ChevronDownBlack />
              ) : (
                <ChevronUpDownBlack />
              )}
            </FilterButton>
            <FilterButton
              isActive={plannedDateFieldActive}
              onClick={(e) => {
                e.stopPropagation();
                setPlannedDateIsOpen(!plannedDateIsOpen);
              }}
              id="planned-date-filter"
            >
              {plannedDateIsOpen ? <FilterFillBlue /> : <FilterBlack className="icon" />}
            </FilterButton>
            <Dropdown
              isOpen={plannedDateIsOpen}
              attachedId="planned-date-filter"
              down={true}
              menuWidth={360}
              menuPadding="19px"
              onClose={() => setPlannedDateIsOpen(false)}
              endYOverride={150}
              positionTweaks={dropdownPosTweaks.current}
            >
              <FormContainer>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="form-checkbox"></div>
                    <DateField
                      name="minDateNextVerificationCheck"
                      label="Entre le"
                      placeholder="Optionnel"
                      required={false}
                      error={
                        errors &&
                        errors.minDateNextVerificationCheck &&
                        errors.minDateNextVerificationCheck.message
                      }
                    ></DateField>
                    <VSpacer size="12.5px" />
                    <DateField
                      name="maxDateNextVerificationCheck"
                      label="et le"
                      required={false}
                      placeholder="Optionnel"
                      error={
                        errors &&
                        errors.maxDateNextVerificationCheck &&
                        errors.maxDateNextVerificationCheck.message
                      }
                    ></DateField>
                    <VSpacer size="25px" />
                    <div className="form-buttons">
                      <Button
                        variant="bigLight"
                        callback={() => {
                          methods.setValue('minDateNextVerificationCheck', undefined, {
                            shouldTouch: false,
                          });
                          methods.setValue('maxDateNextVerificationCheck', undefined, {
                            shouldTouch: false,
                          });
                        }}
                        type="submit"
                      >
                        Supprimer le filtre
                      </Button>
                      <Button variant="primary" icon={CheckedWhite} type="submit">
                        Appliquer
                      </Button>
                    </div>
                  </form>
                </FormProvider>
              </FormContainer>
            </Dropdown>
          </GenericContainer>
        </Element>
      </GridHeaderContainer>
      <GridHeaderContainer>
        <Element>
          <GenericContainer>
            <span>Type d'évenement</span>
            <VSpacer size="4px" />
            <FilterButton
              isActive={eventTypeFieldsActive}
              onClick={(e) => {
                e.stopPropagation();
                setEventTypeIsOpen(!eventTypeIsOpen);
              }}
              id="event-type-filter"
            >
              {eventTypeIsOpen ? <FilterFillBlue /> : <FilterBlack className="icon" />}
            </FilterButton>
            <Dropdown
              isOpen={eventTypeIsOpen}
              attachedId="event-type-filter"
              down={true}
              menuWidth={360}
              menuPadding="19px"
              onClose={() => setEventTypeIsOpen(false)}
              endYOverride={150}
              positionTweaks={dropdownPosTweaks.current}
            >
              <>
                <FormContainer>
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                      <MultiSelect
                        name="eventType"
                        options={eventTypeOptions}
                        placeholder="Choisir un événement"
                      ></MultiSelect>
                      <VSpacer size="12.5px" />
                      <div className="form-buttons">
                        <Button
                          variant="bigLight"
                          callback={() => {
                            methods.setValue('eventType', null);
                          }}
                          type="submit"
                        >
                          Supprimer le filtre
                        </Button>
                        <Button variant="primary" icon={CheckedWhite} type="submit">
                          Appliquer
                        </Button>
                      </div>
                    </form>
                  </FormProvider>
                </FormContainer>
              </>
            </Dropdown>
          </GenericContainer>
        </Element>
      </GridHeaderContainer>
      <GridHeaderContainer>
        <Element>
          <GenericContainer>
            <span>Attribué à</span>
            <VSpacer size="4px" />
            <FilterButton
              isActive={linkedToFieldsActive}
              onClick={(e) => {
                e.stopPropagation();
                setLinkedToIsOpen(!linkedToIsOpen);
              }}
              id="attached-to-filter"
            >
              {linkedToIsOpen ? <FilterFillBlue /> : <FilterBlack className="icon" />}
            </FilterButton>
            <Dropdown
              isOpen={linkedToIsOpen}
              attachedId="attached-to-filter"
              down={true}
              menuWidth={360}
              menuPadding="19px"
              onClose={() => setLinkedToIsOpen(false)}
              endYOverride={150}
              positionTweaks={dropdownPosTweaks.current}
            >
              <>
                <FormContainer>
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                      <MultiSelect
                        name="linkedTo"
                        options={linkedToOptions}
                        placeholder="Choisir un salarié"
                      ></MultiSelect>
                      <VSpacer size="12.5px" />
                      <div className="form-buttons">
                        <Button
                          variant="bigLight"
                          callback={() => {
                            methods.setValue('linkedTo', null);
                          }}
                          type="submit"
                        >
                          Supprimer le filtre
                        </Button>
                        <Button variant="primary" icon={CheckedWhite} type="submit">
                          Appliquer
                        </Button>
                      </div>
                    </form>
                  </FormProvider>
                </FormContainer>
              </>
            </Dropdown>
          </GenericContainer>
        </Element>
      </GridHeaderContainer>
      <GridHeaderContainer>
        <Element>
          <GenericContainer>
            <span>Statut</span>
            <FilterButton
              isActive={isScrappedFieldsActive}
              onClick={(e) => {
                e.stopPropagation();
                setIsScrappedIsOpen(!isScrappedIsOpen);
              }}
              id="isScrapped-filter"
            >
              {isScrappedIsOpen ? <FilterFillBlue /> : <FilterBlack className="icon" />}
            </FilterButton>
            <Dropdown
              isOpen={isScrappedIsOpen}
              attachedId="isScrapped-filter"
              down={true}
              menuWidth={360}
              menuPadding="19px"
              onClose={() => setIsScrappedIsOpen(false)}
              endYOverride={150}
              positionTweaks={dropdownPosTweaks.current}
            >
              <FormContainer>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="form-checkbox"></div>
                    <RadioClassic
                      name="isScrapped"
                      label="Voir seulement le matériel :"
                      values={[
                        {
                          value: 'false',
                          label: 'Actif',
                        },
                        {
                          value: 'true',
                          label: 'Inactif',
                        },
                      ]}
                    />
                    <VSpacer size="12.5px" />
                    <div className="form-buttons">
                      <Button
                        variant="bigLight"
                        callback={() => {
                          methods.setValue('isScrapped', '', { shouldTouch: true });
                        }}
                        type="submit"
                      >
                        Supprimer le filtre
                      </Button>
                      <Button variant="primary" icon={CheckedWhite} type="submit">
                        Appliquer
                      </Button>
                    </div>
                  </form>
                </FormProvider>
              </FormContainer>
            </Dropdown>
          </GenericContainer>
        </Element>
      </GridHeaderContainer>
    </>
  );
}
