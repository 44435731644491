import ReactTooltip from '@huner2/react-tooltip';
import { useEffect, useRef } from 'react';
import { browserName } from 'react-device-detect';
import { unwrapNull } from '../../utilities/Assertions';
import { tooltipStyle } from '../Tooltip.style';
import {
  Cell,
  DesktopGrid,
  DesktopGridContent,
  DesktopGridHeader,
  DesktopGridHeaderContent,
  DesktopGridRow,
  Style,
} from './DataGridStyle';

interface Props {
  children: JSX.Element | JSX.Element[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format: ((rowData: any) => JSX.Element)[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  style: Style;
}

export function DataGrid(props: Props) {
  const browserClass = `browser-${browserName.toLowerCase()}`;

  //#region Add "sticky" class to header when it becomes sticky
  const header = useRef<HTMLDivElement>(null);
  const headerTop = useRef<number>();

  useEffect(() => {
    if (header.current === null) return;

    const scrollCallback = () => {
      if (headerTop.current === undefined) {
        headerTop.current = parseFloat(getComputedStyle(unwrapNull(header.current)).top.replace('px', ''));
      }

      const currentTopValue = unwrapNull(header.current).getBoundingClientRect().top;

      if (currentTopValue === headerTop.current) {
        unwrapNull(header.current).classList.add('sticky');
      } else {
        unwrapNull(header.current).classList.remove('sticky');
      }
    };

    document.addEventListener('scroll', scrollCallback);

    return () => {
      document.removeEventListener('scroll', scrollCallback);
    };
  }, [header.current]);
  //#endregion

  return (
    <>
      <DesktopGrid {...props.style} className="DesktopGrid DataGrid">
        <DesktopGridHeader ref={header}>
          <DesktopGridHeaderContent>{props.children}</DesktopGridHeaderContent>
        </DesktopGridHeader>
        <DesktopGridContent className="DataTable__DesktopGridContent">
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            props.data?.map((item: any, index: number) => (
              <DesktopGridRow key={index} className="DataTable__DesktopGridRow">
                {props.format.map((col, key) => (
                  <Cell
                    key={key}
                    className={`Cell ${browserClass}`}
                    gridColumnNumber={props.style.gridColumnNumber}
                  >
                    {col(item)}
                  </Cell>
                ))}
              </DesktopGridRow>
            ))
          }
        </DesktopGridContent>
      </DesktopGrid>
      <ReactTooltip {...tooltipStyle} />
    </>
  );
}
DataGrid.displayName = 'DataGrid';
